/**
 * Point以外のマーカーで使用
 * 
 * 元々はPointでも一部使っていたので過剰気味、役割を削減していく
 */
export class SimpleMarker {
  /**
   * @param {Object} options ?
   */
  constructor(options) {
    // console.debug("SimpleMarker", options)

    /**
     * @class {google.maps.Map}
     */
    this.map = options.map

    /**
     * @class {google.maps.LatLng}
     */
    this.position = options.position

    /**
     * DOMに付与するクラス
     */
    this.classes = options.classes ? options.classes : []

    /**
     * true: クリック可能
     */
    this.clickable = options.clickable ? options.clickable : false

    /**
     * クリック時
     */
    this.onClicked = options.onClicked ? options.onClicked : () => {}

    /**
     * true: ドラッグ可能
     */
    this.draggable = options.draggable ? options.draggable : false

    /**
     * ドラッグ終了時
     */
    this.onDraggEnd = options.onDraggEnd ? options.onDraggEnd : () => {}

    /**
     * {id, label, extra}
     */
    this.marker = options.marker || {}

    /**
     * マップ上に描画するマーカー
     * @class {google.maps.marker.AdvancedMarkerElement}
     */
    this._marker = null

    /**
     * 一意のID
     * E2Eなどで識別する用途が主
     */
    this.id = options.id ? options.id : null

    /**
     * DOM
     */
    this.div = null

    this._init()
  }

  _init() {
    // console.debug('SimpleMarker#init', this.marker)

    this.div = document.createElement('div')
    this.div.style.display = 'block'

    // アイコン / テキスト
    if (this.marker?.label) {
      const label = document.createElement('div')
      label.className = "label"
      label.innerText = this.marker.label
      this.div.appendChild(label)
    } else {
      const icon = document.createElement('div')
      icon.className = "icon"
      this.div.appendChild(icon)
    }
    
    if (this.marker?.extra) {
      const extra = document.createElement('div')
      extra.className = "extra"
      extra.innerText = this.marker.extra
      this.div.appendChild(extra)
    }

    // アンカー（Marker.vueのanchorと同じ、冗長性減らせたらうれしい）
    const override = document.createElement('div')
    override.className = "override"
    override.innerHTML = `
    <svg width="32" height="40" viewBox="0 0 32 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path class="anchor" d="M16 40C15.5 40 14.4511 37.5413 11.5 34C8.65726 30.5887 6 28.5 6 28.5C12 33 20 33 26 28.5C26 28.5 23.5 30.5 20.5 34C17.5 37.5 16.5 40 16 40Z" fill="black"/>
      <circle class="frame" cx="16" cy="16" r="16" fill="#444444"/>
      <circle class="content" cx="16" cy="16" r="15" fill="white"/>
    </svg>
    `
    this.div.appendChild(override)

    // class設定
    this.addClass("marker")
    this.addClass("on-map")
    this.addClass("one")
    this.addClass(`icon-${this.marker.id}`)

    if (this.marker.image_only) {
      this.addClass("noframe")
    } else {
      this.addClass("frame")
    }

    this.classes.forEach(c => {
      this.addClass(c)
    })

    if (this.id) {
      this.div.id = 'p' + String(this.id) // 文字にしておかないと参照時にエスケープしないと引けない
    }

    this._marker = new google.maps.marker.AdvancedMarkerElement({
      map: this.map,
      content: this.div,
      position: this.position,
      gmpClickable: this.clickable,
      gmpDraggable: this.draggable
    })

    if (this.clickable) {
      this._marker.addListener("click", () => {
        this.onClicked(this.id)
      })  
    }

    if (this.draggable) {
      this._marker.addListener('dragend', (e) => {
        this.onDraggEnd(e.latLng)
      })  
    }
  }

  getPosition() {
    return this._marker.position
  }

  /**
   * 位置を更新
   * @param {google.maps.LatLng} position 更新先の位置
   */
  setPosition(position) {
    this.position = position
    this._marker.position = position
  }

  show() {
    // console.debug('SimpleMarker#show', this.map)
    this._marker.map = this.map
  }

  remove() {
    this._marker.map = null
  }

  addClass(className) {
    this.div.classList.add(className)
  }

  removeClass(className) {
    if (!this.div) {return}
    this.div.classList.remove(className)
  }

  setMap(map) {
    this.map = map
    this._marker.map = map
  }
}


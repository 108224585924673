<template>
  <div v-if="!forceHide" class="marker" :class="{
    'one': count < 2,
    'many': count > 1,
    'noframe': icon.noframe,
    'frame': !icon.noframe,
    'image': icon.url,
    'selected': selected,
    [icon.className]: icon.className
  }" :id="'p' + String(id)" :x-data-key="dataKey" :style="{
    left: icon.x,
    top: icon.y,
    width: icon.size ? icon.size + 'px' : null,
    height: icon.size ? icon.size + 'px' : null
  }"
  >
    {{ '' /** クラスタの件数 */ }} 
    <div class="count" v-show="count > 1">
      {{ count }}
    </div>

    {{ '' /** 内部に出すアイコン、画像のみの場合はここだけ */ }} 
    <div class="icon">
      <img :src="icon.url" v-if="icon?.url">
    </div>

    {{ '' /** 付加もの、基本はなし */ }} 
    <div class="extra" v-html="extra" v-if="extra">
    </div>

    <div class="override">
      <svg width="32" height="40" viewBox="0 0 32 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="anchor" d="M16 40C15.5 40 14.4511 37.5413 11.5 34C8.65726 30.5887 6 28.5 6 28.5C12 33 20 33 26 28.5C26 28.5 23.5 30.5 20.5 34C17.5 37.5 16.5 40 16 40Z" fill="black"/>
        <circle class="frame" cx="16" cy="16" r="16" fill="#444444"/>
        <circle class="content" cx="16" cy="16" r="15" fill="white"/>
      </svg>
    </div>
  </div>
</template>

<script setup>
import { onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import MapService from '@/services/MapService.ts'

const store = useStore()
const props = defineProps({
  /**
   * クラスタの件数
   */
  count: {
    type: Number,
    default: 0
  },
  /**
   * 画像のURL
   */
  icon: {
    type: Object,
    default: {
      /**
       * 画像のURL
       */
      url: '',
      /**
       * 画像のみにする
       * TODO 命名調整
       */
      noframe: false,
      /**
       * 付与するクラス
       */
      className: '',
      /**
       * 位置補正：x [px]
       */
      x: 0,
      /**
       * 位置補正：y [px]
       */
      y: 0,
      /**
       * マーカーサイズ（px）
       * 正方形を想定、設定された場合のみ値が入る
       */
      size: null,
    }
  },
  /**
   * 装飾、HTMLのタグを指定可
   */
  extra: {
    type: String,
    default: ''
  },
  /**
   * trueの場合クラスター内マーカー
   */
   isWithinCluster: {
    type: Boolean,
    default: false
  },
  /**
   * 識別用、主にE2E用
   * DOMのidに付与する
   */
  id: {
    type: Number,
    default: 0
  },
  /**
   * 識別用、idの補助の枠
   * DOMのx-data-keyに付与する
   * 
   * keyだと予約語のためやむを得ずこの命名
   */
  dataKey: {
    type: String,
    default: ''
  },
  /**
   * 選択中
   * true: DOMのクラスにselectedを付与する
   */
  selected: {
    type: Boolean,
    default: false
  }
})

onMounted(() => {
})

/**
 * 強制的に非表示にするかどうか
 * クラスタ内マーカーを選択中、ズームインしてクラスタが分解されると描画マーカーが重複するため、片方を非表示とする
 */
const forceHide = computed(() => {
  const selectedPointId = store.getters.selectedPoint?.id
  const innerPointId = MapService?.pointManager?.innerMarker?.point.id
  const selfId = props.id

  // 次の条件は表示確定なので早期リターン > 選択中のポイントがクラスタ内マーカーではない、自身がクラスタ内マーカー
  if (selectedPointId != innerPointId || props.isWithinCluster) {    
    return false
  }

  return selectedPointId == selfId
})

</script>

<style lang="scss">
// anchorはデフォルトではDIVの真ん中。
// anchorをマーカーの下端にするには、その分ずらす必要がある。

@keyframes fade-in-marker {
  0% {
    opacity: .3;
    transform: scale(.8) translateX(-50%) translateY(-50%);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateX(-50%) translateY(-50%);
  }
}

.marker {
  box-sizing: border-box;
  position: absolute;
  background-size: contain;
  text-align: center;
  cursor: pointer;
  transition: left 500ms ease-out, top 500ms ease-out;

  &.on-map {
    transform: translate(-50%, -50%);
    animation: fade-in-marker 0.3s ease;
  }

  &.hide {
    display: none;
  }

  &.in-list-frame {
    position: relative;
    border-radius: 50%;
    border: solid 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    img {
      object-fit: contain;
      transform: scale(0.6);
    }
  }
  &.in-list-no-frame {
    position: relative;
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }

  &.one {
    .icon {
      position: absolute;
      top: 0;
      left: 0;
      transform: scale(0.4);
      width: 100%;
      height: 100%;

      background-color: #fff;
      border-radius: 38px;
      opacity: .5;
    }

    &.frame {
      color: var(--theme-color);
      width: 32px;
      height: 32px;
      margin-top: calc(32px / -2 - 6px);
      border-radius: 50%;
      background-size: 60%;
      background-repeat: no-repeat;
      background-position: center;
      transform-origin: left center;
      box-shadow: 0 5px 8px rgb(0 0 0 / 30%);

      width: $marker-one-w;
      height: $marker-one-h;
      margin-top: calc(#{$marker-one-h} / -2 - #{$marker-one-foot});

      &.image {
        background-color: #fff !important;
        .icon {
          transform: scale(0.4);
        }
      }

      .icon {
        object-fit: contain;
        position: absolute;
        top: 0;
        left: 0;
        transform: scale(0.4);
        width: 100%;
        height: 100%;
        display: block;
        z-index: 2;
      }

      &.selected {
        z-index: 100;
        animation: 1s linear infinite alternate self-ripple;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);

        &.detail-point {
          animation: none;
          transform: scale(1.2);
        }
      }

      @keyframes self-ripple {
        0% { transform: scale(1.2) translate(-50%, -50%); }
        100% { transform: scale(1.4) translate(-50%, -50%);}
      }

      .override {
        position: absolute;
        top: 0;
        z-index: 0; // 1以上だとsafariでずれる
      }
    }

    &.image {
      .override {
        svg {
          .content {
            fill: #fff;
          }
        }
      }
    }

    &.noframe {
      color: var(--theme-color);
      width: 48px;
      height: 48px;
      background: none;
      border: none;
      transform-origin: left center;
      box-shadow: none;

      width: $marker-one-noframe-w;
      height: $marker-one-noframe-h;
      margin-top: calc(#{$marker-one-noframe-h} / -2);

      .override {
        display: none;
      }

      .icon {
        object-fit: contain;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block
      }

      &.selected {
        z-index: 100;
        animation: 1s linear infinite alternate self-ripple;

        &.detail-point {
          animation: none;
          transform: scale(1.2);
        }
      }

      @keyframes self-ripple {
        0% { transform: scale(1.2) translate(-50%, -50%); }
        100% { transform: scale(1.4) translate(-50%, -50%); }
      }

      &.image {
        width: 32px;
        height: 32px;

        .icon {
          background: none;
          filter: drop-shadow(0px 2px 2px #aaa);
          width: 32px;
          height: 32px;
          transform: scale(1) !important;
          opacity: 1;
        }
      }

      &.image.on-map {
        .icon {
          width: 100%;
          height: auto;
        }
      }
    }

    &.origin {      
      background-color: $directions-color;
      border-color: $directions-color;

      .label {
        position: absolute;
        top: 0;
        bottom: calc(#{$marker-one-h});
        text-align: center;
        width: 100%;
        font-size: 1.25rem;
        line-height: 1.6em;
        color: white;
        z-index: 3;
      }

      .extra {
        transform: translate(-50%, 3rem);
      }

      .override {
        svg {
          .content {
            fill: $directions-color;
          }
          .frame {
            fill: $directions-color;
          }
          .anchor {
            fill: $directions-color;
          }
        }
      }
    }
  }

  &.many {
    // 無効化しないと一時的にズレて描画される、原因わかれば外したい
    transition: none;

    font-size: 20px;
    color: var(--text-color);
    width: $marker-many-w;
    height: $marker-many-h;
    background-color: var(--theme-color-tp70);
    border-radius: 50%;
    margin-top: calc(#{$marker-many-h} * -1);
    margin-left: calc(#{$marker-many-w} / -2);
    display: flex;
    justify-content: center;
    flex-direction: column;

    .override {
      display: none;
    }

    .icon {
      display: none;
    }

    // 内側装飾
    &:before {
      content:'';
      position: absolute;
      display: block;
      border: solid 1px #fff;
      width: calc(#{$marker-many-w} - 10px);
      height: calc(#{$marker-many-h} - 10px);
      border-radius: 50%;
      left: 4px;
      top: 4px;
    }

    // 外側装飾
    &:after {
      content:'';
      position: absolute;
      display: block;

      border: solid 2px var(--theme-color-tp70);

      width: calc(#{$marker-many-w} + 6px);
      height: calc(#{$marker-many-h} + 6px);
      border-radius: 50%;
      left: -5px;
      top: -5px;
    }

    @keyframes highlight-cluster {
      0% {
        transform: scale(1.0);
      }
      100% {
        transform: scale(1.1);
      }
    }

    &.selected {
      animation: highlight-cluster 1s linear infinite alternate;
    }
  }
}
</style>
const hasOwnProperty = Object.prototype.hasOwnProperty

export function _has(obj, key) {
  return hasOwnProperty.call(obj, key)
}

export function _debounce(func, timeout = 300){
  let timer;
  return function(...args) {
    clearTimeout(timer)
    timer = setTimeout(() => { func.apply(this, args) }, timeout)
  }
}

/**
 * 基準点からの距離順でソート
 * 配列の中に、latitudeとlongitudeがあることが前提
 * 
 * コピーを返す方が安全ではあるが、これだけで10倍は遅くなる、実用的に並び替えで問題はないはず（問題があれば戻すか、コピーの方法を検討）
 *
 * @param {google.maps.LatLng} baseLatLng 基準の緯度軽度
 * @param {Array} list ソートする配列 [{latitude, longitude}, ...]
 * @returns {Array} ソート済の配列、距離を付与する
 */
export function sortByDistance(baseLatLng, list = []) {
  // console.debug('sortByDistance', baseLatLng, list.length)

  list.sort((a, b) => {
    a.distance = google.maps.geometry.spherical.computeDistanceBetween(
      baseLatLng,
      { lat: a.latitude, lng: a.longitude }
    )

    b.distance = google.maps.geometry.spherical.computeDistanceBetween(
      baseLatLng,
      { lat: b.latitude, lng: b.longitude }
    )

    return a.distance - b.distance
  })

  return list
}

/**
 * @returns {Object} ページのクエリを辞書型で返す
 */
export function getParams() {
  const url = new URL(window.location.href)
  const paramDict = {}
  const params = url.searchParams

  for(const pair of params.entries()) {
    paramDict[pair[0]] = pair[1]
  }

  return paramDict
}

/**
 * 日本語の大文字→小文字の変換
 * @param {String} str 日本語
 * @returns 大文字→小文字に変換した文字列
 */
function convertJapaneseCharacters(str) {
  // 変換ルールの定義
  const conversionMap = {
    'あ': 'ぁ',
    'い': 'ぃ',
    'う': 'ぅ',
    'え': 'ぇ',
    'お': 'ぉ',
    'や': 'ゃ',
    'ゆ': 'ゅ',
    'よ': 'ょ',
    'つ': 'っ',
    'ア': 'ァ',
    'イ': 'ィ',
    'ウ': 'ぅ',
    'エ': 'ェ',
    'オ': 'ォ',
    'ヤ': 'ャ',
    'ユ': 'ュ',
    'ヨ': 'ョ',
    'ツ': 'ッ',
  };

  let result = str
  Object.keys(conversionMap).forEach(key => {
    result = result.replace(new RegExp(key, 'g'), conversionMap[key])
  })

  return result
}

/**
 * 正規化した文字列を返す、英数字は削除される
 * @param {String} text
 * @return {String}
 */
export function normalizeText(text) {
  if(!text) {
    return ''
  }

  text = String(text)
  text = text.normalize('NFKC')
  text = text.toLowerCase()
  text = text.replace(/ヶ/g, 'ケ')

  // カタカナをひらがなに変換
  text = text.replace(/[ァ-ヴ]/g, match => {
      return String.fromCharCode(match.charCodeAt(0) - 0x60)
  })
  text = convertJapaneseCharacters(text)
  text = text.replace(/\s/g, ' ')

  // 許可された文字以外を削除
  if (!storelocator.normalizeTextDeleteSymbols) {
    text = text.replace(/[^一-龠ぁ-んゔァ-ヴa-zA-Z0-9 ]/g, '')
  }

  return text
}

/**
 * URIをエンコードする
 * encodeURIだとエンコードされない文字を含めてエンコード
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURI#description
 * @param {string} uri エンコードするURI
 * @returns {string} エンコードされたURI
 */
export function enhancedEncodeURI(uri) {
  // 通常のエンコード
  let encodedURI = encodeURI(uri);

  // encodeURIだとエンコードされない文字をエンコード
  // ( ) → CSS background-imageでURIを使う際に含まれるとエラーになる
  // 追加でエンコードする文字があればここに追加
  encodedURI = encodedURI.replace(/[()]/g, (char) => {
    return '%' + char.charCodeAt(0).toString(16);
  });

  return encodedURI;
}

/**
 * バウンズが含まれているか
 * @param {google.maps.LatLngBounds} innerBounds 内包されるか確認する範囲
 * @param {google.maps.LatLngBounds} outerBounds 基準となる範囲
 * @returns {boolean} true: 内包される（innerBoundsがouterBoundsに）
 */
export function isBoundsContained(innerBounds, outerBounds) {
  const ne = innerBounds.getNorthEast()
  const sw = innerBounds.getSouthWest()

  return outerBounds.contains(ne) && outerBounds.contains(sw)
}

/**
 * 文字列からhtmlタグを除去する
 * @param {String} text
 * @returns {String} タグを除去した文字列
 * 例)　"駐車<b>場</b>" -> "駐車場"
 */
export function removeHtmlTags(text) {
  if (text.match(/<[^>]+>/)) {
    const el = document.createElement('div')
    el.innerHTML = text
    return el.textContent
  } else {
    return text
  }
}


<template>
  <div class="keyword-searcher">
    {{ '' /** 右側に表示するアイコン */ }}
    <button
      v-if="isCompleted"
      class="material-symbols-sharp uk-form-icon uk-form-icon-flip clear-word"
      data-icon="close_small"
      @click="onResetClicked">
    </button>
    <button
      v-else-if="instantMode && query.length > 0"
      class="material-symbols-sharp uk-form-icon uk-form-icon-flip clear-word-instant"
      data-icon="close_small"
      @click="onResetClicked">
    </button>
    <span v-else-if="rightIcon"
      class="uk-form-icon uk-form-icon-flip"
      v-on:click="onEnterKeywordInput"
      uk-icon="icon: search"
    />

    {{ '' /** 左側に表示するアイコン */ }}
    <span
      v-if="!rightIcon"
      :class="{'completed': isCompleted}"
      class="material-symbols-sharp uk-form-icon prefix-search-icon"
      :data-icon="isCompleted ? 'search_check' : 'search'"
    />

    <input
      :class="{'completed': isCompleted}"
      class="keyword-input uk-input"
      type="text"
      :placeholder="placeholder"
      @keydown.enter="onEnterKeywordInput"
      @input="onChangeInput"
      enterkeyhint="search"
      v-model="query"
    >

  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'

const props = defineProps({

  /**
   * キーワード検索が有効かどうか
   * この値の影響は見た目だけで、動作には影響しない
   */
  isCompleted: {
    type: Boolean,
    default: false,
  },
  /**
   * プレースホルダー
   * 多言語化するときは注意
   */
  placeholder: {
    type: String,
    default: storelocator.messages.points_keyword_placeholder,
  },
  /**
   * 右側に検索アイコンを表示するかどうか
   */
  rightIcon: {
    type: Boolean,
    default: false,
  },

  /**
   * 入力で即時実行されるかどうか
   * trueにすると入力時に onEnterKeywordInput が呼ばれる
   */
  instantMode: {
    type: Boolean,
    default: false,
  },
  /**
   * trueの場合、storeのqueryを監視して同期する
   * 主に絞り込みリセット実行時の同期用
   */
  syncWithStore: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['onEnterKeywordInput'])
const messages = computed(() => storelocator.messages)

const isChangedQuery = ref(false)

/**
 * キーワード検索につかうクエリ
 */
const query = ref("")

if(props.syncWithStore) {
  const store = useStore()
  watch(() => store.state.filters.query, (newQuery) => {
    query.value = newQuery
  })
}

function onEnterKeywordInput(e) {
  if (e.isComposing) return
  emit('onEnterKeywordInput', query.value)
}

function onResetClicked() {
  query.value = ""
  emit('onEnterKeywordInput', query.value)
}

/**
 * 入力されたことを検知する
 * @param {InputEvent} e
 */
function onChangeInput(e) {
  isChangedQuery.value = true
}

/**
 * 即時検索が有効であれば700ms置きに入力チェックし、変更あればemitを呼ぶ
 * NOTE:
 *   1.5から一部流用、特に理由なければonChangeInputでデバウンスしてもいいかも
 *   onEnterKeywordInputの名称は若干齟齬あるがemit増やすのも微妙なので流用
 */
if(props.instantMode) {
  setInterval(() => {
    if (!isChangedQuery.value) {
      return
    }
    emit('onEnterKeywordInput', query.value)
    isChangedQuery.value = false
  }, 700)
}

onMounted(async() => {
})

</script>

<style lang="scss">
.keyword-searcher {
  width: 100%;
  position: relative;
  transition: all .2s;

  &:focus-within {
    margin: 0 -2px 0 -2px;
    width: calc(100% + 4px);

    .uk-form-icon {
      color: #ccc;
      transition: all .25s;
      &:hover {
        color: #fff;
      }
    }
  }
  .uk-form-icon.completed {
    color: #fff;
  }
  .keyword-input {
    width: 100%;
    background: #eee;
    border: none;
    border-radius: 20px;
    padding-left: 16px;
    transition: all .25s;

    &:focus {
      background: #777;
      color: #fff;
      transition: all .25s;

      &::placeholder {
        color: #aaa;
      }
    }
    &.completed {
      background-color: var(--theme-color);
      color: var(--text-color);
    }
  }
  .clear-word {
    background: none;
    border: none;
    color: #fff;
    width: 40px !important;
  }
  .clear-word-instant {
    background: none;
    border: none;
  }
}

.points-page .filters .filter .keyword-searcher button.clear {
  display: inline;
  border: none;
  padding: 0;
  margin: 0;
  background: none;
  position: absolute;
  cursor: pointer;
}
</style>

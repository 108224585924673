<template>
  <!-- 親 -->
  <ul v-if="hasChildren && filter.id !== Filters.BASE_MARKER_ID"
    class="filter-parent uk-nav-default uk-nav-parent-icon"
    :class="{
      'small': depth > 1,
      'marker-container': type === Filters.TYPE_MARKERS,
      'search-condition-container': type !== Filters.TYPE_MARKERS,
      'not-passed': !isPointPassedFilter(filter),
      'hidden': showPassedOnly && !isPointPassedFilter(filter)
    }"
    :data-id="(type === Filters.TYPE_MARKERS ? 'marker-group-' : 'search-condition-group-') + filter.id"
    v-bind="getFilterAttribute(filter)"
    uk-nav>
    <li class="filter parent" :class="{ 'uk-parent': hasChildren, 'uk-open': opened }">
      <a class="group uk-parent uk-flex uk-flex-middle toggle-open" :class="{'selected': isSelectedChildren}" v-wave>
        <!-- グループ選択 -->
        <template v-if="selectableGroup && selectable !== false">
          <!-- マーカー -->
          <div v-if="type === Filters.TYPE_MARKERS" class="filter-icon-wrapper icon" @click.stop="onFilterClicked(filter)">
            <div v-if="filter.image?.url" class="uk-height-1-1 uk-width-1-1 uk-background-contain" :data-src="filter.image?.url" uk-img="loading: lazy"></div>
            <i v-else class="material-symbols-sharp vertical-middle filter-icon filled" :class="{'selected': isSelectedChildren}" data-icon="location_on"></i>
          </div>

          <!-- 検索条件 -->
          <div v-else class="filter-icon-wrapper icon" @click.stop="onFilterClicked(filter)">
            <!-- ここのアイコンは現状、デザイン管理での制御は不可 -->
            <div v-if="filter.image?.url" class="uk-height-1-1 uk-width-1-1 uk-background-contain" :data-src="filter.image?.url" uk-img="loading: lazy"></div>
            <i v-else class="material-symbols-sharp filter-icon filled" data-icon="check_circle" :class="{'selected': isSelectedChildren}"></i>
          </div>
        </template>

        <!-- 名称 -->
        <slot name="filter-group-name" :filter="filter" :selectable="selectable">
          <p class="name" v-html="filter.show_only.name" v-if="selectable === false && filter.show_only?.name"></p>
          <p class="name" v-html="filter.name" v-else></p>
        </slot>

        <!-- 展開 -->
        <span uk-nav-parent-icon class="toggle"></span>
      </a>

      <!-- 子 -->
      <ul class="children">
        <!-- 説明 -->
        <li class="description-wrapper">
          <p class="description"
            v-if="selectable !== false && filter.description"
            v-html="parse(filter.description)">
          </p>
          <p class="description"
            v-if="selectable === false && filter.show_only?.description"
            v-html="parse(filter.show_only.description)">
          </p>
        </li>

        <!-- 絞り込み -->
        <li v-if="enabledFiltersFilter" class="children-filter-wrapper">
          <div class="children-filter">
            <a v-if="filterQuery" class="uk-form-icon uk-form-icon-flip" @click="onFilterQueryResetClicked" uk-icon="icon: close"></a>
            <input class="uk-input" type="text" ref="filterQueryInput" v-model="filterQuery" :placeholder="messages.common_filter_query_hint" @change="onFilterQueryChanged" />
          </div>
        </li>

        <li class="filter child" v-for="child in filter.children" :class="{
          ['filter-id-' + child.id]: true,
          'child-group': child.children,
          'not-passed': !child.children && !isPointPassedFilter(child),
          'hidden': showPassedOnly && !isPointPassedFilter(child) || (enabledFiltersFilter && !showFilter(child))
        }"
        v-bind="getFilterAttribute(child)">
          <Filter
            :type="type"
            :filter="child"
            :depth="depth + 1"
            :opened="child.opened || selectable === false"
            :selectable="selectable"
            :point="point"
            :show-passed-only="showPassedOnly"
            :filterQueriesFromParent="!enabledFiltersFilter || showAllChildren(filter) ? [] : filterQueries">
            <template #filter-info="props">
              <slot name="filter-info" :filter="props.filter" :selectable="props.selectable"></slot>
            </template>
            <template #filter-group-name="props">
              <slot name="filter-group-name" :filter="props.filter" :selectable="props.selectable"></slot>
            </template>
          </Filter>
        </li>

        <!-- 全て絞り込まれた場合に表示 -->
        <li class="empty" v-if="enabledFiltersFilter && !showAllChildren(filter) && !isContainedFilterQueryInChildren(filter)">
          {{ messages.map_not_found_filter }}
        </li>
      </ul>
    </li>
  </ul>

  <!-- 子 -->
  <component v-else class="filter-child"
    :class="{
      ['filter-id-' + filter.id]: true,
      'filter-marker': type === Filters.TYPE_MARKERS,
      'filter-search-condition': type !== Filters.TYPE_MARKERS,
      'root':depth === 1,
      'selected': isSelected,
      'link': selectable === false && filter.link?.url,
      'no-link': selectable === false && !filter.link?.url
    }"
    :is="selectable === false && filter.link?.url ? 'a' : 'div'"
    :href="selectable === false && filter.link?.url ? getFilterUrl(filter) : ''"
    :target="selectable === false && filter.link?.new_tab ? '_blank' : ''"
    :data-id="(type === Filters.TYPE_MARKERS ? 'marker-' : 'search-condition-') + filter.id"
    @click="onFilterClicked(filter)"
    v-show="isShowable(filter)"
    v-wave>

    <div class="switch-container uk-flex uk-flex-middle">
      <!-- マーカーは地図と同じものを出す -->
      <Marker :icon="getMarkerIcon(filter)" v-if="type === Filters.TYPE_MARKERS"></Marker>

      <!-- 検索条件：選択可：指定アイコンか絞り込み -->
      <div v-else-if="selectable !== false" class="filter-icon-wrapper icon">
        <div v-if="filter.image?.url" class="uk-height-1-1 uk-width-1-1 uk-background-contain" :data-src="filter.image?.url" uk-img="loading: lazy"></div>
        <i v-else class="material-symbols-sharp filter-icon filled" data-icon="check_circle" :class="{'selected': isSelected}"></i>
      </div>
      <!-- 検索条件：選択不可：指定アイコン -->
      <div v-else-if="selectable === false && filter.image?.url" class="filter-icon-wrapper icon">
        <div v-if="filter.image?.url" class="uk-height-1-1 uk-width-1-1 uk-background-contain" :data-src="filter.image?.url" uk-img="loading: lazy"></div>
      </div>

      <p class="name" v-html="filter.name"></p>

      <div class="icon for-link" v-if="selectable === false && filter.link?.url">
        <i class="material-symbols-sharp" data-icon="open_in_new"></i>
      </div>
    </div>

    <!-- 説明 -->
    <p class="description"
      v-if="selectable !== false && filter.description"
      v-html="parse(filter.description)">
    </p>
    <p class="description"
      v-if="selectable === false && filter.show_only?.description"
      v-html="parse(filter.show_only.description)">
    </p>
  </component>

  <slot name="filter-info" :filter="filter"></slot>

  <div class="filter-notify" v-if="notifications[filter.id]">{{ messages.common_filter_selected }}</div>

</template>

<script setup lang="ts">
import { computed, InputHTMLAttributes, ref } from 'vue'
import { useStore } from 'vuex'

import { Filters } from "@/services/Filters.ts"
import Marker from "@/components/common/Marker.vue"
import { normalizeText, removeHtmlTags } from "@/utils/common"

import Filter from "./Filter.vue"


const props = defineProps({
  /**
   * Filters.TYPE_...
   */
  type: {
    type: String,
    default: Filters.TYPE_MARKERS
  },
  /**
   * Filter
   */
  filter: {
    type: Object,
    default: null
  },
  /**
   * true: 子を展開
   * 初期化のときのみ反映される、以降の開閉後に追従はしないので注意
   */
  opened: {
    type: Boolean,
    default: false
  },
  /**
   * 階層の深さ
   */
  depth: {
    type: Number,
    default: 1
  },
  /**
   * Filters.selectable
   *
   * 未設定だとtrueにならない？ので、falseと明示的に比較するように注意
   */
  selectable: {
    type: Boolean,
    default: true
  },
  /**
   * Point視点で見る時にわたす
   * 指定した場合、そのPointに該当するFilterのみ強調・もしくは表示に調整可能にする
   */
  point: {
    type: Object,
    default: () => {}
  },
  /**
   * pointと一緒に使う
   * true: pointが指定されているとき、pointが該当するFilterのみ非表示
   */
  showPassedOnly: {
    type: Boolean,
    default: true
  },
  /**
   * 親の絞り込み用検索文字
   * 親が複数存在する場合もあるため配列で受け取る
   * 例) ["症状・悩みから", "胃腸"]
   */
  filterQueriesFromParent: {
    type: Array,
    default: []
  }
})

const store = useStore()

/**
 * 通知用
 * Filter.id: true
 * 表示中のFilterの分をいれておく
 *
 * filterに直接生やすと潰されるので、別に管理（マーカーだけが潰されるのは謎）
 */
const notifications = ref<{[key: string]: boolean}>({})

/**
 * 絞り込みを絞り込み用の検索文字
 * 例) "六君子湯"
 */
const filterQuery = ref<string>("")

/**
 * 絞り込みを絞り込み用の入力欄
 */
const filterQueryInput = ref<InputHTMLAttributes>(null)

/**
 * true: 絞り込みを絞り込みが有効
 */
const enabledFiltersFilter = computed(() => {
  return props.type === Filters.TYPE_SEARCH_CONDITIONS
    && storelocator.filters?.filter?.enabled
    && storelocator.filters?.filter?.fields?.length
    && props.selectable !== false
})

/**
 * true: グループを選択可
 * Point・Filterがあまりにも多い場合は計算コストが高いので、無効化可能にしておく
 */
const selectableGroup = computed(() => storelocator.filters?.groups?.selectable)

/**
 * true: このFilterを表示
 */
const isShowFilter = computed(() => (filter: Filter) =>  Filters.findById(props.type, filter.id)?.is_show)

/**
 * 絞り込みを絞り込み用の検索文字配列
 * 親の検索文字配列と検索文字を合わせたもの
 * 例) ["症状・悩みから", "胃腸", "六君子湯"]
 */
 const filterQueries = computed(() => {
  let queries = props.filterQueriesFromParent
  if (filterQuery.value) {
    return [...queries, filterQuery.value]
  } else {
    return queries
  }
})

/**
 * 正規化した検索文字配列
 * スペース区切りを考慮する
 */
const normalizedFilterQueries = computed(() => {
  let queries: string[] = []
  filterQueries.value.forEach(query => {
    queries = [...queries, ...query.split(/\s+/).map(query => normalizeText(query))]
  });
  return queries
})

/**
 * フィルターの表示判定
 * path.filters, queryがある時はその中の要素だけにする
 * @params filter
 * @returns 表示:true
 */
function isShowable(filter: Filter): boolean {
  // console.debug("isShowable", props.type, filter)

  // path, queryによる判定
  // 今はマーカーだけ、検索条件も絡んできたら判定を変える必要ある
  if (props.type === Filters.TYPE_MARKERS
    && storelocator.path?.filters
    && storelocator.path?.config[storelocator.path?.id]?.show_filtered_only) {
    return storelocator.path.filters.map(f => f.id).includes(filter.id)
  }

  if (props.type === Filters.TYPE_MARKERS
    && storelocator.query?.filter_ids
    && storelocator.query?.config[storelocator.query?.id]?.show_filtered_only) {
    return storelocator.query.filter_ids.map(f_id => f_id).includes(filter.id)
  }

  if (filter.is_show === false) {
    return false
  }

  return true
}

/**
 * 改行コードを<br>に変換
 * @param text 改行コードが含まれる可能性がある文字列
 * @returns 改行コードを<br>に変換した文字列
 */
function parse(text: string): string {
  return text.replace(/\n/g, "<br>")
}

/**
 * 子要素の判定
 */
const hasChildren = computed(() => {
  return props.filter.children && props.filter.children.length > 0
})

/**
 * 選択状態の判定
 */
const isSelected = computed(() => {
  const propName = props.type === Filters.TYPE_MARKERS ? "selectedMarkers" : "selectedSearchConditions"
  return store.state.filters[propName].includes(props.filter.id)
})

/**
 * true: 子を選択中
 */
const isSelectedChildren = computed(() => {
  if (!props.filter.children || props.filter.children.length === 0) {
    return false
  }

  if (isSelected.value) {
    return isSelected.value
  }

  const propName = props.type === Filters.TYPE_MARKERS ? "selectedMarkers" : "selectedSearchConditions"
  const children = Filters.findChildrenById(props.type, props.filter.id)
  const result = children.some(child => {
    return store.state.filters[propName].includes(child)
  })

  if (result) {
    return true
  }

  return false
})

/**
 * 選択したフィルタを知らせる
 * グループは通知しない（上の部品に被って隠れてしまう）
 *
 * @param filter Filter
 */
function notifySelectedFilter(filter: Filter) {
  // console.debug("notifySelectedFilter", filter.id, filter.name)

  if (filter.children) {
    return
  }

  notifications.value[filter.id] = true

  setTimeout(() => {
    notifications.value[filter.id] = false
  }, 750) // CSSのアニメーション完了より若干早く消す（タイミングがずれると初期表示に戻る瞬間が見えてガクつく）
}

/**
 * リンクを取得
 * Pointの属性を埋め込むあたりを担当
 *
 * @param filter リンクを取得するフィルタ
 * @returns リンクのURL
 */
function getFilterUrl(filter: Filter) {
  if (!props.point) {
    return filter.link?.url || ""
  }

  // 差し込み
  Object.keys(props.point).forEach(key => {
    filter.link.url = filter.link.url.replace(`{{${key}}}`, props.point[key])
  })
  Object.keys(props.point.extra_fields).forEach(key => {
    filter.link.url = filter.link.url.replace(`{{${key}}}`, props.point.extra_fields[key])
  })

  return filter.link?.url || ""
}

/**
 * 絞り込みを選択
 *
 * 何を選択したか明示する
 * @param filter Filter
 * @param parentId 指定すると親の選択状態を更新する
 */
function onFilterClicked(filter: Filter, parentId: string = null) {
  // console.debug("onFilterClicked", filter.id, filter.name, props.selectable)
  if (props.selectable === false) {
    return
  }

  const propName = props.type === Filters.TYPE_MARKERS
    ? "selectedMarkers"
    : "selectedSearchConditions"

  const alreadySelected = store.state.filters[propName].includes(filter.id)

  const isParent = parentId ? true : false
  const setId = parentId ? parentId : props.filter.id

  store.dispatch("changeFilter", {
    type: props.type,
    id: setId,
    parent: isParent,
    noApply: false
  })

  // 何をしたか明示（親選択は特殊な付随処理なので除外）
  if (!parentId && !alreadySelected) {
    notifySelectedFilter(filter)
  }

  // 以下のケースで親を更新
  // ・親が選択中で、兄弟のいずれかが未だ選択
  // ・親が未選択で、兄弟が全選択

  const parent = Filters.findParentById(props.type, setId)
  if (!parent) {
    return
  }

  const siblingIds = parent.children.map(p => p.id)
  const checkSiblingIdsSelect = siblingIds.map(sid => {
    return store.state.filters[propName].includes(sid)
  })

  if ( (store.state.filters[propName].includes(parent.id) && checkSiblingIdsSelect.includes(false))
    || (!store.state.filters[propName].includes(parent.id) && !checkSiblingIdsSelect.includes(false))
  ) {
    onFilterClicked(filter, parent.id)
  }
}
/**
 * 絞り込みを絞り込み用の検索文字をリセット
 */
function onFilterQueryResetClicked() {
  filterQuery.value = ''
  filterQueryInput.value.focus()
}

/**
 * 絞り込みの絞り込み用の検索文字の入力確定時（@inputだと過剰送信になる）
 */
function onFilterQueryChanged() {
  // console.debug("onFilterQueryInput", filterQuery.value)

  storelocator.analytics.client?.send('SearchFilter', {
    SearchFilterQuery: filterQuery.value
  })
}

/**
 * props.pointが指定Filterを満たすか判定
 * @param filter
 * @returns true: 満たす
 */
function isPointPassedFilter(filter: Filter): boolean {
  // props.pointが未指定なら弾く必要がないので通す
  if (!props.point) {
    return true
  }

  // グループは子まで見る
  // 子にprops.pointに該当するFilterが１つでも見つかれば通す
  if (filter.children) {
    let foundAtLeastOne = false
    Filters.findChildren(filter).forEach(child => {
      if (isPointPassedFilter(child)) {
        foundAtLeastOne = true
      }
    })

    return foundAtLeastOne
  }

  // Pointに該当しなければ弾く
  if (!Filters.checkPointByFilter(props.point as Point, filter).passed) {
    return false
  }

  return isShowFilter.value(filter)
}

/**
 * 絞り込みの絞り込みを踏まえて検索条件の表示判定をする
 * @param filter
 * @returns true: 表示する
 */
function showFilter(filter: Filter): boolean {
  return (
    filterQueries.value.length === 0 ||
    normalizedFilterQueries.value.length === 0 ||
    isContainedFilterQuery(filter) ||
    isContainedFilterQueryInChildren(filter)
  )
}

/**
 * 自分のプロパティのみに検索文字配列が含まれる場合に全ての子孫を表示する
 * @param filter
 * @returns true: 表示する
 */
function showAllChildren(filter: Filter): boolean {
  return isContainedFilterQuery(filter) && !isContainedFilterQueryInChildren(filter)
}

/**
 * FilterのプロパティにfilterQueryが含まれるかを判定する
 * @param filter
 * @returns true: 含まれる
 */
function isContainedFilterQuery(filter: Filter): boolean {
  let joined = ''
  storelocator.filters?.filter?.fields?.forEach((key: string) => {
    const field = filter[key as keyof Filter]
    const extraField = filter.extra_fields?.[key]
    if (field != undefined) {
      joined += field
    } else if (extraField != undefined) {
      joined += extraField
    }
  })
  joined = normalizeText(removeHtmlTags(joined))
  return normalizedFilterQueries.value.every(query => joined.indexOf(query) !== -1)
}

/**
 * FilterのchildrenのプロパティいずれかにfilterQueryが含まれるかを再帰的に判定する
 * @param filter
 * @returns true: 含まれる
 */
function isContainedFilterQueryInChildren(filter: Filter): boolean {
  let isContained = false
  if (!filter.children) {
    return isContained
  }
  for(const child of filter.children) {
    if (child.children && child.children.length > 0) {
      isContained = isContainedFilterQueryInChildren(child)
    } else {
      isContained = isContainedFilterQuery(child)
    }
    if (isContained) {
      break
    }
  }
  return isContained
}

/**
 * Filterの属性を取得
 * props.pointに該当しないものは検索エンジンに拾わせたくない
 * @param filter
 */
function getFilterAttribute(filter: Filter): {[key: string]: boolean} {
  if (isPointPassedFilter(filter)) {
    return {}
  }

  return {
    'data-nosnippet': true
  }
}

/**
 * マーカーのアイコン描画用
 *
 * TODO 他でも使うと思われるので、共通化の余地がある
 * @param marker Filter
 * @returns Marker#props.icon
 */
function getMarkerIcon(marker: Filter): {url: string, noframe: boolean, className: string} {
  return {
    url: marker.image?.url,
    noframe: marker.image_only,
    className: `icon-${marker.id}`
  }
}

const messages = computed(() => storelocator.messages)

</script>

<style lang="scss" scoped>

@keyframes filters-fade-in {
  0% {
    opacity: 0;
    transform: translateX(-10%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.filter-parent {
  margin: 1rem;
  background-color: rgba(180, 180, 180, 0.1);
  border-radius: 2px;
  &:last-child {
    border-bottom: none
  }

  &.small {
    margin: 8px 8px 8px 8px;
    background-color: rgba(200, 200, 200, 0.2);
  }

  .group {
    border-radius: 2px 2px 0 0;
    .filter-icon {
      color: #444;
      opacity: .3;
    }
    .name {
      transition: all .3s ease-out;
      color: #444;
    }
    &.selected {
      .name {
        margin: 0 0 0 0.25rem;
      }
      .filter-icon {
        color: var(--theme-color);
        opacity: 1;
        transition: .5s;
      }
    }
    .toggle {
      margin-right: 12px;
      color: #444;
    }
  }

  .filter {
    > .children {
      padding: 0 0 1px 0 !important;
    }

    &.not-passed {
      opacity: .5 !important;
      .filter-child {
        background-color: #ccc;
      }
    }

    &.hidden {
      display: none;
    }
  }

  &.hidden {
    display: none;
  }

  .toggle-open {
    padding: 8px !important;
    color: inherit;
    &:hover {
      text-decoration: none !important;
    }

    &.selected {
      background-color: var(--theme-color-tp05);
    }
  }
}

// ルートに置かれる場合もある
.filter, .filters {
  cursor: pointer;
  position: relative;
  width: 100%;
  .marker {
    position: relative;
    transform: scale(0.7) translateY(35%);
  }
  a {
    color: #444 !important;
  }

  .filter-child {
    display: block;
    margin: 8px .5rem 8px .5rem;
    padding: 6px 6px 5px 8px;
    border-radius: 3px;
    cursor: pointer;
    text-decoration: none !important;
    &.root {
      margin: 4px 18px;
    }
    &.no-link {
      cursor: default;
    }
    .icon, .marker {
      opacity: .3;
    }
    .name {
      transition: all .3s ease-out;
      opacity: .7;
    }
    &.selected {
      background-color: var(--theme-color-tp05);
      border-left: var(--theme-color) solid 3px;
      .name {
        margin: 0 0 0 0.25rem;
        opacity: 1;
      }
      .icon, .marker {
        opacity: 1;
        transition: .5s;
      }
    }
    &.link {
      text-decoration: none;
      .icon {
        &.for-link {
          color: #444;
          display: ruby; // 干渉しなければなんでもいい
          margin: 0 -0.25rem 0 0.5rem;
          opacity: .5 !important;
          position: relative;
          transition: all .25s;
          i {
            font-size: 1.2rem;
            position: absolute;
            top: 2px;
            left: 2px;
          }
        }
      }
      &:hover {
        background-color: var(--theme-color);
        border-color: var(--theme-color) !important;
        color: var(--text-color);
        transition: all .25s;
        * {
          cursor: pointer !important;
        }
        .name {
          color: var(--text-color);
        }
        .icon {
          color: var(--text-color);
          &.for-link {
            display: ruby;
            margin: 0 -0.25rem 0 0.75rem;
            opacity: 1 !important;
          }
        }
      }
    }
    .switch-container {
      width: 100%;
      .marker {
        .icon {
          width: 32px;
        }
      }
    }
    &.filter-marker {
      padding: 12px 6px 11px 8px;
    }
    .description {
      margin: 0;
      padding: 1rem 1rem 0 0;
      display: block;
    }
  }
  .filter {
    max-width: 100%;
    .description-wrapper{
      border-bottom: solid 1px #ddd;
      >.description {
        margin: 0.5rem 0 0.75rem 0;
        padding: 0 0.75rem 0 0.75rem;
        display: block;
      }
    }
    .children-filter-wrapper {
      margin: 0.5rem 0 0.75rem 0;
      padding: 0 0.75rem 0 0.65rem;
      display: block;
      .children-filter {
        position: relative;
        > a {
          color: #999 !important;
          &:hover {
            color: #666 !important;
          }
        }
        > input {
          background-color: unset;
          border-bottom: solid 1px #ddd;
        }
      }
    }
    .empty {
      margin: 8px .5rem 8px .5rem;
      padding: 6px 6px 5px 8px;
      text-align: center;
    }
  }
}

// 最上位の絞り込み欄以外を非表示に
// NOTE: テンプレート側で制御できそうだが、ひとまず
.filter .filter .children-filter-wrapper {
  display: none !important;
}

.name {
  display: block;
  margin: 0;
  font-size: .875rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 40px);
}

.filter-icon {
  color: inherit;
  transition: color .25s;
  &.selected {
    color: var(--theme-color);
  }
}

@keyframes filter-notify-in-out {
  0% {
    opacity: 0;
    transform: translateY(10%);
  }
  30% {
    opacity: 1;
    transform: translateY(0%);
  }
  70% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 0;
    transform: translateY(-10%);
  }
}

.filter-notify {
  position: absolute;
  top: -3.25rem;
  left: 60%;
  right: -.5rem;
  background-color: #444;
  border: 1px solid #444;
  color: #fff;
  padding: 0.25rem .8rem;
  text-align: left;
  animation: filter-notify-in-out .8s ease-in-out;
  z-index: 1000;
  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: #444 transparent transparent transparent;
  }
}

/** アイコン名が検索エンジンに拾われるのを防ぐ */
.material-symbols-sharp {
  &::before {
    content: attr(data-icon);
  }
}
</style>

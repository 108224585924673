export const Panel = {
  /**
   * 展開や表示対象の状態
   */
  States: {
    /**
     * 閉じ
     */
    CLOSED: 0,
    /**
     * 開き
     */
    OPENED: 2,
  }
}

/**
 * ウィンドウサイズ
 */
export const WindowSize = {
  LARGE: "L",
  MEDIUM: "M",
  SMALL: "S",
}

/**
 * 現在地の取得状況
 */
export const LocationStatus = {
  /**
   * 未取得
   */
  NONE: 0,
  /**
   * 取得中
   */
  LOADING: 1,
  /**
   * 取得失敗
   */
  FAILED: 9,
}

/**
 * 駅の取得状況
 */
export const StationsStatus = {
  /**
   * 読み込み前
   */
  NONE: 0,
  /**
   * 読み込み中
   */
  LOADING: 1,
  /**
   * 読み込み後
   */
  LOADED: 2
}
import {format, parse} from 'date-fns';

const hasOwnProperty = Object.prototype.hasOwnProperty

export function _has(obj, key) {
  return hasOwnProperty.call(obj, key)
}

export function _debounce(func, timeout = 300){
  let timer;
  return function(...args) {
    clearTimeout(timer)
    timer = setTimeout(() => { func.apply(this, args) }, timeout)
  }
}

/**
 * deep copy（簡易）
 * @param {*} obj 関数を含まないオブジェクト
 * @returns deep copy
 */
export function deepClone(obj) {
  if (!obj) {
    return obj
  }
  return JSON.parse(JSON.stringify(obj))
}

/**
 * 配列の特定位置に移動
 * @param {Array} array 移動させる配列
 * @param {Number} index 元の位置
 * @param {Number} at 移動先
 * @returns 
 */
export function moveAt(array, index, at) {
  if (index === at || index > array.length -1 || at > array.length - 1) {
    return array;
  }

  const value = array[index];
  const tail = array.slice(index + 1);

  array.splice(index);

  Array.prototype.push.apply(array, tail);

  array.splice(at, 0, value);

  return array;
}

/**
 * 末尾を取得
 * @param {Array} array 
 * @returns 末尾
 */
export function atLast(array) {
  return array.slice(-1)[0]
}

/**
 * 日付を加工
 * 秒まで必要なケースはないのでそれを省く
 * 将来的にはタグまで踏み込みたい（もしくはコンポーネント化）、とりあえず超単純に
 * @param {String} date yyyy-mm-dd hh:mm:ss
 * @returns yyyy-mm-dd hh:mm, 必要な長さがなければそのまま帰す
 */
export function formatDate(date) {
  if (!date) {
    return null
  }

  if (date.length < 16) {
    return date
  }

  return date.substring(0, 16).replaceAll('-', '/')
}

/**
 * @returns 社内アカウントならtrue
 */
export function isInternalUser() {
  if (!goga?.user?.email) {
    return false
  }

  return goga.user.email.split('@')[1] === 'goga.co.jp'
}

/**
 * @returns nameで指定したCookieの値を返す、取れなければnull
  */
export function getCookie(name) {
  const value = "; " + document.cookie
  const parts = value.split("; " + name + "=")

  if (parts.length === 2) {
    return parts.pop().split(";").shift()
  }

  return null
}

/**
 * 0埋め
 * @param {Number} num 0埋めしたい数値
 * @param {Number} length 桁数
 * @returns 0埋めした文字列
 */
export function zeroPadding(num, length){
	return ( Array(length).join('0') + num ).slice( -length );
}

/**
 * 文字列を数値に変換
 * @param {String} value 数値の文字列
 * @reutrns {Number} 数値
 */
export function toNumberIfString(value) {
  return (typeof value === 'string' && !isNaN(value)) ? Number(value) : value
}
import { createStore } from 'vuex'

import MapService from '@/services/MapService.ts'
import { Filters } from '@/services/Filters.ts'
import PointService from '@/services/PointService.ts'
import { sortByDistance } from "@/utils/common"

import { Panel, WindowSize, StationsStatus } from '@/constants'
import api from '@/api.ts'


export default createStore({
  state: {
    /**
     * WindowSize
     */
    windowSize: WindowSize.SMALL,
    /**
     * マップに関する処理が走っているかどうかを判断するためのもの
     * 1以上なら何かが進行している＝ローディング出す等する、といった使い方
     */
    mapReady: 0,
    /**
     * マップページ用をまとめていく
     */
    map: {
      /**
       * 探すパネル
       */
      panel: {
        state: Panel.States.CLOSED,
      }
    },
    /**
     * Pointまわり
     * マップを使うページ（MapとPoint）で共通して使う
     */
    points: {
      /**
       * pointsInBounds用の取得を行ったらtrueにする
       * 店舗取得前に店舗がない表記を出したくないので、その制御用
       */
      initialized: false,
      /**
       * 地図の表示範囲内のPoint
       * 絞り込みは適用済、並び替えは未適用なので注意
       */
      pointsInBounds: [],
      /**
       * 近くのPoint
       * pointsInBoundsを地図の中心or選択中の店舗から近い順に一定件数抽出したもの
       */
      nearPoints: [],
      /**
       * 表示クラスタ内のPoint
       * TODO 中身不明
       */
      pointsInCluster: [],
      /**
       * 選択中のPoint
       * TODO 中身不明
       */
      selected: null,
      /**
       * 選択中のクラスタID
       * TODO 中身不明
       */
      selectedClusterId: null,
    },
    /**
     * Pointページ用
     */
    point: {
      /**
       * 駅まわり
       */
      stations: {
        /**
         * true: 駅の取得を無効化（海外だと取れないので無効化を推奨）
         * 国の判定はマップによるので、必要なら明示的にactionsを読んで無効化する
         */
        disabled: false,
        /**
         * 駅の取得の状況
         */
        status: StationsStatus.NONE,
        /**
         * Pointの近くの駅
         */
        stations: [],
        /**
         * ルートを表示する駅
         */
        station: null
      },
    },
    /**
     * フィルタ
     * MapとPointsで類似のことを行うのでここにまとめる、無理がでてきたら分離
     * filterIdでのURLわけを行う場合はPointでも使う可能性がある
     */
    filters: {
      /**
       * GETで渡された絞り込みなどの設定が完了したらtrueに更新される
       */
      initialized: false,
      selectedMarkers: [],
      selectedSearchConditions: [],
      query: '',
    },
    /**
     * 現在地
     * @type {google.maps.LatLng}
     */
    currentLocation: null,
  },
  getters: {
    /**
     * 駅まわり取得
     * @returns state.stations
     */
    stations: state =>  {
      return state.point.stations
    },
    /**
     * 地図の中心か選択中のPointに近いPointを一定件数まで返す
     * @returns {
     *   length: {Number},
     *   items: {Array}
     * }
     */
    nearPoints: state => {
      return state.points.nearPoints
    },
    /**
     * true: ポイント選択中
     */
    isPointSelected: state => {
      return !!state.points.selected
    },
    /**
     * true: クラスタ選択中
     */
    isClusterSelected: state => {
      return state.points.pointsInCluster.length > 0
    },
    /**
     * @returns true: マップで実行中の処理がない
     */
    processible: state => {
      return state.mapReady === 0
    },
    isPanelClosed: state => {
      return state.map.panel.state === Panel.States.CLOSED
    },
    /**
     * 絞り込みを適用中か判定
     * 
     * URLでの絞り込みを行っている場合がある
     * なので、空かどうかではなく、初期状態と一致するかを見る
     * @returns {Boolean} true: 絞り込みを適用中
     */
    isFilterEnabled: state => {
      if (!state.filters.initialized) {
        return false
      }

      if (state.filters?.query) {
        // 店舗検索用のテキストが存在すれば、その時点で絞り込み状態とする
        return true
      }

      // URL指定されたマーカーを収集（配下込）
      let markerIdsByQuery = []

      storelocator.selected_markers.forEach(id => {
        markerIdsByQuery.push(id)
        
        Filters.findChildren(
          Filters.findById(Filters.TYPE_MARKERS, id)
        ).map(f => f.id).forEach(id => {
          markerIdsByQuery.push(id)
        })
      })

      markerIdsByQuery = JSON.stringify(markerIdsByQuery.sort())

      // URLで指定された検索条件を収集（配下込）
      let searchConditionIdsByQuery = []

      storelocator.selected_search_conditions.forEach(id => {
        searchConditionIdsByQuery.push(id)
        
        Filters.findChildren(
          Filters.findById(Filters.TYPE_SEARCH_CONDITIONS, id)
        ).map(f => f.id).forEach(id => {
          searchConditionIdsByQuery.push(id)
        })
      })

      searchConditionIdsByQuery = JSON.stringify(searchConditionIdsByQuery.sort())

      // stateに反映済のマーカーと検索条件、以下は配下込で入っている
      const markers = JSON.stringify(
        Object.keys(state.filters.selectedMarkers).reduce((mc, mid) => {
          return mc.concat(state.filters.selectedMarkers[mid])
        }, []).sort()
      )

      const searchConditions = JSON.stringify(
        Object.keys(state.filters.selectedSearchConditions).reduce((fc, fid) => {
          return fc.concat(state.filters.selectedSearchConditions[fid])
        }, []).sort()
      )

      // 比較、実体ではなく文字列化したもので見る
      // console.debug("isFilterEnabled", markers, markerIdsByQuery, searchConditions, searchConditionIdsByQuery)
      if (markers === markerIdsByQuery && searchConditions === searchConditionIdsByQuery) {
        return false
      }

      return true
    },
    /**
     * 取得済の現在地を取得
     * @returns {google.maps.LatLng}
     */
    currentLocation: state => {
      return state.currentLocation
    },
    /**
     * 選択中の店舗
     */
    selectedPoint: state => {
      return state.points.selected
    },
    /**
     * フィルタ
     * state.filters
     */
    filters: state => {
      return state.filters
    }
  },
  mutations: {
    /**
     * 駅の取得を無効化
     */
    disableStations(state) {
      state.point.stations.disabled = true
    },
    /**
     * 駅を更新
     * @param {Array} stations /api/stationsの応答まま
     */
    updateStations(state, stations) {
      state.point.stations.stations = stations
    },
    /**
     * ルートを表示する駅を更新
     */
    updateStation(state, station) {
      // console.debug("updateStation", station)
      state.point.stations.station = station
    },
    /**
     * 駅の取得状況を更新
     * @param {Number} status StationsStatus
     */
    updateStationsStatus(state, status) {
      state.point.stations.status = status
    },
    /**
     * マップの処理の積み上げ／下げ
     * @param {boolean} finished true: 処理の完了時に指定
     */
    updateMapReady(state, finished) {
      if (!finished) {
        state.mapReady ++
      } else if (state.mapReady != 0) {
        state.mapReady --
      }
    },
    updateWindowSize(state) {
      const w = window.innerWidth
      const size = w < parseInt(storelocator.breakpoints?.small)
        ? WindowSize.SMALL
        : WindowSize.LARGE

      if (state.windowSize !== size) {
        if (size === WindowSize.SMALL) {
          state.map.panel.state = Panel.States.CLOSED
        } else {
          state.map.panel.state = Panel.States.OPENED
        }
      }

      state.windowSize = size
    },
    /**
     * パネルの開閉を更新
     * @param {number} panelState Panel.States
     */
    updatePanel(state, panelState) {
      state.map.panel.state = panelState
    },
    /**
     * 選択フィルタのアップデート
     * 子孫要素がある場合、子孫も一括で更新する
     *
     * @param {Object}: {
     *   type {String}: markers | search_conditions
     *   ids {Array}: 更新する配列
     *   parent {Bool}: trueの場合、親の選択を行う
     * }
     */
    updateSelectedFilters(state, {type, id, parent}) {
      const key = type === Filters.TYPE_MARKERS
        ? "selectedMarkers"
        : "selectedSearchConditions"

      let children = []
      if (!parent && id !== Filters.BASE_MARKER_ID) {
        children = Filters.findChildrenById(type, id)
      }

      children.push(id)
      if (state.filters[key].includes(id)) {
        // 選択解除
        state.filters[key] = state.filters[key].filter(selectedId => {
          return !children.includes(selectedId)
        })
      } else {
        // 選択
        children.forEach(id => {
          // console.debug("updateSelectedFilters", propName, id)
          if (!state.filters[key].includes(id)) {
            state.filters[key].push(id)

            // パラメータ名から異なる方が集計しやすいので、冗長ながらわけておく
            if (key === 'selectedMarkers') {
              storelocator.analytics.client?.send('SelectMarker', {
                'MarkerId': id,
                'MarkerName': Filters.findById(
                  Filters.TYPE_MARKERS,
                  id
                ).name,
              })
            } else {
              storelocator.analytics.client?.send('SelectSearchCondition', {
                'SearchConditionId': id,
                'SearchConditionName': Filters.findById(
                  Filters.TYPE_SEARCH_CONDITIONS,
                  id
                ).name,
              })
            }
          }
        })
      }

      // 常時送信するパラメータを最新化
      if (storelocator.analytics.client) {
        storelocator.analytics.client.context['SearchMarkerIds'] = state.filters.selectedMarkers.join(',')
        storelocator.analytics.client.context['SearchMarkerNames'] = state.filters.selectedMarkers.map(id => {
          return Filters.findById(Filters.TYPE_MARKERS, id).name
        }).join(',')
  
        storelocator.analytics.client.context['SearchConditionIds'] = state.filters.selectedSearchConditions.join(',')
        storelocator.analytics.client.context['SearchConditionNames'] = state.filters.selectedSearchConditions.map(id => {
          return Filters.findById(Filters.TYPE_SEARCH_CONDITIONS, id).name
        }).join(',')
      }
    },
    updateQuery(state, query) {
      state.filters.query = query
    },
    updateSelectedPoint(state, point) {
      // console.debug("updateSelectedPoint", point)
      state.points.selected = point
    },
    updateSelectedCluster(state, clusterId) {
      state.points.selectedClusterId = clusterId
      state.points.pointsInCluster = []
    },
    /**
     * 地図表示範囲内のPointを更新
     * @param {Array} points 地図表示範囲内のPoint
     */
    updatePointsInBounds(state, points) {
      // console.debug('updatePointsInBounds', points)
      state.points.pointsInBounds = points
      state.points.initialized = true

      // nearPointsの更新
      let baseLatLng = null
      if (state.points.selected) {
        baseLatLng = state.points.selected.position
      } else if (MapService.map?.getCenter()) {
        baseLatLng = MapService.map.getCenter()
      }

      const _points = state.points.pointsInBounds

      sortByDistance(baseLatLng, _points)

      const length = _points.length

      let items
      if (length > 99) {
        items = _points.slice(0, 100)
      } else {
        items = _points
      }

      state.points.nearPoints = {length: length, items: items}
    },
    updatePointsInCluster(state, points) {
      state.points.pointsInCluster = !points ? [] : points
    },
    /**
     * 絞り込みまわりリセット
     * Pointまわりまで反映はしないので、別途整合性を取る必要がある
     */
    resetFilter(state) {
      state.filters.selectedMarkers.splice(0)
      state.filters.selectedSearchConditions.splice(0)
      state.filters.query = ''
    },
    /**
     * @param {google.maps.LatLng} currentLocation 現在地 
     */
    updateCurrentLocation(state, currentLocation) {
      state.currentLocation = currentLocation
    },
    updateFilterInitialized(state, initialized) {
      state.filters.initialized = initialized
    }
  },
  actions: {
    /**
     * 駅の取得を無効化
     */
    disableStations({commit}) {
      commit("disableStations")
    },
    /**
     * 駅の取得状況更新
     * @param {Object} point {latitude, longitude}
     */
    async updateStations({commit, state}, point) {
      if (state.point.stations.disabled) {
        return
      }

      commit("updateStationsStatus", StationsStatus.LOADING)

      const response = await api.fetchStations(
        point.latitude,
        point.longitude
      ).catch(e => {
        console.warn('failed fetch stations', e)
      }).finally(() => {
        commit("updateStationsStatus", StationsStatus.NONE)
      })

      if (response?.status != 200) {
        return
      }

      commit("updateStations", response.data.items)
    },
    /**
     * ルートを表示する駅を選択
     * @param {Object} station /api/stationsの応答まま
     */
    selectStation({commit}, station) {
      commit("updateStation", station)
    },
    /**
     * 現在地を保存
     * @param {google.maps.LatLng} currentLocation 現在地
     */
    saveCurrentLocation({commit}, currentLocation) {
      commit("updateCurrentLocation", currentLocation)
    },
    /**
     * フィルタの初期化完了時
     */
    onFilterInitialized({commit}) {
      commit('updateFilterInitialized', true)
    },
    /**
     * パネルを開く
     */
    openPanel({commit}) {
      commit("updatePanel", Panel.States.OPENED)
    },
    /**
     * パネルを閉じる
     */
    closePanel({commit}) {
      commit("updatePanel", Panel.States.CLOSED)
    },
    /**
     * マップ絡みの処理はじめ
     */
    startProcess({commit}) {
      commit("updateMapReady", false)
    },
    /**
     * マップ絡みの処理終わり
     */
    finishProcess({commit}) {
      commit("updateMapReady", true)
    },
    /**
     * ウィンドウサイズ変更時
     */
    onWindowResized({commit}) {
      commit("updateWindowSize")
    },
    /**
     * 表示範囲内のPointを更新
     * @param {Array} points Point[] [{..., marker, position, ...}, ...]
     */
    updatePointsInBounds({commit}, points) {
      commit("updatePointsInBounds", points)
    },
    // ポイント選択
    selectPoint({state, commit}, point) {
      if (state.points.selected != null && state.points.selected.id === point.id) {
        return
      }
      commit("updateSelectedPoint", point)
    },
    selectCluster({getters, commit}, cluster) {
      if (getters.isPointSelected) {
        commit("updateSelectedPoint", null)
      }

      commit("updateSelectedCluster", cluster.clusterId)
      commit("updatePointsInCluster", cluster.points)
    },
    deselectCluster({commit}) {
      commit("updateSelectedCluster", null)
    },
    deselectPoint({commit}) {
      MapService.pointManager.clearAll()

      commit("updateSelectedPoint", null)
      commit("updateSelectedCluster", null)
    },
    /**
     * 指定の状態へのパネルの開閉
     * @param {number} {value} Panel.States
     */
    changePanel({state, commit}, {value}) {
      if (state.map.panel.state === value) {
        return
      }

      if (value < Panel.States.OPENED) {
        const dom = document.getElementById("PanelContents")
        if (!dom) {
          return
        }
    
        dom.scrollTo(0, 0)
      }

      commit("updatePanel", value)
    },
    /**
     * 絞り込み選択処理
     * @param {Object} {
     *   type {String}: markers | search_conditions
     *   id {String}: ID
     *   parent {Boole}: 子要素が全選択され、親要素を選択するときは trueを指定する
     *                  （trueの場合、子要素の選択判定をスキップする）
     *   noApply {Bool}: trueにすると内部的に状態の更新は行うが画面には反映しない
     * }
     */
    changeFilter({state, commit, dispatch}, {type, id, parent, noApply}) {
      parent = parent == undefined ? false : parent
      noApply = noApply == undefined ? false : noApply

      const propName = type === Filters.TYPE_MARKERS
        ? "selectedMarkers"
        : "selectedSearchConditions"

      if(id != null) {
        commit("updateSelectedFilters", {type: type, id: id, parent: parent})
      }

      // TODO コンポーネントに移す
      if (storelocator.page === "map") {
        PointService.closePoint()
        dispatch("deselectPoint")
      }

      const result = PointService.changeFilter(type, state.filters[propName], noApply)
      if (result) {
        commit("updatePointsInBounds", result.pointsInBounds)
      }

      PointService.updateGuide()
    },
    /**
     * 絞り込みテキストの変更
     * @param {string} text 検索文字列
     * @param {string[]} targets 検索対象 ['name', 'address' ...]
     */
    changeFilterText({ state, commit, dispatch }, { text, targets=[] }) {
      if(state.filters.query === text) {
        return
      }

      Filters.query.setQuery(text)
      Filters.query.setTargets(targets)

      commit("updateQuery", text)
      dispatch("changeFilter", { type: null, id: null })
    },
    /**
     * 絞り込みをリセット
     * 
     * 初期化済である必要がある
     */
    resetFilter({ state, commit, dispatch }) {
      if (!state.filters.initialized) {
        return
      }

      dispatch("deselectPoint")
      commit("resetFilter")

      // TODO 依存関係整理したい、vuex -> serviceは不自然
      const result = PointService.resetFilter()
      commit("updatePointsInBounds", result.pointsInBounds)
    },
    /**
     * 絞り込みをリセット
     * Pointsページ用
     */
    resetFilterForPoints({ state, commit }) {
      commit("resetFilter")
      // Pointsページの絞り仕込みリセットはマーカー・絞り込み条件専用として設置しているため、テキスト検索のクエリはリセットさせない
      PointService.resetFilter(true, false)
    }
  }
})
<template>
  <div v-if="isShow" id="LoadingMap" :class="{[className]: true, mini: !message}">
    <span uk-spinner="ratio: 1"></span>
    <span class="message" v-if="message">{{ message }}</span>
  </div>
</template>

<script setup>
/**
 * ローディングの表現用
 * 
 * すぐ閉じてチラつくのを防ぐ役割が強い
 */
import { ref, watch } from 'vue'

const props = defineProps({
  /**
   * true: 表示の指示をだす
   * falseにしてもすぐ閉じないようにする
   */
  show: {
    type: Boolean,
    default: false
  },
  /**
   * 表示する文言
   */
  message: {
    type: String,
    default: ''
  },
  /**
   * 付与するクラス名
   */
  className: {
    type: String,
    default: ''
  }
})

/**
 * 表示開始から最低表示時間[ms]
 */
const MIN_DURATION = 500

/**
 * true: 表示
 */
const isShow = ref(false)

/**
 * 表示開始時刻
 * Date.now()
 */
let started = null

watch(() => props.show, (newValue) => {
  // console.debug('watch show', newValue)

  if (newValue) {
    isShow.value = true
    started = Date.now()
  } else {
    // 表示開始から一定時間後に閉じるようにする
    const diff = Date.now() - started

    // console.debug('diff', diff, MIN_DURATION)

    if (diff < MIN_DURATION) {
      const wait = MIN_DURATION - diff
      setTimeout(() => {
        isShow.value = false
      }, wait)
    } else {
      isShow.value = false
      started = null
    }
  }
})

</script>

<style lang="scss" scoped>
@keyframes fade-in-loading-map {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#LoadingMap {
  animation: fade-in-loading-map .25s ease-out;
  position: absolute;
  max-width: 20em;
  height: 6em;
  bottom: calc(50% - 6em);
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: .25em;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 1rem;

  .uk-icon {
    margin: 1.25em 3em 0.75rem 3rem;
  }

  .message {
    margin: 0 3em 0 3rem;
    display: block;
  }

  &.selected {
    top: 64px;
    bottom: auto;
  }

  &.mini {
    height: 3em;
    bottom: calc(50% - 2em);
    .uk-spinner {
      margin: .5em 2em 0.5rem 2rem;
    }
  }
}
</style>
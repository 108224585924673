<template>
  <section class="map-area">
    <div class="directions-wrapper" v-show="showDirections">
      <directions :lat="point.latitude" :lng="point.longitude" ></directions>
    </div>

    <div id="map_canvas"></div>

    <!-- 狭いので、選択中は出さない -->
    <loading
      :show="mapReady > 0 && !isSelectedPoints && !MapService.locationManager?.isLoading"
      >
    </loading>

    <div class="toggle-maps" :class="{initialized: useVectorMap}" v-show="useVectorMap">
      <button class="to-2d uk-button" @click="onTo2dClicked" v-wave>2D</button>
      <button class="to-3d uk-button" @click="onTo3dClicked" v-wave>3D</button>
    </div>
  </section>
</template>

<script setup>
/**
 * 店舗ページのマップ
 * 
 * 同ページではSEO用にサーバ描画の範囲が広いので、ページ用のコンポーネントはなく、ここが実質のvue側のルートになる
 */
import { computed, onMounted, ref } from 'vue'
import uikit from 'uikit'

import store from '@/store'

import MapService from '@/services/MapService.ts'
import PointService from '@/services/PointService.ts'
import CommonService from '@/services/CommonService.ts'

import { PointMarker } from '@/points/PointMarker.ts'

import Directions from './Directions.vue'
import Loading from '@/components/common/Loading.vue'

import { toNumberIfString } from '@/core/common.js'

/**
 * 位置情報へのアクセスが無効：取得できない旨出す
 * 位置情報へのアクセスが有効＋現在地の取得に失敗：取得できない旨出す
 * 
 * 地名検索がないので、マップと異なり、こちらでは誘導はしない
 */
CommonService.onFailedPanToCurrentLocation = (e) => {
  // 失敗・タイムアウトは共通
  let message = messages.value.common_failed_to_get_current_location

  // 拒否は明示的にわかるように、地名検索ないので誘導はなし
  if (e.code === MapService.locationManager.CURRENT_LOCATION_RESULT_DENIED) {
    message = messages.value.common_denied_to_get_current_location
  } else {
    console.warn(e)
  }

  uikit.notification.closeAll()
  uikit.notification(
    message,
    {pos: "top-center", timeout: 5000}
  )
}

CommonService.onBeforeSelect = () => {
  reset2d()
}

const props = defineProps({
  point: {
    type: Object,
    default: null
  }
})

/**
 * true: 初期化が完了済
 */
const useVectorMap = ref(null)

/**
 * true: ルート検索表示
 */
const showDirections = ref(true)

onMounted(async () => {
  const point = props.point

  await MapService.init({
    location: {
      zoom: toNumberIfString(storelocator.point?.location?.default?.zoom) || 17
    },
    locationManager: {
      onClicked: async () => {
        await CommonService.panToCurrentLocation()
      }
    },
    pointManager: {
      // 店舗のページはクラスタ化を積極的にはしたくないので、デフォルトは隣接店のみにしておく
      gridSize: storelocator.point?.markers?.clusters?.enabled
        ? storelocator.point?.markers?.clusters?.grid?.size || 1
        : 0,
      onClicked: CommonService.onClusterClicked,
      onInnerMarkerClicked: CommonService.onInnerMarkerClicked,
      isShowPoint: (p) => {
        return true
      }
    }
  })

  MapService.onStreetViewToggled = () => {
    // console.debug('onStreetViewToggled', showDirections.value)
    showDirections.value = !showDirections.value
  }

  // このページの店舗は除外
  PointService.filters.excludes = [
    storelocator.point.point.id
  ]

  // クラスタ・Point詳細
  PointService.initPointWindow({
    formats: storelocator.point.point_window?.formats,
    searchConditions: storelocator.point?.point_window?.search_conditions,
    thumbnail: storelocator.point?.point_window?.thumbnail,
    onInnerPointSelected: CommonService.onInnerPointSelected,
    onClosed: CommonService.onPointClosed,
    onBacked: CommonService.onPointBacked,
  })

  // イベント
  
  // 近隣店の取得は無効化可能（ノイズになるケースもあるため）
  google.maps.event.addListener(MapService.map, 'idle', async () => {
    CommonService.onIdle()
  })

  MapService.map.panTo({
    lat: point.latitude,
    lng: point.longitude,
  })

  // ページの店舗は別途マーカーを設置（選択解除させないなどの例外処置が大変になるので）
  new PointMarker(
    MapService.map,
    new google.maps.LatLng(point.latitude, point.longitude),
    null,
    {
      point: point,
      selected: true,
      clickable: false,
      zIndex: 100000000, // PointMarkerでふる値より上にもっていく必要がある
      onClicked: () => {}
    }
  )

  const interval = setInterval(() => {
    if (MapService.map?.renderingType === 'UNINITIALIZED') {
      return
    }

    if (MapService.map?.renderingType === 'VECTOR') {
      setTimeout(() => {
        // 最初に3Dにしようかと思ったが、建物が邪魔になることがあるのでやめておく、フットプリントがいい感じなら有効にしてもいいかも
        // MapService.map.setTilt(45)
        // MapService.map.setHeading(-45)

        useVectorMap.value = true
      }, 500)
    }

    clearInterval(interval)
  }, 100)
})

const mapReady = computed(() => store.state.mapReady)
const messages = computed(() => storelocator.messages)
const isSelectedPoints = computed(() => store.getters.isFilterEnabled || store.getters.isClusterSelected)
const isPointSelected = computed(() => store.getters.isPointSelected)

/**
 * 地図の角度をリセット（平面に見える状態）
 */
function reset2d() {
  if (!useVectorMap.value) {
    return
  }

  MapService.map.setTilt(0)
  MapService.map.setHeading(0)
}

/**
 * 地図の角度をリセット（立体的に見える状態）
 * Point選択中は回さない、（詳細が画面外にいくので避ける）
 */
function reset3d() {
  if (!useVectorMap.value) {
    return
  }

  if (isPointSelected.value) {
    MapService.map.setTilt(45)
  } else {
    MapService.map.setTilt(45)
    MapService.map.setHeading(-45)
  }
}

/**
 * 2Dクリック時
 */
function onTo2dClicked() {
  reset2d()

  storelocator.analytics.client?.send('PointTo2D', {
  })
}

/**
 * 3Dクリック時
 */
function onTo3dClicked() {
  reset3d()

  storelocator.analytics.client?.send('PointTo3D', {
  })
}
</script>

<style lang="scss">
#app.point {
  #map_canvas {
    width: 100%;
    height: 100%;
  }

  .map-area {
    position: relative;
    width: 100%;
    height: 100%;

    .toggle-maps {
      border-radius: 4px;
      position: absolute;
      bottom: -32px;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0;
      transition: all 0.3s;

      button {
        border: 1px solid #ccc;
        padding: 0 1rem;
        line-height: 1.4rem;
        transition: all 0.3s;

        &.current {
          background-color: var(--theme-color);
          color: var(--text-color);
        }

        &.to-2d {
          border-radius: .7rem 0 0 .7rem;
        }

        &.to-3d {
          border-radius: 0 .7rem .7rem 0;
          border-left: none;
        }

        @media screen and (min-width: $breakpoint-small) {
          &:hover {
            background-color: var(--theme-color);
            border: 1px solid var(--theme-color);
            color: var(--text-color);
          }
        }
        &:active {
          background-color: var(--theme-color);
          border: 1px solid var(--theme-color);
          color: var(--text-color);
        }
      }

      &.initialized {
        opacity: 1;
      }
    }
  }

  .sub-contents {
    margin: 4rem 0 3rem 0;

    @media screen and (min-width: $breakpoint-medium){
      max-width: 500px;
      margin-right: 0;
      margin-left: 0;
    }

    @media screen and (max-width: $breakpoint-medium){
      max-width: 100%;
      width: 100%;
    }
  }

  .near-points {
    .points {
      @media screen and (min-width: $breakpoint-medium){
        width: 500px;
      }

      @media screen and (max-width: $breakpoint-small){
        width: calc(100% + 2rem);
        margin: 0 -1rem;
      }

      .point-wrapper {
        &:first-child {
          border-top: 1px solid #eee;
        }
      }
      .marker {
        opacity: 1;
      }
    }
  }

  .stations-wrapper {
  }

  .gm-bundled-control {
    .gm-svpc {
      margin-top: .1rem !important;
    }
  }
}
</style>

<template>
  <template v-if="filterKey == 'places'">
    <Autocomplete 
      :placeholder="messages.map_filters_places_placeholder"
      right-icon
    />
  </template>
  <template v-else-if="filterKey == 'keywords'">
    <KeywordSearcher
      :placeholder="messages.map_filters_keywords_placeholder"
      @onEnterKeywordInput="handleKeywordInput"
      instant-mode
      right-icon
      sync-with-store
    />
  </template>
</template>

<script setup>
/**
 * FilterComponent.vue
 * HeaderFiltersで使用するコンポーネントをまとめたコンポーネント
 * 各フォームコンポーネントの戻り値をここで受け取り処理する
 */
import { computed, onMounted, onBeforeMount, ref, defineProps } from 'vue'
import { useStore } from 'vuex'
import Autocomplete from '@/components/common/Autocomplete.vue'
import KeywordSearcher from '@/components/common/KeywordSearcher.vue'

const messages = computed(() => storelocator.messages)
const props = defineProps({
  filterKey: String,
})

const store = useStore()

/**
  * キーワード検索実行
  * KeywordSearcherコンポーネントからクエリを受け取りリストに反映し
  * analyticsにSearchByKeywordイベントを送信する
  * 
  * @param query (String) 検索ワード. 空 or null で全件表示 ( =リセット)
  */
function handleKeywordInput(query) {
  const payload = {
    text: query,
    targets: storelocator.map.filters?.keywords?.targets || ['name', 'address']
  }
  store.dispatch("changeFilterText", payload)
  storelocator.analytics.client?.send(
    'SearchByKeyword',
    {
      Keyword: query,
    }
  )
}

</script>
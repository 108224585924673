import MapService from '@/services/MapService.ts'

async function geocoding(query) {
  if (!MapService.geocoder) {
    MapService.geocoder = new google.maps.Geocoder();
  }

  return new Promise((resolve, reject) => {
    MapService.geocoder.geocode({
      'address': query,
      'region': storelocator.google_maps?.region || 'JP'
    }, function(results, status) {
      if (status == google.maps.GeocoderStatus.OK) {
        resolve(results[0])
      } else {
        reject(status)
      }
    })
  })
}

export {
  geocoding
}
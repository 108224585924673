<template>
  <div id="app-container" class="map-page">
    <div id="map-container" :class="{close: panel === _Panel.States.CLOSED, full: panel === _Panel.States.OPENED}">
      <GoogleMap></GoogleMap>
    </div>

    <transition name="fade">
      <div class="backdrop" v-if="panel != 0" @click="onBackdropClicked">
        <span class="panel-closer" uk-icon="icon: close; ratio: 1.8"></span>
      </div>
    </transition>

    <div id="panel-container" :class="{close: panel === _Panel.States.CLOSED, full: panel === _Panel.States.OPENED}">
      <Panel>
        <template #filter-info="props">
          <slot name="filter-info" :filter="props.filter"></slot>
        </template>
        <template #panel-extra-actions>
          <slot name="panel-extra-actions"></slot>
        </template>
        <template #filter-group-name="props">
          <slot name="filter-group-name" :filter="props.filter" :selectable="props.selectable"></slot>
        </template>
      </Panel>
    </div>

    <slot name="product-modal"></slot>
  </div>
</template>

<script setup>
/**
 * マップページ
 *
 * 現状はこのコンポーネントはあまり役割を持っていない（後付のため）
 * マップやその初期化近辺で行う流れはGoogleMap内に多く持っている（絞り込みも）
 *
 * 画面全体に関わるものは徐々にこちらに移してきたい
 */
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'

import GoogleMap from './GoogleMap.vue'
import Panel from './Panel.vue'

import { Panel as _Panel } from '@/constants'
import Page from '@/services/Page'

Page.storage.save('map', location.href)

const store = useStore()

/**
 * ウィンドウサイズ監視時のデバウンス設定
 *
 * ウィンドウサイズが変わった時に挙動を変更する必要がある処理のため、幅の監視を行う
 * ウィンドウ幅が変更され、ブレイクポイントが変わるとstoreの値を更新させる
 */
let resizeTimer = null
const interval = Math.floor(1000 / 60 * 10)

function watchWindowSize() {
  store.dispatch('onWindowResized')

  window.addEventListener("resize", () => {
    if (resizeTimer !== null) {
      clearTimeout(resizeTimer)
    }
    resizeTimer = setTimeout(() => {
      store.dispatch('onWindowResized')
    }, interval)
  })
}

/**
 * バックドロップのクリック時
 */
function onBackdropClicked() {
  store.dispatch("changePanel", {
    value: _Panel.States.CLOSED
  })
}

const panel = computed(() => store.state.map.panel.state)

onMounted(() => {
  watchWindowSize()
})

</script>

<style lang="scss" scoped>
.map-page {
  .panel-closer {
    color: #fff;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }

  // パネル開いた時のバックドロップ
  .backdrop {
    position: absolute;
    left: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: block;
    width: 100%;
    transition: all .25s;
    &.close {
      display: none;
      width: 0;
    }

    @media only screen and (min-width: $breakpoint-medium) {
      display: none;
    }
  }

  #map-container {
    overflow: hidden;
    background-color: rgb(185, 220, 252);
    transition: all .25s;
    width: 100%;
    height: 100%;
  }

  #panel-container {
    flex-shrink: 0;
    overflow: hidden;
    background-color: #fff;
    transition: all .25s;
    box-shadow: 0 0 6px rgba(0, 0, 0, .25);
    width: calc(100% - 3rem);
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 50;

    &.close {
      margin-left: calc(0px - #{calc(100% - 3rem)});
    }

    @media only screen and (min-width: $breakpoint-small) {
      width: 50%;
      &.close {
        margin-left: calc(0px - #{50%});
      }
    }

    @media only screen and (min-width: #{$breakpoint-medium}) {
      position: relative;
      width: 340px;
      &.close {
        margin-left: 0;
      }
    }

    .panel {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>

<template>
  <div id="app-container" class="error-page not-found">
    <div class="content">
      <h1>
        <span class="title" v-html="messages.not_found_title"></span>
        <span class="sub-title" v-html="messages.not_found_sub_title"></span>
      </h1>
      <p class="body" v-html="messages.not_found_body"></p>
    </div>
  </div>
</template>

<script setup>
/**
 * 404用
 * 
 * 基本的にはこれをベースに、組織ごとに変更したものを使う
 */
import { computed } from 'vue'

const messages = computed(() => storelocator.messages)

</script>

<style lang="scss" scoped>
#app-container.error-page {
  width: 100%;
  .content {
    width: 100%;
    display: block;
    text-align: center;

    h1 {
      font-size: 3rem;
      span {
        display: block;
      }
      .title {
        margin: 1.5rem 0 1rem 0;
      }
      .sub-title {
        font-size: 1rem;
      }
    }

    .body {
      font-size: 1rem;
      margin: 3rem 0 0 0;
    }
  }
}
</style>

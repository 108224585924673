<template>
  <div class="header-filters" v-if="enabledFilters.length">
    <template v-if="enabledFilters.length > 1">
      {{ '' /** タブ。有効なフィルタが複数ある場合に表示 */ }}
      <ul v-if="enabledFilters.length > 1" class="uk-tab header-filters__tabs" uk-tab>
        <li v-for="filterKey in enabledFilters" class="header-filters__tab">
          <button class="header-filters__tab-button uk-button uk-button-small" v-wave>
            {{filterTabText(filterKey)}}
          </button>
        </li>
      </ul>
      <hr class="header-filters__tab-divider">

      {{ '' /** タブに対応したフォーム */ }}
      <ul class="uk-switcher">
        <li v-for="filterKey in enabledFilters">
          <FilterComponent :filterKey="filterKey" />
        </li>
      </ul>
    </template>
    <template v-else-if="enabledFilters.length">
      <FilterComponent :filterKey="enabledFilters[0]" />
    </template>
  </div>
</template>

<script setup>
/**
 * HeaderFilters.vue
 * パネル上部のテキスト用フィルター
 * 管理画面の設定に応じてタブが追加される
 * 表示順は固定
 */
import { computed, onBeforeMount, ref } from 'vue'
import FilterComponent from './FilterComponent.vue';

/**
 * 有効なフィルタのキー配列
 * @type {ref<string[]>}
 */
const enabledFilters = ref([])
/**
  * キーワード検索が完了したかどうか
  * @type {ref<boolean>}
  */

const messages = computed(() => storelocator.messages)
const filters = computed(() => storelocator.map.filters)

/**
 * messagesからフィルタタブのテキストを取得
 * @param {string} key 
 */
const filterTabText = (key) => {
  const messageKey = 'map_filters_' + key + '_tab'
  return messages.value[messageKey] || key
}

onBeforeMount(() => {
  enabledFilters.value = Object.keys(filters.value).filter(k => filters.value[k]?.enabled == true)
})

</script>

<style lang="scss">
.header-filters {
  &__tabs {
    margin-bottom: 0;
  }
  &__tab {
    padding-left: 8px;
    &:first-child {
      padding-left: 20px;
    }
    &.uk-active {
      /** 有効時のタブ表示 */
      .header-filters__tab-button {
        position: relative;
        z-index: 1;
        border-bottom: 1px solid #fff;
        background: #fff;
        opacity: 1;
      }
    }
  }
  &__tab-button  {
    font-size: 14px;
    transition: all .15s ease-in-out;
    border-radius: 8px 8px 0 0;
    color: var(--color-text);
    padding: 0 16px;
    opacity: 0.6;
    border: 1px solid #eee;
    &:hover,
    &:visited {
      color: var(--color-text);
    }
  }
  &__tab-divider {
    margin: 0 0 12px;
    border: none;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: -1px;
      left: 0;
      width: 100%;
      height: 1px;
      z-index: 0;
      background: #eee;
    }
  }
}
</style>

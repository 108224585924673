import { ref } from 'vue'
import store from '@/store'

import { Filters } from "@/services/Filters.ts"

// 好ましくないが、まずはロジック分離を優先する
import PointService from "@/services/PointService.ts"
import Page from "@/services/Page"

/**
 * PointsService
 * 
 * 店舗の一覧ページのビジネスロジックやリソースを管理
 * UI要素は極力除外する
 * 
 * リアクティブ性が重要でないものを保持する役割も持つ
 * 
 * 一旦はvuexへの依存を許容する、極力抑えたい
 * 依存だけならともかく、vuex <-> serviceが循環気味なので、最終的にはもっと整理したい
 * 
 * UI操作は極力除外する
 */
export default {
  /**
   * extra_config.pointsで上書き可能
   */
  config: {
    "description": "",
    "filters": {
      "frontend": {},
      "backend": {},
      "area": {
        "kind": null,
        "targets": []
      }
    },
    "points": {
      "point": {
        "formats": [
          {
            "key": "address",
            "kind": "field"
          },
          {
            "class": "distance",
            "format": "{{messages.common_from_point}}{{distance}}",
            "kind": "advanced"
          }
        ]
      }
    },
    "trigger": {
      "initialized": true
    }
  },
  /**
   * ページネーションの設定
   * 
   * sortBy: 並び替えの基準. 現状は以下の値に対応
   *　{String} "location.current"（現在地） | {String} "location.places"（placesなどから取得した緯度軽度）　| null（デフォルト）
   * 
   * latestSortedLocation: 最後に利用した並び替えの基準座標
   */

  pagination: {
    "type": "page",
    "limit": 10,
    "sortBy": null,
    "latestSortedLocation": null,
  },

  /**
   * 位置まわり
   * 近い順に並べるものとか
   */
  locations: ref({
    /**
     * 現在地
     * @type {google.maps.LatLng}
     */
    current: null,

    /**
     * 地名検索から返却された緯度軽度
     * @type {google.maps.LatLng}
     */
    places: null,

    /**
     * 取得中：true
     */
    loading: false,
  }),
  /**
   * 都道府県での絞り込み用
   */
  areas: ref({
    /**
     * 都道府県
     * @type {Object} {area_name, area_code, code}
     */
    area: null,
    /**
     * 都道府県ごとのPoint数の可視化用
     * 
     * 都道府県コード：{
     *   count: 所属・表示対象のPointの件数
     * }
     */
    areas: null
  }),
  /**
   * ストレージまわり
   */
  storage: {
    areas: {
      key: `storelocator:${location.host}:areas`,
      /**
       * エリア選択のトリガーを仕込む
       * この後に店舗の一覧ページを開くと、エリア選択を行うようにする
       */
      save() {
        sessionStorage.setItem(
          this.key,
          JSON.stringify({})
        )
      },
      /**
       * エリア選択が必要か読み取り＋そのための仕込みを解除
       * @returns true: 選択が必要
       */
      load(): boolean {
        const value = sessionStorage.getItem(this.key)
        if (value) {
          sessionStorage.removeItem(this.key)
          return true
        }

        return false
      }
    }
  },
  /**
   * 絞り込みに使うもの、APIに投げる、サーバ側で使うもの
   */
  searchQuerys: {
    /**
     * 全件取得
     * 画面側で絞り込む場合、ポイント絞り込みを行う場合は全件取得する必要がある
     * @returns クエリ
     */
    all() {
      return {}
    }
  },
  /**
   * APIから取得したPoints
   */
  resultPoints: ref([]),
  /**
   * 絞り込み後のPoints
   */
  points: ref([]),
  /**
   * true: 初期化完了
   */
  isInitialized: ref(false),
  /**
   * Pointsの総件数
   */
  total: ref(0),
  /**
   * storelocator.messages
   * 参照専用
   */
  messages: storelocator.messages,
  /**
   * エラーなどあればおいておく
   */
  message: ref(null),
  /**
   * 最後の通信の応答を格納しておく（エラーの判断用）
   * PointService.fetchPointsの応答
   */
  response: null,
  /**
   * ローディング表示
   * 文字列が入る模様？
   */
  loading: ref(false),
  /**
   * 現在のページ
   */
  page: ref(1),
  /**
   * パンくず
   */
  breadcrumb: ref({
    /**
     * true: パンくずを表示
     * 戻し先がなかったり、特殊な遷移になる場合は無効化できようにするための口
     */
    enabled: true,
    /**
     * Breadcrumb.props.items
     * [{label, href}, ...]
     */
    items: []
  }),
  /**
   * 初期化
   */
  init() {
    // グループが１つだけの場合、展開しておく（開くのが手間）
    if (storelocator.markers.length === 1 && storelocator.markers[0].children) {
      storelocator.markers[0].opened = true
    }
    if (storelocator.search_conditions.length === 1 && storelocator.search_conditions[0].children) {
      storelocator.search_conditions[0].opened = true
    }

    // 設定の使い分け
    if (storelocator.path.id && storelocator.points[storelocator.path.id]) {
      this.config = storelocator.points[storelocator.path.id]
    } else if (storelocator.points.default) {
      this.config = storelocator.points.default
    }

    // console.debug("PointsService.init", this.config)
  },
  /**
   * 初期化完了直後用
   */
  async onInitialized() {
    if (!this.config?.trigger?.initialized) {
      this.message.value = this.messages.points_summary_found_points
      this.total.value = 0
      return
    }
  
    await this.searchPoints(this.searchQuerys.all())

    this.isInitialized.value = true
    store.dispatch("onFilterInitialized")
  },
  /**
   * 初期化完了後、フック用
   */
  afterInitialized() {
  },
  /**
   * ポイント絞り込みを初期化
   * 
   * URLで指定したクエリもここで適用
   * クエリを指定した時は、初期化するとクエリを反映した状態にする
   */
  resetFilter() {
    store.dispatch("resetFilterForPoints")

    // 初期値を設定
    Filters.getInitialFilterIds(Filters.TYPE_MARKERS).forEach(id => {
      store.dispatch("changeFilter", {
        type: Filters.TYPE_MARKERS,
        id: id
      })
    })

    Filters.getInitialFilterIds(Filters.TYPE_SEARCH_CONDITIONS).forEach(id => {
      store.dispatch("changeFilter", {
        type: Filters.TYPE_SEARCH_CONDITIONS,
        id: id
      })
    })

    // 親を選択（兄弟が全て選択済になった場合）
    Filters.getInitialFilterIds(Filters.TYPE_MARKERS).forEach(id => {
      const parent = Filters.findParentById(Filters.TYPE_MARKERS, id)
      if (!parent) {
        return
      }

      const siblingIds = parent.children.map(p => p.id)
      const checkSiblingIdsSelect = siblingIds.map(sid => {
        return store.state.filters.selectedMarkers.includes(sid)
      })

      if (!store.state.filters.selectedMarkers.includes(parent.id) && !checkSiblingIdsSelect.includes(false)) {
        storelocator.selected_markers.push(parent.id)
        store.dispatch("changeFilter", {
          type: Filters.TYPE_MARKERS,
          id: parent.id,
          parent: true
        })
      }
    })

    Filters.getInitialFilterIds(Filters.TYPE_SEARCH_CONDITIONS).forEach(id => {
      const parent = Filters.findParentById(
        Filters.TYPE_SEARCH_CONDITIONS,
        id
      )

      if (!parent) {
        return
      }

      const siblingIds = parent.children.map(p => p.id)
      const checkSiblingIdsSelect = siblingIds.map(sid => {
        return store.state.filters.selectedSearchConditions.includes(sid)
      })

      if (!store.state.filters.selectedSearchConditions.includes(parent.id) && !checkSiblingIdsSelect.includes(false)) {
        storelocator.selected_search_conditions.push(parent.id)
        store.dispatch("changeFilter", {
          type: Filters.TYPE_SEARCH_CONDITIONS,
          id: parent.id,
          parent: true
        })
      }
    })

    Filters.cache.updateLocation()
  },
  /**
   * Pointが指定のエリアを含むか判定
   * @param point Point
   * @param area Area, countの更新は行わない
   * @returns true: 指定したエリアをPointが含んでいる
   */
  hasArea(point: Point, area: Area): boolean {
    const areaConfig = this.config.filters.area

    if (areaConfig?.kind && areaConfig?.targets?.length > 0) {
      const value = areaConfig?.kind === 'name'
        ? area.area_name
        : area.area_code

      // eslint-disable-next-line no-unsafe-optional-chaining
      for (const target of areaConfig?.targets) {
        const fieldValue = point[target]
        if (fieldValue && fieldValue.includes(value)) {
          return true
        }

        const extraFieldValue = point.extra_fields[target]
        if (extraFieldValue && extraFieldValue.includes(value)) {
          return true
        }
      }
    } else {
      if (point.address.startsWith(area.area_name)) {
        return true
      }
    }

    return false
  },
  /**
   * Pointの件数を都道府県ごとに集計・反映
   * @param allPoints 絞り込む前
   */
  applyPointsToAreas(allPoints: Point[]) {
    // console.debug("applyPointsToAreas", allPoints)

    const areas: {
      [area_code: string]: Area
    } = {}

    Object.keys(storelocator.area).map(
      i => storelocator.area[parseInt(i)]
    ).forEach(area => {
      Object.keys(area.areas).forEach(area_code => {
        const a = area.areas[area_code]
        areas[a.area_code] = {
          area_code: a.area_code,
          area_name: a.area_name,
          count: 0
        }
      })
    })

    allPoints.forEach(point => {
      for (const area_code of Object.keys(areas)) {
        const area = areas[area_code]

        if (this.hasArea(point, area)) {
          area.count++
        }
      }
    })

    // console.debug("applyPointsToAreas", areas)
    this.areas.value.areas = areas
  },
  /**
   * 都道府県の絞り込みを適用
   * pointsに反映する
   * @param _points
   * @returns 絞り込み後のpoints
   */
  applyAreaToPoints(_points: Point[]): Point[] {
    // console.debug("applyPoints", this.areas.value)

    // 未選択なら絞らない
    if (!this.areas.value.area) {
      return _points
    }

    return _points.filter(p => {
      return this.hasArea(p, this.areas.value.area)
    })
  },
  /**
   * 都道府県の絞り込みを適用
   * pointsに反映する
   * @param allPoints PointsService.resultPoints
   * @param location location
   * @returns 絞り込み後のpoints
   */
  sortByLocation(allPoints: Point[], location: google.maps.LatLng): Point[] {
    // console.debug("sortByLocation")
    
    // ソートの基準座標が変わっているか確認
    let isUpdateDistance = false
    if ( (location.lat() != this.pagination.latestSortedLocation?.lat() || location.lng() != this.pagination.latestSortedLocation?.lng()) ) {
      isUpdateDistance = true
    }
    
    const sorted = allPoints.sort((a, b) => {
      // 距離計算の基準となる地点の文言更新
      // MEMO 動作上問題ないが、sort内で値を書き換えるのは作法的に問題ないか懸念がありつつ、他にちょうど良い場所がないためここで更新
      a.distanceFrom = this.pagination.sortBy == "location.current" ? this.messages.points_distance_from_location : this.messages.points_distance_from_places
      b.distanceFrom = this.pagination.sortBy == "location.current" ? this.messages.points_distance_from_location : this.messages.points_distance_from_places

      // ソート基準の緯度経度が異なっていたら再計算
      if (!a.distance || isUpdateDistance) {
        a.distance = google.maps.geometry.spherical.computeDistanceBetween(
          location,
          new google.maps.LatLng(a.latitude, a.longitude)
        )
      }
      if (!b.distance || isUpdateDistance) {
        b.distance = google.maps.geometry.spherical.computeDistanceBetween(
          location,
          new google.maps.LatLng(b.latitude, b.longitude)
        )  
      }
      return a.distance - b.distance
    })

    this.pagination.latestSortedLocation = location
    return sorted
  },
  /**
   * 取得済のPointに絞り込み・ページングを適用
   * pointsに反映する
   */
  applyFiltersToPoints() {
    // console.debug("applyPoints")

    this.message.value = null
    let _points = this.resultPoints.value

    // 絞り込み
    const markers = Filters.findByIds(
      Filters.TYPE_MARKERS,
      Filters.selectedFilters.markers
    )
  
    const searchConditions = Filters.findByIds(
      Filters.TYPE_SEARCH_CONDITIONS,
      Filters.selectedFilters.search_conditions,
      true
    )

    _points = _points.filter(p => PointService.checkPoint(p, markers, searchConditions))

    // ページネーション前に都道府県の件数を集計
    this.applyPointsToAreas(_points)

    // 都道府県で絞る前に集計が必要（他の県に移動できなくなるため）
    if (this.areas.value.area) {
      _points = this.applyAreaToPoints(_points)
    }

    // 並び替え
    if (this.locations.value.places) {
      this.pagination.sortBy = "location.places"
      
      _points = this.sortByLocation(
        _points,
        this.locations.value.places
      )      

    } else if (this.locations.value.current) {      
      this.pagination.sortBy = "location.current"
      
      _points = this.sortByLocation(
        _points,
        this.locations.value.current
      )
    }

    this.total.value = _points.length

    if (_points.length === 0) {
      this.message.value = this.response?.status === 200
        ? this.messages.points_summary_not_found_points
        : this.messages.common_failed_to_load_points

      this.points.value = _points
      return
    }

    // ページネーション
    const start = (this.page.value - 1) * this.pagination.limit
    const end = start + this.pagination.limit
    _points = _points.slice(start, end)

    // 反映
    this.points.value = _points
  },
  /**
   * APIからPointを取得
   * @param {object} query PointService.fetchPointsの引数と同じ
   */
  async searchPoints(query: {
    text?: string,
  } = {}) {
    // console.debug("searchPoints", query)

    if (!query) {
      return
    }

    this.loading.value = true
    this.message.value = null

    const response = await PointService.fetchPoints(query).catch((e) => {
      return e
    })

    this.response = response

    // console.debug("searchPoints response", response)

    let _points: Point[] = response?.data?.items

    this.loading.value = false

    if (response?.status != 200) {
      this.message.value = this.messages.common_failed_to_load_points
      _points = []
    } else if (response.data?.items?.length === 0) {
      this.message.value = this.messages.points_summary_not_found_points
      _points = []
    }

    this.resultPoints.value = _points.map(p => PointService.parsePoint(p))

    if (_points.length === 0) {
      return
    }

    this.applyFiltersToPoints()
  },
  /**
   * URLで指定されたクエリを適用（Filters以外）
   */
  applyUrlQuery() {
    // console.debug("applyUrlQuery")

    // エリア
    const areaCode = storelocator.area_code
    if (areaCode) {
      let fixedArea = null
      Object.keys(storelocator.area).map(
        i => storelocator.area[parseInt(i)]
      ).forEach(area => {
        const p = area.areas[areaCode]
        if (p) {
          fixedArea = p
        }
      })

      this.areas.value.area = fixedArea

      this.breadcrumb.value.items = [{
        label: this.messages.points_title,
        href: Page.getPointsUrl()
      }]

      this.applyFiltersToPoints()
    }
  },
  /**
   * 位置をリセット
   */
  resetLocation() {
    this.locations.value.current = null
    this.locations.value.places = null
    this.locations.value.loading = false
  },
  /**
   * 現在地から近い順にPointを並べる
   * 現在地の取得も行うので、長い可能性がある
   * 
   * 連続で発火しないように
   */
  async panToCurrentLocation() {
    if (this.locations.value.loading) {
      return
    }

    this.locations.value.loading = true

    const finalize = () => {
      setTimeout(() => {
        this.locations.value.loading = false
      }, 1000)
    }

    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // console.debug('getCurrentPosition', position)

          finalize()

          if (position.coords.accuracy > 100000) {
            console.warn('over accuracy, cannot use position', position.coords.accuracy)
            reject({
              accuracy: position.coords.accuracy
            })
            return
          }

          const location = new google.maps.LatLng(position.coords.latitude, position.coords.longitude)

          // 現在地そのものは送信しない（必要なら別途許諾が必要）
          storelocator.analytics.client?.send('GetCurrentLocation', {
            Status: 'succeeded'
          })

          resolve(location)
        },
        (e) => {
          console.warn(e)
          reject(e)

          finalize()

          storelocator.analytics.client?.send('GetCurrentLocation', {
            Status: 'failed',
            Message: e.message
          })
        },
        {
          timeout: 15 * 1000
        }
      )
    })
  },
  /**
   * true: 絞り込み中
   */
  isFiltered() {
    return store.state.filters.selectedSearchConditions.length > 0
      || store.state.filters.selectedMarkers.length > 0
  }
}

<template>
  <div class="reset-filter">
    <button class="uk-button uk-button-small" @click="onResetFilterClicked" v-wave>
      <span uk-icon="icon: close; ratio: 0.7"></span> {{ messages.map_reset_filters }}
    </button>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import CommonService from '@/services/CommonService.ts'
import PointService from '@/services/PointService.ts'

const messages = computed(() => storelocator.messages)

/**
 * フィルタリセットをクリック時
 */
function onResetFilterClicked() {
  resetFilter()

  storelocator.analytics.client?.send('ResetFilter', {
  })
}

/**
 * 絞り込みを初期状態に戻す
 */
function resetFilter() {
  PointService.restoreToInitialFilters()
  CommonService.onIdle()
}
</script>

<style lang="scss" scoped>
.reset-filter {
  opacity: .9;

  button {
    background-color: #fff;
    border-radius: 16px;
    font-size: 14px;
    height: 32px;
    line-height: 32px;
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
    position: relative;
    padding: 0 1rem 0 2.5rem;
    transition: all 0.3s;
    &:hover {
      background-color: var(--theme-color);
      color: var(--text-color);
    }
    span {
      position: absolute;
      left: 8px;
      top: 0.4rem;
    }
    .uk-icon {
      display: inline-block;
      margin: 3px 0 0 6px;
      transform: scale(1.5);
    }
  }
}
</style>

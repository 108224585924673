<template>
  <div class="search-conditions" v-if="searchConditions.length > 0">
    <div class="search-condition"
      v-for="searchCondition in searchConditions"
      :key="searchCondition.id"
      :class="{'image-only': searchCondition.image_only}"
      :data-id="'search-condition-' + searchCondition.id"
      :uk-tooltip="searchCondition.image_only ? ('title: ' + searchCondition.name) : ''"
    >
      <!-- 画像のみ -->
      <component
        v-if="searchCondition.image?.url && searchCondition.image_only"
        class="content"
        :class="searchCondition.class"
        :is="searchCondition?.link ? 'a' : 'div'" 
        v-bind="getContentAttributes(searchCondition)"
        @click="onClicked(searchCondition)"
      >
        <span class="image">
          <img :src="searchCondition.image.url">
        </span>
      </component>

      <!-- 画像＋名称 -->
      <component
        v-else
        class="content"
        :class="searchCondition.class"
        :is="searchCondition?.link ? 'a' : 'div'" 
        v-bind="getContentAttributes(searchCondition)"
        @click="onClicked(searchCondition)"
      >
        <span class="image">
          <img :src="searchCondition.image.url" v-if="searchCondition.image?.url">
        </span>
        <span class="name" v-html="searchCondition.name"></span>
        <i
          v-if="searchCondition?.link || openable"
          class="material-symbols-sharp"
          data-icon="open_in_new"
        ></i>
      </component>
    </div>
  </div>
</template>

<script setup>
/**
 * Pointに該当する検索条件を列挙
 *
 * TODO 末端のコンポーネントにstoreを紐づけたくない
 */
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'

import { Filters } from '@/services/Filters.ts'


const store = useStore()
const selected = computed(() => store.state.points.selected)

const props = defineProps({
  /**
   * Point
   */
  point: {
    type: Object,
    default: {}
  },
  /**
   * 表示する検索条件のid
   * undefinedの場合は全列挙
   */
  showIds: {
    type: Array,
    default: undefined
  },
  /**
   * 画像のみ指定を無視する
   * true: 画像のみ指定でも名称を表示する
   */
  ignoreImageOnly: {
    type: Boolean,
    default: false
  },
  /**
   * 検索条件のリンク
   * true: リンクが利用可能（検索条件をクリックするとリンクを開く）
   */
  availableLink: {
    type: Boolean,
    default: false
  },
  /**
   * クリックするとウィンドウが開く
   * クリックイベントを設定する場合において
   * アイコンを表示させたいときに使用
   * true: アイコンを表示する
   */
  openable: {
    type: Boolean,
    default: false
  },
  /**
   * クリックイベントを登録する場合に使う
   */
  onClicked: {
    type: Function,
    default: () => {}
  },
  /**
   * extra_fieldsの画像を参照したい場合にフィールド名を指定する
   * デフォルトはsearchCondition.imageを参照
   */
  imageField: {
    type: String,
    default: ''
  },
  /**
   * true: searchConditionにextra_fieldsを保持する
   * クリックイベントなどでextra_fieldsの値を利用したい場合に使う
   */
  useExtraFields: {
    type: Boolean,
    default: false
  }
})

/**
 * Pointに該当する検索条件
 */
const searchConditions = ref([])

async function init() {
  // console.debug('PointSearchConditions init')

  // 描画中のズレ防止のためにあえて遅らせている？
  await new Promise(resolve => setTimeout(resolve, 100))

  // 重複除外用
  const filterNames = {}

  searchConditions.value = Filters.getPointFilters(
    Filters.TYPE_SEARCH_CONDITIONS,
    props.point,
    Filters.getFilterTree(Filters.TYPE_SEARCH_CONDITIONS)
  ).filter(c => c.is_show).map(c => {
    // 名称が同じものは同時に出さない
    if (filterNames[c.name]) {
      return null
    }

    filterNames[c.name] = true

    return {
      id: c.id,
      class: `search-condition-${c.id}`,
      name: c.name,
      image: props.imageField === '' ? c.image : c.extra_fields?.[props.imageField],
      image_only: !props.ignoreImageOnly && c.image_only,
      link: props.availableLink && c?.link?.url ? c.link : null,
      extra_fields: props.useExtraFields ? c.extra_fields : null
    }
  }).filter(c => c !== null)

  if (props.showIds !== undefined) {
    searchConditions.value = searchConditions.value.filter(c => props.showIds.includes(c.id))
  }
}

function updateContents() {
  init()
}

/**
 * 検索条件の要素の属性を取得する
 * @param searchCondition 検索条件
 * @returns 要素に対応した属性
 */
function getContentAttributes(searchCondition) {
  if (searchCondition.link) {
    return {
      href: searchCondition.link.url,
      target: searchCondition?.link?.new_tab ? '_blank' : ''
    }
  }
  return {}
}

// ポイント変更時の表示内容の監視、更新
watch(selected, () => {
  updateContents()
})

watch(() => props.showIds, () => {
  updateContents()
})

onMounted(() => {
  updateContents()
})

</script>

<style lang="scss" scoped>
.search-conditions {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
  margin-top: 1.25rem;

  .search-condition {
    font-size: 12px;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin: 0 4px 4px 0;
    padding: 5px 10px;
    white-space: nowrap;
    max-width: 100%;

    .content{
      display: flex;
      align-items: center;
      line-height: 0;

      .image {
        margin: 0 2px 0 -3px;
        width: auto;
        height: 15px;
        flex-shrink: 0;

        img {
          margin: 0 2px 0 -1px;
          width: auto;
          height: 15px;
        }
      }

      .name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 1;
        font-size: .75rem;
        margin-right: auto;
      }
    }

    &.image-only {
      background: none;
      border: none;
      padding: 0;

      .content {
        .image {
          margin: 0;
          width: auto;
          height: auto;

          img {
            margin: 0;
            width: auto;
            height: 24px;
          }
        }
      }
    }

    a.content {
      color: #262626 !important;
      text-decoration: none !important;
      :is(.name, .material-symbols-sharp)  {
        transition: opacity .25s;
      }
      &:hover :is(.name, .material-symbols-sharp) {
        opacity: .5
      }
    }
  }

  /** アイコン名が検索エンジンに拾われるのを防ぐ (sl-map-v2/frontend/components/common/Filter.vueを参照) */
  .material-symbols-sharp {
    position: relative;
    height: 14px;
    width: 20px;
    min-width: 20px;
    font-size: 1.25rem;
    margin-left: 4px;
    &::before {
      position: absolute;
      top: -3px;
      right: 0;
      content: attr(data-icon);
      opacity: 0.6;
    }
  }
}
</style>

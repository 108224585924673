<template>
  <div id="app-container" class="points-page">
    <div id="contents-header">
      <Breadcrumb :current="meta" :rootPage="rootPage" :items="PointsService.breadcrumb.value.items" v-if="PointsService.breadcrumb.value.enabled"></Breadcrumb>

      <h1 class="title">
        <slot name="title">
          <span class="name">{{ meta.label }}</span>
        </slot>
      </h1>
      <div id="persistent-message" v-if="persistentMessage.enabled && persistentMessage.message">
        <slot name="persistent_message">
          <p class="message" v-html="persistentMessage.message"></p>
        </slot>
      </div>
    </div>

    <div id="main-container">
      <p class="summary has-message" v-if="PointsService.message.value">
        {{ PointsService.message.value }}
      </p>
      <p
        class="summary found-points"
        v-else-if="!PointsService.loading.value && PointsService.total.value"
        v-html="messages.points_summary_found_points.replace('{points_count}', PointsService.total.value)"
      >
      </p>
      <p class="summary not-found-points" v-else-if="!PointsService.loading.value && !PointsService.total.value">
        {{ messages.points_summary_not_found_points }}
      </p>
      <p class="summary loading" v-else>
        {{ messages.common_loading }}
      </p>

      <!--ページ上部の絞り込み / 検索セクション-->
      <div class="filters" v-if="pointServiceInisialized">
        <slot name="prepend-control"></slot>

        <div class="filter-item" v-for="cmp in filterComponents.page">

          <!--モーダル-->
          <template v-if="cmp.options.page?.wrapModal?.enabled">
            <button class="open-filter uk-button" :class="cmp.options.page.class" @click="cmp.options.page.wrapModal.onClicked" v-wave>
              <span>{{ cmp.label }}</span>
              <span class="material-symbols-sharp" :data-icon="check" v-if="false"></span>
              <span class="material-symbols-sharp" :data-icon="cmp.options.icon" v-else></span>
            </button>
          </template>

          <!--フォーム-->
          <template v-else>
            <component
              :is="cmp.component"
              v-bind="cmp.props"
              @onSearchPlace="onSearchPlace"
              @onEnterKeywordInput="onEnterKeywordInput"
              @onCurrentLocationClicked="onCurrentLocationClicked"
            ></component>
          </template>
        </div>

        <!-- 集約ダイアログ表示ボタン -->
        <div class="filter-item" v-if="useHideModal">
          <button class="open-filter uk-button" :class="{completed: hideModalActived, 'no-gap': messages.points_aggregate_menu_label == ''}" @click="onOpenHideModal" v-wave>
            <span class="label" v-if="messages.points_aggregate_menu_label">{{ messages.points_aggregate_menu_label }}</span>
            <span class="material-symbols-sharp" data-icon="check" v-if="hideModalActived"></span>
            <span class="material-symbols-sharp" data-icon="menu" v-else></span>
          </button>
        </div>

        <slot name="append-control"></slot>

      </div>

      <div class="loading-points" v-if="PointsService.loading.value">
        <div class="uk-margin-auto" uk-spinner="ratio: 2"></div>
      </div>

      <Points
        :points="PointsService.points.value"
        :formats="PointsService.config.points?.point?.formats"
        :searchConditions="PointsService.config.points?.point?.search_conditions"
        :link="usePointPage"
        :openable="usePointPage"
        @onPointSelected="onPointSelected"
        v-show="!PointsService.loading.value && PointsService.isInitialized.value"></Points>

      <Pagination
        @onPageChanged="onPageChanged"
        :limit="limit"
        :page="PointsService.page.value"
        :point-total="PointsService.total.value"
        v-if="PointsService.isInitialized.value">
      </Pagination>
    </div>

    <!-- 絞り込みダイアログ -->
    <div id="FiltersModal" uk-modal v-if="usePointFilter && PointsService.isInitialized.value">
      <div class="uk-modal-dialog">
        <div class="uk-modal-header uk-flex uk-flex-between">
          <p class="uk-modal-title-small">{{ messages.points_open_fiters }}</p>
          <button class="uk-modal-close uk-modal-close-relative" type="button" uk-close></button>
        </div>

        <div class="uk-modal-body" uk-overflow-auto>
          <Filters :selectable="true">
            <template #filter-group-name="props">
              <slot name="filter-group-name" :filter="props.filter" :selectable="props.selectable"></slot>
            </template>
          </Filters>
        </div>

        <div class="uk-modal-footer uk-flex">
          <button class="reset uk-button" @click="onResetFilterClicked" v-wave>
            {{ messages.points_reset_fiters }}
          </button>
          <button class="close uk-button" @click="onFilterClosed" v-wave>
            {{ messages.common_close }}
          </button>
        </div>
      </div>
    </div>

    <!-- 都道府県から探すダイアログ -->
    <div id="AreasModal" uk-modal v-if="usePointFilter && PointsService.areas.value.areas">
      <div class="uk-modal-dialog">
        <div class="uk-modal-header uk-flex uk-flex-between">
          <p class="uk-modal-title-small">{{ messages.points_search_by_prefs }}</p>
          <button class="uk-modal-close uk-modal-close-relative" type="button" uk-close></button>
        </div>

        <div class="uk-modal-body" uk-overflow-auto>
          <AreaSelector :areas="PointsService.areas.value.areas" :hide-group-with-no-points="hideAreaGroupWithNoPoints"></AreaSelector>
        </div>

        <div class="uk-modal-footer uk-flex">
          <button class="reset uk-button" @click="onAreasClosed" v-wave>
            {{ messages.common_close }}
          </button>
        </div>
      </div>
    </div>


    <!-- 集約ダイアログ -->
    <div id="HideModal" uk-modal container="#app" v-if="useHideModal">

      <div class="uk-modal-dialog">
        <div class="uk-modal-header uk-flex uk-flex-between">
          <p class="uk-modal-title-small">{{ messages.points_aggregate_menu_label }}</p>
          <button class="uk-modal-close uk-modal-close-relative" type="button" uk-close></button>
        </div>

        <div class="uk-modal-body" uk-overflow-auto>
          <template v-if="pointServiceInisialized">

            <div class="modal-content" :class="{frame: cmp.options.hideModal.frame }" v-for="cmp in filterComponents.hideModal">
              <h3 v-if="cmp.options.hideModal.showTitle">{{ cmp.label }}</h3>

              <!--アコーディオン-->
              <template v-if="cmp.options.hideModal?.wrapAccordion?.enabled">
                <ul class="accordion" uk-nav>
                  <li class="uk-parent">
                    <a href="#" class="group" v-wave>
                      <span class="material-symbols-sharp" :data-icon="cmp.icon" v-if="cmp.icon"></span> {{ cmp.options.hideModal.wrapAccordion.label }} <span uk-nav-parent-icon></span>
                    </a>
                      <ul class="uk-nav-sub">
                          <li>
                            <component
                              :is="cmp.component"
                              v-bind="cmp.props"
                              @onSearchPlace="onSearchPlace"
                              @onEnterKeywordInput="onEnterKeywordInput"
                              @onCurrentLocationClicked="onCurrentLocationClicked"
                            ></component>
                          </li>
                      </ul>
                    </li>
                </ul>
              </template>

              <template v-else>
                <component
                  :is="cmp.component"
                  v-bind="cmp.props"
                  @onSearchPlace="onSearchPlace"
                  @onEnterKeywordInput="onEnterKeywordInput"
                  @onCurrentLocationClicked="onCurrentLocationClicked"
                ></component>
              </template>

              <div class="filter-actions" v-if="cmp.componentName=='Filters'">
                <button class="reset uk-button" @click="onResetFilterClicked" v-wave>
                  {{ messages.points_reset_fiters }}
                </button>
              </div>

            </div>
          </template>
        </div>

        <div class="uk-modal-footer uk-flex">
          <button class="close uk-button" @click="onHideModalClosed" v-wave>
            {{ messages.common_close }}
          </button>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
/**
 * 店舗を一覧・リストで検索するページ
 *
 * 都道府県から探す時の入口に使う
 * 新店の紹介に使うことがある
 */
import uikit from "uikit"
import { computed, onMounted, ref, watch, shallowRef, defineProps } from 'vue'

import Filters from '@/components/common/Filters.vue'
import Autocomplete from '@/components/common/Autocomplete.vue'

import Pagination from '@/components/points/Pagination.vue'
import Breadcrumb from '@/components/common/Breadcrumb.vue'
import Points from '@/components/common/Points.vue'
import CurrentLocator from './CurrentLocator.vue'
import KeywordSearcher from '@/components/common/KeywordSearcher.vue'
import AreaSelector from './AreaSelector.vue'


import PointsService from "@/services/PointsService.ts"
import { Filters as _Filters } from '@/services/Filters.ts'
import Page from '@/services/Page'
import { parseMessage } from "@/utils/format.ts"

import store from '@/store'

const props = defineProps({
  /**
   * ルートページ
   *
   * パンくずの戻し先の最上位を変更するため、Breadcrumbコンポーネントへ渡す値
   * 詳細はBreadcrumbコンポーネントを参照
   */
  rootPage: {
    type: String,
    default: "map"
  }
})

Page.storage.save('points', location.href)

const messages = computed(() => storelocator.messages)

/**
 * メッセージまわり
 */
const persistentMessage = ref({
  enabled: storelocator.points?.persistent_message?.enabled || false,
  message: parseMessage(storelocator.points?.persistent_message?.message || ''),
})

/**
 * true: 店舗ページを使用
 */
const usePointPage = computed(() => storelocator.point?.enabled)

/**
 * 絞り込みの選択状態
 */
const filtersStore = computed(() => store.getters.filters)

/**
 * 絞り込みを変更したら即時反映
 * ためて適用してもいいが、復元も含めて状態簡易が極めて複雑になる、トリガーも拾いにくい
 * UX的にも特に貯める必要はない
 */
watch(() => filtersStore, () => {
  // console.debug('filtersStore changed', filtersStore.value)
  onPageChanged(1)
}, { deep: true })

/**
 * ポイント絞り込みのモーダル
 */
let filterModal = null

/**
 * 都道府県を選ぶモーダル
 */
let areasModal = null

/**
 * 機能集約モーダル
 */
let hideModal = null

/**
 * windowサイズの監視関数
 * TODO 必要？マップが絡まないページであれば消せるように感じる？
 */
let resizeTimer = null

const interval = Math.floor(1000 / 60 * 10)

function watchWindowSize() {
  store.dispatch('onWindowResized')

  window.addEventListener("resize", () => {
    if (resizeTimer !== null) {
      clearTimeout(resizeTimer)
    }

    resizeTimer = setTimeout(() => {
      store.dispatch('onWindowResized')
    }, interval)
  })
}

/**
 * true: 絞り込み中
 */
const isFiltered = computed(() => PointsService.isFiltered())

/**
 * リセットクリック時
 */
function onResetFilterClicked() {
  PointsService.resetFilter()
  _Filters.cache.reset()

  uikit.notification.closeAll()
  uikit.notification({
    message: messages.value.points_reset_fiters_completed,
    pos: 'top-center',
    timeout: 3000
  })
}

/**
 * Points.emits.onPointSelected
 * @param {*} point
 */
function onPointSelected(point) {
  // console.debug('onPointSelected', point)

  storelocator.analytics.client?.send('SelectPointOnPoints', {
    PointName: point.name,
    PointKey: point.key,
    PointAddress: point.address,
  })
}

/**
 * 1ページに表示する件数
 */
const limit = computed(() => {
  return PointsService.pagination.limit ? PointsService.pagination.limit : 0
})

/**
 * true: 絞り込みを有効化
 * NOTE: PointSearvieの初期化の前に値が決定している？空のオブジェクトが渡るので常にtrueとして判断されている？
 */
const usePointFilter = ref(PointsService.config?.filters?.frontend)

/**
 * true: AreaSelectorでポイントが含まれないグループを隠す
 * PointsService初期化後に設定を反映する必要があるため、onMountedで値を再設定する
 */
const hideAreaGroupWithNoPoints = ref(false)

/**
 * このページのメタ情報
 */
const meta = computed(() => {
  return {
    label: getTitlePrefix()
  }
})

/**
 * 表示する絞り込みが存在するか
 * 存在する：true、1つも存在しない：false
 */
const hasFilters = computed(() => {
  const hasMarkers =  storelocator.markers.some(condition => condition.is_show)
  const hasSearchConditions =  storelocator.search_conditions.some(condition => condition.is_show)
  return hasMarkers || hasSearchConditions
})

/**
 * モーダル集約機能が有効ならtrue
 */
const useHideModal = computed(() => {
  return  storelocator?.points?.default?.filters?.hide?.enabled
    && storelocator?.points?.default?.filters?.hide?.hideFilters
    && storelocator?.points?.default?.filters?.hide?.hideFilters.length > 0
})


/**
 * ページ変更
 * @param {Number} p ページ
 */
async function onPageChanged(p) {
  PointsService.page.value = p
  PointsService.applyFiltersToPoints()

  document.body.scrollTo(0, 0)
}

/**
 * 絞り込みモーダルを開く
 */
function onOpenPointFilter() {
  filterModal.show()
}

/**
 * 都道府県を選ぶモーダルを開く
 */
function onOpenAreas() {
  areasModal.show()
}

/**
 * 集約モーダルを開く
 */
 function onOpenHideModal() {
  hideModal.show()
}

/**
 * 絞り込みモーダルを閉じる
 */
function onFilterClosed() {
  filterModal.hide()
}

/**
 * 都道府県を選ぶモーダルを閉じる
 */
function onAreasClosed() {
  areasModal.hide()
}

/**
 * 集約モーダルを閉じる
 */
 function onHideModalClosed() {
  hideModal.hide()
}

/**
 * 現在地から近い順への並べかえ実行
 * CurrentLocatorコンポーネントからemitを受け取りリストに反映する
 */
async function onCurrentLocationClicked() {
  // console.debug('onCurrentLocationClicked')
  PointsService.pagination.sortBy = "location.current"
  onPageChanged(1)
}

/**
 * @returns {String} タイトルのプレフィックスを返す
 *
 * TODO 多言語考慮
 */
function getTitlePrefix() {
  if (PointsService.areas.value.area) {
    return storelocator.messages.points_title_with_area.replace(/\{area\}/, PointsService.areas.value.area.area_name)
  }

  return messages.value.points_title
}


/**
 * キーワード検索がリストに反映されている場合 true を返す
 */
const isCompletedKeywordSearch = ref(false)

 /**
  * キーワード検索実行
  * KeywordSearcherコンポーネントからクエリを受け取りリストに反映する
  *
  * @param query (String) 検索ワード. 空 or null で全件表示 ( =リセット)
  */
function onEnterKeywordInput(query) {
  _Filters.query.setQuery(query);
  onPageChanged(1)

  if (query == "" || query == null) {
    isCompletedKeywordSearch.value = false
  } else {
    isCompletedKeywordSearch.value = true

    if (storelocator.analytics.client) {
      storelocator.analytics.client.context.SearchByKeywordQuery = query
      storelocator.analytics.client.send('SearchByKeyword', {})
    }
  }
}

/**
 * 地名による並び替えが反映されている場合 true を返す
 */
const isPlacesCompleted = computed(() => PointsService.locations.value.places != null)

/**
 * 地名検索
 * AutocompleteコンポーネントからPlaces（geocoding）結果の緯度経度を受けとる
 * @param {google.maps.LatLng} location LatLngオブジェクト
 */
function onSearchPlace(location) {
  // locationが取れなかった場合の処理、メッセージ表示はAutocompleteコンポーネント側で実施
  if (!location) return

  PointsService.resetLocation()

  PointsService.pagination.sortBy = "location.places"
  PointsService.resetLocation()
  PointsService.locations.value.places = location
  onPageChanged(1)
}

/**
 * 現在地による並び替えが反映されている場合 true を返す
 */
 const isLocationCompleted = computed(() => PointsService.locations.value.current != null)


/**
 * 絞り込み/並び替えに使うコンポーネントの定義
 *
 * templete内で動的にコンポーネントを呼び出すため、コンポーネントに渡すものや画面上に表示するラベルなども一緒に設定する
 * MEMO: 絞り込み/並び替の設定は管理画面から行えるので、機能を追加する時意外にこのオブジェクトを編集する必要はない。
 * 　　　 案件によってやむを得ず編集する必要がある場合はPropsで渡せるようにする等の方法を検討
 *
 * {
 *   "storelocator.points.default.filtersに設定されているキー": {
 *   label: "ラベル", // 通常は Messagesで定義されている値を指定
 *   componentName: "コンポーネント名",
 *   component: shallowRef({コンポーネント}), // 表示するコンポーネントを指定. ここもリアクティブにしないと中の値が参照できない & refだとパフォーマンスが落ちるので shallowRef を使うこと
 *   props: {"props名": "値"},　// コンポーネントに渡すProps
 *   isCompleted: false, // 絞り込み/並び替えが反映されているかを判定するリアクティブなBoole値を設定する（通常はcomputed）
 *
 *   // 細かいオプション
 *   options: {
 *     icon:"MatelialSymbolsのキー", // コンポーネントの外でアイコンを表示したい場合に指定（コンポーネント内のアイコンを置き換えるわけではない）
 *
 *     // ページに直接表示する場合のオプション
 *     page: {
 *       // 追加するクラス。「:class」に渡すObject形式で指定
 *       class: {"クラス名": 判定},
 *
 *       // コンポーネントを専用モーダルで表示する設定
 *       wrapModal: {
 *         enabled: true,　// trueだと有効
 *         id: "ui-kitのモーダル内要素DOMのID",
 *
 *         //モーダルを開くボタンをクリックした際に実行する関数。通常はモーダルを開く関数
 *         onClicked: () => {
 *           onOpenPointFilter()
 *         },
 *       }
 *     },
 *     // 集約モーダル内に表示する場合のオプション
 *     hideModal: {
 *       showTitle: true, // trueにするとモーダル内に絞り込み/並べ替えのタイトルを表示する。Placeholderと冗長になる場合は false
 *       frame: true, // コンポーネントの周辺に罫線を表示する。モーダル内に配置するものが多い場合に true にするとまとまりがわりりやすい
 *
 *       // 集約モーダル内のアコーディオン表示の設定
 *       wrapAccordion: {
 *         enanled: true, // true にするとアコーディオンでの開閉可能に
 *         label: "アコーディオンを開くボタンに表示するラベル",
 *       }
 *     }
 *   }
 * }
 *
 */
 const filterComponentDefinitions = ref({
  "area": {
    label: storelocator.messages.points_search_by_prefs,
    componentName: "AreaSelector",
    component: shallowRef(AreaSelector),
    props: PointsService.areas,
    isCompleted: false,
    options: {
      icon:"map",
      page: {
        wrapModal: {
          enabled: true,
          id: "AreasModal",
          onClicked: () => {
            onOpenAreas()
          }
        },
      },
      hideModal: {
        showTitle: false,
        frame: false,
        wrapAccordion: {
          enabled: true,
          label: storelocator.messages.points_search_by_prefs,
        }
      }
    }
  },
  "places": {
    label: storelocator.messages.points_search_place,
    componentName: "Autocomplete",
    component: shallowRef(Autocomplete),
    isCompleted: isPlacesCompleted,
    props: {
      additionalClass: "points-page",
      isCompleted: isPlacesCompleted,
      placeholder: storelocator.messages.points_search_place
    },
    options: {
      page: {},
      hideModal: {},
    }
  },
  "keywords": {
    label: storelocator.messages.points_keyword_placeholder,
    componentName: "KeywordSearcher",
    component: shallowRef(KeywordSearcher),
    isCompleted: isCompletedKeywordSearch,
    props: {
      isCompleted: isCompletedKeywordSearch,
      placeholder: storelocator.messages.points_keyword_placeholder
    },
    options: {
      page: {},
      hideModal: {},
    }
  },
  "location": {
    label: storelocator.messages.points_current_location,
    componentName: "CurrentLocator",
    component: shallowRef(CurrentLocator),
    isCompleted: isLocationCompleted,
    props: {},
    options: {
      page: {},
      hideModal: {},
    }
  },
  "filter": {
    label: storelocator.messages.points_open_fiters,
    componentName: "Filters",
    component: shallowRef(Filters),
    isCompleted: isFiltered,
    props: {selectable: true},
    options: {
      icon:"filter_list",
      page: {
        class: {completed: isFiltered},
        wrapModal: {
          enabled: true,
          id: "FiltersModal",
          onClicked: () => {
            onOpenPointFilter()
          },
        }
      },
      hideModal: { showTitle: false, frame: true },
    }
  }
})

/**
 * 管理画面のページ設定から必要なコンポーネントを抽出
 */
const filterComponents = computed(() => {
  const filters = storelocator?.points?.default?.filters
  const components = {
    page: [],
    hideModal: []
  }

  // 一覧ページの絞り込みが無効 or 有効な設定がない場合は空で応答
  if (!usePointFilter.value || !filters) return components

  // 管理画面から設定した優先順位順でのソート
  let filterKeys = Object.keys(filters)
  filterKeys.sort((keyA, keyB) => {
    const aOrder = /\d+/.test(filters[keyA].order) ? parseInt(filters[keyA].order): 999
    const bOrder = /\d+/.test(filters[keyB].order) ? parseInt(filters[keyB].order): 999
    return aOrder - bOrder
  })

  filterKeys.forEach(key => {

    // 管理画面から無効化されていたらスキップ
    if (!filters[key]?.enabled) return

    // 有効なコンポーネント定義がなければスキップ
    if (!(key in filterComponentDefinitions.value)) return

    // 有効な絞り込み機能がなければスキップ
    if (key == "filter" && !hasFilters.value) return

    if (filters?.hide?.enabled && filters?.hide?.hideFilters.includes(key)) {
      components.hideModal.push(filterComponentDefinitions.value[key])
    } else {
      components.page.push(filterComponentDefinitions.value[key])
    }
  })
  return components
})

/**
 * 集約モーダル内で有効な絞り込み/並び替えがある場合にtrueを返す
 */
const hideModalActived = computed(() => {
  if (!filterComponents.value.hideModal || filterComponents.value.hideModal.length == 0) {
    return false
  }

  // 地域で絞り込まれていたら常に有効表示に
  if (PointsService.areas.value.area) {
    return true
  }

  return filterComponents.value.hideModal.some(agg => {
    return agg.isCompleted
  })
})


/**
 * PointServiceの準備がととのったら true を返す
 * 一覧ページでは PointsService.areas の読み込み完了も必要
 */
const pointServiceInisialized = computed(() => {
  if (!PointsService.isInitialized.value) {
    return false
  }

  if (storelocator?.points?.default?.filters?.area.enabled && !PointsService.areas.value.areas) {
    return false
  }

  return true
})

onMounted(async() => {
  PointsService.init()

  watchWindowSize()
  PointsService.resetFilter()
  watchWindowSize()

  if (storelocator.points.default.filters.keywords?.targets) {
    _Filters.query.setTargets(storelocator.points.default.filters.keywords.targets)
  }

  PointsService.applyUrlQuery()
  await PointsService.onInitialized()

  // 絞り込みモーダル初期化とポイント絞り込み監視
  if (PointsService.config?.filters?.frontend) {
    filterModal = uikit.modal(`#FiltersModal`, {"stack": true})
    areasModal = uikit.modal(`#AreasModal`, {"stack": true})
    hideModal = uikit.modal(`#HideModal`, {"stack": true})

    if (PointsService.storage.areas.load()) {
      areasModal.show()
    }
  }

  // 設定上書きを考慮して、PointsService初期化後に設定を取得
  // filtersに関連するUI側の設定項目が追加された場合は、ここに追加
  hideAreaGroupWithNoPoints.value = PointsService.config.filters.area?.group?.hide_with_no_points || false
})

</script>

<style lang="scss">
// UI-KITのモーダルと競合するので、Autocompleteのz-indexを高くする
.pac-container {
  z-index: 1200;
}

#app-container.points-page {
  background-color: #fff;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1024px;

  .filters {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 1rem;

    margin: .5rem 0;
    padding: 1rem;

    @media screen and (max-width: $breakpoint-small) {
      margin: .25rem 0;
      flex-direction: column;
      .filter-item {
        width: 100%;

        // NOTE: filterに渡すコンポーネントにクローズボタンなど予期せぬbuttonが含まれた場合、その要素にもwidth指定が効いてしまうので、あまり好ましくない
        // filterの表示実装上、コンポーネント内の構成を制限していないので対応方法が悩ましい
        input, button {
          width: 100%;
        }
      }
    }

    .filter-item > button {
      border-radius: 20px;
      background-color: #fff;
      border: solid 1px #ddd;
      padding: 0 1rem 0 2.5rem;
      transition: all 0.25s;
      position: relative;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row-reverse;
      gap: .5rem;
      min-height: 2.5rem;

      &.no-gap {
        gap: 0;
      }

      @media screen and (min-width: $breakpoint-small) {
        &:hover {
          background-color: var(--theme-color);
          color: var(--text-color);
          border-color: #fff;
        }
      }

      &:active {
        background-color: var(--theme-color);
        color: var(--text-color);
      }

      span.material-symbols-sharp {
        position: absolute;
        left: 0.5rem;
        opacity: .7;
        transition: all 0.25s;
      }

      &.completed {
        background-color: var(--theme-color);
        border-color: var(--theme-color);
        color: var(--text-color);
      }
    }

    .filter-item input {
      @media only screen and (max-width: #{$breakpoint-small}) {
        text-align: center;
      }
    }
  }

  .summary {
    margin: 0 1rem;
    font-size: 0.9rem;
    height: 2rem;
    .count{
      font-size: 1.5rem;
      margin: 0 0.25rem 0 0;
    }
  }

  .loading-points {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 5rem 0;
  }

  .title {
    font-size: 1.5rem;
    margin: 2rem 0;
    display: flex;
    align-items: center;
    .material-symbols-sharp {
      font-size: 2.5rem;
      margin-right: .5rem;
    }
  }

  .list-result-message {
    margin: 2rem auto 3rem auto;
    font-size: .875rem;
    text-align: center;
  }

  .overlay-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    color: white;
    font-size: .875em;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);

    .points-overlay {
      box-sizing: border-box;
      max-width: calc(100% - 4rem);
      background-color: rgba(0, 0, 0, 0.6);
      padding: 1rem;
      border-radius: .25rem;
      text-align: center;
      p {
        color: #fff;
      }
    }
  }

  .points {
    .point-wrapper {
      &:first-child {
        border-top: 1px solid #eee;
      }
    }
  }

  #persistent-message {
    margin: 0 0 0.75rem 0;
    color: #666;

    .message {
      margin: 0;
      line-height: 1.25em;
      font-size: 14px;
      white-space: pre-wrap;
      overflow-wrap: break-word;
    }
  }
}

#FiltersModal, #AreasModal, #HideModal {
  .uk-modal-dialog {
    .uk-modal-header {
      padding: 1rem 1.5rem 1rem 1rem;
      p {
        font-size: 1.125rem;
      }
    }
    .uk-modal-body {
      padding: 0;
      a {
        color: #444;
      }
    }
    .uk-modal-footer {
      padding: 1rem;

      justify-content: center;
      gap: 0 1rem;

      button {
        border-radius: 20px;
      }
    }
    .reset {
      background-color: #fff;
      transition: all 0.25s;
      border-radius: 20px;
      &:hover {
        background-color: #eee;
      }
    }
  }
}

// 集約モーダル固有
#HideModal {
  .uk-modal-header {
    border-bottom: solid 1px #eee;
  }
  .uk-modal-footer {
    border-top: solid 1px #eee;
  }

  .uk-modal-body {
    h3 {
      font-size: .75rem;
      opacity: .75;
      font-weight: bold;
      margin-bottom: .5rem;
    }
    .modal-content {
      margin: 1rem;
      padding-bottom: .5rem;

      &.frame {
        padding: 1rem;
        border: solid 1px #ddd;
        border-radius: 1rem;
      }

      .accordion.uk-nav {
        background-color: rgba(180, 180, 180, 0.1);
        border-radius: 20px;
        .group {
          padding: .75rem 1rem;
          font-size: .875rem;

          span.material-symbols-sharp {
            opacity: .7;
            transition: all 0.25s;
            margin-right: .25rem;
          }
        }
      }

      // フィルターコンポーネントのスタイルを集約モーダル用に合わせる
      .filters {
        .filter-parent {
          margin: 1rem 0;

          &.small {
            margin: 8px;
          }
        }
      }

      .filter-actions {
        text-align: right;
        button.reset {
          margin-top: 1rem;
        }
      }
    }
  }
}

// Autocomplete.vueコンポーネントのスタイル上書き
.autocomplete-wrapper.points-page {
  background: none;
  .search-input {
    display: flex;
    align-items: center;
    flex-direction:row;

    .uk-form-icon-flip {
      display: none;
    }

    #autocomplete {
      min-width: 21em; //デフォルトのプレースホルダの文字数に合わせた調整
      background-color: #fff;
      border: 1px solid #ddd;
      padding-right: 10px !important;
      padding-left: 40px !important;

      &.active {
        background-color: var(--theme-color);
        border-color: var(--theme-color);
        color: var(--text-color);
      }
      @media screen and (max-width: $breakpoint-small) {
        min-width: 100%;
      }
    }
  }
  .prefix-search-icon {
    display: inline-flex;
  }

  &:focus-within {
    margin: 0 !important;
    width: 100%;

    #autocomplete {
      background: #777;
      color: #fff;
      transition: all .25s;

      &::placeholder {
        color: #aaa;
      }
    }
  }
}

// KeywordSeacher.vueコンポーネントのスタイル上書き
.points-page {
  .keyword-searcher {
    &:focus-within {
      margin: 0 !important;
      width: 100%;
    }
  }
  .keyword-input {
    background-color: #fff;
    border: solid 1px #ddd;

    &:focus {
      width: 100%;
      background: #777;
      color: #fff;
      transition: all .25s;

      &::placeholder {
        color: #aaa;
      }
    }
  }
}
</style>

/**
 * 店舗の一覧ページのルートコンポーネント
 * 
 * main.jsによって初期化され、テンプレートはJinjaの以下と紐づく
 * templates/organizations/{...}/points.html > templates/default/points.html
 */

import PointsPage from '@/components/points/PointsPage.vue'

export default {
  components: {PointsPage},
  setup() {
  }
}
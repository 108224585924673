import urlhash from './urlhash'
import Split from 'split.js'

/**
 * マップを管理する基本クラス
 */
export class BaseMapManager {
  constructor() {
  }

  /**
   * ライブラリ読み込み
   * @param {Array<string>} libraries 読み込むライブラリ（map, places 等）
   *   https://developers.google.com/maps/documentation/javascript/libraries?hl=ja
   */
  async load(libraries) {
    const promises = libraries.map(lib => google.maps.importLibrary(lib))
    return await Promise.all(promises)
  }

  /**
   * 初期化
   * @param {HTMLElement} mapDiv
   * @param {google.maps.MapOptions} options Options
   */
  init(mapDiv, options=null, extra=null) {
    const goga_latlng = new google.maps.LatLng(35.657896633621114, 139.70518672800154)
    const default_options = {
      zoom: 14,
      center: goga_latlng,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      clickableIcons: false,
      mapTypeControl: true,
      mapTypeControlOptions: {
        position: google.maps.ControlPosition.RIGHT_TOP
      },
      streetViewControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM
      },
      fullscreenControl: false,
      scaleControl: true,
      keyboardShortcuts: false
    }

    if (options) {
      Object.keys(options).forEach(function (key) {
        default_options[key] = options[key]
      })
    }

    this._map = new google.maps.Map(mapDiv, default_options)
    this._geocoder = new google.maps.Geocoder()
    this._places = new google.maps.places.PlacesService(this.map)
    this._infowindow = new google.maps.InfoWindow()
  }

  get map() {
    return this._map
  }

  get geocoder() {
    return this._geocoder
  }

  get places() {
    return this._places
  }

  get infowindow() {
    return this._infowindow
  }

  /**
   * URLのハッシュ制御を有効にする
   */
  enableLocationHash() {
    this._enable_hash = true
    google.maps.event.addListener(this.map, 'idle', () => {
      if (this._enable_hash) {
        urlhash.replace(this.getLocationHash())
      }
    })
    const onhashchange = this.onHashChange.bind(this)
    urlhash.init()
    urlhash.addListener(onhashchange)
    urlhash.fire(onhashchange)
  }

  /**
   * URLハッシュが変更された際の処理
   * @param {string} hash URLハッシュ
   */
  onHashChange(hash) {
    const params = (hash) ? hash.split(',') : []
    if (params.length >= 2) {
      // 緯度,軽度(,ズーム)
      const lat = parseFloat(params[0])
      const lng = parseFloat(params[1])
      if (!isNaN(lat) && !isNaN(lng)) {
        this.map.setCenter({ lat, lng });
        if (params.length == 3) {
          if (params[2].endsWith('z')) {
            const z = parseFloat(params[2].slice(0, -1))
            if (!isNaN(z)) {
              this.map.setZoom(z);
            }
          }
        }
      }
    }
  }

  /**
   * URLのハッシュ制御を一時停止する
   * 詳細パネルを開いた際などに使用する
   */
  stopLocationHash() {
    this._enable_hash = false
  }

  /**
   * URLのハッシュ制御を再開する
   * 詳細パネルを閉じた際などに使用する
   */
  restartLocationHash() {
    this._enable_hash = true
    urlhash.replace(this.getLocationHash())
  }

  /**
   * 地図の緯度経度/ズームレベルをハッシュ用の文字列として返す
   * @returns {string} ハッシュ用の文字列
   */
  getLocationHash() {
    const center = this.map.getCenter()
    const zoom = this.map.getZoom()
    const n = 1000000
    return Math.round(center.lat() * n) / n + ',' + Math.round(center.lng() * n) / n + ',' + Math.round(zoom * n) / n + 'z'
  }

  /**
   * 現在地取得ボタンを追加する
   * @param {google.maps.ControlPosition} position 現在地取得ボタンの位置
   */
  addLocationControl(position) {
    const el = document.createElement('div')
    el.className = 'current-location';
    el.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="18px" height="18px"><path d="M0 0h24v24H0z" fill="none"/><path fill="#666666" stroke="#666666" stroke-width="1" d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"/></svg>`
    el.onclick = this.panToCurrentLocation.bind(this)
    this.map.controls[position].push(el)
  }

  /**
   * オートコンプリートフォームを追加する
   * @param {google.maps.ControlPosition} position オートコンプリートフォームの位置
   */
  addAutocompleteControl(position) {    
    const form = document.createElement('form')
    form.className = 'autocomplete';
    const input = document.createElement('input')
    input.setAttribute("type", "text")
    form.appendChild(input)

    const button = document.createElement('a')
    button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"/></svg>'
    button.onclick = () => {
      this.panToQueryText(input.value)
    }
    form.appendChild(button)

    this.map.controls[position].push(form)
    this.setAutocomplete(input)

    form.onsubmit = () => {
      return false
    }  
  }

  /**
   * メッセージ表示用の枠を追加する
   * @param {google.maps.ControlPosition} position メッセージ表示の位置
   */
  addMessageControl(position) {
    this._msg_el = document.createElement('div')
    this._msg_el.classList.add('map-message')
    this.map.controls[position].push(this._msg_el)

    this.messages = {}
  }

  /**
   * 地図上にメッセージを表示する
   * @param {string} id メッセージのID（削除するときに使用）
   * @param {string} message 表示するメッセージ
   * @param {int} priority 優先度（一番数字が大きいもののみ表示される）
   * @param {number} timeout メッセージを消すまでの時間（0を指定すると自動的に消さない）
   */
  showMessage(id, message, priority, timeout=0) {
    // console.debug('showMessage', id, message, priority)
    if (!this._msg_el) {
      return
    }

    this.messages[id] = {message, priority}

    let msg = null
    let p = -1
    Object.values(this.messages).forEach(m => {
      if (m.priority >= p) {
        p = m.priority
        msg = m.message
      }
    })
    this._msg_el.innerHTML = msg

    // スタイルを直接操作するとGMP側で上書きされてしまうので、クラスを付与する
    this._msg_el.classList.add('visible')
  }

  /**
   * 地図上のメッセージを非表示にする
   * @param {string} id メッセージのID
   */
  hideMessage(id) {
    if (!this._msg_el) {
      return
    }

    delete this.messages[id]

    if (Object.keys(this.messages).length == 0) {
      this._msg_el.innerHTML = ''
      this._msg_el.classList.remove('visible')
    } else {
      let msg = null
      let p = -1
      Object.values(this.messages).forEach(m => {
        if (m.priority >= p) {
          p = m.priority
          msg = m.message
        }
      })
      this._msg_el.innerHTML = msg
    }
  }

  /**
   * 入力フィールドにオートコンプリートを設定する
   * @param {HTMLInputElement} inputField オートコンプリートを設定する入力フィールド
   * @param {number} max_zoom (optional) 最大ズームレベル
   */
   setAutocomplete(inputField, max_zoom=17) {
    const autocomplete = new google.maps.places.Autocomplete(
      inputField,
      {
        componentRestrictions: {country: 'jp'},
        fields: ['geometry']
      }
    )
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace()
      if (place.geometry) {
        this.panToGeometry(place.geometry, max_zoom)
      } else {
        this.panToQueryText(place.name, max_zoom)
      }
    })
  }

  /**
   * 指定したクエリ文字列の場所に地図を移動する
   * setAutocomplete から呼び出されるので必要に応じてオーバーライド
   * @param {string} query クエリ文字列
   * @param {number} max_zoom (optional) 最大ズームレベル
   */
  panToQueryText(query, max_zoom=17) {
    this.panToQueryTextPlaces(query, max_zoom)
  }

  /**
   * 指定したクエリ文字列の場所に地図を移動する（ジオコーディング版）
   * @param {string} query クエリ文字列
   * @param {number} max_zoom (optional) 最大ズームレベル
   */
  panToQueryTextGeocoding(query, max_zoom=17) {
    // 現状は geocoding を使用しているが、places を使ったほうが良いかも
    const options = {
      'address': query,
      'region': storelocator.google_maps?.region || 'JP'
    }
    this.geocoder.geocode(options, (results, status) => {
      if (status == google.maps.GeocoderStatus.OK) {
        this.panToGeometry(results[0].geometry, max_zoom)
      }
    })
  }

  /**
   * 指定したクエリ文字列の場所に地図を移動する（プレイス版）
   * @param {string} query クエリ文字列
   * @param {number} max_zoom (optional) 最大ズームレベル
   */
  panToQueryTextPlaces(query, max_zoom=17) {
    const options = {
      query: query,
      fields: ['geometry'],
      language: 'ja'
    }
    this.places.findPlaceFromQuery(options, (results) => {
      if (results.length > 0) {
        this.panToGeometry(results[0].geometry, max_zoom)
      }      
    })
  }

  /**
   * 指定した PlaceGeometry の場所に地図を移動する
   * @param {PlaceGeometry} geometry 対象のジオメトリ
   * @param {number} max_zoom (optional) 最大ズームレベル
   */
  panToGeometry(geometry, max_zoom=17) {
    const bounds = geometry.viewport
    if (bounds) {
      this.fitBounds(bounds, max_zoom)
    } else {
      this.map.setZoom(max_zoom)
      this.map.panTo(geometry.location)
    }
  }  

  /**
   * 現在地に地図を移動する
   */
  panToCurrentLocation() {
    const self = this
    function success(position) {
      if (position.coords.accuracy > 100000) {
        self.showMessage('位置を取得できませんでした', 3000)
        return
      }
      self.hideMessage()
      const p = new google.maps.LatLng(position.coords.latitude, position.coords.longitude)
      const svg = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 23 23" style="enable-background:new 0 0 23 23;" xml:space="preserve"><style type="text/css">	.st0{opacity:0.25;}	.st1{fill:#4285F4;}	.st2{fill:#FFFFFF;}</style><g class="st0">	<circle class="st1" cx="11.5" cy="11.5" r="11.5"/></g><g>	<circle class="st1" cx="11.5" cy="11.5" r="5.75"/>	<path class="st2" d="M11.5,6.5c2.76,0,5,2.24,5,5s-2.24,5-5,5s-5-2.24-5-5S8.74,6.5,11.5,6.5 M11.5,5C7.91,5,5,7.91,5,11.5		S7.91,18,11.5,18s6.5-2.91,6.5-6.5S15.09,5,11.5,5L11.5,5z"/></g></svg>`

      if (!self._location_marker) {
        self._location_marker = new google.maps.Marker({
          icon: {
            url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svg),
            'size': new google.maps.Size(23, 23),
            'scaledSize': new google.maps.Size(23, 23),
            'origin': new google.maps.Point(0, 0),
            'anchor': new google.maps.Point(11.5, 11.5)
          },
          position: p,
          map: self.map,
          optimized: false
        });
        self.map.panTo(p);

      } else {
        self._location_marker.setPosition(p);
      }
    }
    function error(error) {
      self.showMessage('位置を取得できませんでした', 3000)
    }
    if (this._watch_id) {
      if (this._location_marker) {
        this.map.panTo(this._location_marker.getPosition());
      }
    } else {
      this.showMessage('位置を取得しています...')
      this._watch_id = navigator.geolocation.watchPosition(success, error, {
        enableHighAccuracy: true
      })
    }
  }

  /**
   * ズームレベル制限付き fitBounds
   * bounds が小さすぎてズームしすぎるのを防ぐのに使用する
   * 地図を初期化した直後は max_zoom が機能しないので注意
   * @param {google.maps.LatLngBounds} bounds Bounds to show
   * @param {number} max_zoom 最大ズームレベル
   * @param {number} padding (optional) Padding in pixels
   * @returns 設定したズームレベル
   */
  fitBounds(bounds, max_zoom, padding=30) {
    if (!max_zoom) {
      this.map.fitBounds(bounds, padding)
      return
    }

    var zoom = this.getZoomByBounds(bounds, padding)
    if (!zoom) {
      // 地図を初期化した直後は計算できないので通常の fitBounds を呼ぶ（max_zoom は機能しない）
      this.map.fitBounds(bounds)
      return
    }
    if (zoom > max_zoom) {
        zoom = max_zoom
    }
    this.map.panTo(bounds.getCenter())
    this.map.setZoom(zoom)
    return zoom
  }

  /**
   * 指定した Bounds を表示するのに必要な最大のズームレベルを取得する
   * @param {google.maps.LatLngBounds} bounds Bounds to show
   * @param {number} padding Padding in pixels
   * @returns {number} 計算したズームレベル
   */
  getZoomByBounds(bounds, padding){
    var mapType = this.map.mapTypes.get(this.map.getMapTypeId())
    if (!mapType) {
      // 地図を初期化した直後は mapType が取得できない
      return
    }
    var maxZoom = mapType.maxZoom ? mapType.maxZoom : 21
    var minZoom = mapType.minZoom ? mapType.minZoom : 0
    var northEast = this.map.getProjection().fromLatLngToPoint(bounds.getNorthEast())
    var southWest = this.map.getProjection().fromLatLngToPoint(bounds.getSouthWest())
    var worldCoordWidth = Math.abs(northEast.x - southWest.x)
    var worldCoordHeight = Math.abs(northEast.y - southWest.y)
    for (var zoom = maxZoom; zoom >= minZoom; --zoom) {
      if (worldCoordWidth * (1 << zoom) + 2 * padding < this.map.getDiv().clientWidth && worldCoordHeight * (1 << zoom) + 2 * padding < this.map.getDiv().clientHeight) {
        return zoom
      }
    }
    return 0
  }

  /**
   * 分割表示版のストリートビューを有効にする
   * @param {string} mapDivId mapDiv の ID
   * @param {string} streetViewDivId streetViewDiv の ID
   * @param {google.maps.StreetViewPanoramaOptions} options Options
   */
  enableSplitterStreetView(mapDivId, streetViewDivId, options) {
    const el = document.getElementById(streetViewDivId)
    el.style.display = 'none'

    const default_options = {
      enableCloseButton: true,
      fullscreenControl: false,
      addressControlOptions: {
        position: google.maps.ControlPosition.TOP_RIGHT,
      }
    }
    if (options) {
      Object.keys(options).forEach(function (key) {
        default_options[key] = options[key]
      })
    }
    const sv = new google.maps.StreetViewPanorama(el, default_options)
    this.map.setStreetView(sv)

    this._splitter = null
    this._splitter_last_size = null
  
    google.maps.event.addListener(sv, 'pano_changed', () => {
      if (this._splitter) {
        return
      }

      document.getElementById(streetViewDivId).style.display = 'block'
  
      setTimeout(() => {
        this._splitter = Split(['#' + streetViewDivId, '#' + mapDivId], {
          sizes: this._splitter_last_size ? this._splitter_last_size : [50, 50],
          direction: 'vertical',
          gutterSize: 2,
          gutter: function(index, direction) {
            const gutter = document.createElement('div')
            gutter.className = 'map-gutter'
            gutter.innerHTML = '<div class="handle"><svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M192-360v-72h576v72H192Zm0-168v-72h576v72H192Z"/></svg></div>'
            return gutter
          }
        })
      }, 0)
      
    })
    google.maps.event.addListener(sv, 'closeclick', () => {
      this._splitter_last_size = this._splitter.getSizes()
      this._splitter.destroy(false, false)
      this._splitter = null
      document.getElementById(streetViewDivId).style.display = 'none'
    })

  }
}

import { PointManager } from "./PointManager.ts"
import { PointMarker } from "./PointMarker.ts"

interface PointClusterOptions {
  clickable?: boolean
  isSelected?: boolean
  detailPoint?: boolean
  onClicked?: () => void
}

/**
 * クラスタ１つごとのまとまり？
 */
export class PointCluster {
  points: Point[] = []

  /**
   * ?
   */
  bounds: google.maps.LatLngBounds

  /**
   * マーカー
   */
  marker: PointMarker = null

  /**
   * 管理用
   */
  parent: PointManager

  /**
   * クラスタを示す一意のid
   * Point.idを.で繋いだもの
   */
  clusterId: string = null

  /**
   * true: クリック可能
   */
  clickable: boolean

  /**
   * true: 選択中
   */
  isSelected: boolean

  /**
   * true: 店舗ページのPoint、強調する
   */
  detailPoint: boolean

  /**
   * クリック時
   */
  _onClicked: (parent?: PointCluster) => void = (parent?: PointCluster) => {}

  constructor(bounds: google.maps.LatLngBounds, parent: PointManager, options: PointClusterOptions = {}) {
    // console.debug("PointCluster", bounds, parent, options)

    this.bounds = bounds
    this.parent = parent
    this.clickable = options['clickable'] != undefined ? options['clickable'] : true
    this.isSelected = options['isSelected'] != undefined ? options['isSelected'] : false
    this.detailPoint = options['detailPoint'] != undefined ? options['detailPoint'] : false

    if (options.onClicked) {
      this._onClicked = options.onClicked
    }
  }

  /**
   * 描画
   * 
   * 最初に表示する時はここ
   */
  render() {
    if (!this.marker) {
      this.marker = new PointMarker(
        this.parent.map,
        // boundsは拡張されているので、１つのPointの場所だとズレる可能性がある
        // https://app.asana.com/0/1207838392408252/1208878405455742/f
        this.points.length === 1
          ? this.points[0].position
          : this.bounds.getCenter(),
        this,
        {
          clickable: this.clickable
        }
      )
    }
  }

  /**
   * Pointを所属させる
   */
  mergePoints(points: Point[]) {
    // console.debug('mergePoints', points, 'to', this.points)

    this.points = this.points.concat(points)
    this.setId()

    //マージトランジッション終了をまって値をアップデート
    setTimeout(() => {
      this.marker.update()
      this.select(false)
    }, 600)
  }

  /**
   * クラスタからポイントを分離
   * 
   * @param position 分離後の位置
   */
  separate(position: google.maps.LatLng) {
    // console.debug('separate', position, this.points.length)

    if (!this.marker) {
      this.marker = new PointMarker(
        this.parent.map,
        position,
        this,
        {
          // 移動先が1つ→集合ではないので、そのPointの位置に移動（SLV3A-2068）
          // https://app.asana.com/0/1207838392408252/1208878405455742/f
          next_position: this.points.length === 1
            ? this.points[0].position
            : this.bounds.getCenter(),
          clickable: this.clickable
        }
      )
    }
  }

  /**
   * クラスター再計算・統合時不要になったマーカー削除
   */
  remove(position: google.maps.LatLng = null) {
    // console.debug('remove', position)

    if (!position) {
      this.marker.setMap(null)
    }

    this.marker.next_position = position
    this.marker.remove()
  }

  /**
   * クラスターにポイントを所属させる
   */
  addPoint(point: Point) {
    this.points.push(point)
    this.setId()

    //描画済みだったらアップデート
    if (this.marker) {
      this.marker.update()
      this.select(this.isSelected)
    }
  }

  setId () {
    this.clusterId = this.points.map(p => p.id).sort().join(".")
  }

  select(selected: boolean = null) {
    if (selected === this.isSelected) {
      return
    }

    if (selected === null) selected = !this.isSelected

    if (selected) {
      this.marker.select()
    } else {
      this.marker.deselect()
    }
    this.isSelected = selected
  }

  getPointIds() {
    return this.points.map(p => p.id)
  }

  getPoint(pointId: number) {
    return this.points.find(p => {
      return p.id == pointId
    })
  }

  /**
   * TODO 追加時に最新化されない？
   */
  getBounds(): google.maps.LatLngBounds {
    return this.bounds
  }

  onClicked() {
    this._onClicked(this)
  }
}


// サーバ用
// viteが dynamic import + 変数 を扱えないので、静的なインポートに留める
// frontend/main.jsに置いて使う

import "@babel/polyfill"

import { createApp } from 'vue'
import store from '@/store'
import uikit from 'uikit'
import icons from 'uikit/dist/js/uikit-icons'
import VWave from 'v-wave'

import { Analytics } from '@/utils/analytics.ts'
import { getParams } from '@/utils/common'

import MapService from "@/services/MapService.ts"

import "@/styles/main.scss"

import '@/organizations/_default/style.scss'

// map
import '@/components/map/style.scss'
import MapPage from '@/organizations/_default/map.js'  // ビルド時にここを差し替える

// point
import '@/components/point/style.scss'
import PointPage from '@/organizations/okb/default/point.js'  // ビルド時にここを差し替える

// points
import '@/components/points/style.scss'
import PointsPage from '@/organizations/_default/points.js'  // ビルド時にここを差し替える

// 404（ここは差し替えない）
import '@/components/notfound/style.scss'
import NotFoundPage from '@/organizations/_default/notfound.js'  // ビルド時にここを差し替える

import '@/organizations/okb/default/style.scss'  // ビルド時にここを差し替える


storelocator.debug = storelocator.url_params.debug != undefined

// 指定された組織、テーマに合わせたコンポーネントの動的インポートとアプリのセットアップ
const setup = {
  page: storelocator.page ? storelocator.page : 'map',
  rootComponent: {
  },
  /**
   * 必要なCSS、コンポーネントの読み込み
   * 組織・ページ別 > 組織・デフォルト > デフォルト の順で読む
   */
  async loadConponents() {
    // 分析用
    const sendTo = storelocator.analytics?.google_analytics?.send_to
    if (sendTo) {
      const params = getParams()

      storelocator.analytics.client = new Analytics(
        sendTo,
        storelocator.analytics?.google_analytics?.debug || params.debug != undefined,
        storelocator.analytics?.google_analytics?.ignore || params.ignore != undefined,
        storelocator.analytics?.config
      )

      const PAGE_EVENTS = {
        top: 'PageViewTop',
        map: 'PageViewMap',
        point: 'PageViewPoint',
        points: 'PageViewPoints',
      }
      storelocator.analytics.client?.send(PAGE_EVENTS[this.page])
    }
  },

  async create() {
    const components = {
      top: MapPage,
      map: MapPage,
      point: PointPage,
      points: PointsPage,
      notfound: NotFoundPage
    }
  
    const app = createApp(components[this.page])
    app.use(store)
    app.use(VWave)
    uikit.use(icons)
    app.mount("#app")
  },

  async init() {
    await this.loadConponents()
    await this.create()
  }
}

MapService.manager.load([
  'places', // Placesオートコンプリート
  'routes', // ルート検索
  'geometry', // 距離計算
  'marker', // Advanced Marker
  // '3d' // 3Dビュー version=alphaの時のみ試し中
]).then(() => {
  setup.init()
}).catch((error) => {
  console.error(error)
})

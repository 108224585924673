/**
 * 店舗の詳細ページのルートコンポーネント
 *
 * mainjsによって初期化され、テンプレートはJinjaの以下と紐づく
 * templates/organizations/{...}/point.html > templates/default/point.html
 */

import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'

import PointService from "@/services/PointService.ts"
import Page from "@/services/Page.js"
import { Filters as _Filters } from "@/services/Filters.ts"
import { deepClone } from "@/core/common.js"

import Breadcrumb from '@/components/common/Breadcrumb.vue'
import Filters from '@/components/common/Filters.vue'
import PointAttribute from '@/components/common/PointAttribute.vue'
import GoogleMapForPoint from "@/components/point/GoogleMapForPoint.vue"
import Stations from '@/components/point/Stations.vue'
import SocialActions from '@/components/point/SocialActions.vue'
import Points from '@/components/common/Points.vue'
import { parse, parseMessage } from "@/utils/format.ts"


export default {
  components: {Breadcrumb, GoogleMapForPoint, PointAttribute, Points, Filters, Stations, SocialActions},
  setup() {
    const store = useStore()

    /**
     * ウィンドウサイズ監視時のデバウンス設定
     *
     * ウィンドウサイズが変わった時に挙動を変更する必要がある処理のため、幅の監視を行う
     * ウィンドウ幅が変更され、ブレイクポイントが変わるとstoreの値を更新させる
     */
    let resizeTimer = null
    const interval = Math.floor(1000 / 60 * 10)

    onMounted(async () => {
      watchWindowSize()

      if (storelocator.point.stations.enabled) {
        // 近隣駅を取得
        await store.dispatch('updateStations', storelocator.point.point)
      }

      _Filters.cache.updateLocation()
    })

    function watchWindowSize() {
      store.dispatch('onWindowResized')

      window.addEventListener("resize", () => {
        if (resizeTimer !== null) {
          clearTimeout(resizeTimer)
        }
        resizeTimer = setTimeout(() => {
          store.dispatch('onWindowResized')
        }, interval)
      })
    }

    /**
     * 近隣店を選択時
     * @param {Object} point Point
     */
    function onNearPointClicked(point) {
      location.href = Page.getPointUrl(point)
    }

    /**
     * JINJA2のテンプレートで利用するGoogleMapsへのリンクURL
     * settingsの設定を見て名前検索か、緯度経度検索いずれかのURLを返す
     * @param {String} name 店舗名
     * @param {Number} lat 緯度
     * @param {Number} lng 経度
     */
    const googlemapsLink = computed(() => {
      return (name, lat, lng) => {
        if (!storelocator.point.google_map_opener?.enabled) {
          return ''
        }

        const query = storelocator.point.google_map_opener?.by === 'name' ? `query=${name}`: `query=${lat}%2C${lng}`
        return `https://www.google.com/maps/search/?api=1&${query}`
      }
    })

    /**
     * Googleマップへのリンク（名称・住所）
     * @param {String} address 住所
     * @param {String} name 名称
     */
    const googlemapsLinkByAddress = computed(() => {
      return (address, name) => {
        return Page.getGoogleMapsLink(null, null, `${address} ${name}`)
      }
    })

    /**
     * true: 凡例を表示
     */
    const isShowFiltersHint = computed(() => {
      return storelocator.point?.filters?.help?.show
    })

    /**
     * 近隣店
     *
     * PointService.pointsのうち必要なものを抱える
     */
    const nearPoints = ref([])

    let nearPointsInitialized = false

    // 近隣店
    PointService.afterLoadPoints = (points) => {
      // 並び替える際に距離を埋め込むので、隔離しておく
      const _points = deepClone(points)
      if (nearPointsInitialized) {
        return
      }

      const point = storelocator.point.point

      // 先に並べる
      const sortedPoints = PointService.sortByDistance(
        _points,
        new google.maps.LatLng(
          point.latitude,
          point.longitude
        )
      )

      const NO_DISTANCE = -1
      let maxDistanceKm = NO_DISTANCE

      // max.distanceが 0 より大きい数値の場合その値を採用
      // それ以外(0, null, undefined, NaN, etc.)は、全て NO_DISTANCE として扱う
      if (storelocator.point?.nearby?.max?.enabled && storelocator.point?.nearby?.max?.distance > 0) {
        maxDistanceKm = storelocator.point?.nearby?.max?.distance
      }

      // 表示する店舗に絞る
      const filteredPoints = sortedPoints.filter((p, i) => {
        // 近いものの先頭、最大6店舗に絞る
        if (i > 5) {
          return false
        }

        // NO_DISTANCE の場合は距離制限をかけない
        if (maxDistanceKm !== NO_DISTANCE) {
          // p.distanceはメートルなので、キロメートルに変換
          const pointDistanceKm = p.distance / 1000

          // maxDistanceKmより遠い店舗は非表示
          if (pointDistanceKm > maxDistanceKm) {
            return false
          }
        }

        // 店舗ページのポイントは除く
        // NOTE: GoogleMapForPoint.vueにて、PointService.filters.excluedsに店舗ページのポイントが既に含まれているので不要？
        return p.key !== point.key
      })

      nearPoints.value = filteredPoints

      nearPointsInitialized = true
    }

    /**
     * 店舗
     */
    const point = computed(() => {
      return storelocator.point.point
    })

    /**
     * 店舗のフォーマット
     */
    const pointFormats = computed(() => {
      return storelocator.point?.formats
    })

    /**
     * 近くの店舗のフォーマット
     */
    const nearPointFormats = computed(() => {
      return storelocator.point?.nearby?.points?.point?.formats
    })

    /**
     * 近くの店舗の設定
     */
    const nearPointSearchConditions = ref(storelocator.point?.nearby?.points?.point?.search_conditions)

    /**
     * 表記
     */
    const messages = computed(() => {
      return storelocator.messages
    })

    /**
     * メッセージまわり
     */
    const persistentMessage = ref({
      enabled: storelocator.point?.persistent_message?.enabled || false,
      message: parseMessage(storelocator.point?.persistent_message?.message || ''),
    })

    /**
     * 近隣駅まわり
     */
    const stations = ref({
      enabled: storelocator.point?.stations?.enabled || false,
      max: {
        enabled: storelocator.point?.stations?.max?.enabled,
        distance: storelocator.point?.stations?.max?.distance
      }
    })

    /**
     * Pointの属性のラベルを取得
     * @param {String} label design.point.formats[].label
     * @returns {String} 挿入・変換を済ませたラベル
     */
    function getPointAttributeLabel(label, key) {
      if (!label) {
        return key
      }

      return parse({}, {
        format: label
      })
    }

    /**
     * Pointの属性の値に書式を適用したものを取得
     * @param {Object} format design.point.formats[].format
     * @returns {String} 挿入・変換を済ませた値, 表示対象外ならnull
     */
    function getPointAttributeValue(format) {
      // console.debug('getPointAttributeValue', format.key, parse(point.value, format))

      const value = parse(point.value, format)
      if (value === '' && format.hide_on_empty) {
        return null
      }

      return value
    }

    const rootPage = Page.rootPage

    return {
      messages,
      point,
      rootPage,
      pointFormats,
      getPointAttributeLabel,
      getPointAttributeValue,
      isShowFiltersHint,
      onNearPointClicked,
      nearPointFormats,
      nearPoints,
      nearPointSearchConditions,
      googlemapsLink,
      googlemapsLinkByAddress,
      persistentMessage,
      stations,
    }
  }
}

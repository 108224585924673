<template>
  <div v-if="openable" class="point openable" v-wave>
    <Marker :icon="getMarkerIcon(point)"></Marker>
    <h4 class="name">{{ point.name }}</h4>

    <div class="attributes">
      <slot name="attributes"></slot>
    </div>

    <span class="to-point" uk-icon="icon:  chevron-right; ratio: 1.0" @click="onToPointClicked"></span>
  </div>
  <div v-else class="point" v-wave="false">
    <Marker :icon="getMarkerIcon(point)"></Marker>
    <h4 class="name">{{ point.name }}</h4>

    <div class="attributes">
      <slot name="attributes"></slot>
    </div>
  </div>
</template>

<script setup>
/**
 * Point１つ分
 * 
 * 近くの店舗やクラスタ内の店舗に使う想定
 */

import { onMounted } from 'vue'
import Marker from '@/components/common/Marker.vue'
import { Filters } from '@/services/Filters.ts'

const emit = defineEmits('onToPointClicked')

const props = defineProps({
  /**
   * Point
   */
  point: {
    type: Object,
    default: {
      name: null,
      marker: {}
    }
  },
  /**
   * true: 押下可能
   */
  openable: {
    type: Boolean,
    default: true
  }
})

onMounted(() => {
})

/**
 * マーカーのアイコン描画用
 * @returns {Object} Marker#props.icon
 */
function getMarkerIcon() {
  const marker = Filters.findById(
    Filters.TYPE_MARKERS,
    props.point.markers[storelocator.lang]
  )

  if (!marker) {
    return {}
  }

  return {
    url: marker.image?.url,
    noframe: marker.image_only,
    className: `icon-${marker.id}`
  }
}

/**
 * Pointに移動するアイコンをピンポイントでクリック時
 * 
 * 全体のクリックとアイコンのクリックの割合を把握したいのでわけている
 */
function onToPointClicked(e) {
  e.stopPropagation()

  emit('onToPointClicked', props.point)
}

</script>

<style lang="scss" scoped>
.point {
  display: block;
  font-size: .75rem;
  margin: 0 0 0 0;
  padding: 0.85rem 0.8rem 0.8rem 0.8rem;
  position: relative;
  transition: all .25s;

  &.openable {
    cursor: pointer;
  }

  .name {
    font-size: .875rem;
    line-height: 20px;
    position: absolute;
    top: 20px;
    left: 58px;
    display: inline-block;
    margin: 1px 0 0 0;
    width: calc(100% - 100px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .to-point {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%) scale(1.5);
    opacity: .3;
  }

  .marker {
    position: relative;
    margin-top: 0 !important;
    transform: none;
  }

  &:hover {
    color: var(--theme-color);
  }
}
</style>
/**
 * マップページのルートコンポーネント
 * 
 * main.jsによって初期化され、テンプレートはJinjaの以下と紐づく
 * templates/organizations/{...}/map.html > templates/default/map.html
 */

import MapPage from "@/components/map/MapPage.vue"

export default {
  components: {
    MapPage
  },
  setup() {
  }
}

import HttpClient from '@/utils/httpclient'

class API {
  constructor() {
  }
  /**
   * ポイント取得（ジオハッシュ単位）
   * @param {String} hash ジオハッシュ
   * @returns {Object} {status: Number, data: {items: [...]}}
   */
  async fetchPointsByGeohash(hash: string): Promise<{
    status: number,
    data: {
      items: Point[]
    }}
  > {
    const params = {}

    const response = await new HttpClient().get(`/api/points/${hash}`, {params}).catch(e => {
      return {status: e.response?.status, data: []}
    })

    return {status: response?.status, data: response?.data}
  }

  /**
   * 集約したポイントを取得
   * @returns 全ての集約を応答（失敗がある場合はリトライかリロードを促すこと）
   */
  async fetchAggregatedPoints(): Promise<{
    [key: string]: {
      status: number,
      data: any 
    }
  }> {
    const allResponse: { [key: string]: any } = {}

    for (const aggregateKey of Object.keys(storelocator.map.points.aggregates)) {
      const params = {}

      const response = await new HttpClient().get(`/api/aggregated_points/${aggregateKey}`, {params}).catch(e => {
        return {status: e.response?.status, data: []}
      })

      allResponse[aggregateKey] = response
    }

    return allResponse
  }

  /**
   * ポイント取得
   * @param params パラメータ（今のところURLエンコードは行わないので、URLセーフな内容だけにする）
   */
  async fetchPoints(params: object = null): Promise<{
    status: number,
    data: {
      items: Point[]
    }
  }> {
    const response = await new HttpClient().get(`/api/points`, {
      params: params,
      timeout: 20 * 1000 // 長めにしておく、キャッシュや細分化が整ったら短くしてもいい
    }).catch(e => {
      return e
    })

    if (response?.status !== 200) {
      throw new Error('fetchPoints failed', response)
    }

    return {
      status: response?.status,
      data: response?.data
    }
  }

  /**
   * 駅を取得
   * @param {Number} latitude 緯度
   * @param {Number} longitude 経度
   * @returns {Promise} 応答の形は{items: [{
   *   name: 駅名
   *   line_name: 路線名
   * }]}
   */
  async fetchStations(latitude: number, longitude: number): Promise<{
    status: number,
    data: {
      items: Station[]
    }
  }> {
    const response = await new HttpClient().get('/api/stations', {
      params: {
        latitude: latitude,
        longitude: longitude
      }
    }).catch(e => {
      return e.response
    })

    return {
      status: response?.status,
      data: response?.data
    }
  }
}

export default new API()

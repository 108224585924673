import { PointCluster } from "./PointCluster.ts"
import { PointMarker } from "./PointMarker.ts"
import { Filters } from "@/services/Filters.ts"

import MapService from "@/services/MapService.ts"


interface PointManagerOptions {
  gridSize: number
  onClicked?: () => void
  onInnerMarkerClicked?: () => void
  isShowPoint?: (point: Point) => boolean
}

/**
 * Pointと紐づくマーカーの描画を管理
 */
export class PointManager {
  /**
   * マップ
   */
  map: google.maps.Map

  /**
   * 全Point
   */
  all_points: Point[] = []

  /**
   * 絞り込み適用後のPoint（クラスタなし、マップ内外含む）
   */
  filtered_points: Point[] = []

  /**
   * クラスタへの所属前のPoint
   */
  unprocessed_points: Point[] = []

  /**
   * クラスタ
   */
  clusters: PointCluster[] = []

  /**
   * 最後に記録したズームレベル
   * ズーム変更時にクラスタの所属先が変わるので、記録が必要
   */
  last_zoom: number

  /**
   * PointCluster.id
   */
  lastSelectedCluster: string

  /**
   * クラスタ内で選択したPoint（のマーカー）
   * Point単位で抱えたいが、PointMarker自体がPointを含んでいるのでマーカーで持つ
   */
  innerMarker: PointMarker

  /**
   * クラスタ化のしきい値
   */
  gridSize: number

  /**
   * 最後に表示した範囲
   * ループ防止用
   */
  latestBounds: google.maps.LatLngBounds

  /**
   * クリック時
   */
  onClicked: () => void = () => {}

  /**
   * クラスタ内のPointをクリックした時
   */
  onInnerMarkerClicked: () => void = () => {}

  /**
   * ポイントを表示するかどうかの判定
   */
  isShowPoint: (point: Point, markers: Filter[], searchConditions: Filter[]) => boolean

  /**
   * 要整理
   * @param {google.maps.Map} map 
   * @param {Object} options {gridSize, onClicked, onInnerMarkerClicked, isShowPoint}
   */
  constructor(map: google.maps.Map, options: PointManagerOptions) {
    this.map = map
    this.unprocessed_points = []
    this.clusters = []
    this.last_zoom = null
    this.lastSelectedCluster = null
    this.innerMarker = null
    this.gridSize = options.gridSize

    if (options.onClicked) {
      this.onClicked = options.onClicked
    }

    if (options.onInnerMarkerClicked) {
      this.onInnerMarkerClicked = options.onInnerMarkerClicked
    }

    if (options.isShowPoint) {
      this.isShowPoint = options.isShowPoint
    }        
  }

  /**
   * Pointを追加
   * @param point Point
   */
  addPoint(point: Point) {
    // console.debug('addPoint', point)
    this.all_points.push(point)
    this.unprocessed_points.push(point)
  }

  /**
   * 表示範囲内のPointを再描画、クラスタの含有を更新
   * unprocessed_points -> clusters
   * 
   * @param useLatestBounds true: Pointの再読み込みをスキップ（ループ防止用）
   */
  render(useLatestBounds = false): {
    /**
     * true: クラスタが更新？
     */
    isClusterUpdated: boolean,
    /**
     * 表示範囲内のPoint（絞り込み適用済）
     */
    pointsInBounds: Point[]
  } {
    // console.debug('render', this.unprocessed_points.length, this.clusters.length)

    const newBounds = useLatestBounds
      ? this.latestBounds
      : this.map.getBounds()

    const map_bounds = this._getExtendedBounds(newBounds, 200)

    const zoom = this.map.getZoom()

    // 選択中のクラスタを除外
    let selectedPoint
    const selectedCluster = this.clusters.find(cluster => cluster.isSelected)
    if (selectedCluster && selectedCluster.points.length === 1) {
      selectedPoint = selectedCluster.points[0].id
    }

    // 画面範囲外のクラスタを削除
    const clusters_in: PointCluster[] = []
    this.clusters.forEach((cluster: PointCluster) => {
      if (!map_bounds.contains(cluster.bounds.getCenter())) {
        if (cluster.isSelected) {
          this.lastSelectedCluster = cluster.clusterId
        }

        this.unprocessed_points = this.unprocessed_points.concat(cluster.points)
        cluster.remove()
      } else {
        clusters_in.push(cluster)
      }
    })
    this.clusters = clusters_in

    let isClusterUpdated = false
    if (this.last_zoom != null && zoom < this.last_zoom) {
      // 広域になっている場合は、クラスタ同士をマージ
      isClusterUpdated = true
      const new_clusters: PointCluster[] = []

      this.clusters.forEach((cluster: PointCluster) => {
        cluster.bounds = this._getExtendedBounds(
          new google.maps.LatLngBounds(
            cluster.bounds.getCenter(), cluster.bounds.getCenter())
        )

        const hit_cluster: PointCluster = this._intersects(
          new_clusters,
          cluster.bounds
        ) as PointCluster

        if (hit_cluster) {
          hit_cluster.mergePoints(cluster.points)
          cluster.remove(hit_cluster.bounds.getCenter())
        } else {
          new_clusters.push(cluster)
        }
      })

      this.clusters = new_clusters

    } else if (this.last_zoom != null && zoom > this.last_zoom) {
      //狭域分割
      isClusterUpdated = true
      let new_clusters: PointCluster[] = []

      this.clusters.forEach((cluster) => {
        if (cluster.points.length > 1) {
          const index = new_clusters.length === 0 ? 0 : new_clusters.length -1
          const newClusters = this._makeClusters(cluster.points, new_clusters)

          // 新しい所属先に移動
          for (let i = index; i < newClusters.length; i ++) {
            newClusters[i].separate(cluster.bounds.getCenter())
          }

          new_clusters = newClusters
          cluster.remove()
        } else {
          new_clusters.push(cluster)
        }
      })

      this.clusters = new_clusters
    }

    // クラスタ化前のポイント処理
    const points_in: Point[] = [] //マップ内ポイント
    const points_out: Point[] = [] //マップ外ポイント

    const markers = Filters.findByIds(
      Filters.TYPE_MARKERS,
      Filters.selectedFilters.markers
    )
  
    const searchConditions = Filters.findByIds(
      Filters.TYPE_SEARCH_CONDITIONS,
      Filters.selectedFilters.search_conditions,
      true
    )

    this.unprocessed_points.forEach((point) => {
      if (map_bounds.contains(point.position)) {
        const isShow = this.isShowPoint(point, markers, searchConditions)

        // console.debug('render', point.name, isShow)
        if (isShow) {
          points_in.push(point)
        }
      } else {
        points_out.push(point)
      }
    })

    this.unprocessed_points = points_out

    const newClusters = this._makeClusters(points_in, this.clusters)
    newClusters.forEach(cluster => {
      cluster.render()

      // 最後に選択していたクラスタと同じものがあれば再選択
      if (this.lastSelectedCluster && cluster.clusterId == this.lastSelectedCluster) {
        cluster.select(true)
      }
    })

    this.unprocessed_points = points_out
    this.last_zoom = zoom

    if (selectedPoint) {
      this.selectPoint(selectedPoint)
    }

    this.latestBounds = newBounds

    // this.postRender(isClusterUpdated)
    return {
      isClusterUpdated: isClusterUpdated,
      pointsInBounds: this.getPointsInBounds()
    }
  }

  getPointsInBounds() {
    let points: Point[] = []
    for(let i = 0, q = this.clusters.length; i < q; i++) {
      points = points.concat(this.clusters[i].points)
    }
    return points
  }

  getPointInCluster() {
    const target = this.clusters.find(cluster => cluster.isSelected)
    if (target) {
      return target.points
    }

    return []
  }

  /**
   * Pointを選択
   * @param id Point.id
   */
  selectPoint(id: number) {
    // console.debug('selectPoint', id)

    this.clearPoint()
    this.clusters.forEach(cluster => {
      const point = cluster.getPoint(id)

      if (!point) {
        cluster.select(false)
        return
      }

      cluster.select(true)
      if (cluster.points.length > 1) {
        cluster.select(true)

        this.innerMarker = new PointMarker(
          MapService.map,
          new google.maps.LatLng(point.latitude, point.longitude),
          null,
          {
            point: point,
            selected: true,
            clickable: true,
            zIndex: 100000010, // 100000000 までは南マーカー優先表示に使っているのでそれ以上の値を指定
            isWithinCluster: true,
            onClicked: () => {this.onInnerMarkerClicked()}
          }
        )
      }
    })
  }

  /**
   * 選択したPointを解除
   */
  clearPoint() {
    // console.debug('clearPoint', this.innerMarker)

    if (this.innerMarker) {
      this.innerMarker.remove()
      this.innerMarker = null
    }
  }

  async clearCluster() {
    this.clusters.forEach(c => {
      if (c.isSelected) {
        c.select(false)
      }
    })
  }

  async clearAll() {
    this.clearPoint()
    this.clearCluster()
  }

  /**
   * 絞り込みを適用
   * 
   * all_points -> unprocessed_points
   * clusters -> []
   */
  applyFilter() {
    this.filtered_points = []
    this.unprocessed_points = []

    const markers = Filters.findByIds(
      Filters.TYPE_MARKERS,
      Filters.selectedFilters.markers
    )
  
    const searchConditions = Filters.findByIds(
      Filters.TYPE_SEARCH_CONDITIONS,
      Filters.selectedFilters.search_conditions,
      true
    )

    this.all_points.forEach(point => {
      const isShow = this.isShowPoint(point, markers, searchConditions)

      // console.debug('applyFilter', point.name, isShow)
      if (isShow) {
        this.filtered_points.push(point)
        this.unprocessed_points.push(point)
      }
    })

    // console.debug('unprocessed_points', this.unprocessed_points)
    this.clusters.forEach(cluster => {
      cluster.remove()
    })

    this.clusters = []
  }

  hideAll() {
    this.unprocessed_points = this.all_points.map(p => p)

    this.clusters.forEach(cluster => {
      cluster.remove()
    })

    this.clusters = []
  }

  /**
   * gridSize ピクセル分、上下左右に広げた bounds を返す
   * マップを初期化しきれていない場合はそのまま帰す
   * @param bounds ?
   * @param gridSize ?
   * @returns ?
   */
  _getExtendedBounds(bounds: google.maps.LatLngBounds, gridSize: number = null) {
    if (gridSize === null) {
      gridSize = this.gridSize
    }

    const projection = this.map.getProjection()
    if (!projection) {
      return bounds
    }

    const pow = Math.pow(2, this.map.getZoom())

    const sw = projection.fromLatLngToPoint(bounds.getSouthWest())
    const ne = projection.fromLatLngToPoint(bounds.getNorthEast())

    const hit_sw = projection.fromPointToLatLng(
      new google.maps.Point(
        sw.x - gridSize / 2 / pow,
        sw.y + gridSize / 2 / pow  // 上下反転なのでこれで良い,
      )
    )
    const hit_ne = projection.fromPointToLatLng(
      new google.maps.Point(
        ne.x + gridSize / 2 / pow,
        ne.y - gridSize / 2 / pow  // 上下反転なのでこれで良い,
      )
    )
    return new google.maps.LatLngBounds(hit_sw, hit_ne)
  }

  /**
   * bounds が重なる一番近いクラスタを返す（なければ null）
   * hit_only の場合は、重なるクラスタがあるかどうかのみを返す
   * @param clusters ?
   * @param bounds ?
   * @param hit_only ?
   * @returns ?
   */
  _intersects(clusters: PointCluster[], bounds: google.maps.LatLngBounds, hit_only=false): PointCluster | boolean {
    const hit_clusters = clusters.filter((cluster) => {
      return cluster.getBounds().intersects(bounds)
    })

    if (hit_clusters.length == 0) {
      return
    }

    if (hit_only) {
      return true
    }

    let nearest: PointCluster = null
    let min_distance: number = null
    hit_clusters.forEach((cluster) => {
      const distance = google.maps.geometry.spherical.computeDistanceBetween(
        bounds.getCenter(),
        cluster.getBounds().getCenter()
      )

      if (!nearest || distance < min_distance) {
        nearest = cluster
        min_distance = distance
      }
    })

    return nearest
  }

  /**
   * ポイントリストからクラスタリストを作成
   * @param points ?
   * @param clusters ?
   * @returns ?
   */
  _makeClusters(points: Point[], clusters: PointCluster[] = []): PointCluster[] {
    // 2回判定するので bounds をキャッシュ
    const bounds_list = points.map((point) => {
      return this._getExtendedBounds(
        new google.maps.LatLngBounds(point.position, point.position))
      })

    // memo: gridSize を徐々に大きくすれば一番偏らいないかも（ただし計算量大）

    // 一度クラスタを作る
    for (let i = 0; i < points.length; i++) {
      const bounds = bounds_list[i]
      if (!this._intersects(clusters, bounds, true)) {
        const cluster = new PointCluster(bounds, this, {
          onClicked: this.onClicked,
        })

        clusters.push(cluster)
      }
    }

    // 改めて最寄りのクラスタにポイントを割り振る（偏りを防ぐため）
    for (let i = 0; i < points.length; i++) {
      const point = points[i]
      const bounds = bounds_list[i]
      const cluster = this._intersects(clusters, bounds) as PointCluster
      cluster.addPoint(point)
    }

    return clusters
  }
}

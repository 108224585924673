/**
 * 店舗の詳細ページのルートコンポーネント
 *
 * テンプレートはJinjaの以下と紐づく
 * templates/organizations/{...}/point.html > templates/default/point.html
 */
import { defineComponent, onMounted, ref } from 'vue'
import PointPage from "@/organizations/_default/point"
import BusinessHours from '@/components/point/BusinessHours.vue'
import PointSearchConditionsByGroup from '@/components/common/PointSearchConditionsByGroup.vue'

export default defineComponent({
  extends: PointPage,
  components: { BusinessHours, PointSearchConditionsByGroup},
  setup(props, context) {
    const page = PointPage.setup(props, context)
    const point = storelocator.point.point

    // 営業時間を整形, ',' 区切りの場合は改行
    // 10:00-19:00 -> 10:00 〜 19:00
    const formattedBusinessHour = (businessHour) => {
      if (!businessHour) return
      return businessHour.replace(/-/g, ' 〜 ').replace(',', '\n')
    }

    // 店舗ページで使用する店舗情報を格納
    const store = {
      isHeadOrBranchOffice: point.extra_fields["店舗種類"] === "本支店・出張所",
      isLoanPlaza: point.extra_fields["店舗種類"] === "ローンプラザ",
      isAtm: point.extra_fields["店舗種類"] === "ATM",
      isOther: point.extra_fields["店舗種類"] === "その他",

      reservationUrl: point.extra_fields["来店予約URL"],

      // サービスを格納 { store: 店舗サービス, atm: ATMサービス }
      searvices: {
        store: {
          groupId: 100,
          description: point.extra_fields["店舗サービス備考"],
        },
        atm: {
          groupId: 10,
          description: point.extra_fields["ATMサービス備考"],
        },
      },

      // 営業時間を格納 { store: 店舗営業時間, atm: ATM営業時間 }
      businessHours: {
        store: {
          businessHours: {
            mon: formattedBusinessHour(point.extra_fields["店舗営業時間月曜日"]) || null,
            tue: formattedBusinessHour(point.extra_fields["店舗営業時間火曜日"]) || null,
            wed: formattedBusinessHour(point.extra_fields["店舗営業時間水曜日"]) || null,
            thu: formattedBusinessHour(point.extra_fields["店舗営業時間木曜日"]) || null,
            fri: formattedBusinessHour(point.extra_fields["店舗営業時間金曜日"]) || null,
            sat: formattedBusinessHour(point.extra_fields["店舗営業時間土曜日"]) || null,
            sun: formattedBusinessHour(point.extra_fields["店舗営業時間日曜日"] || null)
          },
          description: point.extra_fields["店舗営業時間備考"],
          hasBusinessHours: false
        },
        atm: {
          businessHours: {
            mon: formattedBusinessHour(point.extra_fields["ATM営業時間月曜日"]) || null,
            tue: formattedBusinessHour(point.extra_fields["ATM営業時間火曜日"]) || null,
            wed: formattedBusinessHour(point.extra_fields["ATM営業時間水曜日"]) || null,
            thu: formattedBusinessHour(point.extra_fields["ATM営業時間木曜日"]) || null,
            fri: formattedBusinessHour(point.extra_fields["ATM営業時間金曜日"]) || null,
            sat: formattedBusinessHour(point.extra_fields["ATM営業時間土曜日"]) || null,
            sun: formattedBusinessHour(point.extra_fields["ATM営業時間日曜日"] || null)
          },
          description: point.extra_fields["ATM営業時間備考"],
          hasBusinessHours: false
        }
      },
    }

    // 営業時間が設定されているかどうかを判定
    store.businessHours.store.hasBusinessHours = Object.values(store.businessHours.store.businessHours).some(v => !!v)
    store.businessHours.atm.hasBusinessHours = Object.values(store.businessHours.atm.businessHours).some(v => !!v)

    // 営業時間で使用する現在時刻
    const currentDate = ref(new Date())

    // 1分毎に現在時刻を更新する
    const intervalTime = 1 * 60 * 1000
    setInterval(() => {
      currentDate.value = new Date()
    }, intervalTime)

    return {
      ...page,
      store,
      currentDate
    }
  }
})

/**
 * OneTrust関連
 */

declare global {
  interface Window {
    OnetrustActiveGroups?: string;
    gtag?: (event: string, action: string, params: Record<string, any>) => void;
  }
}

/**
 * OneTrustの設定
 */
interface OneTrustConfig {
  enabled: boolean; // OneTrustを有効とするか 有効にした場合は同意がなければ送信しない
  consentId: string; // Analyticsを同意したとする対象のID 例: "C0002"
}

/**
 * Analyticsの詳細設定
 */
interface AnalyticsConfig {
  onetrust?: OneTrustConfig;
}

export class OneTrust {

  /** 同意対象のID */ 
  public consentId: string

  /** イベント名 候補はイベント設計を参照 */
  public name: string

  /** 送信するもの、イベントごとのパラメータ */
  public params: any

  /** デバッグログの出力有無 */
  public debug: boolean

  /** 
   * onetrustから渡される現在同意済みのイベント名
   * 例: ",C0001,C0002,C0004,C0003," 
   */
  public activeGroups: string

  /**
   * @param {string} consentId 
   * @param {string} name 
   * @param {Object} params 送信するもの、イベントごとのパラメータ
   * @param {boolean} debug イベントのパラメータをログにだす場合はtrue
   */
  constructor(consentId: string, name: string, params: any, debug = false) {
    this.consentId = consentId
    this.name = name
    this.params = params
    this.debug = debug
  }

  /**
   * OneTrustの初期化状態と同意がされているかをチェックし
   * 同意済みの場合は送信し、同意が取れる前に送られたイベントは同意後も処理されない
   *
   * NOTE: 同意のcallbackを取るためにOptanonWrapper関数でOneTrustGroupsUpdatedを手動で発火させる必要あり。
   *
   * ```html
   * <script type="text/javascript">
   * function OptanonWrapper() {
   *  var event = new Event('OneTrustGroupsUpdated');
   *  document.dispatchEvent(event);
   * }
   * </script>
   * ```
   * OptanonWrapperは読み込み時、保存時に実行される関数
   * 参考:
   * https://my.onetrust.com/s/article/UUID-29158b4e-22f6-0067-aa36-94f3b8cf3561?language=en_US
   * https://my.onetrust.com/s/article/UUID-730ad441-6c4d-7877-7f85-36f1e801e8ca?language=ja#idm46636330750352
   */
  sendEventOnConsent() {
    // NOTE: 同意の取れている場合は OnetrustActiveGroups に文字列が入る
    //       例: ",C0001,C0002,C0004,C0003,"
    if (typeof window.OnetrustActiveGroups !== 'undefined') {
      // 同意の取れている項目の確認
      this.activeGroups = window.OnetrustActiveGroups || ''
      if (this.activeGroups.includes(this.consentId)) {
        // 対象IDの同意が確認できたら送信する
        this.#sendEvent()
        this.debugLog(`✅ Event has been successfully sent (OneTrust)`, `ActiveGroups: ${this.activeGroups}`, this.name, this.params)
        return true
      } else {
        this.debugLog(`❌ Failed to send '${this.name}' event. code ${this.consentId} was not consent upon.`, `ActiveGroups: ${this.activeGroups}`)
        // 同意されていない場合、引き続き更新を監視する
        document.addEventListener('OneTrustGroupsUpdated', ()=> this.sendEventOnConsent(), { once: true })
        return false
      }
    } else {
      // ページの読み込み直後はOnetrustActiveGroupsが未定義の状態なので更新イベントを監視して再度処理する
      this.debugLog("⏳ OneTrust status is undefined. Waiting for load completion...")
      // OneTrustの更新イベントを監視
      document.addEventListener('OneTrustGroupsUpdated', ()=> this.sendEventOnConsent(), { once: true })
      return false
    }
  }

  #sendEvent() {
    try {
      window.gtag('event', this.name, this.params)
    } catch (e) {
      console.warn(e)
    }
  }

  debugLog(...messages: string[]) {
    if(this.debug) {
      console.debug(...messages)
    }
  }
}

/**
 * Google Analyticsのイベント送信関連の制御用
 * 
 * 例外は自動的に送信する
 */
export class Analytics {
  /**
   * true: デバッグモード、送信はしないが、送信値をログには出したいときに使う
   */
  public debug: boolean

  /**
   * 送信しない場合はtrue、社内で確認する場合に使う
   */
  private ignore: boolean

  /**
   * 送信先、GAのプロパティID
   */
  private sendTo: string

  /**
   * 最後に送信したパラメータ
   * 連続での重複送信防止用
   */
  private latestQuery: string = ''

  /**
   * 送信前に実行する処理
   */
  private onBeforeSend: () => void = () => {}

  /**
   * 送信するパラメータの初期値、常に送信するものを格納する
   */
  private context: {[key: string]: string | number | boolean} = {}

  /**
   * 過剰なイベント送信の抑制用
   * {送信したイベント名: { イベントの識別ID: {} }}
   */
  private cache: {[EventName: string]: {[EventUniqueId: string]: {} }} = {}

  /**
   * jsonで記述するAnalyticsの詳細設定
   */
  private config: AnalyticsConfig 

  /**
   * @param sendTo 送信先、GAのプロパティID
   * @param debug イベントのパラメータをログにだす場合はtrue
   * @param ignore 送信しない場合はtrue、社内で確認する場合に使う
   * @param {AnalyticsConfig} config jsonで記述するAnalyticsの詳細設定
   */
  constructor(sendTo: string, debug = false, ignore = false, config: AnalyticsConfig = {}) {
    this.debug = debug
    this.ignore = ignore
    this.sendTo = sendTo
    this.config = config

    // 補足できてない例外は自動送信
    const onError = (e: any) => {
      console.warn('onError', e.type) // 補足できてない以上、勝手にerrorログにはでるので、ここは最低限でいい

      this.send('ExceptionThrowed', {
        ErrorMessage: e.reason?.message || e.type,
        ErrorStack: e.reason?.stack
      })
    }
    window.addEventListener('error', (e) => {
      onError(e)
    })
    window.addEventListener('unhandledrejection', (e) => {
      onError(e)
    })
  }

  /**
   * 送信前に実行する処理を設定
   * contextの最新化を仕込む運用を想定
   * @param callback 送信前に実行する処理
   */
  setBeforeSend(callback: () => {}) {
    this.onBeforeSend = callback
  }

  /**
   * キャッシュをリセット
   */
  resetCache() {
    this.cache = {}
  }

  /**
   * GAへのイベント送信
   * 肥大化したらクラス化も検討
   * 
   * window.google_analyticsが未定義なら送信しない
   * 
   * @param name イベント名 候補はイベント設計を参照
   * @param params 送信するもの、イベントごとのパラメータ
   * @param cacheId 送信の重複を防ぐためのID、同じIDでの連続送信は抑制される
   */
  send(name: string, params: any, cacheId: string = null){ 
    if (!this.debug && !(window as any).gtag) {
      /** 
       * gtagが定義されていない場合は送信しない、
       * NOTE:
       *  - debugモードの時は、gtag未定義でも送信直前まで進ませる
       *  - 2.1現在、gtagの定義は管理画面のデザイン設定から行う（ソースコード上には存在しない）
       */
      return
    }
  
    if (!this.sendTo) {
      if (this.debug) console.debug('target is not set')
      return
    }

    this.onBeforeSend()

    const base = JSON.parse(JSON.stringify(this.context))
    base.send_to = this.sendTo
    base.value = 1

    const fixedParams = Object.assign(base, params)

    // 過剰に呼ばれる処理があるときに二重送信にならないように
    const query = `${name}${JSON.stringify(fixedParams)}`
    if (this.latestQuery == query) {
      if (this.debug) console.debug('skip double send', name, fixedParams)
      return
    }

    if (this.ignore) {
      if (this.debug) console.debug('not send event')
      return
    }

    if (this.cache[name] && cacheId && this.cache[name][cacheId]) {
      if (this.debug) console.debug('cached event, skip sent event', name, cacheId)
      return
    }

    /** イベントの送信 */
    try {
      let success = false
      if(this.config.onetrust?.enabled) {
        // OneTrustが有効であれば、同意状態を確認してから送信
        const consentId = this.config.onetrust.consentId || 'C0002'
        if(this.debug) console.debug(`Sending event on OneTrust`, name, fixedParams)
        const onetrust = new OneTrust(consentId, name, fixedParams, this.debug)
        success = onetrust.sendEventOnConsent()
      } else if (window.gtag) {
        // デバッグモードの時はgoogle_analytics未定義でもここにくる
        window.gtag('event', name, fixedParams)
        success = true
      } else {
        if (this.debug) console.debug('gtag is not defined', name, fixedParams)
      }
      if(!success) {
        // 送信が成功しなかった場合は処理を停止
        return
      }
    } catch (e) {
      console.warn(e)
    }

    if (this.debug) console.debug('send event', name, fixedParams)

    // キャッシュ記録
    if (!this.cache[name]) {
      this.cache[name] = {}
    }

    if (this.debug) console.debug('cache sent event', name, cacheId)
    this.cache[name][cacheId] = {}

    this.latestQuery = query
  }
}
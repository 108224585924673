<template>
  <div class="directions-trigger" :class="{clicked: show}"
    :uk-tooltip="'title: ' + messages.point_stations_station_search_route + '; pos: top-left'">
    <button class="uk-button" v-wave @click="onActionClicked">
      <i class="material-symbols-sharp" data-icon="undo" v-if="click_mode"></i>
      <i class="material-symbols-sharp" data-icon="close" v-else-if="show"></i>
      <i class="material-symbols-sharp filled" data-icon="arrow_top_right" v-else></i>
    </button>
  </div>
  <div class="directions" :class="{show: show, hide: !show}">
    <!-- 交通手段・出発地を選択 -->
    <div class="input" v-wave>
      <!-- 交通手段 -->
      <div class="travel-modes" v-show="!click_mode">
        <span class="travel-mode" @click="onTravelModeClicked(TravelMode.WALKING)">
          <i :class="{active: travel_mode === TravelMode.WALKING}" class="material-symbols-sharp walking" data-icon="directions_walk"></i>
        </span>
        <span class="travel-mode" @click="onTravelModeClicked(TravelMode.DRIVING)">
          <i :class="{active: travel_mode === TravelMode.DRIVING}" class="material-symbols-sharp driving" data-icon="directions_car"></i>
        </span>
      </div>

      <!-- 出発地を指定 -->
      <div class="origin-selecter" v-show="!click_mode">
        <div class="summary uk-form uk-select">
          <span>
            {{ selectedName }}
            <i class="material-symbols-sharp" data-icon="expand_more"></i>
          </span>
        </div>

        <div id="Origins" uk-dropdown="pos: bottom-right; boundary: .directions; boundary-align: true; offset: 16; mode: click">
          <ul class="uk-nav uk-dropdown-nav">
            <template v-for="origin in origins">
              <template v-if="origin.stations != undefined">
                <!-- 固定の選択肢 -->
                <li class="title" :key="origin.name" v-if="origin.name">{{origin.name}}</li>

                <!-- 駅 -->
                <li class="origin station" @click="onDepartureSelected(station.value)" v-for="station in origin.stations" :key="station.name">
                  {{ station.name }}
                </li>
              </template>
              <li v-else class="origin" @click="onDepartureSelected(origin.value)" :key="origin.name">{{origin.name}}</li>
            </template>
          </ul>
        </div>
      </div>

      <!-- 地図をクリック時の案内 -->
      <div class="click-mode" v-show="click_mode">
        <p class="uk-text-small uk-margin-remove">{{ messages.point_directions_click_origin }}</p>
      </div>
    </div>
  </div>
  <!-- 取得できたルートのサマリ -->
  <div class="directions-result" :class="{show: show && route != null, hide: route == null}">
    <div class="summary" v-if="route != null">
      {{ messages.point_directions_result_prefix }} {{ travel_mode === TravelMode.WALKING ? messages.point_directions_result_prefix_walking : messages.point_directions_result_prefix_driving }}
      <span class="duration">
        <span v-if="route != null">{{ route.duration.text }}</span>
        <span v-else>-</span>
      </span>
      <span class="distance">
        <span v-if="route != null">（{{ route.distance.text }}）</span>
        <span v-else>-</span>
      </span>
    </div>
    <button class="reset" @click="reset" :disabled="!route">
      <i class="material-symbols-sharp" data-icon="close"></i>
    </button>
  </div>
</template>

<script setup>
/**
 * ルート検索
 */
import { computed, ref, onMounted, onBeforeUnmount, watch } from 'vue'
import uikit from "uikit"

import { SimpleMarker } from "@/points/SimpleMarker"

import store from '@/store'
import MapService from "@/services/MapService.ts"

import { StationsStatus } from '@/constants'

/**
 * true: 表示
 */
const show = ref(false)

/**
 * メインのアクションクリック時
 * 
 * 以下がありえる
 * ルート検索を開始・終了
 * 出発地クリックのモードを解除
 */
function onActionClicked() {
  if (click_mode.value) {
    reset()
    return
  }

  show.value = !show.value
}

/**
 * 交通手段
 * @type {google.maps.TravelMode}
 */
const TravelMode = {
  WALKING: "walking",
  DRIVING: "driving"
}

/**
 * 駅関連
 */
const Stations = {
  /**
   * 読み込みの状況
   */
  status: null
}

Stations.status = ref(StationsStatus.NONE)

const props = defineProps({
  /**
   * Point.id
   */
  pointId: {
    type: Number,
    required: false,
    default: null
  },
  /**
   * 目的地の緯度（10進）
   */
  lat: {
    type: Number,
    required: true,
    default: null
  },
  /**
   * 目的地の経度（10進）
   */
  lng: {
    type: Number,
    required: true,
    default: null
  }
})

/**
 * 選択肢
 * @type {uikit.UIkitDropdownElement}
 */
let selectbox = null

/**
 * 駅
 */
const stations = []

/**
 * 出発地の指定方法の選択肢
 * [{name, value}]
 */
const origins = ref([])

/**
 * 交通手段
 */
const travel_mode = ref(TravelMode.WALKING)

/**
 * 出発地の指定方法
 */
const Origins = {
  /**
   * 未選択
   */
  NONE: 100,
  /**
   * 地図をクリック
   */
  CLICK: 102,
  /**
   * 現在地から
   */
  LOCATION: 101
}

/**
 * 出発地の種類
 * Origins
 * 
 * 駅の場合はstation_cdをいれる
 */
const originBy = ref(Origins.NONE)

/**
 * 出発地の位置
 * @type {google.maps.LatLng}
 */
let origin = null

/**
 * true: クリック用のモード
 */
const click_mode = ref(false)

/**
 * ルート
 * @type {google.maps.DirectionsLeg}
 */
const route = ref(null)

/**
 * @type {google.maps.DirectionsService}
 */
let directions = null

/**
 * @type {google.maps.DirectionsRenderer}
 */
let renderer = null

/**
 * 出発地のマーカー
 * @type {SimpleMarker}
 */
let marker = null

const messages = computed(() => storelocator.messages)

const storedStations = computed(() => store.getters.stations)

watch(() => props.pointId, () => {
  resetOptions()
  reset()
})

watch(() => storedStations.value.stations, (after) => {
  // console.debug(after)
  stations.value = after
})

watch(() => storedStations.value.station, (after) => {
  // console.debug(after)
  originBy.value = after.station_cd
  updateDirection(after.latitude, after.longitude)
})

const emit = defineEmits(["onGetDirection", "onReset"])

onMounted(() => {
  origins.value = [
    {name: messages.value.point_directions_select_origin, value: Origins.NONE},
    {name: messages.value.point_directions_select_click_map, value: Origins.CLICK},
    {name: messages.value.point_directions_select_from_location, value: Origins.LOCATION},
  ]

  directions = new google.maps.DirectionsService()
  renderer = new google.maps.DirectionsRenderer({
    suppressMarkers: true,
    preserveViewport: true
  })

  selectbox = uikit.dropdown("#Origins")

  uikit.util.on("#Origins", 'beforeshow',async (e) => {
    if (Stations.status.value !== StationsStatus.NONE) {
      return
    }

    e.preventDefault()

    Stations.status.value = StationsStatus.LOADING

    // 反映
    stations.value = storedStations.value.stations || []

    origins.value.push({
      name: stations.value.length === 0
        ? messages.value.point_directions_not_found_stations
        : messages.value.point_directions_select_station,
      value: null,
      stations: stations.value.filter((s, i) => i < 5).map((s) => {
        return {
          name: `${s.name} [${s.line_name}]`,
          value: s.station_cd
        }
      })
    })

    Stations.status.value = StationsStatus.LOADED
    selectbox.show()
  })
})

onBeforeUnmount(() => {
  reset()
})

/**
 * 出発地のマーカーを用意
 * 
 * 位置の後入れに苦戦していた頃のなごりでつくり直している、１つだけなので大きな問題はないはず
 * @param {google.maps.LatLng} originPosition 出発地の位置
 */
function updateDeparture(originPosition) {
  // console.debug("updateDeparture", marker)

  if (marker) {
    marker.remove()
    marker = null
  }

  marker = new SimpleMarker({
    map: MapService.map,
    classes: ["origin"],
    draggable: true,
    onDraggEnd: (p) => {
      updateDirection(p.lat(), p.lng())
    },
    marker: {
      id: "Origin",
      label: "S",
      extra: messages.value.point_directions_origin_extra,
    }
  })

  marker.setPosition(originPosition)

  origin = originPosition
}

/**
 * 交通手段を切り替え
 * @param {google.maps.TravelMode} travelMode 交通手段
 */
function onTravelModeClicked(travelMode) {
  // console.debug("onTravelModeClicked", travel_mode.value, route.value)

  route.value = null

  travel_mode.value = travelMode

  setTimeout(() => {
    updateDirection(
      origin.lat(),
      origin.lng()
    )
  }, 250)
}

/**
 * リセット
 */
function reset() {
  if (marker) {
    marker.remove()
  }

  if (renderer) {
    renderer.setMap(null)
  }

  route.value = null
  originBy.value = Origins.NONE

  if (click_mode) {
    revertClickMode()
  }

  emit("onReset")
}

/**
 * 選択肢を戻す？
 */
function resetOptions() {
  if (Stations.status.value === StationsStatus.LOADED) {
    origins.value.splice(-1, 1)
    Stations.status.value = StationsStatus.NONE
  }
}

/**
 * クリックモードを解除
 */
function revertClickMode() {
  google.maps.event.clearListeners(MapService.map, 'click')
  click_mode.value = false
}

function onDepartureSelected(value) {
  // console.debug("onDepartureSelected", value)

  selectbox.hide(false)
  originBy.value = value

  if (originBy.value === Origins.NONE) {
    reset()
    return
  }

  if (originBy.value === Origins.LOCATION) {
    // 現在地から
    store.dispatch('startProcess')

    // TODO 現在地の扱いはページと統合する
    navigator.geolocation.getCurrentPosition((_position) => {
      updateDirection(_position.coords.latitude, _position.coords.longitude)
      store.dispatch('finishProcess')
    }, (error) => {
      console.warn(error)
      MapService.showMessage(messages.value.common_failed_to_get_current_location)

      store.dispatch('finishProcess')
    }, {
      enableHighAccuracy: true
    })
  } else if (originBy.value === Origins.CLICK) {
    // 地図をクリック
    renderer.setMap(null)
    route.value = null
    click_mode.value = true

    google.maps.event.addListener(MapService.map, 'click', (e) => {
      updateDeparture(e.latLng)

      updateDirection(e.latLng.lat(), e.latLng.lng())
      revertClickMode()
      originBy.value = Origins.NONE
    })
  } else {
    // 駅から
    const target = stations.value.find(s => s.station_cd === originBy.value)

    updateDirection(target.latitude, target.longitude)
  }
}

/**
 * ルートを計算
 * @param {google.maps.LatLng} lat 出発地の緯度
 * @param {google.maps.LatLng} lng 出発地の経度
 */
function updateDirection(lat, lng) {
  // console.debug("updateDirection", lat, lng, destination.value.lat(), destination.value.lng())

  directions.route({
    origin: new google.maps.LatLng(lat, lng),
    destination: destination.value,
    travelMode: google.maps.TravelMode[travel_mode.value.toUpperCase()]
  }, (result, status) => {
    // console.debug("direction result", result, status)

    if (status !== google.maps.DirectionsStatus.OK) {
      uikit.notification({
        message: messages.value.point_directions_not_found_route,
        status: 'danger',
        pos: 'top-center',
        timeout: 3000
      })

      return
    }

    updateDeparture(new google.maps.LatLng(lat, lng))

    route.value = result.routes[0].legs[0]

    store.dispatch('finishProcess')

    renderer.setDirections(result)
    renderer.setMap(MapService.map)

    const bounds = result.routes[0].bounds
    bounds.extend(new google.maps.LatLng(lat, lng))
    bounds.extend(new google.maps.LatLng(props.lat, props.lng))

    MapService.map.fitBounds(bounds)
    emit("onGetDirection")

    MapService.map.setZoom(MapService.map.getZoom() - 1)

    // 駅など、外部から起動されたときに展開
    setTimeout(() => {
      if (!show.value) {
        show.value = true
      }
    }, 1250)
  })
}

/**
 * 目的地の座標
 * @type {google.maps.LatLng}
 */
const destination = computed(() => new google.maps.LatLng(props.lat, props.lng))

/**
 * 出発地の表記
 */
const selectedName = computed(() => {
  // console.debug("selectedName 1", originBy.value)

  // 未指定
  if (originBy.value === Origins.NONE) {
    return messages.value.point_directions_select_origin
  }

  if (originBy.value === Origins.CLICK) {
    return messages.value.point_directions_select_click_map
  } else if (originBy.value === Origins.LOCATION) {
    return messages.value.point_directions_select_from_location
  } else { // 駅
    const station = stations.value.find(s => s.station_cd === originBy.value)
    return `${station.name} [${station.line_name}]` + messages.value.point_directions_origin_suffix
  }
})
</script>

<style lang="scss" scoped>
@keyframes directions-trigger-clicked {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.directions-trigger {
  position: absolute;
  top: .75rem;
  left: .75rem;
  z-index: 4;
  button {
    background-color: $directions-color;
    border-radius: 24px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.3);
    width: 40px;
    height: 40px;
    padding: 0;
    position: relative;
    i {
      color: #fff;
      font-size: 1.5rem;
      position: absolute;
      top: calc(50%);
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &.clicked {
    animation: directions-trigger-clicked .25s ease-out;
  }
}

@keyframes fade-in-directions {
  0% {
    opacity: .8;
    width: 70%;
    display: none;
  }
  100% {
    opacity: 1;
    width: calc(100% - 4rem);
    display: block;
  }
}

@keyframes fade-out-directions {
  0% {
    opacity: 1;
    width: calc(100% - 4rem);
    display: block;
  }
  100% {
    opacity: 0;
    width: 0;
    display: none;
  }
}

.directions {
  background-color: rgb(240, 240, 240);
  border-radius: 0px 20px 20px 0;    
  box-shadow: 0 1px 1px rgba(0, 0, 0, .3);
  margin: 0 0 0 20px;
  max-width: 500px;
  opacity: .95;
  overflow-x: hidden;
  padding: 0 0 0 20px;
  position: absolute;
  top: .75rem;
  left: .75rem;
  width: calc(100% - 4rem);
  z-index: 3;

  display: none;

  &.show {
    animation: fade-in-directions .25s ease-out;
    display: block;
    overflow: visible;
  }

  &.hide {
    animation: fade-out-directions .25s ease-out;
  }

  .input {
    position: relative;
    z-index: 2;
    display: flex;
    .travel-modes {
      flex-shrink: 0;
      margin: 0 8px 0 4px;
      z-index: 1;
      .travel-mode {
        box-sizing: border-box;
        cursor: pointer;
        width: 40px;
        height: 40px;
        display: inline-block;
        text-align: center;
        padding-top: 8px;

        .material-symbols-sharp {
          display: inline-block;
          color: #ccc;
          transition: color .25s;

          &::before {
            content: attr(data-icon);
          }

          &.active {
            color: #444
          }
        }
      }
    }

    .summary {
      border-radius: 0 20px 20px 0;
      display: inline-block;
      position: relative;
      width: 100%;
      padding: 0 2rem 0 .75rem;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      cursor: pointer;
      border: none;
      background-color: rgb(255, 255, 255);
      .spinner {
        width: 1.25rem;
        margin-left: .5rem;
      }
      span {
        line-height: 2.5rem;

        i {
          position: absolute;
          top: 50%;
          right: .5rem;
          transform: translateY(-50%);
        }
      }
      // uikit標準のは消す
      &:not([multiple]):not([size]) {
        background-image: none;
      }
    }

    .origin-selecter {
      position: relative;
      border-radius: 0px 20px 20px 0;
      overflow: hidden;
      padding: 0;
      width: 100%;
      #Origins {
        background-color: #fff;
        margin-left: 20px;
        opacity: .95;
        padding: 0;
        position: fixed;
        width: 100%;
        max-width: calc(320px - 2rem);

        @media only screen and (max-width: $breakpoint-small) {
          left: 1.125rem !important;
          max-width: calc(100% - 4rem) !important;
        }

        .uk-nav {
          border-top: 2px solid var(--theme-color);
          max-height: 324px;
          overflow-y: auto;
          li {
            font-size: 0.875rem;
            cursor: pointer;
            line-height: 1.75rem;
            padding: 0 4px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            &.title {
              color: #aaa;
              cursor: default;
              border-top: solid 1px #f3f3f3;
              margin: 0 0 0 8px;
              padding: 8px 0 0 4px;
            }

            &.station {
              padding-left: 1rem;
              padding: .3rem .75rem;
            }

            &.origin {
              color: #444;
              padding: .3rem .75rem;
              &:first-child {
                padding-top: .4rem;
                cursor: default;
                opacity: .5;
                &:hover {
                  background-color: #fff;
                }
              }
            }
            &.origin:hover {
              background-color: #eee; // TODO
            }
          }
        }
      }
    }

    .click-mode {
      padding: 8px 16px;
      width: 100%;

      p {
        padding: 0;
        margin: 0;
        line-height: 1.6rem;
      }
    }
  }
}

@keyframes fade-in-derections-result {
  0% {
    opacity: 0;
    height: 0;
    display: none;
  }
  100% {
    opacity: 1;
    height: 2.25rem;
    display: block;
  }
}
@keyframes fade-out-derections-result {
  0% {
    opacity: 1;
    height: 2.25rem;
    display: block;
  }
  100% {
    opacity: 0;
    height: 0;
    display: none;
  }
}
.directions-result {
  display: none;
  opacity: .9;
  position: absolute;
  top: 3.2rem;
  left: 2.5rem;
  border-radius: 0 0 4px 4px;
  box-sizing: border-box;
  background-color: rgb(0 81 177);
  color: #fff;
  max-width: 492px;
  padding: .5rem .75rem .5rem .75rem;
  box-shadow: 0 1px 4px rgba(0, 0, 0, .3);
  font-size: 0.875rem;
  transition: all .25s;
  width: calc(100% - 4.5rem);
  z-index: 1;

  &.show {
    animation: fade-in-derections-result .25s ease-out;
    display: block;
  }

  &.hide {
    animation: fade-out-derections-result .25s ease-out;
  }

  .reset {
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    right: .25rem;
    top: calc(50% + 2px);
    transform: translateY(-50%);
    i {
      color: #fff;
      font-size: 1.25rem;
    }
  }
}
</style>

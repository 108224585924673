<template>
  <div class="point-window uk-card" v-if="isShow">
    <!-- Point見出し -->
    <div v-if="selected" class="point-header uk-card-header uk-flex uk-flex-between" :class="{backable: backable()}">
      <button class="back" type="button" aria-label="Back" @click="options.onBacked">
        <span uk-icon="icon: chevron-left"></span>
      </button>

      <h4 class="uk-card-title"> {{ selected.name }} </h4>

      <button class="close" type="button" aria-label="Close" uk-close @click="options.onClosed"></button>
    </div>

    <!-- クラスタ見出し -->
    <div  v-else class="cluster-header uk-card-header uk-flex uk-flex-between">
      <h4 class="uk-card-title small" v-html="messages.common_points_found.replace('{points_count}', pointsInCluster.length)"></h4>
      <button class="close" type="button" aria-label="Close" uk-close @click="options.onClosed"></button>
    </div>

    <!-- Pointの属性・もしくはクラスタに所属するPoint -->
    <div ref="scrollarea"
      class="uk-card-body uk-padding-small uk-panel-scrollable overscroll-shadow"
      :class="{
        top: overflowDirection.top,
        bottom: overflowDirection.bottom,
        'uk-padding-remove': !selected,
        'with-footer': selected,
      }"
      @wheel.stop
      @touchstart.stop
      @scroll="onScroll">
      <!-- 選択したPoint -->
      <template v-if="selected">
        <div class="point-body">
          <div class="image-wrapper" v-if="thumbnailUrl">
            <img class="image" :src="thumbnailUrl">
          </div>

          <div class="content-wrapper">
            <div class="attribute" v-for="format in options.formats">
              <PointAttribute :point="selected" :format="format"></PointAttribute>
            </div>
          </div>
        </div>
        <div class="conditions">
          <PointSearchConditions :point="selected" :showIds="showSearchConditionIds" v-if="options.searchConditions?.show"></PointSearchConditions>
        </div>
      </template>

      <!-- クラスタ -->
      <template v-else>
        <ul class="points">
          <li
            class="point-wrapper uk-flex uk-flex-between uk-flex-middle"
            v-for="point in pointsInCluster"
            :key="point.id"
            @click="options.onInnerPointSelected(point)"
            v-wave
          >
            <Point :point="point" @onToPointClicked="options.onInnerPointSelected(point)"></Point>
          </li>
        </ul>
      </template>
    </div>

    <!-- 選択したPoint用の操作 -->
    <div class="actions uk-text-center card-footer" v-if="selected && (usePointPage || actions.length > 0)" :class="{bottom: overflowDirection.bottom}">
      <template v-for="action in actions">
        <!-- リンク -->
        <a class="action uk-button uk-button-small" v-if="action.href && isShowAction(action) && (!action?.isHide || action.isHide())"
          :id="action.id"
          :class="action.class"
          :href="getActionHref(action)"
          :target="action.target"
          v-wave
        >
          <span class="text-modifier">
            {{ getActionLabel(action) }}
          </span>
        </a>

        <!-- ボタン -->
        <button class="action uk-button uk-button-small"
          v-if="!action.href && isShowAction(action) && (!action?.isHide || action.isHide())"
          :id="action.id"
          :class="action.class"
          @click="action.onclick(selected)"
          v-wave
        >
          <span class="text-modifier">
            {{ getActionLabel(action) }}
          </span>
        </button>
      </template>

      <a class="to-point uk-button uk-button-primary uk-button-small"
        :href="Page.getPointUrl(selected)"
        target="_parent"
        v-if="usePointPage"
        v-wave>
        <span class="text-modifier">
          {{ messages.common_to_point }}
        </span>
        <i class="material-symbols-sharp" data-icon="chevron_right"></i>
      </a>
    </div>
  </div>
</template>

<script setup>
/**
 * Point用のInfoWindow的に使うもの
 *
 * Point・クラスタの両方に対応
 * 単独ではマップ上に埋め込めないので、components.common.InfoWindow.jsに埋め込んで使う
 */
import { inject, ref, onMounted, watch, nextTick, computed } from 'vue'
import VWave from 'v-wave'

import { useStore } from 'vuex'

import Point from '@/components/common/Point.vue'
import PointAttribute from '@/components/common/PointAttribute.vue'
import PointSearchConditions from '@/components/common/PointSearchConditions.vue'
import { Filters } from '@/services/Filters.ts'

import PointService from "@/services/PointService.ts"
import Page from "@/services/Page"
import { parse } from '@/utils/format.ts'


/**
 * 起動時に渡されるもの
 * 構造は一旦PointService.initPointWindow参照
 */
const options = inject('pointWindow')

/**
 * iframeごしだと別途定義が必要
 */
const { vWave } = VWave.createLocalWaveDirective({})

const showSearchConditionIds = ref()

const messages = computed(() => storelocator.messages)

// storeから店舗選択の詳細を参照
const store = useStore()
// 選択している店舗
const selected = computed(() => store.state.points.selected)
const selectedCluster = computed(() => store.state.points.selectedClusterId)
const pointsInCluster = computed(() => store.state.points.pointsInCluster)
const enabledThumbnail = computed(() => options.thumbnail?.enabled)
const thumbnailKey = computed(() => options.thumbnail?.key || "")

/**
 * サムネイルのURLを返す
 * 画像がない場合は空文字を返す
 * @returns {String}
 */
const thumbnailUrl = computed(() => {
  // サムネイルなしの場合は空文字として表現
  const noThumbnail = ""

  // サムネイル表示条件が揃っていない場合は空文字を返す
  if (!selected.value) return noThumbnail
  if (!enabledThumbnail.value) return noThumbnail
  if (!thumbnailKey.value) return noThumbnail

  const imageField = selected.value.extra_fields[thumbnailKey.value]

  // imageFieldにはURLが格納されているため、そのまま返す
  return imageField || noThumbnail
})

/**
 * true: 店舗ページを使用
 */
const usePointPage = computed(() => storelocator.point?.enabled)

/**
 * リンクはこれで十分
 *
 * リンク以外の場合
 * ここの初期化前にactionsを変更（関数を仕込むに必要）
 *
 * 行儀悪めではあるので、必要になったら改めて仕込み方を考えたほうがいいかも
 */
const actions = ref(storelocator.map?.point_window?.actions || [])

// infoWindowの表示 / 非表示を計算
const isShow = computed(() => {
  if (!selected.value && !selectedCluster.value) {
    return null
  }
  return [!!selected.value, !!selectedCluster.value]
})

// 表示アニメーション、スクロールエフェクトの更新
onMounted(async () => {
  await nextTick()
  await new Promise(resolve => setTimeout(resolve, 100))
  onScroll()
})

watch(isShow, async () => {
  // console.debug('isShow', isShow.value)

  if (isShow.value) {
    // 選択した検索条件のみ表示
    if (options.searchConditions?.show && options.searchConditions?.only_selected) {
      updateShowSearchConditionIds()
    }

    // 画面更新、アニメーション分遅延
    await nextTick()
    await new Promise(resolve => setTimeout(resolve, 100))
    if (!scrollarea.value) {
      return
    }

    scrollarea.value.scrollTo(0, 0)
    onScroll()
  } else {
    // ここで消さないと、矢印だけ残ってしまう
    PointService.closePoint()
  }
})

// スクロールエフェクト オーバーフローしている方向にシャドウをつける
const scrollarea = ref()
const overflowDirection = ref({
  top: false,
  bottom: false
})

async function onScroll() {
  const target = scrollarea.value
  if (!target?.offsetHeight || !target?.scrollHeight) {
    return
  }

  if (target.offsetHeight >= target.scrollHeight) {
    overflowDirection.value.bottom = false
    overflowDirection.value.top = false
    return
  }

  const scrollSpan = target.scrollHeight - target.offsetHeight
  const endScroll = scrollSpan - scrollSpan * 0.1
  overflowDirection.value.bottom = target.scrollTop > scrollSpan * 0.1
  overflowDirection.value.top = target.scrollTop < endScroll
}

function backable() {
  // console.debug('backable', PointService.returnCluster)
  return !!PointService.returnCluster
}

/**
 * 表示するアクションか判断
 * @param {Object} action actions[]
 * @returns {Boolean} 必要なフィールドを空以外で持っていれば表示として判断
 */
function isShowAction(action) {
  return !action.need
    || (action.need && (selected.value[action.need.key] || selected.value.extra_fields[action.need.key]))
}

/**
 * ラベル取得
 * @param {Object} action actions[]
 * @returns {String} messagesにあれば採用、なければそのまま使う
 */
 function getActionLabel(action) {
  return messages.value[action.label] || action.label
}

/**
 * リンク用意
 * 主に電話番号を想定
 * @param {Object} action actions[]
 */
function getActionHref(action) {
  return parse(selected.value, {
    type: 'text',
    format: action.href?.format || ''
  })
}

/**
 * 表示する検索条件を更新
 */
function updateShowSearchConditionIds() {
  // マップページ
  showSearchConditionIds.value = Object.values(Filters.selectedFilters.search_conditions)

  if (showSearchConditionIds.value.length > 0) {
    return
  }

  // マップページ以外
  if (sessionStorage) {
    const cache = Filters.cache.load()
    showSearchConditionIds.value = cache?.search_conditions || []
  }
}
</script>

<style lang="scss">
@keyframes point-window-fade-in {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

#PointWindow {
  animation: point-window-fade-in .5s ease-out;
  cursor: auto;
  position: absolute;
  width: calc(100vw - 2rem);
  max-width: 320px;
  z-index: 2;
  opacity: 1;
  visibility: visible;
  transition: all .25s;
  transform: translate(-50%, -100%) translate(0, -60px);
  margin: 0 0 1rem 0;
  &::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: solid 12px #fff;
    margin:auto;
  }

  &.ready {
    opacity: 0;
    visibility: hidden;
  }

  .point-window {
    background-color: rgba(255, 255, 255, .95);
    box-shadow: 0 0 6px rgba(0, 0, 0, .25);
    border-radius: 4px;

    .uk-card-header {
      background-color: var(--theme-color);
      border-radius: 4px 4px 0 0;
      padding: .65rem 1rem;

      .uk-card-title {
        color: var(--text-color);
        font-size: 1rem;
        font-weight: bold;
        margin: 0 1rem 0 0;
      }
      &.cluster-header {
        .uk-card-title {
          font-size: 0.75rem;
          font-weight: normal;
          .points-count {
            font-size: 1.25rem;
            margin: 0 0.5rem 0 0;
          }
        }
      }
      &.point-header {
        border: none;
      }
      button {
        background: none;
        cursor: pointer;
        border: none;
        color: var(--text-color);
        min-width: 1rem;
        flex-shrink: 0;
        margin-left: auto;

        &.back {
          margin: 0 .5rem 0 -0.25rem;
          display: none;
          &:hover {
            transform: translateX(-10%);
            transition: all .5s;
          }
        }
      }

      &.backable {
        .uk-card-title {
          margin: 2px .5rem 0 0;
        }
        .back {
          display: block;
        }
      }
    }

    .card-footer {
      border-top: solid 1px #efefef;
      padding: .65rem 1rem;
      &.bottom {
        border-top: solid 1px #eaeaea;
      }
      a, button {
        flex-grow: 1;
        margin-left: 4px;
      }
    }

    .uk-card-body {
      max-height: 200px;
      &.with-footer {
        max-height: 150px;
        height: auto;
      }
    }

    .point-body {
      display: flex;

      .image-wrapper {
        display: flex;
        align-items: start;
        padding-right: 1rem;
        .image {
          width: 100px;
          height: 100px;
          border-radius: 4px;
          object-fit: cover;
        }
      }

      .content-wrapper {
        .attribute {
          font-size: .9rem;
          margin-bottom: .5rem;
          overflow-wrap: break-word;
          &:last-child {
            margin-bottom: 0;
          }
          p, a {
            margin: 0
          }

          .sub-title {
            font-size: .75rem;
            color: #999
          }
        }
      }
    }

    .points {
      padding: 0;

      .point-wrapper {
        border-bottom: solid 1px #eee;
        .point {
          padding: 0.2rem 0.4rem 0 0.4rem;
          width: 100%;

          .name {
            top: 12px;
            left: 38px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: calc(100% - 48px - 28px);
          }

          .marker {
            margin: 0 0 8px 0;
            transform: scale(0.8);
          }

          .arrow {
            transform: translateY(-50%) scale(1);
          }
          &:hover {
            .arrow {
              right: 0.75rem;
              opacity: .9;
              transition: all .25s;
            }
          }
        }
      }
    }

    .actions {
      a {
        border: 1px solid var(--text-color);
        border-radius: 15px;
        color: var(--theme-color-tp70);
        &:hover {
          background-color: var(--theme-color);
          border: 1px solid var(--theme-color);
          color: var(--text-color);
          transition: all .5s;
          opacity: 1;
        }
      }
      .to-point {
        border: 1px solid var(--text-color);
        border-radius: 15px;
        color: var(--text-color);
        padding: 0 2rem 0 1rem;
        position: relative;
        i {
          display: inline;
          position: absolute;
          top: 2px;
          right: .5rem;
          top: 50%;
          transform: translateY(-50%);
          opacity: .7;
        }
        &:hover {
          transition: all .5s;
          i {
            opacity: 1;
            right: .25rem;
            transition: all .5s;
          }
        }
      }
    }
  }
}
</style>

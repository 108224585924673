import { createApp } from 'vue'

import store from '@/store'

import api from '@/api.ts'
import MapService from "@/services/MapService.ts"

import InfoWindow from '@/components/common/InfoWindow.js'
import PointWindow from '@/components/common/PointWindow.vue'

import { Filters } from '@/services/Filters.ts'
import { GeohashManager } from '@/services/Geohash.js'
import { GuideToPointManager } from '@/services/Guide.js'
import { sortByDistance } from "@/utils/common"
import { PointCluster } from '@/points/PointCluster'

/**
 * PointService
 */
export default {
  /**
   * 選択中のクラスタ
   * @class {PointCluster}
   */
  selectedCluster: null,
  /**
   * Pointの詳細から戻す先のクラスタ
   * @class {PointCluster}
   */
  returnCluster: null,
  /**
   * マーカーを設置済のPoint
   * TODO markersと二重になっている、必要？
   * TODO 形がわからない
   */
  points: [],
  /**
   * 並び替え・絞り込み済のPoint
   * 
   * 画面内・外両方を含む
   * Guideなど、外部から絞り込み済のものを参照したい時に使う
   * 
   * 参照する際は絞り込みの反映が間に合っていないケースがありえるので、必ず確認すること
   */
  filterdPoints: [],
  /**
   * Filtersとは別に適用する特殊な絞り込み
   */
  filters: {
    /**
     * 残すPoind.id 該当するのもだけ取り込む
     * @type {Number[]}
     */
    includes: null,
    /**
     * 弾くPoind.id 該当するものを除外
     * @type {Number[]}
     */
    excludes: null
  },
  /**
   * ?
   */
  isLoadedPoints: false,
  /**
   * @class {GeohashManager}
   */
  geohashManager: null,
  /**
   * 最寄り店舗可視化用
   * @class {GuideToPointManager}
   */
  guideToPointManager: null,
  /**
   * 店舗の詳細
   */
  pointWindow: null,
  /**
   * 集約したPointを補完
   * {
   *   {Map.points.aggregates[]}: {
   *     {Map.points.aggregates[].keys}: AggregatedPoints
   *   }
   * }
   */
  aggregates: null,
  /**
   * 言語別定義を吸収
   *
   * 言語別の定義がない場合と同様の形に収める
   * 言語別のフィールドを言語未指定のフィールドに上書きする
   * 例）extra_fields['name.en'] -> name
   *
   * 言語別の定義は全てextra_fieldsにある前提
   * 適用先は予約・追加フィールドのどちらかにある
   *
   * デフォルト言語、指定言語、優先言語を考慮する
   * 言語別の指定がないフィールドはデフォルト言語を使う
   * 指定言語のフィールドがなく、優先言語のフィールドがある場合は優先言語を使う
   *
   * デフォルト：ja 指定：zh 優先：en の場合
   * 指定言語あり（指定を使う）：name=aaa name.zh=aaa.zh -> name=aaa.zh
   * 指定言語なし（優先を使う）：address=bbb name.en=bbb.en -> name=aaa.en
   * 指定・優先なし（デフォルトを使う）：address=ccc name.tw=bbb.tw -> name=ccc
   *
   * 言語別の定義は以降は不要なので、ここで除去する
   *
   * @param point /api/points/... の {items: []}
   * @returns 加工後のpoint
   */
  parsePoint(point: Point) {
    const delimiter = '.'

    // 指定言語を反映
    const lang_suffix = delimiter + storelocator.lang
    Object.keys(point.extra_fields)
      .filter(key => key.endsWith(lang_suffix))
      .forEach(keyWithLang => {
        const keyNoLang = keyWithLang.replace(lang_suffix, '')

        if (!point[keyNoLang] && !point.extra_fields[keyNoLang] && point.extra_fields[keyNoLang]) {
          // console.debug("parsePoint not found no lang key", point.key, point.name, keyNoLang)
          return
        }

        // console.debug(`parsePoint ${point.key} ${point.name} ${keyWithLang} -> ${keyNoLang} value=${point.extra_fields[keyWithLang]}`)

        // 予約語かどうかを厳密に判定した方が安全
        // 空なら上書きはしない、0などを入れる値で言語別に定義することはないと思うので一旦この判定ですませる
        if (!point.extra_fields[keyWithLang]) {
          return
        }

        if (point[keyNoLang]) {
          point[keyNoLang] = point.extra_fields[keyWithLang]
        } else if (point.extra_fields[keyNoLang]) {
          point.extra_fields[keyNoLang] = point.extra_fields[keyWithLang]
        }
      })

    // 優先言語を反映
    if (storelocator.lang != storelocator.preffered_lang) {
      const preffered_lang_suffix = delimiter + storelocator.preffered_lang
      Object.keys(point.extra_fields)
        .filter(key => key.endsWith(preffered_lang_suffix))
        .forEach(keyWithPrefferedLang => {
          const keyNoLang = keyWithPrefferedLang.replace(preffered_lang_suffix, '')
          const keyWithLang = keyNoLang + delimiter + storelocator.lang

          // 指定言語があればそちらを優先してよい
          if (point.extra_fields[keyWithLang]) {
            return
          }

          if (!point[keyNoLang] && !point.extra_fields[keyNoLang] &&point.extra_fields[keyWithPrefferedLang]) {
            // console.debug("parsePoint not found no lang key", point.key, point.name, keyNoLang, keyWithPrefferedLang)
            return
          }

          // console.debug(`parsePoint ${point.key} ${point.name} ${keyWithPrefferedLang} -> ${keyNoLang} value=${point.extra_fields[keyWithPrefferedLang]}`)

          if (!point.extra_fields[keyWithPrefferedLang]) {
            return
          }

          if (point[keyNoLang]) {
            point[keyNoLang] = point.extra_fields[keyWithPrefferedLang]
          } else if (point.extra_fields[keyNoLang]) {
            point.extra_fields[keyNoLang] = point.extra_fields[keyWithPrefferedLang]
          }
        })
    }

    Object.keys(point.extra_fields)
      .filter(key => key.includes(delimiter))
      .forEach(keyWithLang => {
        delete point.extra_fields[keyWithLang]
      })

    return point
  },
  /**
   * @returns 地図の中心から近い順のPoint、初期化前なら[]
   */
  _getSortedPoints(): Point[] {
    if (!MapService.map) {
      return []
    }

    if (this.points.length === 0) {
      return []
    }

    return sortByDistance(MapService.map.getCenter(), this.points)
  },
  /**
   * Pointの読み込み完了時
   * フック用
   * @param points this.points
   */
  afterLoadPoints(points: Point[]) {},
  /**
   * 最寄り店への案内を更新（初期化もこの中で）
   */
  updateGuide() {
    if (!this.guideToPointManager) {
      this.guideToPointManager = new GuideToPointManager(
        MapService.map,
        {
          messages: {
            move_to_point: storelocator.page === 'map'
              ? storelocator.messages.common_move_to_nearest_point
              : storelocator.messages.point_move_to_page_point
          },
          icon: storelocator.page === 'map'
            ? null
            : 'undo',
          onMoveClicked: () => {
            if (storelocator.page === 'map') {
              storelocator.analytics.client?.send('MoveToNearestPoint', {})
            } else {
              storelocator.analytics.client?.send('BackToOriginalPoint', {})
            }
          },
          distance: storelocator.page === 'map'
            ? null
            : {show: false}
        })
    }

    // マップでは最寄り店・店舗では最初の店舗に誘導
    if (storelocator.page === 'map') {
      // console.debug('updateGuide', this.filterdPoints)

      // 絞り込みも適用が必要
      this.guideToPointManager.update(
        this.filterdPoints
      )
    } else if (storelocator.page === 'point') {
      this.guideToPointManager.update(
        this.sortByDistance(
          [storelocator.point.point],
          MapService.map.getCenter()
        )
      )
    }
  },
  /**
   * 集約したPointを取得
   * @returns true: 成功, 失敗が１つでもあればfalse
   */
  async loadAggregatedPoints(): Promise<boolean> {
    const responses = await api.fetchAggregatedPoints()

    for (const aggregateKey in responses) {
      const response = responses[aggregateKey]

      if (response?.status != 200) {
        return false
      }

      this.aggregates = this.aggregates || {}
      this.aggregates[aggregateKey] = {
        groups: response.data?.items
      }
    }

    return true
  },
  /**
   * 表示範囲内のPointをAPIから取得
   * 地図に反映まではしない
   * @returns {Promise} {status: HTTPステータスコード} エラーでなければnull
   */
  async loadPoints(): Promise<{
    /**
     * HTTPのステータスコード
     */
    status: number
  } | null> {
    // console.debug('loadPoints')

    if (storelocator.map?.location?.minZoom > MapService.map.getZoom()) {
      // 詳細の最初の店舗への誘導は、ズームレベルによらず行う
      if (storelocator.page === 'point') {
        this.updateGuide()
      }
      return null
    }

    if (!this.geohashManager) {
      this.geohashManager = new GeohashManager(MapService.map)
    }

    this.geohashManager.update(storelocator.map?.points.geohash.digits || 3)

    for (const geohash of this.geohashManager.geohashs) {
      // console.debug("load geohash", geohash)

      if (geohash.isIgnored) {
        // console.debug("no points, skipped", geohash)
        continue
      }

      if (this.geohashManager.loaded.has(geohash.hash)) {
        // console.debug("already loaded", geohash)
        continue
      }

      this.geohashManager.loaded.add(geohash.hash)

      const response = await api.fetchPointsByGeohash(geohash.hash)

      if (response?.status != 200) {
        console.warn("failed fetch geohash", geohash.hash)

        this.geohashManager.loaded.delete(geohash.hash)

        // 404は許容
        if (response.status == 404) {
          console.warn("geohash not found.")
        } else { // TODO エラー処理
          return {status: response.status}
        }
      }

      this.onLoadPoints(
        response.data.items.map(p => this.parsePoint(p))
      )
    }

    // 最寄り店・最初の店舗への案内
    this._updateFilteredPoints()
    this.updateGuide()

    this.isLoadedPoints = true

    this.afterLoadPoints(this.points)
    return null
  },
  /**
   * 店舗リストを取得する
   * 緯度軽度が渡された場合は、近い順にソートする
   * 
   * 異常系のさばきは呼び出し側に任せる
   * 
   * @param {Object} params  {
   *   text: String 検索ワード
   * }
   * @returns point
   */
  async fetchPoints(params: {
    text?: string
  }) {
    const response = await api.fetchPoints(params).catch(e => {
      return e
    })

    if (response?.status !== 200) {
      throw new Error('fetchPoints failed', response)
    }

    return response
  },
  /**
   * Point取得後
   * APIから取得したPointをマップに反映
   * @param points /api/pointsから取得したものまま
   */
  onLoadPoints(points: Point[]) {
    // console.debug('onLoadPoints', points)

    points.forEach(point => {
      // 設置済ならスキップ
      if (this.points.find(p => p.id == point.id)) {
        return
      }

      // 特殊な絞り込み
      if (this.filters.includes && !this.filters.includes.includes(point.id)) {
        return
      }

      if (this.filters.excludes && this.filters.excludes.includes(point.id)) {
        return
      }

      // 追加
      this.points.push(point)

      const addPoint = Object.assign({
        'position': new google.maps.LatLng(
          point.latitude,
          point.longitude
        )
      }, point)

      MapService.pointManager.addPoint(addPoint)
    })

    this._updateFilteredPoints()
  },
  /**
   * 絞り込みを変更
   * @param type Filters.TYPE_...
   * @param selectedIds 選択されたFilter.id
   * @param noApply true: 適用しない?
   */
  changeFilter(type: string, selectedIds: string[], noApply: boolean = null) {
    Filters.selectedFilters.setSelected(type, selectedIds)

    if (noApply) {
      return
    }

    return this.applyFilter()
  },
  /**
   * 絞りこみを全てカラにする
   * 
   * 選択中の絞り込み、テキスト検索クエリいずれかのみリセットしたいケースがあるので
   * リセットするものを明示的に指定可能
   * 
   * @params selectedFilters Boolean trueを指定すると選択中の絞り込みをリセット
   * @params query Boolean trueを指定するとテキスト検索クエリをリセット
   * 
   * @returns MapService.update() {pointsInBounds: [...]}
   */
  resetFilter(selectedFilters: boolean = true, query: boolean = true ) {
    Filters.reset(selectedFilters, query)
    return this.applyFilter()
  },
  /**
   * 絞り込みを適用
   * @returns Markers.update
   */
  applyFilter() {
    // console.debug('applyFilter', store.state.filters.initialized)

    // マップ > 店舗の一覧に遷移した時に初期化でキャッシュを潰されないように
    if (store.state.filters.initialized) {
      Filters.cache.save(Filters.selectedFilters)
    }

    if (!MapService.pointManager) {
      return null
    }

    MapService.pointManager.applyFilter()

    const result = MapService.update()

    this._updateFilteredPoints()

    return result
  },
  /**
   * filterdPointsを最新化
   */
  _updateFilteredPoints() {
    // console.debug('_updateFilteredPoints')

    const markers = Filters.findByIds(
      Filters.TYPE_MARKERS,
      Filters.selectedFilters.markers
    )
  
    const searchConditions = Filters.findByIds(
      Filters.TYPE_SEARCH_CONDITIONS,
      Filters.selectedFilters.search_conditions,
      true
    )

    this.filterdPoints = this._getSortedPoints().filter(p => Filters.checkPointByFilters(p, markers, searchConditions))
  },
  /**
   * 絞り込みを初期状態に戻す
   */
  restoreToInitialFilters() {
    store.dispatch("resetFilter")

    const patterns = [
      {
        type: Filters.TYPE_MARKERS,
        initialFilters: Filters.getInitialFilterIds(Filters.TYPE_MARKERS),
        selectedFiltersInStore: store.state.filters.selectedMarkers,
        selectedFiltersInConfig: storelocator.selected_markers
      },
      {
        type: Filters.TYPE_SEARCH_CONDITIONS,
        initialFilters: Filters.getInitialFilterIds(Filters.TYPE_SEARCH_CONDITIONS),
        selectedFiltersInStore: store.state.filters.selectedSearchConditions,
        selectedFiltersInConfig: storelocator.selected_search_conditions
      }
    ]

    // console.debug('restoreToInitialFilters', patterns)

    patterns.forEach(pattern => {
      pattern.initialFilters.forEach(id => {
        store.dispatch("changeFilter", {
          type: pattern.type,
          id: id
        })
      })
    })

    // 親を選択、同階層（指定したフィルタと同じ親に所属するもの）を全て選択してたら
    patterns.forEach(pattern => {
      pattern.initialFilters.forEach(id => {
        // 親を取得
        const parent = Filters.findParentById(pattern.type, id)
        if (!parent) {
          return
        }

        // 同階層の選択中を取得
        const siblingIds = parent.children.map(p => p.id)
        const selectedSiblingIds = siblingIds.map(sid => {
          return pattern.selectedFiltersInStore.includes(sid)
        })

        // 親を選択してない＋同階層を全て選択中：親を選択
        if (!pattern.selectedFiltersInStore.includes(parent.id) && !selectedSiblingIds.includes(false)) {
          pattern.selectedFiltersInConfig.push(parent.id)
          store.dispatch("changeFilter", {
            type: pattern.type,
            id: parent.id,
            parent: true
          })
        }
      })
    })

    Filters.cache.updateLocation()

    // ここで呼ばないとURLで指定した絞り込みがキャッシュに乗らない（店舗の一覧に引き継げない）
    Filters.cache.save(Filters.selectedFilters)
  },
  /**
   * 表示するPointかどうかを判定
   * 
   * PointManager.isShowPointに紐づける（名前を統一したいが、単純に置換するとバグるので一旦このまま）
   * @param point Point
   * @param markers 全てのマーカー、外から渡すように変えている途中
   * @param searchConditions 全ての検索条件（TODO 末端？要確認、こちらだけedgeをつけていた）、外から渡すように変えている途中
   * @param skipZoomLimit true: ズーム制限を超過しても非表示扱いにしない
   * @returns true: 表示対象
   */
  checkPoint(point: Point, markers: Filter[] = null, searchConditions: Filter[] = null, skipZoomLimit = false): boolean {
    // console.debug('checkPoint', point, markers, searchConditions)

    // 表示制限（TODO ページでの判定を切り離す）
    if (!skipZoomLimit && MapService.map && storelocator.map.location?.minZoom > MapService.map.getZoom()) {
      return false
    }

    // 紐づくポイントがなかった場合の警告
    if (!point.markers) {
      console.warn("An undrawn marker was found. Check your base marker settings in console.", point.name)
      return false
    }

    return Filters.checkPointByFilters(point, markers, searchConditions)
  },
  /**
   * ポイントを指定位置からの距離順に並び替えて返す
   * 
   * 距離を埋め込むので、locationを変えて複数回使う時は注意（pointsにDeepコピーを渡すなどする）
   * @param points ポイントの配列
   * @param location 基準となる位置、ここから近い順に並び替え
   * @returns 中心から近い順のPoint
   */
  sortByDistance(points: Point[], location: google.maps.LatLng = null): Point[] {
    // console.debug('sortByDistance', points, location.lat(), location.lng())

    if (points.length === 0) {
      return []
    }

    let center
    if (location) {
      center = location
    } else {
      center = MapService.map.getCenter()
    }

    const resultPoints = points.map(p => {
      const pointPosition = new google.maps.LatLng(p.latitude, p.longitude)
      p.distance = google.maps.geometry.spherical.computeDistanceBetween(
        center,
        pointPosition
      )

      return p
    })

    resultPoints.sort((a, b) => a.distance - b.distance)
    return resultPoints
  },
  /**
   * PointWindowの初期化
   * @param options
   */
  initPointWindow(options: {
    /**
     * PointWindowの代わりに表示するコンポーネント
     */
    component?: object,
    formats?: PointFormat[],
    searchConditions?: PointSearchConditionsConfig,
    thumbnail?: PointThumbnailConfig,
    /**
     * 閉じるクリック時
     */
    onClosed?: () => void,
    /**
     * 戻るクリック時
     */
    onBacked?: () => void,
    /**
     * 戻るクリック時
     */
    onInnerPointSelected?: (point: Point) => void,
    /**
     * PointWindowの表示位置のYオフセット
     */
    offsetY?: number,
    /**
     * PointWindowの表示位置のYオフセット
     */
    panOffsetY?: number
  } = {}) {
    const app = createApp(options?.component ? options.component : PointWindow)

    app.provide('pointWindow', options)

    app.use(store)

    this.pointWindow = new InfoWindow({
      map: MapService.map,
      app: app,
      offsetY: options?.offsetY ? options.offsetY : 150,
      panOffsetY: options?.panOffsetY ? options.panOffsetY : 0,
      id: 'PointWindow'
    })
  },
  /**
   * Pointの詳細を表示
   * @param position 
   */
  showPoint(position: google.maps.LatLng) {
    if (this.pointWindow) {
      this.pointWindow.show(position)
    }
  },
  /**
   * Pointの詳細を非表示
   */
  closePoint() {
    if (this.pointWindow) {
      this.pointWindow.hide()
    }
  },
  /**
   * クラスタを選択
   * @param cluster 
   */
  selectCluster(cluster: PointCluster) {
    const isSelected = !cluster.isSelected

    this.closePoint()

    // 選択する必要がなければ中断
    if (!isSelected) {
      return
    }

    // 選択
    cluster.select(isSelected)

    this.selectedCluster = cluster
  },

  /**
   * 最寄り店の案内が表示されていれば削除する
   * 
   */
  clearGuide() {
    this.guideToPointManager?.clear()
  }
}

<template>
  <div class="guide-to-location" v-if="nearPoints.length === 0 && nearestPoint.id">
    <!-- 表示範囲に店舗なし＋範囲外で店舗見つかったら誘導 -->
    <div class="to-nearest-point" @click="onMoveToNearestPointClicked">
      <button class="uk-button" v-wave>
        {{ messages.common_move_to_nearest_point }} （{{ nearestPoint.displayDistance }}）
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'

import MapService from '@/services/MapService.ts'
import PointService from '@/services/PointService.ts'

import { deepClone } from '@/core/common.js'
import { parseDistance } from '@/utils/format.ts'


const store = useStore()

const messages = computed(() => storelocator.messages)
const nearPoints = computed(() => store.getters.nearPoints)

const NEAREST_POINT_DEFAULT = {
  /**
   * Point.id
   * ここで最寄り店舗の有無を見る
   */
  id: null,
  /**
   * 最寄り店舗の緯度
   * Point.latitude
   */
  latitude: null,
  /**
   * 最寄り店舗の経度
   * Point.longitude
   */
  longitude: null,
  /**
   * 最寄り店舗と地図の中心の距離（km）
   */
  distance: null
}

/**
 * 最寄り店舗への移動関連
 * 
 * 画面外で、取得済で、絞り込みで除外されていない店舗が入る
 */
const nearestPoint = ref(deepClone(NEAREST_POINT_DEFAULT))

/**
 * 最寄りの店舗に移動クリック時
 * 
 * 最寄りの店舗に移動
 */
function onMoveToNearestPointClicked() {
  // console.debug('onMoveToNearestPointClicked', this.nearestPoint)

  const latitude = nearestPoint.value.latitude
  const longitude = nearestPoint.value.longitude

  // 移動前に初期化しておく（移動中に半端な状態に描画されてしまわないように）
  nearestPoint.value = deepClone(NEAREST_POINT_DEFAULT)

  setTimeout(() => {
    MapService.map.panTo({
      lat: latitude,
      lng: longitude
    })
  }, 100)
}

/**
 * 付近の店舗が更新されたら最寄りの店舗を更新
 */
watch(nearPoints, () => {
  // console.debug('mapReady', value)
  updateNearestPoint()
})

/**
 * 最寄りの店舗を取得・更新（距離を記録）
 * 最寄りの店舗への案内用
 */
function updateNearestPoint() {
  if (MapService.minZoom > MapService.map.getZoom()) {
    nearestPoint.value = deepClone(NEAREST_POINT_DEFAULT)
    return
  }

  const points = PointService.filterdPoints
  // console.debug('GuideToLocation checkPointByFilters', points)

  if (points.length > 0 && MapService.map) {
    const point = points[0]

    const distance = google.maps.geometry.spherical.computeDistanceBetween(
    MapService.map.getCenter(),
      {
        lat: point.latitude,
        lng: point.longitude
      }
    )

    nearestPoint.value.id = point.id
    nearestPoint.value.latitude = point.latitude
    nearestPoint.value.longitude = point.longitude
    nearestPoint.value.distance = Math.round(distance / 1000 * 10) / 10
    nearestPoint.value.displayDistance = parseDistance(nearestPoint.value.distance * 1000)
  } else {
    nearestPoint.value = deepClone(NEAREST_POINT_DEFAULT)
  }
}
</script>

<style lang="scss" scoped>
@keyframes fade-in-guide-to-location {
  0% {
    opacity: 0;
    transform: translateX(-2.5%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.guide-to-location {
  animation: fade-in-guide-to-location 0.25s ease-in;
  padding: 1rem 1rem 2rem 1rem;
  text-align: center;
  .uk-button {
    border: 1px solid #fff;
    border-radius: 19px;
    color: #444;
    background-color: #fff;
    border: 1px solid #444;
    padding: 0 1.3rem;
    margin: 0;
    width: 100%;
    &:hover {
      color: var(--text-color);
      background-color: var(--theme-color);
      border-color: var(--theme-color);
      margin: 0 0.5rem 0 -0.5rem;
      width: calc(100% + 1rem);
      transition: all .5s;
    }
  }
}
</style>

import geohashlib from '../core/geohash'

/**
 * ジオハッシュ管理
 * 
 * 表示範囲内のジオハッシュを取得・保持
 * デバッグ用の描画ももたせておく
 * 
 * TODO core.drawまわりと被る部分あるかも
 */
export class GeohashManager {
  constructor(map) {
    this.debug = {
      enabled: storelocator.debug,
      /**
       * @type {Array} google.maps.Polyline
       */
      polylines: []
    }
    
    /**
     * @type {Array} {geohash: String, polyline: google.maps.Polyline}
     */
    this.geohashs = []

    /**
     * ジオハッシュの桁数
     * ズームに応じて調整する可能性がある
     */
    this.depth = 5

    /**
     * 最小のジオハッシュ桁数
     */
    this.MIN_DEPTH = 2

    this.map = map

    /**
     * 読み込み済のジオハッシュ
     */
    this.loaded = new Set()

    /**
     * 読み込まないジオハッシュ
     * Pointがない場所
     */
    this.ignoreHashs = new Set(storelocator.no_points)
  }

  /**
   * ジオハッシュを描画
   * @param {Object} geohash {hash: ジオハッシュ, isCenter: Boolean, isIgnore: Boolean}
   */
  draw(geohash) {
    // console.debug('draw geohash', geohash.hash)

    const box = geohashlib.decode_bbox(geohash.hash, this.depth)

    geohash.polyline = new google.maps.Polyline({
      path: [
        {lat: box[0], lng: box[1]},
        {lat: box[0], lng: box[3]},
        {lat: box[2], lng: box[3]},
        {lat: box[2], lng: box[1]},
        {lat: box[0], lng: box[1]}
      ],
      geodesic: true,
      strokeColor: "#22c",
      strokeOpacity: 1,
      strokeWeight: 2,
      map: this.map
    })

    let className = 'geohash'
    if (geohash.isCenter) {
      className += ' center'
    }
    if (geohash.isIgnored) {
      className += ' ignored'
    }

    const markerContent = document.createElement('div')
    markerContent.innerHTML = `<span class="${className}">${geohash.hash}</span>`

    geohash.marker = new google.maps.marker.AdvancedMarkerElement({
      content: markerContent,
      map: this.map,
      position: (new google.maps.LatLngBounds(
        {lat: box[0], lng: box[1]},
        {lat: box[2], lng: box[3]}
      )).getCenter(),
    })
  }

  /**
   * 地図のズームにあわせてハッシュの桁数調整
   */
  updateDepth() {
    this.depth = 5

    // ベクターマップだと小数があるので、すり抜けないように潰しておく
    const zoom = Math.round(this.map.getZoom())
    if ([12, 11].includes(zoom)) {
      this.depth = 4
    } else if ([10, 9, 8].includes(zoom)) {
      this.depth = 3
    } else if (8 > zoom) {
      this.depth = 2
    }

    // console.debug('updateDepth', zoom, this.depth)
  }

  getGeohashs(bounds) {
    // console.debug('Geohash#getGeohashs', bounds)

    const geohashs = geohashlib.bboxes(
      bounds.getSouthWest().lat(),
      bounds.getSouthWest().lng(),
      bounds.getNorthEast().lat(),
      bounds.getNorthEast().lng(),
      this.depth
    )

    return geohashs
  }

  /**
   * 日付変更線をまたぐ場合に分割（bboxで一回で計算できないため用意）
   * @param {google.maps.LatLngBounds} bounds 地図の表示範囲
   */
  splitBounds(bounds) {
    const sw = bounds.getSouthWest()
    const ne = bounds.getNorthEast()

    // console.debug('Geohash#splitBounds', sw.lat(), sw.lng(), ne.lat(), ne.lng())

    // 日付変更線をまたぐ時は分割
    if (sw.lng() > ne.lng()) {
      const splitted = [
        new google.maps.LatLngBounds(
          new google.maps.LatLng(sw.lat(), sw.lng()),
          new google.maps.LatLng(ne.lat(), 180)
        ),
        new google.maps.LatLngBounds(
          new google.maps.LatLng(sw.lat(), -179.999999), // -180にすると180に丸められるので、ギリギリにしておく
          new google.maps.LatLng(ne.lat(), ne.lng())
        )
      ]

      // console.debug('Geohash#splitBounds splitted', splitted)
      return splitted
    }

    // console.debug('Geohash#splitBounds no split', bounds)
    return [bounds]
  }

  /**
   * ジオハッシュ最新化
   * debug.enabled = true なら描画も行う
   * @param {Number} depth ジオハッシュの桁数、指定するとズームを無視する
   */
  update(depth = null) {
    this.geohashs.forEach(gh => {
      gh.polyline?.setMap(null)
      gh.marker?.setMap(null)
    })
    this.geohashs = []

    if (depth) {
      this.depth = Math.max(this.MIN_DEPTH, depth)
    } else {
      this.updateDepth()
    }

    // まずは狭い範囲で取ることを検討してもいい、ただ収まらず通信回数増えることもあるので、まだ様子見でOK
    const center = this.map.getCenter()
    const centerGeohash = geohashlib.encode(center.lat(), center.lng(), this.depth)

    const bounds = this.map.getBounds()
    if (!bounds) {
      return
    }

    const geohashs = []

    this.splitBounds(bounds).forEach(b => {
      const swLat = b.getSouthWest().lat()
      const swLng = b.getSouthWest().lng()
      const neLat = b.getNorthEast().lat()
      const neLng = b.getNorthEast().lng()

      // console.debug('Geohash#update', swLat, swLng, neLat, neLng)

      geohashs.push(...geohashlib.bboxes(
        swLat,
        swLng,
        neLat,
        neLng,
        this.depth
      ))
    })

    geohashs.forEach(geohash => {
      // console.debug('geohash in bounds', this.map.getZoom(), this.depth, geohash)

      const geohashWrapper = {
        hash: geohash,
        isCenter: geohash == centerGeohash,
        isIgnored: this.ignoreHashs.has(geohash),
      }

      this.geohashs.push(geohashWrapper)

      if (!this.debug.enabled) {
        return
      }
  
      this.draw(geohashWrapper)
    })
  }
}
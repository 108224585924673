<template>
  <section class="map-area" :class="{
    'show-bottom': isShowBottomContent,
    'selected-points': isSelectedPoints
    }">
    <div id="map_canvas"></div>

    <div class="persistent-message-wrapper" v-if="persistentMessage.enabled && persistentMessage.message">
      <message :message="persistentMessage.message"></message>
    </div>

    <transition name="fade">
      <div class="reset-filter-wrapper" v-if="isFilterEnabled">
        <reset-filter></reset-filter>
      </div>
    </transition>

    <slot name="panel-opener">
      <div class="panel-opener" :class="{open: !isPanelClosed}" @click="onOpenPanelClicked">
        <span>{{ messages.map_open }}</span>
        <span uk-icon="icon: search; ratio: 1"></span>
      </div>
    </slot>

    <div class="bottom-content-closer" v-show="isShowBottomContent" @click="onCloseBottomClicked">
      <span>PR</span>
      <i class="material-symbols-sharp" data-icon="close"></i>
    </div>
    <div class="bottom-content-wrapper" v-show="isShowBottomContent">
      <priority-points ref="priorityPointsRef" :options="priorityPointsOptions" v-if="usePriorityPoints"></priority-points>
    </div>

    <div class="guide-wrapper to-panel" @click="onOpenPanelClicked" v-if="guide.show" v-wave>
      <span class="guide">
        <i class="material-symbols-sharp" data-icon="arrow_back"></i>
        {{ messages.map_guide_to_panel }}
      </span>
    </div>

    <div class="guide-wrapper to-location" @click="onLocationGuideClicked" v-if="guide.show" v-wave>
      <span class="guide">
        {{ messages.map_guide_to_location }}
        <i class="material-symbols-sharp" data-icon="arrow_forward"></i>
      </span>
    </div>

    <!-- 現在地取得は操作を止めないよう、別の方法で表現する -->
    <Loading
      :show="mapReady > 0"
      :message="MapService.locationManager?.isLoading ? messages.common_getting_current_location : messages.common_loading"
      :className="isSelectedPoints ? 'selected' : ''">
    </Loading>

    <a class="sl-powered-by" copywrite href="https://www.goga.co.jp/services/storelocator/" target="_blank">Powered<br>by GOGA</a>
  </section>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import uikit from 'uikit'

import store from '@/store'

import Loading from '@/components/common/Loading.vue'
import ResetFilter from '@/components/common/ResetFilter.vue'
import Message from '@/components/map/Message.vue'
import PriorityPoints from '@/components/map/PriorityPoints.vue'

import MapService from '@/services/MapService.ts'
import PointService from '@/services/PointService.ts'
import CommonService from '@/services/CommonService.ts'

import { Panel } from '@/constants'
import { parseMessage } from '@/utils/format.ts'
import { isBoundsContained } from '@/utils/common.js'


CommonService.onBeforePanToCurrentLocation = () => {
  guide.value.show = false
}

/**
 * 位置情報へのアクセスが無効：取得できない旨出す＋地名検索を促す
 * 位置情報へのアクセスが有効＋現在地の取得に失敗：取得できない旨出す
 */
CommonService.onFailedPanToCurrentLocation = (e) => {
  // 誘導あり
  let message = messages.value.map_failed_to_get_current_location_to_places

  // 拒否は明示的にわかるように
  if (e.code === MapService.locationManager.CURRENT_LOCATION_RESULT_DENIED) {
    message = messages.value.map_denied_to_get_current_location_to_places
  } else {
    console.warn(e)
  }

  uikit.notification.closeAll()
  uikit.notification(
    message,
    {pos: "top-center", timeout: 5000}
  )

  focusAutocomplete()
}

/**
 * 地名検索にフォーカス
 * パネルも開く
 *
 * フォーカスは現在地取得済ならスキップ
 */
function focusAutocomplete() {
  // console.debug('focusAutocomplete', MapService.locationManager)

  // パネル開き、地名検索に誘導
  store.dispatch("changePanel", {value: Panel.States.OPENED})

  if (MapService.locationManager.location) {
    return
  }

  const autocomplete = document.getElementById('autocomplete')
  if (autocomplete) {
    autocomplete.focus()

    // ここでメッセージを出してもいいが、現在地の取得失敗と被るので、一旦なし
  }
}

/**
 * ガイドまわり
 */
const guide = ref({
  show: false
})

/**
 * メッセージまわり
 */
const persistentMessage = ref({
  enabled: storelocator.map?.persistent_message?.enabled || false,
  message: parseMessage(storelocator.map?.persistent_message?.message || ''),
})

/**
 * 優先店まわり
 */
const priorityPointsOptions = ref<PriorityPointsConfig>( storelocator.map.priority_points )

/**
 * MapService.latestBoundsと同様の用途
 * ここで別途バインドするidleを止めるために個別に用意
 */
let latestBounds: google.maps.LatLngBounds = null

onMounted(async () => {
  await MapService.init({
    locationManager: {
      onClicked: async () => {
        await CommonService.panToCurrentLocation()
      }
    },
    pointManager: {
      // マップは基本的にクラスタ有効化
      gridSize: storelocator.map?.markers?.clusters?.enabled
        ? storelocator.map?.markers?.clusters?.grid?.size || 50
        : 0,
      onClicked: CommonService.onClusterClicked,
      onInnerMarkerClicked: CommonService.onInnerMarkerClicked,
      isShowPoint: PointService.checkPoint
    }
  })

  MapService.onClicked = () => {
    guide.value.show = false
  }

  // クラスタ・Point詳細
  PointService.initPointWindow({
    formats: storelocator.map?.point_window?.formats,
    searchConditions: storelocator.map?.point_window?.search_conditions,
    thumbnail: storelocator.map?.point_window?.thumbnail,
    onInnerPointSelected: CommonService.onInnerPointSelected,
    onClosed: CommonService.onPointClosed,
    onBacked: CommonService.onPointBacked
  })

  // イベント
  google.maps.event.addListener(MapService.map, 'idle', async () => {
    // console.debug('GoogleMap#idle')

    // 新しいboundsが、latestBoundsに内包される場合はPointの全数を変更しない
    const newBounds = MapService.map.getBounds()
    let useLatestBounds = false
    if (latestBounds
      && !latestBounds.equals(newBounds)
      && isBoundsContained(newBounds, latestBounds)) {
      // console.debug('GoogleMap#idle latestBounds contains new bounds')
      latestBounds = newBounds
      
      useLatestBounds = true
    }

    CommonService.onIdle(useLatestBounds)

    latestBounds = newBounds
  })

  PointService.restoreToInitialFilters()
  store.dispatch('onFilterInitialized')

  // キャッシュなしなら現在地・地名検索に誘導、もしくは案内だし
  if (!MapService.locationCache.loadedCache) {
    if (storelocator.map?.location?.default?.pan_to_current_location) {
      await CommonService.panToCurrentLocation()
    } else {
      // 一定時間で消す
      guide.value.show = true
      setTimeout(() => {
        guide.value.show = false
      }, 3750)

      // 意外とわかりにくいので一旦やめておく、今後検討
      // focusAutocomplete()
    }
  }

  const loaded = await PointService.loadAggregatedPoints()

  if (loaded) {
    console.debug('Aggregated points loaded', PointService.aggregates)
  } else {
    // TODO エラー表示
  }
})

function onOpenPanelClicked() {
  guide.value.show = false

  store.dispatch("changePanel", {
    value: Panel.States.OPENED
  })

  // 自動フォーカスするか考える、過剰にかかっても微妙なので、様子みながら
  focusAutocomplete()

  storelocator.analytics.client?.send('ToggleMapPanel', {
    MapPanelAction: 'open'
  })
}

function onLocationGuideClicked() {
  CommonService.panToCurrentLocation()
}
const mapReady = computed(() => store.state.mapReady)
const isFilterEnabled = computed(() => store.getters.isFilterEnabled)
const isPanelClosed = computed(() => store.getters.isPanelClosed)
const messages = computed(() => storelocator.messages)

/**
 * マップの表示領域内のポイント
 */
const pointsInBounds: ComputedRef<Array<Point>> = computed(() => store.state.points.pointsInBounds)

/**
 * 優先表示の件数取得用、なければ領域を隠したい
 */
const priorityPointsRef = ref<PriorityPoints | null>(null)

/**
 * true: 優先店を使用
 */
const usePriorityPoints = computed(() => {
  return priorityPointsOptions.value.enabled
})

/**
 * 移動時のbounds保存
 * isShowBottomContentの制御用
 *
 * 移動後にここに格納されているboundsになる場合、ループの可能性ありと判断し、優先Pointの領域を消す
 * ループは優先Pointの領域が消える際に発生するため、消す挙動でよい
 * TODO ただし、これでも一度は表示→消える流れになるので、まだ表示に違和感が残る、できるだけ調整したい
 *  
 * MapService.map.bounds.toString(): true
 */
const boundsOnMoved: {
  [key: string]: boolean
} = {}

/**
 * true: 最下部に表示するものがある（この時に全体のレイアウト調整がいるので、ここで判断）
 */
const isShowBottomContent = computed(() => {
  // console.debug('isShowBottomContent', isBottomClosed.value, pointsInBounds.value.length > 0, priorityPointsRef.value?.priorityPointsInBounds?.length > 0)

  const result = usePriorityPoints.value
    && !isBottomClosed.value

    // pointsInBoundsも加えると、ここの変更が２重に発火するのでpriorityPointsInBoundsだけで見る
    // && pointsInBounds.value.length > 0
    && priorityPointsRef.value?.priorityPointsInBounds?.length > 0 // usePriorityPointsに押し込めたいが、初期化後見ないといけないので仕方なくここで

  // if (MapService.map) {
  //   const bounds = MapService.map.getBounds()
  //   if (bounds) {
  //     const boundsKey = bounds.toString()
  //     if (boundsOnMoved[boundsKey]) {
  //       // console.debug('boundsOnMoved saved bounds', result)
  //       delete boundsOnMoved[boundsKey]
  //       return false
  //     }

  //     boundsOnMoved[boundsKey] = true
  //     // console.debug('boundsOnMoved', result, Object.keys(boundsOnMoved).length, boundsOnMoved)
  //   }
  // }

  return result
})

/**
 * Point・クラスタいずれか選択中
 */
const isSelectedPoints = computed(() => store.getters.isPointSelected || store.getters.isClusterSelected)

/**
 * ture: 最下部のエリアを閉じた
 */
const isBottomClosed = ref(false)

/**
 *  閉じるクリック時
 */
function onCloseBottomClicked() {
  // console.debug('onCloseBottomClicked')
  isBottomClosed.value = true
}

</script>

<style lang="scss">
.map-area {
  background-color: #fff;

  #map_canvas {
    width: 100%;
    height: 100%;
  }

  .bottom-content-closer {
    display: none;
  }

  // 下部のコンテンツ（現状は優先店）がある時用、専用の領域を取る
  &.show-bottom {
    #map_canvas {
      height: calc(100% - 170px);
      border-bottom: 1px solid #ccc;
    }

    .bottom-content-closer {
      background: #fffd;
      display: flex;
      justify-content: center;
      font-size: 12px;
      padding: 4px 4px 4px 8px;
      position: absolute;
      right: 0;
      z-index: 100;

      span {
        margin-right: 4px;
      }

      i {
        font-size: 1rem;
        line-height: 1;
      }

      &:hover {
        cursor: pointer;
        background-color: #fafafa;
      }
    }

    .sl-powered-by {
      bottom: 208px;
    }
  }

  @media only screen and (max-width: $breakpoint-small) {
    &.selected-points {
      .panel-opener {
        opacity: .3;
      }
      .persistent-message-wrapper {
        display: none;
      }
    }
  }
}

.panel-opener {
  position: absolute;
  bottom: auto;
  left: 0px;
  background-color: var(--theme-color);
  cursor: pointer;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
  box-shadow: 0 1px 4px rgb(0 0 0 / 50%);
  top: 16px;
  border-radius: 0 1.5rem 1.5rem 0;
  padding: 0 .75rem 0 .5rem;

  .uk-icon {
    margin-left: .5rem
  }

  @media only screen and (min-width: $breakpoint-medium) {
    display: none
  }

  &:hover {
    transition: all .25s;
    .uk-icon {
      margin-left: .75rem;
      transition: all .25s;
    }
  }
}
.sl-powered-by {
  position: absolute;
  display: block;
  bottom: 2rem;
  border-radius: 3px;
  left: .7rem;
  margin: 0;
  font-size: 9px;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 4px 6px;
  color: #333;
  border: solid 1px #888;
}

.map-area .reset-filter-wrapper {
  box-sizing: border-box;
  position: absolute;
  top: 16px;
  left: 12px;

  // SP・タブレット パネル内に表示
  @media screen and (max-width: $breakpoint-medium) {
    display: none;
  }
}

.map-area {
  position: relative;
  width: 100%;
  height: 100%;
}

@keyframes fade-in-guide {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.guide-wrapper {
  animation: fade-in-guide 1.2s ease-in-out;
  background-color: rgba(0, 0, 0, .75);
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  margin-bottom: .5rem;
  padding: .5rem;
  width: auto;

  .guide {
    position: relative;

    i {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  @keyframes fade-out-guide {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  &.to-panel {
    animation: fade-out-guide 4s ease-in-out;
    position: absolute;
    top: 1.25rem;
    left: 6rem;
    .guide {
      padding: .5rem .5rem .5rem 2rem;
    }
    i {
      left: 0.1rem;
    }
    @media screen and (min-width: $breakpoint-small) {
      left: 1.25rem;
    }
  }

  &.to-location {
    animation: fade-out-guide 4s ease-in-out;
    position: absolute;
    bottom: 7.5rem;
    right: 4rem;
    .guide {
      padding: .5rem 2rem .5rem .5rem;
    }
    i {
      right: 0.1rem;
    }
  }

  &:hover {
    background-color: rgba(0, 0, 0, .7);
  }
}

.bottom-content-wrapper {
  position: absolute;
  right: 4px;
  left: 4px;
  display: flex;
  justify-content: center;
}

.gm-bundled-control {
  .gm-svpc {
    margin-top: .6rem !important;
  }
}

.persistent-message-wrapper {
  position: absolute;
  top: 16px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: end;

  > #persistent-message {
    margin: 0 12px 0 224px;

    @media screen and (max-width: $breakpoint-medium) {
      margin: 0 12px 0 112px;
    }
  }
}
</style>

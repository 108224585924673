import { BaseMapManager } from '@/core/mapmanager'
import { PointManager } from '@/points/PointManager.ts'
import { LocationManager, LocationCache } from '@/services/Location.js'
import PointService from '@/services/PointService.ts'


class MapManager extends BaseMapManager {
  constructor() {
    super()
  }
}

/**
 * マップ単位の操作を担当
 * 
 * init -> onIdle -> update
 */
export default {
  /**
   * マップ
   * @class {google.maps.Map}
   */
  map: null,
  /**
   * 最小ズームレベル
   * これを下回るとPointを非表示にする
   */
  minZoom: storelocator.map?.location?.minZoom != undefined
    ? storelocator.map.location.minZoom
    : 11,
  /**
   * ズームレベルが最小を下回っている？
   * TODO 必要？minZoonで十分では？
   */
  isZoomLimited: false,
  /**
   * ズームレベルの下限を下回り、Pointを表示しないことを示すのに使う
   * TODO 必要？minZoonで十分では？
   */
  isShowZoomLimitMessage: true,
  /**
   * 表示・ズームのキャッシュ
   */
  locationCache: new LocationCache().restore(),
  /**
   * @class {MapManager}
   */
  manager: new MapManager(),
  /**
   * ページごとの事情を加味した方がいいので、初期化は任せる
   * @class {PointManager}
   */
  pointManager: null,
  /**
   * 現在地まわり
   * @class {LocationManager}
   */
  locationManager: null,
  /**
   * 初期化
   * @param {Object} options PointManagerにそのまま渡す（乱暴かも、まずは更新点を絞りたい）
   * {
   *   location: {
   *     zoom: ズームレベル
   *   },
   *   locationManager: 現在地マネージャの引数 options
   *   pointManager: PointManagerの引数 options
   * }
   */
  async init(options) {
    // console.debug('init')

    this.map = new google.maps.Map(
      document.getElementById('map_canvas'),
      {
        zoom: options.location?.zoom || this.locationCache.default.zoom || 16,
        center: new google.maps.LatLng(
          this.locationCache.default.lat,
          this.locationCache.default.lng
        ),
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        mapId: storelocator.google_maps.map_id,
        clickableIcons: false,
        mapTypeControl: false,
        cameraControl: false,
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_BOTTOM
        },
        fullscreenControl: false,
        scaleControl: true,
        gestureHandling: "greedy",
        streetViewControl: true,
        streetViewControlOptions: {
          position: storelocator.page === 'map'
            ? google.maps.ControlPosition.RIGHT_CENTER
            : google.maps.ControlPosition.LEFT_BOTTOM,
        },
        keyboardShortcuts: false
      })

    this.pointManager = new PointManager(
      this.map,
      options?.pointManager || {}
    )

    this.enableDoubleTouchMoveZoom()

    this.geocoder = new google.maps.Geocoder()
    this.places = new google.maps.places.PlacesService(this.map)

    // メッセージ表示用
    this.msg_el = document.createElement('div')
    this.msg_el.classList.add('map-message')
    this.msg_el.innerHTML = ''
    this.map.getDiv().appendChild(this.msg_el)

    // 現在地
    this.locationManager = new LocationManager(
      this.map,
      options?.locationManager || {}
    )

    // イベント
    google.maps.event.addListener(this.map, 'idle', () => {
      this.onIdle()
    })

    google.maps.event.addListener(this.map, 'click', () => {
      this.onClicked()
    })

    google.maps.event.addListener(this.map.getStreetView(), 'visible_changed', () => {
      this.onStreetViewToggled()
    })
  },
  /**
   * クリック時
   */
  onClicked() {
  },
  /**
   * onIdleの後に発火
   */
  afterIdle() {
  },
  /**
   * 地図の操作ごとに発火
   */
  onIdle() {
    this.locationCache.save(this.map.getCenter(), this.map.getZoom())
    this.locationManager.update()
    this.afterIdle()
  },
  /**
   * ストリートビュー表示切り替え時
   */
  onStreetViewToggled() {
  },
  /**
   * 全体の更新
   * 
   * Pointのマーカー
   * ズームによる非表示制御＋メッセージ
   * @param useLatestBounds true: Pointの読み込み範囲を前回取得したBoundsにする（ループ防止用）
   * @returns PointManager.render()の結果
   */
  update(useLatestBounds = false): { isClusterUpdated: boolean, pointsInBounds: Point[] } {
    if (this.minZoom > this.map.getZoom()) {
      // 最寄り店の案内を削除
      PointService.clearGuide()
      // マーカー非表示
      this.pointManager.hideAll()

      this.showMessage(
        storelocator.page === 'map'
          ? storelocator.messages.map_zoom_outed
          : storelocator.messages.point_zoom_outed
        , 0)

      this.isZoomLimited = true

      return {
        isClusterUpdated: true,
        pointsInBounds: []
      }
    } else {
      if (this.isZoomLimited) {
        this.isZoomLimited = false
        this.hideMessage()
      }
    }

    return this.pointManager.render(useLatestBounds)
  },
  /**
   * ダブルタップでのズーム用 TODO 後で切り離し
   */
  _click_ts: null,
  /**
   * ダブルタップでのズーム用 TODO 後で切り離し
   */
  _drag_zoom: false,
  /**
   * ダブルタップでのズーム用 TODO 後で切り離し
   */
  _drag_zoom_y: null,
  /**
   * ダブルタップからの上下ドラッグによるズームを有効にする
   * GMPが生成するDOMにイベントを送信するため、GMPの実装が変わると動かなくなる可能性あり
   * 
   * TODO 後で切り離し
   */
  enableDoubleTouchMoveZoom() {
    // マーカークリック等には反応しないよう、最初の他イップはマップのイベントから取得
    this.map.addListener('click', (e) => {
      this._click_ts = Date.now()
    })

    const el = this.map.getDiv()
    el.addEventListener('touchstart', (e) => { 
      // ダブルクリックの閾値の標準は500msのようだが、500ms だと少し長いので、やや短めの300msとする
      if (Date.now() - this._click_ts < 300) {
        this._drag_zoom = true
        this._drag_zoom_y = e.touches[0].screenY
      }
    })

    el.addEventListener('touchmove', (e) => {
      if (this._drag_zoom) {
        // ドラッグイベントをスクロールイベントに置き換える
        // （setZoom で実装すると、もっさりしてイマイチだった）

        const diff = e.touches[0].screenY - this._drag_zoom_y
        this._drag_zoom_y = e.touches[0].screenY

        // GMPの実装に依存するが、やむを得ない
        const target = el.querySelector("div.gm-style > div:nth-child(1)")
        const rect = el.getBoundingClientRect()
        // タップした位置に関わらず、画面中央を中心としたズームとする（公式の仕様がそうなっている）
        const event = new WheelEvent("wheel", {
          clientX: rect.x + rect.width / 2,
          clientY: rect.y + rect.height / 2,
          deltaY: diff * (-1), // ドラッグ量とスクロール量が同じ値で丁度良い
          deltaMode: 0x00
        })
        target.dispatchEvent(event);
      }
    })

    el.addEventListener('touchend', () => {
      this._drag_zoom = false
    })
  },
  fitBounds(bounds: google.maps.LatLngBounds, max_zoom: number) {
    if (!max_zoom) {
      this.map.fitBounds(bounds)
      return
    }

    let zoom = this.getZoomByBounds(bounds)
    if (zoom > max_zoom) {
        zoom = max_zoom
    }

    this.map.panTo(bounds.getCenter())
    this.map.setZoom(zoom)

    return zoom
  },
  getZoomByBounds(bounds: google.maps.LatLngBounds) {
    const mapType = this.map.mapTypes.get(this.map.getMapTypeId())
    if (!mapType) {
      return
    }

    const maxZoom = mapType.maxZoom ? mapType.maxZoom : 21
    const minZoom = mapType.minZoom ? mapType.minZoom : 0
    const northEast = this.map.getProjection().fromLatLngToPoint(bounds.getNorthEast())
    const southWest = this.map.getProjection().fromLatLngToPoint(bounds.getSouthWest())
    const worldCoordWidth = Math.abs(northEast.x - southWest.x)
    const worldCoordHeight = Math.abs(northEast.y - southWest.y)
    const fitPad = 30

    for (let zoom = maxZoom; zoom >= minZoom; --zoom) {
      if (worldCoordWidth * (1 << zoom) + 2 * fitPad < this.map.getDiv().clientWidth && worldCoordHeight * (1 << zoom) + 2 * fitPad < this.map.getDiv().clientHeight) {
        return zoom
      }
    }

    return 0
  },
  /**
   * メッセージを表示
   * @param message 表示する文言
   * @param timeout ミリ秒で指定し、指定時間が経過すると消す、0なら消さない
   */
  showMessage(message: string, timeout = 5000) {
    console.debug('showMessage')

    this.msg_el.innerHTML = message
    this.msg_el.style.display = 'block'

    if (timeout) {
      setTimeout(() => {
        this.hideMessage()
      }, timeout)
    }
  },
  /**
   * メッセージを消す
   */
  hideMessage() {
    console.debug('hideMessage')

    this.msg_el.innerHTML = ''
    this.msg_el.style.display = 'none'
  },
  fromLatLngToPoint(lat: number, lng: number) {
    const projection = this.map.getProjection()

    const bounds = this.map.getBounds()
    const sw = bounds.getSouthWest()
    const ne = bounds.getNorthEast()

    const p1 = projection.fromLatLngToPoint(new google.maps.LatLng(ne.lat(), sw.lng()))
    const p2 = projection.fromLatLngToPoint(new google.maps.LatLng(lat, lng))

    const scale = Math.pow(2, this.map.getZoom())

    return {
      x: (p2.x - p1.x) * scale,
      y: (p2.y - p1.y) * scale
    }
  },
  adjastMap() {
    if (!this.pointManager) {
      return
    }

    if (this.pointManager.selectedPoint.bounds == null) {
      return
    }
    const pointBounds = this.pointManager.selectedPoint.bounds
    const mapBounds = this.map.getBounds()
    if (!mapBounds.intersects(pointBounds)) {
      console.log("マップ位置調整")
      this.map.panTo(pointBounds.getCenter())
    }
  },
  /**
   * boundsの拡大
   * 引数のboundsを東西南北方向に X 倍する
   * @param {*} bounds ?
   * @param {*} xn ?
   * @param {*} xe ?
   * @param {*} xs ?
   * @param {*} xw ?
   * @returns ?
   */
  extendBounds(bounds: google.maps.LatLngBounds, xn: number, xe: number, xs: number, xw: number) {
    const ne = bounds.getNorthEast()
    const sw = bounds.getSouthWest()
    const extend_x = (ne.lng() - sw.lng())
    const extend_y = (ne.lat() - sw.lat())

    const n = ne.lat() + extend_y * xn
    const e = ne.lng() + extend_x * xe
    const s = sw.lat() - extend_y * xs
    const w = sw.lng() - extend_x * xw

    const newBounds = new google.maps.LatLngBounds()
    newBounds.extend({lat: n, lng: e})
    newBounds.extend({lat: s, lng: w})

    return newBounds
  }
}

<template>
  <div class="area-selector" uk-grid>
    <div class="area-groups" v-for="area in Areas" :key=area.name v-show="!hideGroupWithNoPoints || hasAreaPoints(area)">
      <span class="area-group-name" v-if="area.name">{{ area.name }}</span>
      <ul class="areas">
        <li class="area" v-for="a in area.areas" :class="{'no-points': areas[a.area_code].count === 0, selected: a.area_code == areaCode}">
          <!-- 参照中のエリア：リンクなし＋件数あり -->
          <span class="label" v-if="a.area_code == areaCode">
            <span class="area-name">
              {{ a.area_name }}
            </span>
            <span class="count" v-if="areas[a.area_code].count">
              （{{ areas[a.area_code].count }}）
            </span>
          </span>

          <!-- Pointあり：リンクあり＋件数あり -->
          <a class="link" :href="Page.getPointsUrl(a.area_code)" v-else-if="areas[a.area_code].count > 0">
            <span class="area-name">
              {{ a.area_name }}
            </span>
            <span class="count" v-if="areas[a.area_code].count">
              （{{ areas[a.area_code].count }}）
            </span>
          </a>

          <!-- Pointなし：リンクなし＋件数なし -->
          <span class="label" v-else>
            <span class="area-name">
              {{ a.area_name }}
            </span>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
/**
 * 地域を選ぶ用
 * 日本なら都道府県
 *
 * TODO 2.1+ 選択中の都道府県をハイライト
 * NOTE: エリアグループの非表示に応じて、各エリアグループの幅を調整する場合は、.area-groups要素を編集する必要がある
 * (ui-kitのグリッドシステムを利用する場合は、uk-width-1-3のようなクラスを.area-groups要素に追加)
 */
import { computed, onMounted } from 'vue'

import Page from '@/services/Page'

/**
 * 地域の列挙用（Pointの件数なし）
 */
const Areas = computed(() => storelocator.area)

/**
 * 操作不可にするためのもの
 */
const areaCode = computed(() => storelocator.area_code)

const props = defineProps({
  /**
   * 都道府県ごとのPoint数を渡すための口
   * 今後の拡張もありえるので深めに
   *
   * {都道府県コード}: {name: 都道府県名, count: Point数}
   */
  areas: {
    type: Object,
    default: {}
  },
  /**
   * Pointを含まないAreaGroupを非表示にする
   * true: Pointを含まないAreaGroupを非表示
   * false: 全てのAreaGroupを表示(default)
   */
  hideGroupWithNoPoints: {
    type: Boolean,
    default: false
  }
})

/**
 * エリア内にPointがあるかを返す
 *
 * @param {Object} area Area { name, areas: { [key: string]: Area }}
 * Area: { area_code: string, area_name: string }
 */
function hasAreaPoints(area) {
  const areas = Object.values(area.areas)

  // エリアに含まれる都道府県のいずれかがポイントを持っているかどうかを返す
  return areas.some(_area => props.areas[_area.area_code].count > 0)
}

onMounted(() => {
  // console.debug('AreaSelector', Areas.value, storelocator.area_code)
})
</script>

<style lang="scss" scoped>
.area-selector {
  margin: 0;
  .area-groups {
    padding: 0 0 0 1rem;
    text-align: left;
    .area-group-name {
      display: block;
      border-bottom: 1px solid #ccc;
      font-size: 1rem;
      margin: .25rem 0 .25rem 0;
      padding: .25rem 0;
    }
    .areas {
      list-style: none;
      margin-top: 0;
      padding-left: 0;
      .area {
        opacity: .9;
        position: relative;

        a {
          color: var(--theme-color);
          text-decoration: none;
          transition: all 0.25s;
        }

        * {
          cursor: pointer;
        }

        .area-name {
          cursor: pointer;
          font-size: 14px;
          padding: 0;
          line-height: 28px;
          .uk-icon {
            display: none;
            transform: translate(-5px, -1px);
          }
        }

        .label {
          cursor: default;
          .area-name {
            cursor: default;
          }
        }

        .count {
          font-size: 14px;
        }

        .link {
          &:hover {
            opacity: .8;
            transition: all 0.25s;
            .area-name {
              color: var(--theme-color);
              a {
                opacity: 1;
              }
            }
          }
        }

        &.no-points {
          opacity: .5;
          cursor: default;
          // hoverを潰す、もっとスマートなやり方ありそう
          .area-name {
            &:hover {
              color: #777 !important;
            }
          }
        }

        &.selected {
          background-color: var(--theme-color-tp05);
          border-inline-start: var(--theme-color) solid 3px;
          margin-inline-start: -6px;
          padding-inline-start: 3px;
          color: #333;
          * {
            cursor: default;
          }
        }

        @media only screen and (max-width: $breakpoint-small) {
          flex: 1 1 calc(50% - 10px);
        }
      }

      @media only screen and (max-width: $breakpoint-small) {
        display: flex;
        flex-wrap: wrap;
        gap: 0 1rem;
      }
    }
    @media only screen and (max-width: $breakpoint-small) {
      max-width: 300px;
    }
  }

  // PCサイズでエリアグループが１つの場合は、幅を広げる
  @media only screen and (min-width: $breakpoint-small) {
    .area-groups:only-child {
      width: 100%;
      max-width: 100%;
      .areas .area {
        margin-inline: -1rem 1rem;
        padding-inline-start: 1rem;
        &.selected {
          // borderの幅を考慮
          padding-inline-start: calc(1rem - 3px);
        }
      }
    }
  }
}
</style>

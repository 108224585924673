import uikit from 'uikit'
import store from '@/store'

import MapService from './MapService.ts'
import PointService from './PointService.ts'
import { PointCluster } from '@/points/PointCluster.ts'


const messages = storelocator.messages

/**
 * マップページ・店舗ページで共通のもの
 * 
 * 切り出しを優先するため、vuex依存も許容する
 * uikitは極力減らしたいが、通知くらいはひとまずは許容する
 * 
 * ページ依存の処理こ直接仕込まない（フックによせる）
 */
const CommonService = {
  /**
   * 現在地への移動前
   */
  onBeforePanToCurrentLocation: () => {},
  /**
   * 現在地の取得に成功、移動後
   */
  onSucceededPanToCurrentLocation: () => {},
  /**
   * 現在地への取得に失敗
   */
  onFailedPanToCurrentLocation: (e) => {},
  /**
   * 現在地への遷移
   * 
   * 位置情報へのアクセスが無効：取得できない旨出す＋地名検索を促す
   * 位置情報へのアクセスが有効＋現在地の取得に失敗：取得できない旨出す
   */
  async panToCurrentLocation() {
    this.onBeforePanToCurrentLocation()

    if (MapService.locationManager.isLoading) {
      return
    }

    store.dispatch('startProcess')

    let location = null
    try {
      location = await MapService.locationManager.panToCurrentLocation()

      this.onSucceededPanToCurrentLocation()

      uikit.notification.closeAll()
      uikit.notification({
        message: messages.common_moved_to_current_location,
        pos: 'top-center',
        timeout: 3000
      })
    } catch (e) {
      this.onFailedPanToCurrentLocation(e)
    }

    store.dispatch('finishProcess')

    store.dispatch('saveCurrentLocation', location)
  },
  deselectPoint() {
    store.dispatch("deselectPoint")

    PointService.closePoint()
  },
  /**
   * pointWindowでクラスター内のポイントを選択した時の処理
   * @param { PointObject } point クラスター内で選択したポイント
   */
  onInnerPointSelected(point: Point) {
    // console.debug('onInnerPointSelected', point)

    CommonService.deselectPoint()

    MapService.pointManager.selectPoint(point.id)
    store.dispatch("selectPoint", point)

    PointService.showPoint(point.position)

    PointService.returnCluster = PointService.selectedCluster
    PointService.selectedCluster = null

    // selectPointの中に置くと多重発火するのでここに
    storelocator.analytics.client?.send('SelectPoint', {
      PointName: point.name,
      PointKey: point.key,
      PointAddress: point.address,
      ZoomLevel: MapService.map.getZoom()
    })
  },
  /**
   * Point/Clusterの選択前
   */
  onBeforeSelect() {
  },
  /**
   * クラスタ選択前
   * 
   * 一度解除しておく
   */
  onBeforeSelectCluster() {
    store.dispatch('deselectCluster')
    store.dispatch("deselectPoint")

    CommonService.onBeforeSelect()
  },
  /**
   * Pointの詳細から戻るを押した時
   * クラスタから選択した場合はクラスタに戻す
   */
  onPointBacked() {
    // console.debug('onPointBacked')

    CommonService.deselectPoint()
    CommonService.onBeforeSelectCluster()
    PointService.selectCluster(PointService.returnCluster)
    CommonService.onAfterSelectCluster(PointService.returnCluster)

    PointService.returnCluster = null
  },
  /**
   * Pointの詳細を閉じた時
   * 閉じたときはクラスタに戻さない
   */
  onPointClosed() {
    // console.debug('onPointClosed')
    CommonService.deselectPoint()
  },
  /**
   * クラスタ内のマーカーのクリック時
   * 実質pointWindowを閉じるだけ
   */
  onInnerMarkerClicked() {
    CommonService.deselectPoint()
  },
  /**
   * クラスタ選択後
   * 
   * クラスタの中身・店舗の詳細を表示
   */
  onAfterSelectCluster(cluster: PointCluster) {
    let position
    if (cluster.points.length > 1) {
      store.dispatch("selectCluster", cluster)
      position = cluster.marker.position
    } else {
      const firstPoint = cluster.points[0]
      store.dispatch("selectPoint", firstPoint)
      position = firstPoint.position
    }

    PointService.showPoint(position)
  },
  /**
   * クラスタ選択時
   * @param { PointCluster } cluster 選択したクラスタ
   */
  onClusterClicked(cluster: PointCluster) {
    // console.debug('onClusterClicked', cluster)

    // クラスタ選択 > Point選択 とした時にも戻し先が残らないようにする
    if (cluster.points.length === 1 && (PointService.selectedCluster || PointService.returnCluster)) {
      PointService.selectedCluster = null
      PointService.returnCluster = null
    }

    CommonService.onBeforeSelectCluster()
    PointService.selectCluster(cluster)
    CommonService.onAfterSelectCluster(cluster)

    // 単独のPointならPoint選択とみなしてイベント送信
    if (cluster.points.length === 1) {
      const point = cluster.points[0]

      storelocator.analytics.client?.send('SelectPoint', {
        PointName: point.name,
        PointKey: point.key,
        PointAddress: point.address,
        ZoomLevel: MapService.map.getZoom()
      })
    }
  },
  /**
   * マップの操作時
   * 
   * 移動・ズーム時はここにくる
   * 表示範囲内のPointの取得・描画を実施
   * 
   * 店舗ページで近隣店使わない場合はここの通信は潰せたほうが望ましい
   * ただし、単純にスキップすると元の場所に戻す判定に支障が出るので、一旦そのまま（CDNには乗るので、致命的な負荷にはならないはず）
   * 
   * @param useLatestBounds true: Pointの読み込み範囲を前回取得したBoundsにする（ループ防止用）
   * @return 常にfalse、エラーがあった場合のみステータスを返す {status: XXX}
   */
  async onIdle(useLatestBounds = false): Promise<boolean> {
    // console.debug('CommonService#onIdle', useLatestBounds)

    store.dispatch('startProcess')
    const error = await PointService.loadPoints()
    store.dispatch('finishProcess')

    if (error) {
      uikit.notification({
        message: messages.common_failed_to_load_points,
        status: 'danger',
        pos: 'top-center',
        timeout: 3000
      })

      return false
    }

    if (storelocator.page === 'point' && !storelocator.point.nearby?.enabled) {
      return false
    }

    const result = MapService.update(useLatestBounds)

    // 画面更新
    if (store.state.points.selectedClusterId) {
      if (result.isClusterUpdated) {
        store.dispatch('deselectCluster')
      }
    } else {
      store.dispatch('updatePointsInBounds', result.pointsInBounds)
    }  

    return false
  }
}

export default CommonService
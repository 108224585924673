import { parseDistance } from '@/utils/format.ts'

/**
 * Pointへの誘導を行う
 * 
 * 基本は近いPointへ
 * 拡張も可能にする（Pointページでのもとに戻す時とか）
 * 
 * 肥大化したら分解
 */
export class GuideToPointManager {
  /**
   * 
   * @param {google.maps.Map} map 
   * @param {Object} options {
   *   表記を差し替え（storelocator.messagesと同じ構造、使うものだけ差し替えればいい）
   *   messages: {...},
   *   onMoveClicked,
   *   distance: {show: Boolean},
   * }
   */
  constructor(map, options = {}) {
    this.map = map

    /**
     * 描画中ものをかかえる
     */
    this.nearest = {
      /**
       * { latitude: Number, longitude: Number, distance: Number }
       */
      point: null,
      /**
       * @type {google.maps.Polyline}
       */
      polyline: null,
      /**
       * @type {google.maps.AdvancedMarkerElement}
       */
      marker: null,
    }

    /**
     * storelocator.messagesのかわり
     * 差し替えがきくように
     */
    this.messages = options.messages || {
      move_to_point: storelocator.messages.common_move_to_nearest_point
    }

    /**
     * 移動クリック時
     */
    this.onMoveClicked = options?.onMoveClicked

    /**
     * アイコン
     * 
     * material-symbols-sharpの名前
     * 未指定ならPointへの方角を出す
     * 
     * 戻すだけの場合は方角が不要なので、そういう時に指定
     */
    this.icon = options?.icon

    /**
     * 距離
     * 現状は表示制御のみ
     */
    this.distance = options.distance || {
      show: true
    }
  }

  /**
   * 角度を取得
   * @param {Number} lat1 基準点の緯度
   * @param {Number} lng1 基準点の経度
   * @param {Number} lat2 目的地の緯度
   * @param {Number} lng2 目的地の経度
   * @returns 南が0（360）度
   */
  getAngle(lat1, lon1, lat2, lon2) {
    const toRadians = angle => (angle * Math.PI) / 180;
    const toDegrees = angle => (angle * 180) / Math.PI;

    const lat1Rad = toRadians(lat1);
    const lat2Rad = toRadians(lat2);
    const deltaLonRad = toRadians(lon2 - lon1);

    // yとxの成分を計算
    const y = Math.sin(deltaLonRad) * Math.cos(lat2Rad);
    const x = Math.cos(lat1Rad) * Math.sin(lat2Rad) - Math.sin(lat1Rad) * Math.cos(lat2Rad) * Math.cos(deltaLonRad);

    // atan2を使用して角度を求め、ラジアンから度へ変換
    const bearingRad = Math.atan2(y, x);
    let bearingDeg = toDegrees(bearingRad);

    // 負の値を扱うための処理
    if (bearingDeg < 0) {
        bearingDeg = 360 + bearingDeg; // 負の値を正の角度に変換
    }

    return bearingDeg
  }

  /**
   * 更新・描画
   * @param {Array} points { latitude: Number, longitude: Number, distance: Number }
   */
  update(points) {
    // 既存の要素を除去（理想は描画後に消す）
    this.clear()

    if (!points || points.length === 0) {
      return
    }

    points.sort((a, b) => {
      return a.distance - b.distance
    })
    
    this.nearest.point = points[0]

    // 最寄りが表示範囲内なら何もしなくていい
    if (this.map.getBounds().contains(new google.maps.LatLng(this.nearest.point.latitude, this.nearest.point.longitude))) {
      return
    }

    // 描画

    // 最寄りが表示範囲外なら、その場所を示したい

    // マーカーで表現（Advanced Marker）、表示位置はマップの四隅のいずれか
    const angle = this.getAngle(
      this.map.getCenter().lat(),
      this.map.getCenter().lng(),
      this.nearest.point.latitude,
      this.nearest.point.longitude
    )

    const distanceBetween = Math.round(google.maps.geometry.spherical.computeDistanceBetween(
      new google.maps.LatLng(this.map.getCenter().lat(), this.map.getCenter().lng()),
      new google.maps.LatLng(this.nearest.point.latitude, this.nearest.point.longitude)
    ) / 1000 * 10) / 10

    const content = document.createElement("div")

    const parsedDistance = parseDistance(distanceBetween * 1000)
    // console.debug('distanceBetween', distanceBetween, parsedDistance)

    const distance = this.distance?.show
      ? `（${parsedDistance}）`
      : ''

    if (this.icon) {
      content.innerHTML = `<div class="to-nearest"><span class="label">${this.messages.move_to_point} ${distance}</span><div class="direction"><i class="material-symbols-sharp" data-icon="${this.icon}"></i></div>`
    } else {
      content.innerHTML = `<div class="to-nearest"><span class="label">${this.messages.move_to_point} ${distance}</span><div class="direction"><svg style="transform: rotate(${angle}deg)" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-80v-647L256-544l-56-56 280-280 280 280-56 57-184-184v647h-80Z"/></svg></div>`
    }

    // console.debug(this.nearest.point)

    this.nearest.marker = new google.maps.marker.AdvancedMarkerElement({
      map: this.map,
      position: this.map.getCenter(),
      content: content,
      zIndex: 99999990 // PointMarkerの値より大きく、InfoWindowより小さい値
    })

    this.nearest.marker.addListener('click', async () => {
      this.onMoveClicked && this.onMoveClicked()
      this.map.panTo({lat: this.nearest.point.latitude, lng: this.nearest.point.longitude})
    })

    this.nearest.marker.gmpClickable = true
  }

  /**
   * 表示されている要素があれば削除する
   */
  clear() {
    if (this.nearest.point) {
      this.nearest.point = null
    }

    if (this.nearest.polyline) {
      this.nearest.polyline.setMap(null)
      this.nearest.polyline = null
      clearInterval(this.nearest.interval)
    }

    if (this.nearest.marker) {
      this.nearest.marker.setMap(null)
      this.nearest.marker = null
    }
  }
}
import { App, createApp, h, Ref, ref } from 'vue'
import store from '@/store'

import Marker from '@/components/common/Marker.vue'
import { Filters } from '@/services/Filters.ts'
import { PointCluster } from './PointCluster'

interface PointMarkerOptions {
  point?: Point
  clickable?: boolean
  selected?: boolean
  next_position?: google.maps.LatLng
  zIndex?: number,
  isWithinCluster?: boolean,
  extra?: string
  onClicked?: (parent?: PointCluster) => void
}

/**
 * 位置からzIndexを取得
 * 南にあるPointほど上にしたい
 * @param lat 緯度（10進）
 * @param order zIndexのオーダー（緯度経度の） 
 * @returns 
 */
function getZIndexFromLat(lat: number, order = 1000000) {
  return Math.round((90 - lat) * order)
}

/**
 * Pointのマップ上の表現用
 * 
 * クラスタ、もしくはPoint単体のマーカーを描画
 */
export class PointMarker {
  position: google.maps.LatLng
  parent: PointCluster
  point: Point

  /**
   * true: 選択中
   * 
   * point同様、parentを使わない場合に指定
   */
  selected: boolean

  /**
   * createAppで作成したコンポーネントをもたせておく
   * 一旦中で管理する、必要に応じて外からいれる
   */
  private app: App<Element>

  /**
   * クラスタの再構成時の移動先（TODO もういらないかも
   */
  next_position: google.maps.LatLng

  /**
   * true: クリック可能
   */
  clickable: boolean

  /**
   * クリック時
   * 未設定の場合、parentのonClickedを呼ぶ
   */
  onClicked: (parent?: PointCluster) => void

  /**
   * クラスタの結合 / 分離アニメーション時などdivが作られた際に追加する予約class
   */
  stackedClass: string

  /**
   * 描画用
   */
  private div: HTMLDivElement

  /**
   * マーカー
   */
  private _marker: google.maps.marker.AdvancedMarkerElement

  /**
   * Markerにわたすprops
   */
  private props: Ref<{
    count?: number
    id?: number
    dataKey?: string
    isWithinCluster?: boolean
    icon?: {
      url: string
      noframe: boolean
      className: string
      x?: number | string
      y?: number | string
      size?: number
    }
    extra?: string
    selected?: boolean
  }>

  /**
   * マーカーのzIndex
   */
  private zIndex: number

  /**
   * クラスタ内マーカーかどうか
   */
  private isWithinCluster: boolean

  /**
   * 付与するラベルのHTML
   */
  private extra: string

  /**
   * マップ
   */
  map: google.maps.Map

  /**
   * @param map MapService.map
   * @param position クラスタ・マーカーの位置
   * @param parent クラスタ内のPointを抱えたクラスタ、こちらかoptions.pointのどちらかを指定
   * @param options PointMarkerOptions
   */
  constructor(map: google.maps.Map, position: google.maps.LatLng, parent: PointCluster = null, options: PointMarkerOptions = {}) {
    // console.debug("PointMarker", parent, options)

    this.position = position
    this.parent = parent
    this.point = options.point
    this.selected = options.selected
    this.next_position = options['next_position']
    this.clickable = options['clickable'] != undefined ? options['clickable'] : true
    this.onClicked = options['onClicked']
    this.setMap(map)

    this.props = ref({})

    this.zIndex = options.zIndex
    this.isWithinCluster = options.isWithinCluster === true
    this.extra = options.extra

    this.init()
  }

  setMap(map: google.maps.Map) {
    this.map = map
    if (this._marker) {
      this._marker.map = map
    }
  }

  init() {
    this.div = document.createElement('div')
    this.div.style.display = 'block'

    const point = this.parent
      ? this.parent.points[0] // クラスタ経由で使う場合
      : this.point // Point単独で使う場合

    if (this.parent?.points.length > 1) {
      // クラスタとして描画
      this.props.value = {
        count: this.parent?.points.length
      }

      const props = this.props
      this.app = createApp({
        render() {
          return h(Marker, props.value)
        }
      })

      // console.debug("PointMarker#init many", this.props.value)
    } else {
      // 単独のPointとして描画
      const marker = Filters.findById(
        Filters.TYPE_MARKERS,
        point.markers[storelocator.lang]
      )

      this.props.value = {
        count: 1,
        id: point.id,
        dataKey: point.key,
        isWithinCluster: this.isWithinCluster,
        icon: {
          url: marker.image?.url,
          noframe: marker.image_only,
          className: `icon-${marker.id} on-map`,
          size: marker.image_only && marker.image_size ? marker.image_size : null,
        },
        extra: this.extra
      }
      // console.debug("PointMarker#init one", point.name, this.props.value)

      const props = this.props
      this.app = createApp({
        render() {
          return h(Marker, props.value)
        }
      })
    }

    if (this.parent?.isSelected || this.selected) {
      this.props.value.selected = true
    }

    if (this.parent?.detailPoint) {
      this.addClass("detail-point")
    }

    if (this.stackedClass != null) {
      this.addClass(this.stackedClass)
      this.stackedClass = null
    }

    this.app.use(store)
    this.app.mount(this.div)

    this._marker = new google.maps.marker.AdvancedMarkerElement({
      map: this.map,
      content: this.div,
      position: this.position,
      zIndex: this.zIndex ? this.zIndex : getZIndexFromLat(this.position.lat()),
      gmpClickable: this.clickable
    })

    this._marker.addListener("click", () => {
      // 単独で設置した時は自身のを呼ぶ
      if (this.onClicked) {
        this.onClicked()
        return
      }

      if (!this.parent) {
        return
      }

      this.parent.onClicked()
    })

    // クラスター再設定時のアニメーション
    if (this.next_position) {
      setTimeout(()=> {
        this.setPosition(this.next_position, true)
      }, 100)
    }
  }

  /**
   * 位置を更新
   * 
   * @param position 移動先の位置
   */
  setPosition(position: google.maps.LatLng, animate = false) {
    // アニメーションなし・クラスタなら単純移動
    if (!animate || !this.props.value.icon) {
      // console.debug('setPosition no animate', position, this.parent.points[0].name)

      this.position = position
      this._marker.position = position
      return
    }

    // アニメーション用（クラスタ所属など）
    const projection = this._marker.map.getProjection()

    const bounds = this._marker.map.getBounds()
    const topRight = projection.fromLatLngToPoint(bounds.getNorthEast())
    const bottomLeft = projection.fromLatLngToPoint(bounds.getSouthWest())
    const scale = Math.pow(2, this._marker.map.getZoom())

    // console.debug('setPosition animate', this._marker.position)
    const fromPoinByWorld = projection.fromLatLngToPoint(
      this._marker.position
    )

    const fromPointByPx = new google.maps.Point((fromPoinByWorld.x - bottomLeft.x) * scale, (fromPoinByWorld.y - topRight.y) * scale)

    const toPoinByWorld = projection.fromLatLngToPoint(new google.maps.LatLng(position.lat(), position.lng()))
    const toPointByPx = new google.maps.Point(
      (toPoinByWorld.x - bottomLeft.x) * scale,
      (toPoinByWorld.y - topRight.y) * scale
    )

    this.props.value.icon.x = Math.round((toPointByPx.x - fromPointByPx.x)) + 'px'
    this.props.value.icon.y = Math.round((toPointByPx.y - fromPointByPx.y)) + 'px'

    setTimeout(() => {
      // 一度非表示にしないとズレが見える
      const map = this._marker.map
      this._marker.map = null

      // DOMを移動させただけなので、移動後は戻さないとズレが残る
      if (this.props.value.icon) {
        this.props.value.icon.x = 0
        this.props.value.icon.y = 0
      }

      this.position = position
      this._marker.position = position

      this._marker.map = map
    }, 500)
  }

  /**
   * 再描画
   * 
   * ポイントとクラスタをまたぐ場合、一度propsをあらいがえる必要がある
   * TODO 作成時と冗長
   */
  update() {
    // console.debug('update', this.parent.points, this.props.value)

    if (!this.div) {
      return
    }

    const pointsCount = this.parent.points.length

    if (pointsCount > 1) {
      this.props.value = {
        count: pointsCount
      }
    } else {
      const point = this.parent.points[0]

      const marker = Filters.findById(
        Filters.TYPE_MARKERS,
        point.markers[storelocator.lang]
      )

      this.props.value = {
        count: 1,
        id: point.id,
        dataKey: point.key,
        icon: {
          url: marker.image?.url,
          noframe: marker.image_only,
          className: `icon-${marker.id} on-map`
        }
      }
    }
  }

  /**
   * クラスター再設定時の不要なマーカー削除
   */
  remove() {
    if (this.next_position) {
      setTimeout(()=> {
        this.setPosition(this.next_position, true)

        setTimeout(() => {
          this._marker.map = null
        }, 500)
      }, 100)
    } else {
      this._marker.map = null
    }
  }

  /**
   * 選択
   * 
   * 選択中はアニメーションして明示
   */
  select() {
    this.props.value.selected = true
  }

  /**
   * 選択を解除
   */
  deselect() {
    this.props.value.selected = false
  }

  addClass(className: string) {
    if (!this.div) {
      this.stackedClass = className
      return
    }

    this.div.classList.add(className)
  }

  removeClass(className: string) {
    if (!this.div) {
      return
    }

    this.div.classList.remove(className)
  }

  setClickEvent() {
    if (!this.div) {
      return
    }

    this.div.addEventListener('click', () => {
      if (!this.clickable) {
        return
      }

      google.maps.event.trigger(this, 'click')
      this.parent.onClicked()
    })
  }

  clearClickEvent() {
    google.maps.event.clearListeners(this.div, 'click')
  }
}
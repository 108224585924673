<template>
  <div class="business-hours-wrapper">
    <h2>{{ title }}</h2>
    <table class="business-hours uk-table uk-table-small uk-table-divider">
      <tbody>
        <tr v-for="day in daysOfWeekForDisplay" :class="{'current-day': isCurrent(day.key)}">
          <td class="label">{{ day.label }}</td>
          <td class="value" align="left">{{ businessHours[day.key] || textForBlank }}</td>
        </tr>
      </tbody>
    </table>
    <p v-if="description" class="description" v-html="description"></p>
  </div>
</template>

<script setup lang="ts">
import { computed, PropType} from 'vue'

/**
 * Propsとして受け取る営業時間の型
 * 曜日とそれに対応する表示内容を持つオブジェクト
 * 例）{ sun: null, mon: '9:00 ~ 15:00', ...}
 */
interface BusinessHours {
  // 日曜日
  sun: string | null
  // 月曜日
  mon: string | null
  // 火曜日
  tue: string | null
  // 水曜日
  wed: string | null
  // 木曜日
  thu: string | null
  // 金曜日
  fri: string | null
  // 土曜日
  sat: string | null
}

// 曜日に対応するstringの型, BusinessHoursのkeyと一致する
type DayOfWeekKeys = 'sun' | 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat'

// 各曜日に対する、key('sun' etc.)と表示用ラベル('日曜日' etc.)の組み合わせを持つオブジェクトの型
type DaysOfWeek = {
  key: DayOfWeekKeys,
  label: string
}

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  // 表示する営業時間、BusinessHoursで定義するオブジェクトに相当
  businessHours: {
    type: Object as PropType<BusinessHours>
  },
  // 曜日に対応する営業日が設定されていない場合に表示するテキスト
  textForBlank: {
    type: String,
    default: '-'
  },
  description: {
    type: String,
    default: ''
  },
  // 営業時間テーブルの開始曜日, 'mon'のようなstringで指定する
  weekStartBy: {
    type: String as PropType<DayOfWeekKeys>,
    default: 'sun'
  },
  // new Date()で作成された現在のDateオブジェクト(タイムゾーン未調整でOK)
  currentDate: {
    type: Date,
    default: null
  }
})

const messages = computed(() => storelocator.messages)

/**
 * 一週間を表す配列
 * 順番はDate.prototype.getDay()の返値に合わせる
 */
const daysOfWeek: Array<DaysOfWeek> = [
  {key: 'sun', label: messages.value.businesshours_sunday },
  {key: 'mon', label: messages.value.businesshours_monday},
  {key: 'tue', label: messages.value.businesshours_tuesday},
  {key: 'wed', label: messages.value.businesshours_wednesday},
  {key: 'thu', label: messages.value.businesshours_thursday},
  {key: 'fri', label: messages.value.businesshours_friday},
  {key: 'sat', label: messages.value.businesshours_saturday}
]

/**
 * weekStartByで指定された曜日('mon' etc.)に該当するindexを返す
 * indexはDate.prototype.getDay()の返値と対応する
 * @return {number}
 */
const startDayIndex = computed(() => {
  return daysOfWeek.findIndex((day) => day.key === props.weekStartBy)
})

/**
 * daysOfWeekをweekStartByで指定した曜日スタートに並び替えた配列
 * 画面上のレンダリングに使用
 * @return {array}
 */
const daysOfWeekForDisplay = computed(() => {
  return daysOfWeek.slice(startDayIndex.value).concat(daysOfWeek.slice(0, startDayIndex.value))
})

/**
 * 日本時間に相当するDateインスタンスを返す
 * @return {Date}
 */
const currentJstDate = computed(() => {

  if (props.currentDate === null) {
    return null
  }

  // 日本時間相当に調整
  const jst = props.currentDate.toLocaleString( 'ja-JP', { timeZone: 'Asia/Tokyo' })
  return new Date(jst)
})

/**
 * 与えられたkey('mon' etc.)が本日かどうかを返す
 * @param {DayOfWeekKeys} key
 * @return {boolean}
 */
const isCurrent = (key: DayOfWeekKeys): boolean => {

  if (currentJstDate.value === null) {
    return false
  }

  const currentKey = daysOfWeek[currentJstDate.value.getDay()].key
  return currentKey === key
}
</script>

<style lang="scss" scoped>
.business-hours-wrapper {
  .business-hours {
    width: 100%;
    margin-bottom: 0;
    tr {
      font-size: 0.9rem;
      .label {
        padding-left: 1rem;
        padding-right: 0;
        width: 80px;
      }
      .value {
        max-width: 180px;
        white-space: pre-wrap;
        word-break: break-word;
      }
    }
    .current-day {
      background-color: var(--theme-color-tp05);
      .label {
        font-weight: bold;
      }
    }
  }
  .description {
    max-width: 100%;
    white-space: pre-wrap;
    word-break: break-word;
    margin: 0.5rem 0 0 0;
    font-size: .75rem;
    opacity: 0.5;
  }
}
</style>

<template>
  <div class="panel">
    <div class="panel-header">
      <slot name="panel-header">
        <HeaderFilters />
      </slot>
      <div class="actions">
        <div class="current-location">
          <CurrentLocation></CurrentLocation>
        </div>

        <div class="areas-opener" v-if="usePointsPage">
          <a :href="Page.getPointsUrl()" @click="onOpenPointsByAreasClicked">
            {{ messages.map_search_by_pref }} <i class="material-symbols-sharp" data-icon="chevron_right"></i>
          </a>
        </div>
        <slot name="panel-extra-actions"></slot>
      </div>
    </div>

    <div id="PanelContents" class="panel-contents scroll">
      <Filters :selectable="true">
        <template #filter-info="props">
          <slot name="filter-info" :filter="props.filter"></slot>
        </template>
        <template #filter-group-name="props">
          <slot name="filter-group-name" :filter="props.filter" :selectable="props.selectable"></slot>
        </template>
      </Filters>

      <div class="sticky-content">

        <!-- 読み込み前 -->
        <div v-if="!initialized" class="points-summary">
          <span>{{ messages.common_loading_points }}</span>
        </div>
        <!-- 読み込み後：店舗を表示しないズーム -->
        <div v-else-if="isZoomOuted" class="points-summary">
          <span>{{ messages.map_points_zoom_outed }}</span>
        </div>
        <!-- 読み込み後：Pointsなし -->
        <div v-else-if="nearPoints.length === 0" class="points-summary">
          <span>{{ messages.map_points_not_found }}</span>
        </div>

        <!-- 読み込み後：Pointsあり -->
        <div v-else class="points-summary">
          <span
            v-if="nearPoints.length >= 99"
            v-html="messages.common_points_found_over.replace('{points_count}', nearPoints.items.length)">
          </span>
          <span
            v-else
            v-html="messages.common_points_found.replace('{points_count}', nearPoints.items.length)">
          </span>
        </div>
      </div>

      <!-- Windowだすのでリンクにしない -->
      <Points
        @onPointSelected="onPointSelected"
        :formats="formats"
        :searchConditions="searchConditions"
        :link="false"
        :openable="true">
      </Points>

      <transition name="fade">
        <div class="reset-filter-wrapper" v-if="isFilterEnabled">
          <reset-filter></reset-filter>
        </div>
      </transition>

      <GuideToLocation></GuideToLocation>
    </div>
  </div>
</template>

<script setup>
/**
 * 横のパネル
 *
 * 扱うもの
 * 地名検索
 * 絞り込み（マーカー・検索条件）
 * 近くの店舗（地図の表示範囲内）
 * 近くに店舗がない時の誘導
 */
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'

import MapService from '@/services/MapService.ts'
import PointService from '@/services/PointService.ts'
import PointsService from '@/services/PointsService.ts'
import Page from '@/services/Page'

import Filters from '@/components/common/Filters.vue'
import Points from '@/components/common/Points.vue'
import Autocomplete from '@/components/common/Autocomplete.vue'
import ResetFilter from '@/components/common/ResetFilter.vue'
import CurrentLocation from './Panel/CurrentLocation.vue'
import GuideToLocation from './Panel/GuideToLocation.vue'
import HeaderFilters from '@/components/map/Panel/HeaderFilters.vue'

import { Panel, WindowSize } from '@/constants'


const store = useStore()

/**
 * Points.props.formats
 */
const formats = computed(() => storelocator.map?.points?.point?.formats)

/**
 * Points.props.searchConditions
 */
const searchConditions = ref(storelocator.map?.points?.point?.search_conditions)

const windowSize = computed(() => store.state.windowSize)

/**
 * true: 店舗の一覧ページを使う
 */
const usePointsPage = computed(() => storelocator.points?.enabled)

/**
 * true: Pointが一度以上読み込み済 = Pointがなかった旨を出してもよい
 */
const initialized = computed(() => store.state.points.initialized)

const nearPoints = computed(() => store.getters.nearPoints)
const messages = computed(() => storelocator.messages)
const isFilterEnabled = computed(() => store.getters.isFilterEnabled)

/**
 * true: Pointの表示ズーム外
 */
const isZoomOuted = ref(false)

MapService.afterIdle = () => {
  // console.debug('Panel: afterIdle', MapService.minZoom, MapService.map.getZoom())
  isZoomOuted.value = MapService.minZoom > MapService.map.getZoom()
}

/**
 * Points.onPointSelected
 * @param {Object} point getters.nearPoints[]
 */
function onPointSelected(point) {
  MapService.pointManager.clearPoint()

  MapService.pointManager.selectPoint(point.id)
  store.dispatch("selectPoint", point)

  if (windowSize != WindowSize.LARGE) {
    store.dispatch("changePanel", {value: Panel.States.CLOSED})
  }

  PointService.showPoint(point.position)

  // selectPointの中に置くと多重発火するのでここに
  storelocator.analytics.client?.send('SelectPoint', {
    PointName: point.name,
    PointKey: point.key,
    PointAddress: point.address,
    ZoomLevel: MapService.map.getZoom()
  })
}

/**
 * エリア（日本なら都道府県）から探すクリック時
 *
 * エリア選択を促すための仕込みをしておく
 * 現状はキーだけいれておけばいい
 */
function onOpenPointsByAreasClicked() {
  PointsService.storage.areas.save()
}

onMounted(() => {
})
</script>

<style lang="scss">
.panel {
  .panel-contents {
    flex-grow: 1;
    height: calc(100% - 60px);
    overflow: hidden;

    &.scroll {
      overflow-y: auto;
    }

    .points {
      margin: 0;
    }
  }

  .panel-header {
    padding: 1rem;
    border-bottom: solid 1px #eaeaea;
  }

  .sticky-content {
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: #fff;

    @media screen and (max-width: $breakpoint-medium) {
      // ピクセルズレが発生するので微調整
      top: -1px;
    }
  }

  .points-summary {
    align-items: center;
    background-color: #fff;
    border-bottom: solid 1px #444;
    cursor: auto;
    display: flex;
    font-size: 0.875rem;
    height: 3.25rem;

    justify-content: center;

    @media screen and (max-width: $breakpoint-medium) {
      // ピクセルズレが発生するので微調整
      top: -1px;
    }

    span.rotation {
      transform: rotate(180deg);
    }

    .points-count {
      display: inline-block;
      font-size: 24px;
      margin-right: .5rem;
      vertical-align: -2px;
    }
  }

  .points {
    margin: 0 0 1rem 0;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0 0 0;
    min-height: 1.25rem;

    .areas-opener {
      flex-shrink: 0;
      font-size: 14px;
      text-align: center;
      position: relative;
      padding: 0 1rem 0 0;
      a {
        color: #444 !important;
        margin: 0 0 0 .5rem;
        opacity: .7;
        transition: all .25s;
        i {
          font-size: 1.25rem;
          position: absolute;
          top: 50%;
          opacity: .5;
          transform: translateY(-50%);
          transition: all .25s;
        }
        &:hover {
          text-decoration: none;
          opacity: 1;
          i {
            margin: 0 0 0 .125rem;
            opacity: 1;
          }
        }
      }
    }
  }

  .reset-filter-wrapper {
    display: none;

    // SP・タブレットのみ表示
    @media screen and (max-width: $breakpoint-medium) {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 3;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}
</style>

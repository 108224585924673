/**
 * マップページのルートコンポーネント
 * 
 * main.jsによって初期化され、テンプレートはJinjaの以下と紐づく
 * templates/organizations/{...}/notfound.html > templates/default/notfound.html
 */

import NotFound from "@/components/notfound/NotFound.vue"

export default {
  components: {
    NotFound
  },
  setup() {
  }
}

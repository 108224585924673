<template>
  <div class="point-search-conditions-by-group-wrapper">
    <h2 class="title">{{ displayTitle }}</h2>
    <ul
      class="search-conditions"
      :class="searchConditionsClass"
      v-if="searchConditions.length > 0"
    >
      <li
        class="search-condition"
        :class="{
          [searchConditionClass]: true,
          [notPassedClass]: !isPointPassedFilter(searchCondition),
        }"
        v-for="searchCondition in searchConditions"
        v-show="isSearchConditonVisibled(searchCondition)"
        :key="searchCondition.id"
        :data-id="'search-condition-' + searchCondition.id"
        v-bind="getFilterAttribute(searchCondition)"
      >
        <div class="content" :class="searchCondition.class">
          <div v-if="isPointPassedIconVisibled" class="passed-icon-wrapper">
            <i
              v-if="isPointPassedFilter(searchCondition)"
              class="material-symbols-sharp filled passed-icon passed"
              data-icon="circle"
            ></i>
            <i
              v-else
              class="material-symbols-sharp passed-icon not-passed"
              data-icon="close"
            ></i>
          </div>

          <span v-if="showImage" class="image">
            <img
              :src="searchCondition.image.url"
              v-if="searchCondition.image?.url"
            />
          </span>
          <span class="name" v-html="searchCondition.name"></span>
        </div>
      </li>
    </ul>
    <p v-if="description" class="description" v-html="description"></p>
  </div>
</template>

<script setup lang="ts">
/**
 * IDを指定し、フィルターに含まれる直下の検索条件を列挙
 * TODO: フィルターを再帰的に表示する場合は追加の実装が必要
 *
 * PointSearchCondition.vueも同様にフィルターを表示するが、そちらはポイントに該当するフィルターのみを表示
 * グループ内のフィルターを列挙し、独自にデザインを変更したい場合は、こちらのコンポーネントを使用
 */
import { computed, onMounted, ref, PropType } from "vue"

import { Filters } from "@/services/Filters.ts"

const props = defineProps({
  /**
   * 検索条件を列挙するフィルターグループのid
   */
  groupId: {
    type: [String, Number] as PropType<string | number>,
  },
  /**
   * タイトル
   * filter.nameを上書きするときに使用
   */
  title: {
    type: String,
    default: "",
  },
  /**
   * 備考
   * 値がある場合は表示
   */
  description: {
    type: String,
    default:"",
  },
  /**
   * 表示する検索条件のid
   * undefinedの場合は全列挙
   */
  showIds: {
    type: Array,
    default: undefined,
  },
  /**
   * フィルターに設定された画像の表示切り替え
   * false: 表示しない(defult)
   */
  showImage: {
    type: Boolean,
    default: false,
  },
  /**
   * Point視点で見る時にわたす
   * 指定した場合、そのPointに該当するFilterに対するスタイル変化を可能にする
   */
  point: {
    type: Object,
    default: () => {},
  },
  /**
   * ポイントが満たすフィルターのみ表示するかどうか
   * pointと一緒に使う
   * true: ポイントが満たすフィルターのみ表示する
   * false: 全てのフィルターを表示(default)
   */
  showPassedOnly: {
    type: Boolean,
    default: false,
  },
  /**
   * ポイントがフィルターに該当するかどうかのアイコン表示
   * showPassedOnly = falseの場合のみ使用可能
   * アイコンは●と✕に固定
   * NOTE: アイコンをカスタム可能にする場合は追加実装が必要
   *
   * false: 表示しない(default)
   */
  showPassedIcon: {
    type: Boolean,
    default: false,
  },
  /**
   * 検索条件のラッパーのカスタムクラス
   */
  searchConditionsClass: {
    type: String,
    default: "default",
  },
  /**
   * 列挙する検索条件のカスタムクラス
   */
  searchConditionClass: {
    type: String,
    default: "default",
  },
  /**
   * ポイントが検索条件を満たさなかった際に付与されるクラス
   * pointと一緒に使用
   */
  notPassedClass: {
    type: String,
    default: "not-passed",
  },
})

/**
 * 表示するフィルターグループ
 * childrenを持つフィルターに該当
 */
const filterGroup = ref(null)

/**
 * 表示する検索条件
 */
const searchConditions = ref([])

/**
 * 表示するタイトル
 * props.titleがあればそれを、なければfilterGroupのnameを表示
 */
const displayTitle = computed(() => {
  return props.title || filterGroup.value?.name || ""
})

/**
 * 検索条件に一致したかどうかを示すアイコンの表示状態を返す
 */
const isPointPassedIconVisibled = computed<boolean>(() => {
  // pointが指定されていなければ、アイコンは表示しない(判定できないため)
  if (!props.point) return false
  // ポイントに一致する検索条件のみ表示する場合は、アイコンは表示しない
  if (props.showPassedOnly) return false

  return props.showPassedIcon
})

/**
 * 検索条件の表示状態を返す
 */
function isSearchConditonVisibled(searchCondition: Filter): boolean {
  // props.pointが指定されていない場合、全て表示
  if (!props.point) return true
  // 一致する条件のみ表示する指定がなければ、全て表示
  if (!props.showPassedOnly) return true

  // ポイントがフィルターの条件を満たす場合は表示する
  return isPointPassedFilter(searchCondition)
}

/**
 * 表示する検索条件のグループを取得
 */
function getSearchConditionGroup(id: string | number): Filter {
  // idがnumberの場合に対応
  const _id = String(id)
  const filter = Filters.findById(Filters.TYPE_SEARCH_CONDITIONS, _id)

  return filter
}

/**
 * 表示する検索条件を取得
 */
function getSearchConditions(filter: Filter) {
  if (!filter) {
    return []
  }

  // グループに含まれる検索条件を保持
  const _searchConditions: Array<Filter> = filterGroup.value.children

  // 表示が有効なフィルターにフィルタリング
  const enabledSearchConditions = _searchConditions.filter((c) => c.is_show)

  // id指定がある場合は該当する検索条件にフィルタリング
  if (props.showIds !== undefined) {
    const filteredSearchConditions = enabledSearchConditions.filter((c) =>
      props.showIds.includes(c.id),
    )

    return filteredSearchConditions
  }

  return enabledSearchConditions
}

/**
 * props.pointが指定Filterを満たすか判定
 * @param filter
 * @returns true: 満たす
 */
function isPointPassedFilter(filter: Filter): boolean {
  // props.pointが未指定なら弾く必要がないので通す
  if (!props.point) {
    return true
  }

  // グループは検索条件を持たないのでそのまま通す
  if (filter.children) {
    return true
  }

  // Pointに該当しなければ弾く
  return Filters.checkPointByFilter(props.point as Point, filter).passed
}

/**
 * Filterの属性を取得
 * @param filter
 * @returns Object 属性
 */
function getFilterAttribute(filter: Filter): {[key: string]: boolean} {
  if (isPointPassedFilter(filter)) {
    return {}
  }

  return {
    // props.pointに該当しないものは検索エンジンに拾わせない
    'data-nosnippet': true
  }
}

onMounted(() => {
  filterGroup.value = getSearchConditionGroup(props.groupId)
  searchConditions.value = getSearchConditions(filterGroup.value)
})
</script>

<style lang="scss" scoped>
.point-search-conditions-by-group-wrapper {
  .search-conditions {
    list-style-type: none;
    padding: 0;
    margin-bottom: 0;
    display: flex;

    // カスタムCSS対応
    &.default {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      align-content: flex-start;
    }

    .search-condition {
      white-space: nowrap;
      max-width: 100%;

      // カスタムCSS対応
      &.default {
        margin: 0 4px 4px 0;
        font-size: 0.85rem;
        border: 1px solid #ccc;
        border-radius: 3px;
        padding: 5px 10px;

        &.not-passed {
          opacity: 0.5;
          background-color: #ccc;
        }
      }

      // content内部は共通
      // TODO: 必要に応じてdefaultとカスタムに仕分けする必要がある
      .content {
        display: flex;
        align-items: center;
        line-height: 0;

        .image {
          width: auto;
          flex-shrink: 0;

          img {
            margin-right: 2px;
            width: auto;
            height: 15px;
          }
        }

        .name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-height: 1em;
        }
      }

      .passed-icon-wrapper {
        margin-right: 4px;

        .passed-icon {
          font-size: 0.85rem;

          &.passed {
            color: var(--theme-color);
          }
        }
      }
    }
  }

  .description {
    margin-top: 0.5rem;
    max-width: 100%;
    white-space: pre-wrap;
    word-break: break-word;
    font-size: .75rem;
    opacity: 0.5;
  }
}
</style>

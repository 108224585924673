<template>
  <div class="pagination">
    <button class="prev uk-button-page" @click="selectPage('-')">
      <span uk-icon="icon: triangle-left"></span>
    </button>

    <button class="first uk-button-page" :class="{selected: 1 === page}" @click="selectPage(1)">1</button>

    <span class="ellipsis" v-if="showStartEllips">...</span>

    <button
      class="inners uk-button-page"
      :class="{selected: _page === page}"
      v-for="_page in centerPages"
      :key="_page"
      @click="selectPage(_page)"
    >{{ _page }}</button>

    <span class="ellipsis" v-if="showEndEllipsis">...</span>

    <button
      class="last uk-button-page"
      :class="{selected: pageTotal === page}"
      v-if="pageTotal > 1"
      @click="selectPage(pageTotal)"
    > {{ pageTotal }}</button>

    <button class="next uk-button-page" @click="selectPage('+')">
      <span uk-icon="icon: triangle-right"></span>
    </button>
  </div>

</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import { WindowSize } from '@/constants'

const store = useStore()
const emit = defineEmits()

/**
 * ページの状態
 */
const props = defineProps({
  limit: { type: Number, default: 30},
  page: { type: Number, default: 1},
  pointTotal: { type: Number, default: 1},
})

const pageTotal = computed(() => {
  return Math.ceil(props.pointTotal / props.limit)
})

const windowSize = computed(() => store.state.windowSize)

// 1 と 最大ページを除いた、表示するページ番号を計算
function getStartAndEnd() {
  const max = windowSize.value === WindowSize.SMALL ? 3 : 7
  const offset = Math.floor(max / 2)
  let start = 2
  let end = pageTotal.value - 1

  if (pageTotal.value - 2 > max) {
    start = props.page - offset
    end = props.page + offset
    if (start < 2) {
      start = 2
      end = start + max -1
    } else if (end >= pageTotal.value) {
      end = pageTotal.value - 1
      start = end - max +1
    }
  }
  return {start: start , end: end}
}

// 表示するページ番号の配列を変える
const centerPages = computed(() => {
  if (pageTotal.value - 2 <= 0) {
    return []
  }

  const startAndEnd = getStartAndEnd()

  const pages = []
  for (let i = 0;  i <= startAndEnd.end - startAndEnd.start; i++) {
    pages.push(startAndEnd.start + i)
  }
  return pages
})

// はじめのellips表示
const showStartEllips = computed(() => {
  const startAndEnd = getStartAndEnd()
  return startAndEnd.start > 2
})

//うしろのellips表示
const showEndEllipsis = computed(() => {
  const startAndEnd = getStartAndEnd()
  return startAndEnd.end < pageTotal.value -1
})

/**
 * ページ選択
 * @param {Number} page ページ
 */
function selectPage(page) {
  if (page === "+") {
    page = props.page + 1
    if (page > pageTotal.value) {
      page = pageTotal.value
    }
  }

  if (page === "-") {
    page = props.page - 1
    if (page < 1) {
      page = 1
    }
  }

  if (props.page == page) {
    return
  }

  emit("on-page-changed", page)
}
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  justify-content: center;
  padding: 1rem;
  button {
    border: none;
  }
  .ellipsis {
    margin: 0 1rem;
    display: inline-block;
    line-height: 32px;
  }
}
</style>
<template>
  <ul class="uk-breadcrumb">
    <li :class="{current: item.current, root: item.root}" v-for="item in breadcrumb" :key="item.label">
      <a v-if="item.href" :href="item.href">{{ item.label }}</a>
      <span v-else>{{ item.label }}</span>
    </li>
  </ul>
</template>

<script setup lang="ts">
/**
 * パンくず
 * 店舗の詳細・店舗の一覧ページのナビゲーション用
 * 
 * ややこしくなってきたので、どこかで整理する
 * 上書きが必要なら、まるごと上書きできた方がシンプルにすむ
 */
import { computed, onMounted, ref, watch } from 'vue'

import Page from '@/services/Page'


interface BreadcrumbItem {
  /**
   * 表記
   */
  label: string

  /**
   * リンク先
   * 末端はリンクなし
   */
  href?: string

  /**
   * true: 現在のページ
   */
  current?: boolean

  /**
   * true: 最上位
   */
  root?: boolean
}

interface Props {
  /**
   * ページのPoint、店舗ページ用
   */
  point?: {
    name: string
  }

  /**
   * 要素
   * トップから順に
   */
  items?: BreadcrumbItem[]

  /**
   * 現在のページを指すもの、現在のもの、末端
   */
  current?: BreadcrumbItem

  /**
   * ルートページ
   * 
   * 戻し先の最上位を変更するための口
   * 店舗の一覧が最上位になる場合、パンくずの最上位を変えたい、時に使う
   * 
   * マップ：map
   * 店舗の一覧：points
   */
  rootPage?: string
}

const props = withDefaults(
  defineProps<Props>(),
  {
    point: () => ({ name: '' }),
    items: () => [],
    current: () => null,
    rootPage: () => 'map'
  }
)

const messages = computed<{[key: string]: string}>(() => storelocator.messages)

/**
 * パンくずの要素
 */
const breadcrumb = ref<BreadcrumbItem[]>([])

function update() {
  // console.debug('update breadcrumb', props, document.referrer)

  breadcrumb.value.length = 0

  // ルートを確認
  const savedPage = Page.storage.get()

  // 店舗ページ用
  if (props.point.name) {
    // console.debug('from', document.referrer, savedPage)

    // 店舗の一覧から遷移してきたらそこに戻せるように
    const pointsPageItems: BreadcrumbItem[] = []
    if (Page.isFromPointsPage()) {
      // console.debug('from points')

      pointsPageItems.push({
        label: messages.value.points_title,
        href: document.referrer
      })

    // 店舗の一覧が大元に指定されていたら差し替え（特殊な上書き時用）
    } else if (props.rootPage === 'points') {
      // console.debug('from points by page')

      pointsPageItems.push({
        label: messages.value.points_title,
        href: savedPage?.points?.url || Page.getPointsUrl() // 直接遷移（リファラなし）の可能性がある
      })
    
    // 店舗から来ているとリファラからは判断できないので、大本のアクセス先から戻し先を決める
    } else if (Page.isFromPointPage() && savedPage?.points?.url) {
      // console.debug('from point')

      pointsPageItems.push({
        label: messages.value.points_title,
        href: savedPage.points.url
      })
    }

    breadcrumb.value = []

    // 必ずしもマップが最上位とは限らない
    if (props.rootPage === 'map') {
      breadcrumb.value.push({
        label: messages.value.common_back_to_map,
        href: savedPage?.map?.url ? savedPage?.map?.url : Page.getMapUrl()
      })
    }

    breadcrumb.value = breadcrumb.value.concat(props.items)
    breadcrumb.value = breadcrumb.value.concat(pointsPageItems)
    
    // 末端（現在のページ）
    breadcrumb.value.push({
      label: props.current?.label
        ? props.current?.label
        : props.point?.name,
      current: true
    })

    breadcrumb.value[0].root = true

  // 店舗の一覧ページ用
  } else {
    // 必ずしもマップが最上位とは限らない
    if (props.rootPage === 'map') {
      breadcrumb.value.push({
        label: messages.value.common_back_to_map,
        href: savedPage?.map?.url ? savedPage.map?.url : Page.getMapUrl()
      })
    }

    breadcrumb.value = breadcrumb.value.concat(props.items)

    // 末端（現在のページ）
    breadcrumb.value.push({
      label: props.current?.label
        ? props.current?.label
        : messages.value.points_title,
      current: true
    })
  }

  if (breadcrumb.value.length > 1) {
    breadcrumb.value[0].root = true
  }
}

onMounted(() => {
  update()
})

watch(() => props.items, () => {
  setTimeout(() => {
    update()
  }, 100)
})
</script>

<style lang="scss" scoped>
.uk-breadcrumb {
  margin: 0 1rem 2rem 0;
  a {
    color: #444 !important;
  }
  @media only screen and (max-width: $breakpoint-small) {
    .current {
      display: none;
    }
  }
}
</style>
<template>
  <div class="current-location" :class="{loading: loading, completed: currentLocation}" @click="onToCurrentLocationClicked">
    <button class="uk-button uk-button-small" v-wave>
      <i class="material-symbols-sharp" data-icon="more_horiz" v-if="loading"></i>
      <i class="material-symbols-sharp" data-icon="my_location" v-else></i>
      <label>{{ messages.map_pan_to_current_location }}</label>
    </button>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

import uikit from 'uikit'

import MapService from '@/services/MapService.ts'
import CommonService from '@/services/CommonService.ts'

const store = useStore()

const messages = computed(() => storelocator.messages)

const currentLocation = computed(() => store.getters.currentLocation)

/**
 * 現在地へ移動クリック時
 */
async function onToCurrentLocationClicked() {
  await panToCurrentLocation()
}

/**
 * true: 現在地取得中
 * MapService.locationManager.isLoadingでまかないたいが、リアクティブにするのが手間
 */
const loading = ref(false)

/**
 * 現在地への遷移
 * 
 * 位置情報へのアクセスが無効：取得できない旨出す＋地名検索を促す
 * 位置情報へのアクセスが有効＋現在地の取得に失敗：取得できない旨出す
 */
async function panToCurrentLocation() {
  if (MapService.locationManager.isLoading) {
    return
  }

  store.dispatch('startProcess')
  loading.value = true

  let location = null
  try {
    location = await MapService.locationManager.panToCurrentLocation()

    uikit.notification.closeAll()
    uikit.notification({
      message: messages.value.common_moved_to_current_location,
      pos: 'top-center',
      timeout: 3000
    })
  } catch (e) {
    CommonService.onFailedPanToCurrentLocation(e)
  } finally {
    setTimeout(() => {
      loading.value = false
    }, 500)
  }

  store.dispatch('finishProcess')

  store.dispatch('saveCurrentLocation', location)
}
</script>

<style lang="scss" scoped>
@keyframes fade-in-nearest-point {
  0% {
    opacity: 0;
    transform: translateX(-2.5%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.current-location {
  animation: fade-in-nearest-point 0.25s ease-in;
  padding: 0;
  text-align: center;

  * {
    cursor: pointer;
  }

  button {
    background-color: #fff;
    border-radius: 15px;
    border: 1px solid #eee;
    position: relative;
    height: 30px;
    width: 8.25rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: right;
    transition: all .15s ease-in-out;
    i {
      display: inline-block;
      position: absolute;
      font-size: 16px;
      top: 50%;
      left: 1rem;
      transform: translateX(-50%) translateY(-50%);
    }
    label {
      font-size: 14px;
      display: inline-block;
    }
  }

  @keyframes loading-loop {
    0% {
      clip: rect(0px, 5px, 16px, 0px);
    }
    50% {
      clip: rect(0px, 10px, 16px, 0px);
    }
    100% {
      clip: rect(0px, 16px, 16px, 0px);
    }
  }

  &.loading {
    button {
      i {
        animation: loading-loop .75s infinite;
        color: $current-location-color;
      }
    }
  }

  &.completed {
    button {
      i {
        color: $current-location-color;
      }
    }
  }
}
</style>

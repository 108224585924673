import { toNumberIfString } from '@/core/common.js'

/**
 * 現在地まわり
 * 
 * #CurrentLocation のボタンをマップ上に設置
 * 
 * 現在地を取得前は以下を表示
 * .ready
 * 
 * 取得中は以下を表示
 * .loading
 * 
 * 取得後は以下をつける（表示する要素は.ready）
 * .completed
 * 
 * TODO 地図の表示範囲内から離れたら最初の状態に戻してもいい
 */
export class LocationManager {
  /**
   * Geolocation API の結果コード：位置の取得拒否
   */
  static CURRENT_LOCATION_RESULT_DENIED = 1

  /**
   * Geolocation API の結果コード：タイムアウト
   */
  static CURRENT_LOCATION_RESULT_TIMEOUTED = 3

  /**
   * @param {google.maps.Map} map 
   */
  constructor(map, options) {
    /**
     * @type {google.maps.Map}
     */
    this.map = map

    /**
     * 現在地取得のタイムアウト[ms]
     */
    this.timeout = 15 * 1000

    /**
     * 現在地取得中表現の最小時間[ms]
     */
    this.MIN_DURATION = 500

    /**
     * true: 読込中
     */
    this.isLoading = false

    /**
     * 現在地取得の開始時刻
     * Date.now()
     */
    this.started = null

    /**
     * 現在地マーカー
     */
    this.marker = null

    // マップに設置
    const button = document.createElement('div')
    button.id = 'CurrentLocation'
    button.innerHTML = `
      <svg class="ready" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18px" height="18px"><path d="M0 0h24v24H0z" fill="none"/><path stroke-width="1" d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"/></svg>
      <svg class="loading" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-155.5t86-127Q252-817 325-848.5T480-880q17 0 28.5 11.5T520-840q0 17-11.5 28.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160q133 0 226.5-93.5T800-480q0-17 11.5-28.5T840-520q17 0 28.5 11.5T880-480q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80Z"/></svg>
      `
    button.onclick = async () => {
      // console.debug('CurrentLocationButton clicked')
      if (!options.onClicked) {
        return
      }

      await options.onClicked()
    }

    this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(button)
  }

  /**
   * 地図移動時の更新
   * 
   * 現在地が表示範囲外に出ていたら、取得前の状態に戻す
   * 範囲内に戻ったら取得済にする
   */
  update() {
    if (!this.location) {
      return
    }

    const target = document.getElementById("CurrentLocation")

    if (target && this.map.getBounds().contains(this.location)) {
      target.classList.add("completed")

      return
    }

    if (target) {
      target.classList.remove("loading")
      target.classList.remove("completed")
    }
  }

  /**
   * 現在地を取得して移動
   * 精度が低い場合は採用しない
   * @param {*} getOnly true = 現在地を取得するだけで移動しない
   * @resolve google.maps.LatLng
   * @reject {Error}
   * @returns {Promise}
   * resolve: google.maps.LatLng
   * reject: { 例外そのもの＋取れた時は精度
   *   code: number コード
   *   message: string メッセージ
   *   accuracy: number 精度
   * }
   */
  async panToCurrentLocation(getOnly = false) {
    this.onBeforeGetLocation()

    // 取得中表示の最小時間を確保する準備
    const target = document.getElementById("CurrentLocation")
    if (target) {
      target.classList.add("loading")
    }
    this.started = Date.now()
    this.isLoading = true

    const finalize = () => {
      const target = document.getElementById("CurrentLocation")
      if (target) {
        target.classList.remove("loading")
      }

      this.isLoading = false
    }

    // 取得開始
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // console.debug('getCurrentPosition', position)
      
          // 一定時間後に閉じるようにする
          const diff = Date.now() - this.started
      
          if (diff < this.MIN_DURATION) {
            const wait = this.MIN_DURATION - diff
            setTimeout(() => {
              finalize()
            }, wait)
          } else {
            finalize()
            this.started = null
          }

          if (position.coords.accuracy > 100000) {
            console.warn('over accuracy, cannot use position', position.coords.accuracy)
            reject({
              accuracy: position.coords.accuracy
            })
            return
          }

          this.location = new google.maps.LatLng(position.coords.latitude, position.coords.longitude)
          if (!getOnly) {
            this.setCurrentLocationMarker(this.location)
            this.map.panTo(this.location)
          }

          const target = document.getElementById("CurrentLocation")
          if (target) {
            target.classList.add("completed")
          }

          // 現在地そのものは送信しない（必要なら別途許諾が必要）
          storelocator.analytics.client?.send('GetCurrentLocation', {
            Status: 'succeeded'
          })

          this.onSucceededGetLocation(this.location)

          resolve(this.location)
        },
        (e) => {
          this.onAfterGetLocation(e)

          finalize()

          console.warn(e)
          reject(e)

          storelocator.analytics.client?.send('GetCurrentLocation', {
            Status: 'failed',
            Message: e.message
          })
        },
        {
          timeout: this.timeout
        }
      )
    })
  }

  /**
   * マーカーとして設置
   * @param {google.maps.Map} position 現在地
   */
  setCurrentLocationMarker(position) {
    if (!this.marker) {
      const image = document.createElement("div")
      image.innerHTML = '<img src="/assets/images/self.svg">'

      this.marker = new google.maps.marker.AdvancedMarkerElement({
        map: this.map,
        title: "現在地",
        content: image
      })
    }

    this.marker.position = {
      lat: position.lat(),
      lng: position.lng()
    }
  }

  /**
   * 現在地取得前
   * フック用
   */
  onBeforeGetLocation() {}

  /**
   * 現在地取得後
   * 取得成功とは限らない
   * フック用
   */
  onAfterGetLocation() {}

  /**
   * 現在地取得に成功し、移動した後
   * フック用
   * @param {google.maps.LatLng} location
   */
  onSucceededGetLocation(location) {}

  /**
   * 現在地取得に成功し、移動した後
   * フック用
   * @param {Error} e
   */
  onFailedGetLocation(e) {}
}

/**
 * 位置のキャッシュ
 * ローカルストレージの保存・復元を担当
 * 移動までは担当しない
 */
export class LocationCache {
  constructor() {
    this.key = `storelocator:${location.host}:location`

    /**
     * 初期値
     * かつキャッシュの復元先になる
     */
    this.default = {
      lat: 35.6812362,
      lng: 139.7671248,
      zoom: 16
    }

    /**
     * 最小ズームレベル
     * 今は使えてない
     */
    this.minZoom = 11

    /**
     * true: キャッシュから復元したことを示す
     * 現在地への移動を行っていいかどうかの判断に使う
     */
    this.loadedCache = false
  }

  /**
   * 表示位置・ズームをキャッシュ
   * @param {google.maps.LatLng} position map.getCenter()
   * @param {Number} zoom map.getZoom()
   */
  save(position, zoom) {
    if (!storelocator.map?.cache?.enabled) {
      return
    }

    if (localStorage) {
      localStorage.setItem(
        this.key,
        JSON.stringify({
          lat: position.lat(),
          lng: position.lng(),
          zoom: zoom,
          expireTime: Math.floor( new Date().getTime() / 1000 )
            + (storelocator.map?.cache?.lifetime ? storelocator.map?.cache?.lifetime : 180)
        })
      )
    }
  }

  /**
   * 位置のキャッシュを読み込み
   * @returns {Object} {lat, lng, zoom}
   */
  load() {
    if (!localStorage) {
      return undefined
    }

    const value = localStorage.getItem(this.key)
    if (value == undefined) {
      return undefined
    }

    return JSON.parse(value)
  }

  /**
   * 位置をキャッシュから復元
   * デフォルト値や上限値のみ変更、移動までは行わない
   * 期限切れのキャッシュは破棄する
   * 
   * 優先順位に一貫性がないようにも見える、要確認
   * 
   * @returns {LocationCache}
   */
  restore() {
    let cache = this.load()

    if (cache) {
      // 期限切れのキャッシュなら破棄
      const date = new Date()
      const now = date.getTime() / 1000
  
      if (now > parseInt(cache.expireTime)) {
        console.info('expired cache, remove cache', now, cache.expireTime)
        localStorage.removeItem(this.key)
      }

      // 開き直しや外部からのアクセスであればキャッシュは使わない
      // console.debug('referrer', document.referrer, location.host)
      if (!document.referrer || document.referrer.indexOf(location.host) === -1) {
        // console.debug('reopen or from external, remove cache')
        localStorage.removeItem(this.key)
        cache = undefined
      }
    }

    if (cache) {
      this.loadedCache = true
    }

    const config = storelocator.map?.location?.default

    if (config && cache != undefined) {
      this.default.lat = toNumberIfString(cache.lat)
      this.default.lng = toNumberIfString(cache.lng)
    } else if (config?.latitude != null && config?.longitude != null) {
      this.default.lat = toNumberIfString(config.latitude)
      this.default.lng = toNumberIfString(config.longitude)
    }

    if (config && cache != undefined) {
      this.default.zoom = toNumberIfString(cache.zoom)
    } else if (config?.zoom != null) {
      this.default.zoom = toNumberIfString(config.zoom)
    }

    if (config?.min_zoom != undefined) {
      this.minZoom = toNumberIfString(config.min_zoom)
    }

    return this
  }
}
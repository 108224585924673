<template>
  <!-- 空＋空なら出さない -->
  <div class="empty" :class="format.class" v-if="format.hide_on_empty && isEmpty(parse(point, format))">
  </div>

  <!-- 高度な設定 -->
  <div class="advanced" :class="format.class" v-if="(format.format || format.patterns) && !isEmpty(parse(point, format))">
    <span v-html="parse(point, format)"></span>
  </div>

  <!-- フィールド -->
  <div class="field" :class="format.class" v-else-if="format.key && !isEmpty(point[format.key])">
    <span v-html="parse(point, format)"></span>
  </div>

  <!-- 追加フィールド：空以外 -->
  <div class="extra-field" :class="format.class" v-else-if="format.key">
    <span v-html="parse(point, format)"></span>
  </div>

</template>

<script setup>
/**
 * 店舗の属性
 *
 * デザイン管理で指定するフォーマットを適用してPointの属性を表記
 */

import { parse } from "@/utils/format.ts"

const props = defineProps({
  /**
   * 明示的に表示対象を決める場合に指定
   * 指定しなければ地図の表示範囲のPointを出す
   */
  point: {
    type: Object,
    required: true,
    default: {}
  },
  /**
   * Pointのフォーマット
   * storelocator.map.points.point.formats[]
   * storelocator.point.nearby.point.formats[]
   */
  format: {
    type: Object,
    required: true,
    default: {},
  }
})

function isEmpty(value) {
  return value == null || value === '' || value === undefined
}

</script>

<style lang="scss" scoped>
.empty {
  display: none;
}
</style>
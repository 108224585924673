<template>
  <ul class="points" v-if="nearPoints.length > 0">
    <li
      v-for="point in nearPoints"
      class="point-wrapper"
      :class="{selected: selectedPoint?.id === point.id}"
      :key="point.id"
      @click="onPointSelected(point)"
    >
      <!-- リンク -->
      <a :href="Page.getPointUrl(point)" v-if="link">
        <Point :point="point" :openable="openable" @onToPointClicked="onToPointClicked">
          <template #attributes>
            <template v-for="f in formats">
              <p class="subline">
                <PointAttribute :point="point" :format="f"></PointAttribute>
              </p>
            </template>
            <PointSearchConditions :point="point" :showIds="showSearchConditionIds" v-if="searchConditions?.show"></PointSearchConditions>
          </template>
        </Point>
      </a>

      <!-- 直接配置 -->
      <Point :point="point" :openable="openable" @onToPointClicked="onToPointClicked" v-else>
        <template #attributes>
          <template v-for="f in formats">
            <p class="subline">
              <PointAttribute :point="point" :format="f"></PointAttribute>
            </p>
          </template>
          <PointSearchConditions :point="point" :showIds="showSearchConditionIds" v-if="searchConditions?.show"></PointSearchConditions>
        </template>
      </Point>
    </li>
  </ul>
</template>

<script setup>
/**
 * 近くの店舗
 * 
 * 地図上に表示している店舗を表示
 * 地図の中心から近い順
 */

import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'

import Page from "@/services/Page.js"
import { Filters } from '@/services/Filters.ts'

import Point from '@/components/common/Point.vue'
import PointAttribute from '@/components/common/PointAttribute.vue'
import PointSearchConditions from '@/components/common/PointSearchConditions.vue'


const store = useStore()

const emit = defineEmits('onPointSelected')

const props = defineProps({
  /**
   * 明示的に表示対象を決める場合に指定
   * 指定しなければ地図の表示範囲のPointを出す
   */
  points: {
    type: Array,
    default: () => [],
  },
  /**
   * Pointのフォーマット
   * storelocator.map.points.point.formats
   * storelocator.point.nearby.point.formats
   * storelocator.points.points.point.formats
   */
  formats: {
    type: Array,
    default: [
      {
        key: "address",
        kind: "field",
      },
    ],
  },
  /**
   * 検索条件まわり
   * 今後拡張の可能性高いのでオブジェクト
   */
  searchConditions: {
    type: Object,
    default: {
      show: false,
      only_selected: false,
    },
  },
  /**
   * true: 操作可能、リンクないしPointの詳細を開ける
   */
  openable: {
    type: Boolean,
    default: true,
  },
  /**
   * true: リンクにする
   * 
   * 選択時に特に追加処理がなければこちらで
   */
  link: {
    type: Boolean,
    default: true
  }
})

const showSearchConditionIds = ref()

/**
 * 近くのPoint
 */
const nearPoints = computed(() => {
  // NOTE: Panel.vueからはprops.pointsが渡されない
  // その場合は、store.getters.nearPointsを使っているが、Panel.vueでも同様にnearPointsにアクセスしている
  // 結果として、nearPoints内でsortByDistanceが余計に実行されている様子
  // Panel.vueからもprops.pointsを渡すように修正すると効率化するはず
  if (props.points.length > 0) {
    return props.points
  }

  if (!store.getters.nearPoints || store.getters.nearPoints.length === 0) {
    return []
  }

  return store.getters.nearPoints.items
})

const selectedPoint = computed(() => store.getters.selectedPoint)
const currentLocation = computed(() => store.getters.currentLocation)

onMounted(() => {
  updatePoints()
})

watch(currentLocation, () => {
  updatePoints()
})

watch(nearPoints, () => {
  updatePoints()
})

/**
 * Point選択時
 * @param {Object} point getters.nearPoints[]
 */
function onPointSelected(point) {
  // console.debug('onPointSelected', point)

  setTimeout(() => {
    emit('onPointSelected', point)
  }, 200)

  storelocator.analytics.client?.send('SelectPointOnPoints', {
    PointName: point.name,
    PointKey: point.key,
    PointAddress: point.address,
    TargetInPoint: 'row'
  })
}

/**
 * 遷移のアイコンをピンポイントでクリック
 * 
 * イベント記録のためにわけている
 * 
 * Point.emit.onToPointClicked
 * @param {Object} point Point
 */
function onToPointClicked(point) {
  // console.debug('onToPointClicked', point)

  setTimeout(() => {
    emit('onPointSelected', point)
  }, 200)

  storelocator.analytics.client?.send('SelectPointOnPoints', {
    PointName: point.name,
    PointKey: point.key,
    PointAddress: point.address,
    TargetInPoint: 'button'
  })
}

/**
 * Point反映
 */
function updatePoints() {
  // console.debug('Points.vue updatePoints')

  if (props.searchConditions?.show && props.searchConditions?.only_selected) {
    updateShowSearchConditionIds()
  }
}

/**
 * 表示する検索条件を更新
 */
function updateShowSearchConditionIds() {
  // マップページ
  showSearchConditionIds.value = Object.values(Filters.selectedFilters.search_conditions)

  if (showSearchConditionIds.value.length > 0) {
    return
  }

  // マップページ以外
  if (sessionStorage) {
    const cache = Filters.cache.load()
    showSearchConditionIds.value = cache?.search_conditions || []
  }
}
</script>

<style lang="scss">
@keyframes point-fade-in {
  0% {
    opacity: 0;
    transform: translateX(-5%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.points {
  margin: 0;
  padding: 0;
  list-style: none;
  .point-wrapper {
    animation: point-fade-in 0.5s ease;
    border-bottom: solid 1px #eee;

    a {
      text-decoration: none !important;
      * {
        text-decoration: none !important;
      }
    }

    // Point側にまとめたいが、scopedとslotの相性の問題もあって難しそう
    // 単純にPointにいれると、全行が同時に反応する
    .point {
      .marker {
        margin-bottom: 20px !important;

        &.noframe {
          .icon {
            width: 32px;
            height: 32px;
            transform: scale(0.4);
          }
        }
      }

      &:hover {
        .to-point {
          right: 0.75rem;
          opacity: .9;
          transition: all .25s;
        }
      }
    }

    .attributes {
      color: #444;
      margin: -12px 28px 0 46px;

      .subline {
        align-items: center;
        margin: .25rem 0 .25rem 0;

        .distance {
          opacity: .5;
        }
      }
    }

    @keyframes selected-point-scale {
      0% {
        opacity: 0.5;
      }
      100% {
        opacity: 1.0;
      }
    }

    &.selected {
      background-color: #f9f9f9;

      .point {
        .marker {
          animation: 1s linear infinite alternate selected-point-scale;
        }
        .arrow {
          opacity: 0;
        }
      }
    }
  }
}
</style>
/**
 * 中身のURLをリンク化
 * 既にaタグがあれば無視（一部だけURLのみ、は想定しない）
 * @param text 
 * @returns リンク化（aタグに変換）したtext
 */
export function linkify(text: string): string {
  const urlPattern = /(https?:\/\/[^\s]+)/g
  const imagePattern = /<img.+src=[\"\']http/g

  return text.replace(urlPattern, (url) => {
    if (text.includes('<a')) {
      // 既にaタグがあれば無視（一部だけURLのみ、は想定しない）
      return url
    } else if (imagePattern.test(text)) {
      // imgタグのsrcは無視
      return url
    } else {
      return `<a href="${url}" target="_blank">${url}</a>`
    }
  })
}

/**
 * 所定のフォーマットを受け取り、htmlタグを返す
 * formatはマスタッシュの中にポイント情報のキーを入れると、置き換えて返される
 *
 * @param point ポイント
 * @param settings {
 *   type: text（デフォルト） | link | tel | postal | mail | boolean | | distance | func,
 *   format: "任意のテキスト{{pointのkey}}任意のテキスト"
 *   key: Pointのフィールドのキー、複雑な書式が不要ならこちらを使う
 * }
 *
 * ※ 距離を表示するには、先に現在地を取得しておく必要がある
 *
 * @returns タグ または type = textの場合はテキスト, nullは項目ごと消したい時（未設定・もしくは隠す条件に該当、空文字と明示的に区別）
 */
export function parse(point: Point, settings: PointFormat): string {
  if (!settings) {
    return null
  }

  // 高度な設定のパターンを適用
  let advancedValue = null
  if (settings.kind === 'advanced') {
    for (const pattern of settings.patterns || []) {
      // 条件を満たすか判定
      const passedOrNot: boolean[] = []
      pattern.conditions.forEach(condition => {
        // TODO: 共通化の余地あり(Filters.ts, PriorityPoints.vue) ここから
        let originalValue: string | number | object = point[condition.key as keyof Point] || point.extra_fields[condition.key]
        if (originalValue == null) {
          originalValue = ''
        }

        let conditionValue: string | number = condition.value
        if (conditionValue == null) {
          conditionValue = ''
        }

        // console.debug('pattern condition', condition, originalValue, conditionValue)

        const fieldType = getFieldType(condition.key)

        // 比較対象フィールドが日付/日時の場合
        if (fieldType == 'date' || fieldType == 'datetime') {

          // 入力値が日付か日時かを判定
          const conditionDateType = getDateType(conditionValue as string)

          // 比較に使う型（date/datetime)が異なる場合は"date"で比較する
          const dateType = fieldType === conditionDateType ? fieldType : 'date';

          // 日付の文字列をタイムスタンプに置き換え
          const now = new Date()
          originalValue = getTimeStampFromDateString(originalValue as string, dateType, now)
          conditionValue = getTimeStampFromDateString(conditionValue as string, dateType, now)

        } else if (
          // 数値に直せるのであれば数値で比較
          (typeof originalValue === 'string' && !isNaN(parseFloat(conditionValue)) && !isNaN(parseFloat(originalValue))) ||
          (typeof originalValue === 'number' && !isNaN(parseFloat(conditionValue)))
        ) {
          originalValue = Number(originalValue)
          conditionValue = Number(conditionValue)
        }


        if (condition.comparison === '=') {
          passedOrNot.push(conditionValue == originalValue)
        } else if (condition.comparison === '!=') {
          passedOrNot.push(conditionValue != originalValue)
        } else if (conditionValue === null || originalValue === null) {
          // 比較する値がnullの場合、'<', '>'による比較は行わず、常にfalseを返す
          passedOrNot.push(false)
        } else if (condition.comparison === '>') {
          passedOrNot.push(originalValue > conditionValue)
        } else if (condition.comparison === '<') {
          passedOrNot.push(originalValue < conditionValue)
        }
      })

      // TODO: 共通化の余地あり(Filters.ts, PriorityPoints.vue) ここまで

      // AND: 全て満たす / OR: いずれか満たす 必要ある
      if (pattern.operation === 'AND' && !passedOrNot.every(v => v)) {
        continue
      } else if (pattern.operation === 'OR' && !passedOrNot.some(v => v)) {
        continue
      }

      // 隠すものなら、空として決定
      if (pattern.hide) {
        // console.debug('pattern hide', pattern)
        return null
      }

      // 最初に条件を満たしたものを使う
      // console.debug('pattern use value', pattern.value_format)
      advancedValue = pattern.value_format
      break
    }
  }

  let value = advancedValue

  // 高度な設定でパターンを使ったもの以外
  if (value == null) {
    value = settings.key
      ? (point[settings.key as keyof Point] || point.extra_fields[settings.key]) as string // TODO 0を救えるように
      : settings.format?.replace(/\n/g, "<br>")
  }

  // 比較ブレ防止・空対策
  if (value == null) {
    value = ''
  }

  // 表記反映
  value = parseMessage(value)

  // 距離
  let distance: string
  const isDistance = settings.format && settings.format.includes("{{distance}}")
  if (isDistance) {
    distance = mToKm(point.distance)

    if (!distance) {
      return ''
    }  

    if (storelocator.distance?.unit === 'mile') {
      distance = mToMile(distance)
    }
  }

  value = value.replaceAll(/{{(.+?)}}/g, (match, key: string) => {
    if (key == 'distance') {
      return distance
    }

    if (!point?.extra_fields) {
      return key
    }

    return point.extra_fields[key] != null
      ? point.extra_fields[key] as string
      : point[key as keyof Point] as string
  })

  // 挿入後も空なら、空で確定
  if (value == undefined || value == null || value == "" || value == "null" || value == "undefined") {
    return ''
  }

  value = linkify(value)

  // リンク化や書式調整
  let result
  if (settings.kind === "link") {
    result = `<a href="${value}" target="_blank">${value}</a>`
  // いったんやめる、使っていてぎょっとするので、電話の方法は別に用意する、一応残しておく
  // } else if(settings.kind === "tel") {
  //   result = `<a href="tel:${value}">${value}</a>`
  } else if(settings.kind === "mail") {
    result = `<a href="mailTo:${value}">${value}</a>`
  } else if (settings.kind === "postal") {
    result = postal(value)
  } else {
    result = value
  }

  // 改行コードを置き換え
  if (result) {
    result = result.replace(/\n/g, "<br>")
  }

  return result
}

/**
 * 距離を単位を添えた文字列に変換、m を km に変換
 * @param m 距離（m）
 * @returns kmに変換した距離（単位も添える）、1km未満ならm表記
 */
export function mToKm(m: number): string {
  if (!m) {
    return ''
  }

  if (m < 10) {
    return ''
  }

  if (m < 1000) {
    return `${Math.round(m)} m`
  }

  const km =  (Math.round(m / 100)) / 10
  return `${km} km`
}

/**
 * kmをマイル表記に変換
 * @param distance 距離（m, km）
 * @returns 
 */
export function mToMile(distance: string): string {
  // console.debug('mToMile', distance)

  let meter = 0
  if (distance.includes('km')) {
    meter = Number.parseFloat(distance.replace('km', '')) * 1000
  } else if (distance.includes('m')) {
    meter = Number.parseFloat(distance.replace('m', ''))
  } else {
    meter = Number.parseFloat(distance)
  }

  const mile = (meter / 1609.344).toFixed(1)

  return `${mile} mi`
}

/**
 * 
 * @param distance 距離（メートル）
 * @returns 単位を付与した文字列
 */
export function parseDistance(distance: number): string {
  // console.debug('parseDistance', distance)

  if (!distance) {
    return ''
  }

  const meter = mToKm(distance)

  if (storelocator.distance?.unit === 'mile') {
    return mToMile(meter)
  }

  return meter
}

/**
 * 郵便番号フォーマット
 * @param code 郵便番号いりの文字列
 * @returns 〒000-0000の形
 */
function postal(code: string): string {
  code = code.replace(/(.*)([0-9０-９]{3,3})([0-9０-９]{4,4})/, "$1$2-$3")
  if (!/〒/.test(code)) {
    code = "〒" + code
  }
  return code
}

/**
 * 開始日・終了日から、今日が期間内かどうか判定する
 * @param {String} startDate 開始日 (yyy-mm-dd 形式の文字列)
 * @param {String} endDate 終了日 (yyy-mm-dd 形式の文字列)
 * @returns {Boolean} 期間内:true、期間外:false
 */
export function isTodayWithinPeriod(startDate: string, endDate: string) {
  const now = new Date()
  const todayTimeStamp = getTimeStampFromDateString('{today}', 'date', now)
  let startTimeStamp, endTimeStamp = null
  if (startDate) {
    startTimeStamp = getTimeStampFromDateString(startDate, 'date', now)
  }
  if (endDate) {
    endTimeStamp = getTimeStampFromDateString(endDate, 'date', now)
  }

  if (startTimeStamp && startTimeStamp > todayTimeStamp) return false
  if (endTimeStamp && endTimeStamp < todayTimeStamp) return false

  return true
}


/**
 * 日付/日時の文字列からタイムスタンプを返す
 * @param {String} date 日付/日時の文字列 {now},{today},'2024-10-01','2024-01-01 12:00:00'など
 * @param {String} dateType date または datetime
 * @param {Date} now 現在日時のDateオブジェクト
 * @returns {number|null} タイムスタンプ、空や不正な形式の文字列でタイムスタンプが取得できない場合はnull
 */
function getTimeStampFromDateString(date: string, dateType: string, now: Date) {
  let dateObj
  if (date === '{now}') {

    if (dateType == 'date') {
      dateObj = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    } else {
      dateObj = now
    }

  } else if (date === '{today}') {
    dateObj = new Date(now.getFullYear(), now.getMonth(), now.getDate())

  } else {
    if (dateType == 'date') {
      const dateOnly = date.split(' ')[0]
      // 時間を指定しない場合はUTCになってしまうので時間を指定してローカルタイムにする
      dateObj = new Date(`${dateOnly}T00:00:00`)
    } else {
      dateObj = new Date(date)
    }
  }

  return Number.isNaN(dateObj.getTime()) ? null : dateObj.getTime()
}

/**
 * フィールドキーからフィールド種類を取得する
 * @param {String} fieldKey フィールドキー
 * @returns {string|null} フィールド種類（string,date など）、フィールドタイプが存在しない場合はnull
 */
function getFieldType(fieldKey: string) {
  return storelocator.fields[fieldKey]?.type || null
}

/**
 * 日付/日時の文字列が 日付のみ（date）か時刻も含むか（datetime）を判定
 * @param {String} value 日付/日時の文字列 "2024-10-01","2024-01-01 12:00:00"など
 * @returns {string|null} 日付のみの場合:date、日時の場合:datetime、空や不正な形式で判定不能の場合:null
 */
function getDateType(value: string) {

  if (value === '{now}') return 'datetime'
  if (value === '{today}') return "date"

  const date = new Date(value)
  // 有効な日付かどうか
  if (isNaN(date.getTime())) return null

  // 日にちのみか時刻を含むかを判定
  const isDateOnly = value.trim().match(/^\d{4}-\d{2}-\d{2}$/)

  if (isDateOnly) {
    return 'date'
  } else {
    return 'datetime'
  }
}

/**
 * 与えられた文字列をパースして、表記反映後の文字列を返す
 * 表記の設定 MapConfig.design[lang_code].messages
 * ex. 表記設定 { hello_world: 'こんにちは世界' }, value: 'ここに文字を埋め込む→{{some_messages}}'
 *     => 'ここに文字を埋め込む→こんにちは世界'
 *
 * @param value パースする文字列
 * @returns 表記反映後の文字列
 */
export function parseMessage(value: string) {
  const messages = storelocator.messages

  Object.keys(messages)
  .filter(key => value.includes(`{{messages.${key}}}`))
  .forEach(key => {
      value = value.replace(`{{messages.${key}}}`, storelocator.messages[key])
  })

  return value
}

/**
 * 改行コードを<br>に変換
 * @param text 改行コードを含む文字列
 * @returns 改行コードが<br>に置換された文字列
 */
export function newLineToBr(text: string): string {
  return text.replace(/\n/g, "<br>")
}

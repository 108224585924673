<template>
  <div class="autocomplete-wrapper" :class="additionalClass">
    <div class="search-input">
      <template v-if="rightIcon && !isCompleted">
        <span
          :class="{completed: isCompleted}"
          class="uk-form-icon uk-form-icon-flip"
          v-on:click="onSearchClicked"
          uk-icon="icon: search"
        />
      </template>
      <template v-else>
        <span 
          :class="{completed: isCompleted}"
          class="uk-form-icon prefix-search-icon material-symbols-sharp"
          :data-icon="isCompleted ? 'check' : 'distance'"
        />
      </template>
      <input
        style="display:block"
        @click.stop="onFocused"
        @keydown.enter="onEnter"
        id="autocomplete"
        class="uk-input uk-form-small"
        :class="{'active': isCompleted}"
        :placeholder="placeholder"
        type="text"
        @change="onChanged"
        enterkeyhint="search" />
    </div>
  </div>
</template>

<script setup>
import uikit from 'uikit'
import { onMounted, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { geocoding } from '@/utils/geocoding'
import MapService from '@/services/MapService.ts'

const store = useStore()

const emit = defineEmits(['onSearchPlace'])
const props = defineProps({
  /**
   * プレースホルダー
   * 多言語化するときは注意
   */
  placeholder: {
    type: String,
    default: storelocator.messages.map_search_place
  },
  /**
   * デフォルトで入力するクエリ
   */
  query: {
    type: String,
    default: null,
  },
  /**
   * 追加クラス
   * 画面表示の調整に用いる想定
   * 'points-page'を指定すると、一覧ページのデザインに合わせて表示調整される
   * それ以外は案件ごとに個別のCSSで調整する想定
   */
  additionalClass: {
    type: String,
    default: "",
  },
  /**
   * 地名検索結果が有効かどうか
   * ポイント一覧ページで利用する想定. 地名検索結果を用いたソートが有効な場合、true を指定する
   * この値の影響は見た目だけで、動作には影響しない
   */
  isCompleted: {
    type: Boolean,
    default: false,
  },
  /**
   * 右側に検索アイコンを表示するかどうか
   */
  rightIcon: {
    type: Boolean,
    default: false,
  }
})

onMounted(() => {
  if (props.query) {
    document.getElementById("autocomplete").value = props.query
  }
  if (storelocator?.map?.searchers?.places?.autocomplete?.enabled) {
    initAutocomplete()
  }
  if (storelocator?.points?.default?.filters?.places?.autocomplete?.enabled) {
    initAutocomplete()
  }
})

function onEnter(e) {
  if (!e.isComposing) {
    document.getElementById("autocomplete").blur()
    onSearchClicked()
  }
}

const messages = computed(() => storelocator.messages)

/**
 * Autocompleteに書き換えられていないクエリをもっておきたい
 */
let inputtedPlaceQuery = ''
function onChanged() {
  inputtedPlaceQuery = document.getElementById('autocomplete').value
}

// イベントが複数発火したときの防止用
let searching = false

function initAutocomplete() {
  const autocomplete = new google.maps.places.Autocomplete(
    document.getElementById('autocomplete'),
    {
      componentRestrictions: {country: storelocator.google_maps?.country || 'jp'},
      fields: ['geometry', 'address_components']
    }
  );

  autocomplete.addListener('place_changed', async () => {
    // console.debug('place_changed')

    if (searching) {
      // console.debug('intterupt place_changed')
      return
    }

    searching = true

    const place = autocomplete.getPlace()
    let geometry
    let addressComponents
    let by

    if (place.geometry) {
      geometry = place.geometry
      addressComponents = place.address_components
      by = 'Places'
    } else {
      try {
        const geocoded = await geocoding(place.name)
        geometry = geocoded.geometry
        addressComponents = geocoded.address_components
        by = 'Geocoding'
      } catch (status) {
        console.warn('geocode failed', status)
        if (status === 'ZERO_RESULTS') {
          uikit.notification({
            message: messages.value.map_search_place_zero_result,
            pos: 'top-center',
            timeout: 3000
          })
        } else if (status === 'ERROR') {
          uikit.notification({
            message: messages.value.common_failed_network_connect,
            pos: 'top-center',
            timeout: 3000
          })
        }

        searching = false
        return
      }
    }

    // 送信
    if (storelocator.analytics.client) {
      storelocator.analytics.client.context.PlaceQuery = inputtedPlaceQuery
      storelocator.analytics.client.context.Pref = addressComponents.find((c) => c.types.includes('administrative_area_level_1'))?.long_name
      storelocator.analytics.client.context.City = addressComponents.find((c) => c.types.includes('locality'))?.long_name
      storelocator.analytics.client.send('SearchPlace', {
        'SearchPlaceBy': by,
      })
    }

    const bounds = geometry.viewport
    
    if (MapService.map) {
      if (bounds) {
        MapService.fitBounds(bounds, 17)
      } else {
        MapService.map.setZoom(17);
        MapService.map.panTo(place.geometry.location);
      }
    } else {
      // マップ以外は一律親コンポーネントに緯度経度を emit する
      // 他のページでの利用や、emitする値の変更が必要な場合は要調整
      emitLatlng(place.geometry.location)      
    }

    blur()

    store.dispatch("closePanel")

    searching = false
  })
}

/**
 * 検索アイコンクリック時
 * 
 * この時は候補を取れないので、Geocodingに流す
 */
async function onSearchClicked() {
  // console.debug('onSearchClicked')

  if (searching) {
    // console.debug('intterupt onSearchClicked')
    return
  }

  searching = true

  // vue の変数だと Autocomplete が書き換えた値を拾えない
  const query = document.getElementById('autocomplete').value
  if (query) {
    let geocoded = null
    try {
      geocoded = await geocoding(query)
    } catch (status) {
      console.warn('geocode failed', status)
      if (status === 'ZERO_RESULTS') {
        uikit.notification({
          message: messages.value.map_search_place_zero_result,
          pos: 'top-center',
          timeout: 3000
        })
      } else if (status === 'ERROR') {
        uikit.notification({
          message: messages.value.common_failed_network_connect,
          pos: 'top-center',
          timeout: 3000
        })
      }

      searching = false
      return
    }

    const geometry = geocoded.geometry

    // 送信
    if (storelocator.analytics.client) {
      storelocator.analytics.client.context.PlaceQuery = inputtedPlaceQuery
      storelocator.analytics.client.context.Pref = geocoded.address_components.find((c) => c.types.includes('administrative_area_level_1'))?.long_name
      storelocator.analytics.client.context.City = geocoded.address_components.find((c) => c.types.includes('locality'))?.long_name
      storelocator.analytics.client.send('SearchPlace', {
        'SearchPlaceBy': 'Geocoding',
      })
    }

    const bounds = geometry.viewport
    if (MapService.map) {
      if (bounds) {
        MapService.fitBounds(bounds, 17)
      } else {
        MapService.map.setZoom(17);
        MapService.map.panTo(geometry.location);
      }
    } else {
      // マップ以外は一律親コンポーネントに緯度経度を emit する
      // 他のページでの利用や、emitする値の変更が必要な場合は要調整
      emitLatlng(geometry.location)
    }

    store.dispatch("closePanel")

    searching = false
  }

  searching = false
}

async function onFocused() {
  document.getElementById("autocomplete").value = ""
  if (store.getters.isPanelClosed) {
    store.dispatch("openPanel")
  }
}

/**
 * 現在地を取得・移動したら入力を消す
 * 表示位置との差が生まれる可能性があり、混乱するため
 */
watch(() => store.getters.currentLocation, () => {
  document.getElementById("autocomplete").value = ""
})

function clear() {
  document.getElementById("autocomplete").value = ""
}

/**
 * 検索結果の緯度経度を親コンポーネントに送信
 * @param {google.maps.LatLng} location Autocomplete / ジオコーディング結果に含まれるLatLngオブジェクト
 */
function emitLatlng(location) {
  emit('onSearchPlace', location)
}

defineExpose({
  clear,
})
</script>

<style lang="scss">
.autocomplete-wrapper {
  width: 100%;
  position: relative;
  border-radius: 4px;
  transition: all .2s;

  .search-input {
    position: relative;

    #autocomplete {
      width: 100%;
      border: none;
      font-size: 16px;
      border-radius: 20px;
      background-color: #eee;
      transition: all .25s;
      height: 40px;
      padding-left: 16px;
      &::placeholder {
        font-family: system-ui, -apple-system,  sans-serif;
      }
    }
  }

  .uk-form-icon.completed {
    color: #fff;
  }

  &:focus-within {
    margin: 0 -2px 0 -2px;
    width: calc(100% + 4px);
    #autocomplete {
      background: #777;
      color: #fff;
      transition: all .25s;

      &::placeholder {
        color: #aaa;
      }
    }

    .uk-form-icon {
      color: #ccc;
      transition: all .25s;
      &:hover {
        color: #fff;
      }
    }
  }
}
</style>

<template>
  <section id="Filters" class="filters">
    <!-- マーカー：ベースしかない場合など、出さない時もある -->
    <template v-if="isShowMarkers">
      <template v-for="marker in markers">
        <Filter
          v-if="isShowFilter('markers', marker.id)"
          type="markers"
          :filter="marker"
          :opened="marker.opened || !selectable"
          :selectable="selectable"
          :point="point"
          :showPassedOnly="showPassedOnly">
          <template #filter-info="props">
            <slot name="filter-info" :filter="props.filter"></slot>
          </template>
          <template #filter-group-name="props">
            <slot name="filter-group-name" :filter="props.filter" :selectable="props.selectable"></slot>
          </template>
        </Filter>
      </template>
    </template>

    <!-- 検索条件 -->
    <template v-for="searchCondition in searchConditions">
      <Filter
        v-if="isShowFilter('search_conditions', searchCondition.id)"
        type="search_conditions"
        :filter="searchCondition"
        :opened="searchCondition.opened || !selectable"
        :selectable="selectable"
        :point="point"
        :showPassedOnly="showPassedOnly">
        <template #filter-info="props">
          <slot name="filter-info" :filter="props.filter"></slot>
        </template>
        <template #filter-group-name="props">
          <slot name="filter-group-name" :filter="props.filter" :selectable="props.selectable"></slot>
        </template>
      </Filter>
    </template>
  </section>
</template>

<script>
// どう絞り込むかを文章で表現する準備中
// import { mapGetters } from 'vuex'

import Filter from "./Filter.vue"
import { Filters } from "@/services/Filters.ts"

export default {
  components: { Filter },
  props: {
    /**
     * true: 選択可能
     * false: 選択不可、表示専用、凡例などヘルプ的な用途
     */
    selectable: {
      type: Boolean,
      default: true
    },
    /**
     * Point視点で見る時にわたす
     * 指定した場合、そのPointに該当するFilterのみ強調・もしくは表示に調整可能にする
     */
    point: {
      type: Object,
      default: () => {}
    },
    /**
     * pointと一緒に使う
     * true: pointが指定されているとき、pointが該当するFilterのみ非表示
     */
    showPassedOnly: {
      type: Boolean,
      default: true
    }
  },
  beforeMount() {
    if (!storelocator.search_conditions) {
      return
    }

    this.searchConditions = storelocator.search_conditions
  },
  data() {
    return {
      searchConditions: null
    }
  },
  methods: {
  },
  computed: {
    // mapGetters,
    markers() {
      if (!storelocator.markers) {
        return []
      }

      return storelocator.markers
    },
    /**
     * @returns true: マーカーの絞り込みを表示
     * マーカーが１つしかない場合は選ぶ余地がないので、非表示
     */
    isShowMarkers() {
      if (!storelocator.markers) {
        return false
      }

      if (storelocator.markers.length === 1
        && storelocator.markers[0].children?.length === 0) {
        return false
      }

      // Pointを指定するのは店舗ページなど=不要（ただ、少し乱暴かも）
      if (this.point) {
        return false
      }

      return true
    },
    isShowFilter() {
      return (type, id) => {
        const filter = Filters.findById(type, id)

        // 取れない
        if (!filter) {
          return false
        }

        // 設定で表示対象外
        if (!filter.is_show) {
          return false
        }

        // グループなのに子がない
        if (filter.children && filter.children.length === 0) {
          return false
        }

        return true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

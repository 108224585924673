<template>
  <div class="stations-wrapper" v-if="displayStations.length > 0">
    <h2>{{ messages.point_near_stations }}</h2>

    <ul class="stations">
      <li
        v-for="station in displayStations"
        class="station"
        :class="{loading: station.loading}"
      >
        <h4>
          <span class="name">{{ station.name }}{{ messages.point_near_stations_station_suffix }}</span>
          <span class="line-name">{{ station.line_name }}</span>
        </h4>

        <div class="attributes">
          <span class="address attribute">{{ station.address }}</span>
          <span class="distance attribute">{{ messages.common_from_point }} {{ mToKm(station.distance * 1000) }}</span>
        </div>

        <div class="to-station" v-wave @click="onShowRouteClicked(station)">
          <button class="uk-button ready">
            <i class="material-symbols-sharp filled" data-icon="directions"></i>
          </button>
          <span class="label ready" v-html="messages.point_stations_station_show_route"></span>

          <button class="uk-button loading">
            <i class="material-symbols-sharp" data-icon="more_horiz"></i>
          </button>
          <span class="label loading" v-html="messages.point_stations_station_loading_route"></span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
/**
 * 近くの駅
 *
 * 選択している店舗から近い順に表示する
 */
import uikit from 'uikit'

import { computed, onMounted, PropType } from 'vue'
import { useStore } from 'vuex'

import { mToKm } from '@/utils/format.ts'

const props = defineProps({
  /**
   * 最大距離制限の有効化
   * true: 最大距離制限を有効化
   * デフォルトは距離制限を行わない
   */
  enabledMax: {
    type: Boolean,
    default: false
  },
  /**
   * 表示する駅の最大距離(km)
   * > 0の場合、最大距離を超える駅は非表示
   * <= 0の場合、全ての駅を表示
   * デフォルトはすべて表示
   */
  maxDistanceKm: {
    type: Number as PropType<number | null>,
    default: -1
  }
})

const store = useStore()

/**
 * 近隣駅
 */
const stations = computed(() => store.getters.stations)

/**
 * 表示する近隣駅
 * 最大距離を用い表示する駅をフィルタリングする
 */
const displayStations = computed(() => {
  const NO_DISTANCE = -1
  let maxDistanceKm = NO_DISTANCE

  // props.maxDistanceKmが 0 より大きい数値の場合その値を採用
  // それ以外(0, null, undefined, NaN, etc.)は、全て NO_DISTANCE として扱う
  if (props.enabledMax && props.maxDistanceKm > 0 ) {
    maxDistanceKm = props.maxDistanceKm
  }

  if (maxDistanceKm !== NO_DISTANCE) {
    return stations.value.stations.filter(station => station.distance <= maxDistanceKm)
  } else {
    // -1の場合は全て表示
    return stations.value.stations
  }
})

const messages = computed(() => storelocator.messages)

onMounted(() => {
})

/**
 * 駅からのルートを見るクリック時
 * @param {Object} station /api/stationsからの応答まま
 */
function onShowRouteClicked(station) {
  // console.debug('onShowRouteClicked', station)

  station.loading = true

  try {
    store.dispatch('selectStation', station)
  } catch (e) {
    console.warn(e)
  } finally {
    setTimeout(() => {
      document.body.scrollTo({
        top: 0,
        behavior: 'smooth'
      })

      uikit.notification.closeAll()
      uikit.notification({
        message: messages.value.point_directions_show_from_station.replace("{station_name}", station.name),
        pos: 'top-center',
        timeout: 3000
      })

      station.loading = false
    }, 750)
  }
}
</script>

<style lang="scss" scoped>
@keyframes station-fade-in {
  0% {
    opacity: 0;
    transform: translateX(-5%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }

}

.stations {
  animation: station-fade-in 0.5s ease-in-out;
  list-style: none;
  margin: 0;
  padding: 0;
  @media screen and (max-width: $breakpoint-small) {
    margin: 0 -1rem;
  }
  .station {
    border-bottom: 1px solid #eee;
    padding: .9rem 4rem .75rem 1rem;
    position: relative;
    min-width: 240px;
    h4 {
      font-size: 1rem;
      margin: 0 0 1rem 0;
      .name {
        margin: 0 1rem 0 0;
      }
      .line-name {
        font-size: 0.8rem;
        color: #666;

      }
    }

    .attributes {
      .attribute {
        display: block;
        font-size: .75rem;
        color: #666;
        margin: 0 0 .25rem 0;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .distance {
        opacity: .5;
      }
    }

    &:first-child {
      border-top: 1px solid #eee;
    }

    .to-station {
      cursor: pointer;
      position: absolute;
      right: 1rem;
      top: 50%;
      width: 2rem;
      height: 3.75rem;
      opacity: 0.9;
      transform: translateY(-50%);
      transition: all 0.3s ease-in-out;

      .label {
        display: inline-block;
        font-size: .5rem;
        width: 2rem;
        text-align: center;
      }

      button {
        background: none;
        padding: 0;
        width: 2rem;
        height: 2rem;
      }

      i {
        color: var(--theme-color-tp50);
        font-size: 2rem;
        transition: all 0.3s ease-in-out;
      }

      .ready {
        display: inline-block;
      }

      .loading {
        display: none;
      }

      &:hover {
        opacity: 1;
        right: calc(1rem - 2px);
        color: $directions-color;
        i {
          color: $directions-color;
        }
      }
    }

    &.loading {
      .to-station {
        .ready {
          display: none;
        }
        .loading {
          display: inline-block;
        }
      }
    }
  }
}
</style>

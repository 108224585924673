<template>
  <div>
    <button id="PanToCurrentLocation" class="open-filter uk-button" :class="{
        highlight: PointsService.locations.value.loading || PointsService.locations.value.current,
        loading: PointsService.locations.value.loading && !PointsService.locations.value.current,
        completed: PointsService.locations.value.current
      }" @click="onCurrentLocationClicked" v-wave>
      <span>{{ messages.points_current_location }}</span>
      <span class="material-symbols-sharp" data-icon="check" v-if="PointsService.locations.value.current"></span>
      <span class="material-symbols-sharp" data-icon="more_horiz" v-else-if="PointsService.locations.value.loading"></span>
      <span class="material-symbols-sharp" data-icon="my_location" v-else></span>
    </button>  
  </div>
</template>

<script setup>
import uikit from "uikit"
import { computed, onMounted, ref, watch } from 'vue'
import PointsService from "@/services/PointsService.ts"
import { LocationManager } from "@/services/Location"

const emit = defineEmits(['onCurrentLocationClicked'])
const messages = computed(() => storelocator.messages)

/**
 * 現在地から近い順に並べるクリック時
 */
 async function onCurrentLocationClicked() {
  // console.debug('onCurrentLocationClicked')

  // 多重発火抑止
  if (PointsService.locations.value.loading) {
    return  
  }

  // PointsService.sortByLocation()で距離の再計算をさせるためリセット
  if (PointsService.locations.value.places) {
    PointsService.resetLocation()
  }

  try {
    PointsService.locations.value.current = await PointsService.panToCurrentLocation()
    emit('onCurrentLocationClicked')

  } catch (e) {
    console.warn(e)

    // 失敗・タイムアウトは共通
    let message = messages.value.common_failed_to_get_current_location

    // 拒否は明示的にわかるように、地名検索がないので誘導はなし、都道府県にしても範囲が広い
    if (e.code === LocationManager.CURRENT_LOCATION_RESULT_DENIED) {
      message = messages.value.common_denied_to_get_current_location
    } else {
      console.warn(e)
    }

    uikit.notification({
      message: message,
      pos: 'top-center',
      timeout: 3000
    })
  }
}

onMounted(async() => {  
})

</script>

<style lang="scss">
#PanToCurrentLocation {
  border-radius: 20px;
  background-color: #fff;
  border: solid 1px #ddd;
  padding: 0 1rem 0 2.5rem;
  position: relative;
  transition: all 0.25s;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  gap: .5rem;

  @media screen and (min-width: $breakpoint-small) {
    &:hover {
      background-color: var(--theme-color);
      color: var(--text-color);
      border-color: #fff;
    }
  }

  &:active {
    background-color: var(--theme-color);
    color: var(--text-color); 
  }

  span.material-symbols-sharp {
    position: absolute;
    left: 0.5rem;
    opacity: .7;
    transition: all 0.25s;
  }

  &.highlight {
    span.material-symbols-sharp {
      opacity: 1;
    }
  }

  @keyframes points-loading-loop-current-locatio {
    0% {
      clip: rect(0px, 5px, 12px, 0px);
      opacity: 0;
    }
    50% {
      clip: rect(0px, 16px, 24px, 0px);
      opacity: 50;
    }
    100% {
      clip: rect(0px, 24px, 24px, 0px);
      opacity: 100;
    }
  }
  &.loading {    
    span.material-symbols-sharp {
      animation: points-loading-loop-current-locatio .75s infinite;      
    }
  }
  &.completed {
    background-color: var(--theme-color);
    border-color: var(--theme-color);
    color: var(--text-color);
  }

}
</style>
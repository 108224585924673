/**
 * 主にPointの詳細表示用
 * 
 * google.maps.InfoWindowが調整しにくいので、SL専用に用意
 * コンポーネントは外部から仕込めるので、他の用途に使っても良い
 * 
 * マップ上にAdvanced Markerでのせ、指定したコンポーネントを埋め込む
 */
export default class InfoWindow {
  constructor(options) {
    this.map = options.map ? options.map : null

    /**
     * createAppで作成したコンポーネント
     * これを埋め込む
     */
    this.app = options.app ? options.app : null

    /**
     * @class {google.maps.LatLng}
     */
    this.position = options.position ? options.position : null

    /**
     * 付与するクラス
     */
    this.classes = options.classes ? options.classes : []

    /**
     * マーカーとinfowindowの間の距離（px）。大きくすると離れる
     */
    this.offsetY = options.offsetY ? options.offsetY : 150

    /**
     * 表示した時の地図移動先の縦方向オフセット（px）。正の数を設定すると上方向、負の数を入れるとした方向にずれる
     * 地図のセンターに来る様にマップが移動する
     */
    this.panOffsetY = options.panOffsetY ? options.panOffsetY : 0

    // DOM構築
    this.div = document.createElement('div')
    this.div.id = options.id
    this.app.mount(this.div)

    // 表示アニメーション
    this.addClass("ready")
    this.classes.forEach(c => {
      this.addClass(c)
    })

    this.marker = new google.maps.marker.AdvancedMarkerElement({
      map: this.map,
      content: this.div,
      gmpClickable: true,
      zIndex: 200000000, // PointMarkerでふる値より上にもっていく必要がある
    })

    this.marker.addListener("click", () => {
      if (this.app?.onClicked) {
        this.app.onClicked()
      }
    })
  }

  addClass(className) {
    this.div.classList.add(className)
  }

  removeClass(className) {
    if (!this.div) {
      return
    }

    this.div.classList.remove(className)
  }

  /**
   * 表示
   * @param {google.maps.LatLng} position 基準点（Pointのマーカーの位置）
   */
  show(position) {
    this.marker.position = {
      lat: position.lat(),
      lng: position.lng()
    }

    this.addClass("ready")
    this.marker.map = this.map

    // 描画を待って地図移動
    setTimeout(()=> {
      this.marker.map.panTo(
        this.offsetLatLng(
          this.marker.map,
          this.marker.position,
          {x: 0, y: -this.offsetY}
        )
      )

      this.removeClass("ready")
    }, 100)
  }

  /**
   * 非表示
   */
  hide() {
    this.addClass("ready")
    this.marker.map = null
  }

  /**
   * 指定pxずらした緯度経度を取得
   * @param {google.maps.Map} map マップ、ズームレベルの取得用
   * @param {google.maps.LatLng} latLng 基準点
   * @param {Object} offset ずらすpx {x: number, y: number}
   * @returns {google.maps.LatLng} ずらした緯度経度
   */
  offsetLatLng(map, latLng, offset) {
    const scale = Math.pow(2, map.getZoom())

    const projection = map.getProjection()

    const worldCoordinate = projection.fromLatLngToPoint(latLng)

    const pixelCoordinate = new google.maps.Point(
      worldCoordinate.x * scale + offset.x,
      worldCoordinate.y * scale + offset.y
    )

    const newWorldCoordinate = new google.maps.Point(
      pixelCoordinate.x / scale,
      pixelCoordinate.y / scale
    )

    return projection.fromPointToLatLng(newWorldCoordinate)
  }
}
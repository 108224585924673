import {getParams} from '@/utils/common'

/**
 * ページ間で使用するものをまとめる
 * 主にURLの決定
 */
export default {
  /**
   * 最上位のページ
   * 
   * 戻し先を決めるのに使う
   * 
   * マップ：map
   * 店舗の一覧：points
   */
  rootPage: 'map',
  /**
   * 直接転送したいクエリ
   * ページ間で共有するクエリでもある
   */
  forwardQueries: {
    /**
     * GAの送信無視
     */
    ignore: {},
    /**
     * デバッグモード
     */
    debug: {},
    /**
     * 埋め込みモード
     */
    iframe: {},
    /**
     * 言語指定
     */
    lang: {}
  },
  /**
   * ベースURL
   * 専用のURL体系のマップがありえるので、調整する場合はここで調整
   */
  baseUrls: {
    /**
     * マップページ用
     */
    map: '/',
    /**
     * 店舗の一覧ページ用
     */
    points: '/',
    /**
     * 店舗ページ用
     * 前後の/まで含んでいる想定
     */
    point: `${storelocator.point_path}`,
  },
  storage: {
    key: `storelocator:${location.host}:page`,
    /**
     * ルートを保存
     * 
     * トップ・マップ・店舗の一覧で呼ぶ
     * 
     * パンくずの最上位を決めるための仕組み
     * これがないと、マップ -> 店舗 -> 店舗 となったときに最上位がわからなくなる
     * 
     * @param {String} key ページのキー map, points, point
     * @param {String} url ページのURL
     */
    save(key, url) {
      const cache = this.get()

      if (cache) {
        cache[key] = {
          url: url
        }

        sessionStorage.setItem(this.key, JSON.stringify(cache))

      } else {
        const newCache = {}

        newCache[key] = {
          url: url
        }

        sessionStorage.setItem(this.key, JSON.stringify(newCache))
      }

    },
    get() {
      const saved = sessionStorage.getItem(this.key)

      // 直接店舗ページにきたらない可能性がある
      if (!saved) {
        return null
      }

      return JSON.parse(saved)
    }
  },
  /**
   * 引き継ぐクエリを抽出
   * @param {Object} params GETパラメータ ["aaa=1", "bbb"]
   * @returns {Array} クエリ
   */
  getForwardQuery() {
    const queries = []

    const _params = getParams()
    if (Object.keys(_params).length > 0) {
      // 直接転送するクエリ
      Object.keys(this.forwardQueries).filter(
        key => _params[key] != undefined
      ).forEach(key => {
        if (_params[key] == undefined || _params[key] == null || _params[key] === "") {
          queries.push(`${key}`)
        } else {
          queries.push(`${key}=${_params[key]}`)
        }
      })
    }

    return queries
  },
  /**
   * マップページへのリンクURLを返す
   *
   * @param {Array} params 付与するGETパラメータ ["aaa=1", "bbb"]
   * @returns マップページへのリンク
   */
  getMapUrl(params = []) {
    const url = this.baseUrls.map
    const query = this.getForwardQuery()

    if (params.length > 0) {
      query.push(...params)
    }

    const queryString = query.join("&")

    return url + (queryString ? '?' + queryString : "")
  },
  /**
   * 店舗ページへのURLを返す
   * GETパラメータがついていた場合、引き継ぐ
   *
   * @param {Object} point Point
   * @param {Array} params GETパラメータ ["aaa=1", "bbb"]
   * @returns Pointページへのリンク
   */
  getPointUrl(point, params = []) {
    // console.debug("getPointUrl", point, params)

    let url = ""
    if (storelocator.path.id) {
      url = `/${storelocator.path.id}`
    }

    url = url + this.baseUrls.point + point.key

    const query = this.getForwardQuery()

    if (params.length > 0) {
      query.push(...params)
    }

    const queryString = query.join("&")

    return url + (queryString ? '?' + queryString : "")
  },
  /**
   * 店舗の一覧ページへのURLを返す
   * GETパラメータがついていた場合、引き継ぐ
   * 
   * @param {Number} area_code 都道府県コード
   * @param {Array} params GETパラメータ ["aaa=1", "bbb"]
   * @returns Pointページへのリンク
   */
  getPointsUrl(area_code = null, params = []) {
    // console.debug("getPointsUrl", area_code, params)

    const url = this.baseUrls.points
    const query = this.getForwardQuery()

    if (params.length > 0) {
      query.push(...params)
    }

    const queryString = query.join("&")

    return url + (area_code ? `areas/${area_code}/` : '') + "points" + (queryString ? `?${queryString}` : "")
  },
  /**
   * 店舗ページからきたか判定
   * @param {String} url 判定するURL（指定しなければリファラを使う）
   * @returns {Boolean} true: 店舗ページから遷移
   */
  isFromPointPage(url = null) {
    const fixedUrl = url || document.referrer
    return fixedUrl.includes('/points/')
  },
  /**
   * 店舗の一覧ページからきたか判定
   * @param {String} url 判定するURL（指定しなければリファラを使う）
   * @returns {Boolean} true: 店舗の一覧ページから遷移
   */
  isFromPointsPage(url = null) {
    const fixedUrl = url || document.referrer
    return fixedUrl.includes('/points') && !fixedUrl.includes('/points/')
  },
  /**
   * GoogleマップへのリンクのURLを返す
   * SPの場合は、設定によってアプリが起動する
   * 
   * TODO 類似の処理がある？（point.js
   *
   * @param {Number} lat 緯度
   * @param {Number} lng 経度
   * @param {String} name 店舗名 指定すると名前でリンク先を指定する
   * @returns
   */
  getGoogleMapsLink(lat = null, lng  = null, kword = null) {
    if ((!lat || !lng) && !kword) {
      return ""
    }

    const query = kword ? `query=${encodeURIComponent(kword)}` : `query=${lat}%2C${lng}`
    return `https://www.google.com/maps/search/?api=1&${query}`
  }
}
import { deepClone } from '@/core/common'
import { normalizeText } from '@/utils/common'

/**
 * 絞り込みの状態
 */
interface FiltersState {
  markers: string[]
  search_conditions: string[]
  location: {
    href: string | null
    host: string | null
  } 
}

/**
 * 選択中のマーカー・検索条件
 * 
 * これ自体の役割は少ない、FiltersStateとも被ってる、どこかで潰しても良い
 * 
 * キャッシュに保存する形としては使う
 */
class SelectedFilters {
  /**
   * 選択中のマーカーのid
   */
  markers: string[] = []

  /**
   * 選択中の検索条件のid
   */
  search_conditions: string[] = []

  /**
   * キャッシュ保存時のページ
   * 反映可否の判断用
   */
  location: {
    href: string | null
    host: string | null
  }

  constructor() {
    this.reset()
  }

  reset() {
    this.markers = []
    this.search_conditions = []
    this.location = {
      href: null,
      host: null
    }
  }

  /**
   * 選択したフィルタのidを反映
   * @param type Filters.TYPE_...
   * @param filterIds Marker.id or SearchCondition.id []
   */
  setSelected(type: string, filterIds: string[]) {
    if (type === Filters.TYPE_MARKERS) {
      this.markers = filterIds
    } else if (type === Filters.TYPE_SEARCH_CONDITIONS) {
      this.search_conditions = filterIds
    }
  }

  /**
   * 選択中のフィルタか判定
   * @param type Filters.TYPE_...
   * @param filterId Marker.id or SearchCondition.id
   * @returns true: 選択中のID
   */
  isSelected(type: string, filterId: string): boolean {
    if (type === Filters.TYPE_MARKERS) {
      return this.markers.includes(filterId)
    } else if (type === Filters.TYPE_SEARCH_CONDITIONS) {
      return this.search_conditions.includes(filterId)
    }

    return false
  }
}

/**
 * マーカー・検索条件の絞り込みの状態をキャッシュ
 * セッションストレージを使う
 */
export class FilterCache {
  private key: string = `storelocator:${location.host}:filters`

  constructor() {
  }

  /**
   * 保存
   * @param {SelectedFilters} {
   *   markers: [Marker.id, ...],
   *   search_conditions: [SearchCondition.id, ...],
   *   location: {
   *     href: location.href どこで保存したかわかるように
   *   }
   * }
   */
  save(filters: FiltersState) {
    if (sessionStorage) {
      // console.debug("save cache filters", filters)
      
      sessionStorage.setItem(this.key, JSON.stringify({
        markers: filters.markers,
        search_conditions: filters.search_conditions,
        location: {
          href: location.href,
          host: location.host
        }
      }))
    }
  }

  /**
   * 読み込み
   * @returns SelectedFilters
   */
  load() {
    if (sessionStorage) {
      const cache = sessionStorage.getItem(this.key)

      if (cache) {
        const cacheJson = JSON.parse(cache)

        const filters = new SelectedFilters()
        filters.markers = cacheJson.markers
        filters.search_conditions = cacheJson.search_conditions
        filters.location = cacheJson.location

        return filters
      }
    }

    return undefined
  }

  /**
   * 保存したキャッシュを削除
   * 
   * まっさらな状態から動くことを確認しやすいよう、{}を保存するのではなく、消すように
   */
  reset() {
    if (sessionStorage) {
      sessionStorage.removeItem(this.key)
    }
  }

  /**
   * 保存場所だけ更新
   * 
   * ページ遷移時に使う想定
   * ここを更新しておかないと、マップで絞り込み -> 店舗の一覧でリロード -> でずっと絞り込みが残ってしまう
   */
  updateLocation() {
    if (sessionStorage) {
      const cache = sessionStorage.getItem(this.key)

      if (cache) {
        const cacheJson = JSON.parse(cache)
        cacheJson.location.href = location.href
        cacheJson.location.host = location.host

        sessionStorage.setItem(this.key, JSON.stringify(cacheJson))
      }
    }
  }
}

/**
 * 文字列での絞り込み用
 */
class FilterQuery {
  /**
   * 検索文字をスペースで分割して格納
   */
  texts: string[] = []

  /**
   * 検索するPointのキー
   */
  targets: string[] = []

  constructor() {
    this.texts = []
    this.targets = []
  }

  /**
   * クエリ更新
   */
  setQuery(query: string) {
    if (query != null && query != undefined && query != '') {
      this.texts = query.split(/\s+/).map(
        token=> normalizeText(token)
      )
    } else {
      this.texts = []
    }
  }

  /**
   * 分解後の検索文字
   */
  getQuery(): string[] {
    return this.texts
  }

  /**
   * 検索対象のフィールドを更新
   * @param targets [Pointのキー, ...]
   */
  setTargets(targets: string[]) {
    this.targets = targets
  }

  /**
   * @returns 検索対象のフィールドのキー、未設定なら [name]
   */
  getTargets(): string[] {
    if (this.targets.length === 0) {
      return ['name']
    }

    return this.targets
  }

  /**
   * @returns true: クエリあり
   */
  hasQuery(): boolean {
    return this.texts.length > 0
  }

  /**
   * 初期化
   */
  reset() {
    this.texts = []
    this.targets = []
  }
}

/**
 * マーカー・検索条件まわりを押し込め
 */
export class Filters {
  /**
   * type: マーカー
   * storelocatorに生えているフィールド名でもある
   * 
   * FilterTypeに置き換えたい、単純に入れ替えたら参照できなかった。。
   */
  static TYPE_MARKERS = 'markers'

  /**
   * type: 検索条件
   * storelocatorに生えているフィールド名でもある
   */
  static TYPE_SEARCH_CONDITIONS = 'search_conditions'

  /**
   * 条件同士の接続：AND
   */
  static COMPARE_AND = 'AND'

  /**
   * 条件同士の接続：OR
   */
  static COMPARE_OR = 'OR'

  /**
   * ベースマーカーのID
   */
  static BASE_MARKER_ID = 'base'

  /**
   * 選択中のフィルタ
   * 他で賄えそうには見える
   */
  static selectedFilters = new SelectedFilters()

  /**
   * キャッシュ
   */
  static cache = new FilterCache()

  /**
   * 文字列での絞り込み用
   */
  static query = new FilterQuery()

  /**
   * 全Filterのツリー
   * 
   * getFilterTreeの結果
   * 一度計算すればいい
   * Pointごとに渡したいので、キャッシュして使い回す
   * 
   * 動的にFilterの内容を調整するロジックが存在する場合は注意
   */
  static filterTreeCache: Filter[] = null

  /**
   * 評価を変更したい検索条件の定義
   * 最上位階層の設定を変更する場合はidに" "root" を指定する
   *
   * {Filter.id}: {
   *   parent: 絞り込みIDを他のグループと比較する時の判定を変更する "AND" | "OR"
   *   child: 絞り込みIDに所属する条件の判定を変更する "AND" | "OR"
   * }
   */
  static comparison = storelocator.filters?.comparison?.enabled
    ? {
      default: {
        parent: storelocator.filters.comparison.default.parent,
        child: storelocator.filters.comparison.default.child,
      }
    }
    : {
    default: {
      parent: "AND",
      child: "OR",
    }
  }

  /**
   * 複数のFilter.idから本体を取得
   * @param type Filters.TYPE_...
   * @param filterIds Filter.id[]
   * @param edgeOnly true: 末端のみ返す
   * @param filters 再起的に実行する際に、検索する子要素の配列を指定（呼び出し時は指定しない）
   * @returns Filter[]
   */
  static findByIds(type: string, filterIds: string[], edgeOnly = false, filters: Filter[] = null): Filter[] {
    // console.debug('findByIds', type, filterIds, edgeOnly, filters)

    const _filters: Filter[] = filters
      ? filters
      : type === Filters.TYPE_MARKERS
        ? storelocator.markers
        : storelocator.search_conditions

    let foundFilters: Filter[] = []

    _filters.forEach(filter => {
      if (!edgeOnly && filterIds.includes(filter.id)) {
        foundFilters.push(filter)
      } else if (edgeOnly && !filter.children && filterIds.includes(filter.id)) {
        foundFilters.push(filter)
      }

      if (filter.children) {
        foundFilters = foundFilters.concat(
          Filters.findByIds(type, filterIds, edgeOnly, filter.children)
        )
      }
    })
    return foundFilters
  }

  /**
   * 絞り込みIDからその設定を取得する
   * @param type Filters.TYPE_...
   * @param filterId Filter.id
   * @param filters 再起的に実行する際に、検索する子要素の配列を指定（呼び出し時は指定しない）
   * @returns Filter
   */
  static findById(type: string, filterId: string, filters: Filter[] = null): Filter {
    const _filters = filters
      ? filters
      : type === Filters.TYPE_MARKERS
        ? storelocator.markers
        : storelocator.search_conditions

    let foundFilter = null  

    if (!type) {
      // console.debug('findById', type, filterId, _filters)
      return
    }

    for (const filter of _filters) {
      if (filter.id === filterId) {
        foundFilter = filter
        break
      }

      if (filter.children) {
        const result = Filters.findById(type, filterId, filter.children)
        if (result) {
          foundFilter = result
          break
        }
      }
    }

    return foundFilter
  }

  /**
   * 配下の子孫を全てフラットに返す
   *
   * @param parentFilter 掘るFilter
   * @returns 見つかった子孫の1次元配列
   */
  static findChildren(parentFilter: Filter): Filter[] {
    let filters: Filter[] = []
    for (const f of parentFilter.children || []) {
      filters.push(f)
      if (f.children) {
        filters = filters.concat(Filters.findChildren(f))
      }
    }

    return filters
  }  

  /**
   * 指定のIDの絞り込み設定の配下の全ての子孫設定のIDを返す
   *
   * @param type Filters.TYPE_...
   * @param filterId 探すFilter.id
   * @returns 見つかった全ての子孫のIDの1次元の配列
   */
  static findChildrenById(type: string, filterId: string): string[] {
    const filter = Filters.findById(type, filterId)
    if (!filter || !filter.children) {
      return []
    }

    return Filters.findChildren(filter).map(f => {
      return f.id
    })
  }

  /**
   * 親を返す
   * @param type Filters.TYPE_...
   * @param filterId Filter.id
   * @param filters 再帰的に実行する際に、検索する子要素の配列を指定（呼び出し時は指定しない）
   * @returns Filter
   */
  static findParentById(type: string, filterId: string, filters: Filter[] = null): Filter {
    const _filters = filters
      ? filters
      : type === Filters.TYPE_MARKERS
        ? storelocator.markers
        : storelocator.search_conditions

    let found = null
    for (const filter of _filters) {
      if (filter.children) {
        if (filter.children.map(f => {return f.id}).includes(filterId)) {
          found = filter
          break
        }

        const result = Filters.findParentById(type, filterId, filter.children)
        if (result) {
          found = result
          break
        }
      }
    }

    return found
  }

  /**
   * 末端をフラットにし、上位階層からのツリーを添えて返す
   *
   * @param filter 再帰的に実行する際に、検索する子要素の配列を指定（呼び出し時は指定しない）
   * @param tree 再帰的に実行する際に、現在要素までのツリーを格納（呼び出し時は指定しない）
   * @returns Filter[] ?
   */
  static _getFilterTree(filter: Filter = null , tree: Filter[] = []): Filter[] {
    if (!filter) {
      return []
    }

    let foundFilters: Filter[] = []
    for (const _filter of filter.children) {
      const _tree = [].concat(tree)
      if (_filter.children) {
        const copied = deepClone(_filter)
        delete copied.children

        _tree.push(copied)
        foundFilters = foundFilters.concat(Filters._getFilterTree(_filter, _tree))
      } else {
        _filter.tree = _tree
        foundFilters.push(_filter)
      }
    }

    return foundFilters
  }

  /**
   * フィルタのツリーを取得（キャッシュも行う）
   * @param type TYPE_...
   * @param update true: キャッシュを無視して再計算する
   * @returns _getFilterTreeの結果
   */
  static getFilterTree(type: string, update: boolean = false): Filter[] {
    // console.debug('getFilterTree', type)

    const filters = type === Filters.TYPE_MARKERS
      ? storelocator.markers
      : storelocator.search_conditions

    const root: Filter = {
      children: filters
    }

    if (update || !Filters.filterTreeCache) {
      // console.debug('getFilterTree', type, 'update')
      Filters.filterTreeCache = Filters._getFilterTree(root)
    }

    return Filters.filterTreeCache
  }

  /**
   * Pointが選択中のFilterの表示条件を満たすかを判定
   * 
   * @param point Point
   * @param filter Filter グループは対象外
   * @returns 表示条件を満たすならpassed: true
   * {
   *   passed: false,
   *   debugInfo: `checkPointByFilter', ${point.name}, false`,
   * }
   */
  static checkPointByFilter(point: Point, filter: Filter): {
    passed: boolean
    debugInfo: string
  } {
    // console.debug('checkPointByFilter', point.name, filter.id, filter.name)

    // 数値同士の比較用
    const INTEGER_PATTERN = /^-?\d+$/
    const FLOAT_PATTERN = /^-?\d*\.\d+$/

    const results = filter.conditions?.filter(condition => {
      // 存在しないフィールドを間引く
      return Object.keys(storelocator.fields).includes(condition.key) || point[condition.key] !== undefined
    })?.map(condition => {
      // TODO: 共通化の余地あり(format.ts, PriorityPoints.vue) ここから
      const value = (
        point.extra_fields[condition.key] === undefined
          ? (point[condition.key] ?? "")
          : (point.extra_fields[condition.key] ?? "")
        ).trim()

      const conditionValue = (condition.value ?? "").trim()

      // 数値同士なら数値として比較
      let numberValue = null
      let numberConditionValue = null

      if ((INTEGER_PATTERN.test(value) || FLOAT_PATTERN.test(value))
        && (INTEGER_PATTERN.test(conditionValue) || FLOAT_PATTERN.test(conditionValue))) {
        numberValue = parseFloat(value)
        numberConditionValue = parseFloat(conditionValue)
      }

      let passed = true 

      try {
        switch (condition.comparison) {
          case "=": {
            if (numberValue !== null && numberValue != numberConditionValue) {
              passed = false
            } else if (value != condition.value) {
              passed = false
            }
            break
          }
  
          case "!=": {
            if (numberValue !== null && numberValue == numberConditionValue) {
              passed = false
            } else if (value == condition.value) {
              passed = false
            }
            break
          }
  
          case "<": {
            if (numberValue !== null && numberValue >= numberConditionValue) {
              passed = false
            } else if (value >= conditionValue) {
              passed = false
            }
            break
          }
  
          case ">": {
            if (numberValue !== null && numberValue <= numberConditionValue) {
              passed = false
            } else if (value <= conditionValue) {
              passed = false
            }
            break
          }
  
          case "reg": {
            const reg = new RegExp(condition.value)
            if (!reg.test(value)) {
              passed = false
            }
            break
          }
        }
      } catch (e) { // 異状入力で万が一にもこけて全体を止めないように
        console.warn('checkPointByFilter', point.name, filter.name, e)
        passed = false
      }

      return passed
      // TODO: 共通化の余地あり(format.ts, PriorityPoints.vue) ここまで
    })

    // ベースマーカーは特殊
    if (!results && filter.is_base) {
      return {
        passed: point.markers[storelocator.lang] === Filters.BASE_MARKER_ID,
        debugInfo: "base"
      }
    }

    if (filter.operation === Filters.COMPARE_OR) {
      // OR=trueが１つあればOK
      const result = results.includes(true)
      return {
        passed: result,
        debugInfo: `checkPointByFilter OR', ${point.name}, ${result}`,
      }
    } else if (filter.operation === Filters.COMPARE_AND) {
      // AND=全てtrue=falseが１つでもあったらNG
      const result = results.length && !results.includes(false)

      // console.debug('checkPointByFilter AND', point.name, filter.name, result)

      return {
        passed: result,
        debugInfo: `checkPointByFilter AND', ${point.name}, ${result}`,
      }
    }

    return {
      passed: false,
      debugInfo: `checkPointByFilter', ${point.name}, false`,
    }
  }

  /**
   * ポイントに対して、当てはまるFilterを全て返す
   * 
   * @param type TYPE_...
   * @param point Point
   * @param filters この中からPointが合致するものを返す、getFilterTree(type)の結果を渡せばいい（空でも動くようにはしているが、Pointごとに実行すると高負荷なので避けること）
   * @returns [
   *   {
   *     id: 絞り込みid
   *     name: 名前,
   *     icon: アイコン画像のURL
   *     tree: 最上位階層からのツリー [{id: 第一階層id, name: 第一階層name}, {id: 第二階層id ,name: 第二階層name},...]
   *     extraFields: Filter.extra_fields,
   *     point: Filter.point
   *   }, ...
   * ]
   */
  static getPointFilters(type: string, point: Point, filters: Filter[]): Filter[] {
    const hitFilter: Filter[] = []
    const allFilters: Filter[] = filters
      ? filters
      : Filters.getFilterTree(type)

    if (allFilters.length === 0) {
      return []
    }

    allFilters.forEach(filter => {
      if (Filters.checkPointByFilter(point, filter).passed) {
        hitFilter.push(deepClone(filter))
      }
    })

    return hitFilter
  }

  /**
   * 店舗名が検索用テキストと部分一致するか検証する
   * @param point 
   * @return true: 該当した場合、検索用テキストが無ければ常に true を返す
   */
  static hasFilterTextInPoint(point: Point): boolean {
    if(!Filters.query.hasQuery()) {
      return true
    }

    if(!point.name) {
      return false
    }

    // 検索対象の指定が無い場合は名前のみ対象とする
    const filterTargets = Filters.query.getTargets()
    // 追加フィールドも含めてフラットにする
    for (const [key, value] of Object.entries(point.extra_fields)) {
      if (value !== null) point[key] = value;
    }
    const targetTexts = filterTargets.map(t=> normalizeText(point[t]))

    return Filters.query.getQuery().every(text=> {
      return targetTexts.join().indexOf(text) !== -1
    })
  }

  /**
   * 全選択中か判定
   * @param type Filters.TYPE_...
   * @returns true: 全選択の場合
   */
  static isAllSelected(type: string): boolean {
    const filters = type === Filters.TYPE_MARKERS
      ? storelocator.markers
      : storelocator.search_conditions

    const rootFiltersSelected = filters.map((r) => {
      return Filters.selectedFilters.isSelected(type, r.id)
    })

    return !rootFiltersSelected.includes(false)
  }

  /**
   * checkPointByFiltersをベースに、親・子で接続方法を調整可能にしたもの
   * 
   * 全てのFilterを適用して表示するPointかどうかを判定
   * 地図・ページ・マーカーを考慮した判断はここで行わない（Filterの概念での判断に集中する）
   * 
   * @param point Point
   * @param _markers 全てのマーカー、外から渡すように変えている途中
   * @param _searchConditions 全ての検索条件（TODO 末端？要確認、こちらだけedgeをつけていた）、外から渡すように変えている途中
   * @returns true: 表示
  */
  static checkPointByFilters(point: Point, _markers: Filter[] = null, _searchConditions: Filter[] = null): boolean {
    // console.debug('checkPointByFilters', point.key, point.name, point.markers, _markers, _searchConditions)

    if (!Filters.hasFilterTextInPoint(point)) {
      return false
    }
  
    // マーカーの表示条件を満たすか確認
    const markers = _markers ? _markers : Filters.findByIds(
      Filters.TYPE_MARKERS,
      Filters.selectedFilters.markers
    )
  
    const markerResults: boolean[] = []
    const markerDebugInfo = []
    markers.forEach(marker => {
      // 表示条件が設定されていない場合はスキップ
      // ベースマーカーがあてられるのは該当なしのPointなので、判定する必要なし
      if (!marker.conditions && marker.id !== Filters.BASE_MARKER_ID) {
        return
      }
  
      let checkResult
      if (marker.id === Filters.BASE_MARKER_ID) {
        checkResult = {
          passed: point.markers[storelocator.lang] === Filters.BASE_MARKER_ID,
          markerDebugInfo: "baseMarker"
        }
      } else {
        checkResult = Filters.checkPointByFilter(point, marker)
      }

      markerResults.push(checkResult.passed)
      markerDebugInfo.push(checkResult.debugInfo)
    })

    if (markers.length > 0 && !markerResults.includes(true)) {
      return false
    }

    // 検索条件の表示条件を満たすか確認
    const searchConditions = _searchConditions ? _searchConditions : Filters.findByIds(
      Filters.TYPE_SEARCH_CONDITIONS,
      Filters.selectedFilters.search_conditions,
      true
    )

    if (searchConditions.length == 0
      || (
        Filters.isAllSelected(Filters.TYPE_SEARCH_CONDITIONS)
        && storelocator.filters?.search_conditions?.compare === Filters.COMPARE_OR
      )
    ) {
      return true
    }

    // 現在選択中の検索条件との判定を親IDを参照し、グループごとににまとめる
    const checkResults: {
      [key: string]: boolean[]
    } = {}

    searchConditions.forEach(searchCondition => {
      const parent = Filters.findParentById(
        Filters.TYPE_SEARCH_CONDITIONS,
        searchCondition.id
      )

      const parentId = parent ? parent.id : "root"
      if (!checkResults[parentId]) {
        checkResults[parentId] = []
      }

      checkResults[parentId].push(Filters.checkPointByFilter(point, searchCondition).passed)
    })

    // グループ内での判定を行いつつ、グループ間での判定（AND, OR）ごとにまとめる
    const groupResults: { AND: boolean[], OR: boolean[] } = {
      AND: [],
      OR: [],
    }

    // console.debug('checkPointByFilters', point.name, Filters.comparison, storelocator.filters?.comparison?.enabled)

    const useComparison = storelocator.filters?.comparison?.enabled

    Object.keys(checkResults).forEach(id => {
      let result = true
      const childComparesion = useComparison
        ? Filters.comparison.default?.child
        : storelocator.filters?.search_conditions?.compare

      const parentComparesion = useComparison
        ? Filters.comparison.default?.parent
        : storelocator.filters?.search_conditions?.compare

      if (childComparesion === Filters.COMPARE_AND && checkResults[id].includes(false)) {
        result = false
      } else if (childComparesion === Filters.COMPARE_OR && !checkResults[id].includes(true)) {
        result = false
      }
  
      if (parentComparesion === Filters.COMPARE_AND) {
        groupResults.AND.push(result)
      } else {
        groupResults.OR.push(result)
      }
    })

    // グループ間で判定
    const andResult = groupResults.AND.length == 0
      ? true
      : !groupResults.AND.includes(false)

    const orResult = groupResults.OR.length == 0
      ? true
      : groupResults.OR.includes(true)

    return andResult && orResult
  }

  /**
   * 初期化時に適用するフィルタを取得
   * 
   * キャッシュに依存、反映の必要がないと判断したらキャッシュを消す
   * TODO ここで消すのは乱暴、ページ内で複数呼ぶこともあり、どこかに移したい
   * 
   * @params type Filters.TYPE_...
   * @returns 設定・もしくはキャッシュから復元したFilter.id
   */
  static getInitialFilterIds(type: string): string[] {
    // console.debug('getInitialFilterIds', type)

    const filtersCache = Filters.cache.load()

    // ドメインが一致 + 別ページからきたら復元
    // 同一ページならリロード・リセット目的の可能性が高い
    // 別ドメインからきたら時間がたっていて復元の必要がない可能性が高い
    const needApplyCache = filtersCache?.location?.href !== location.href
      && filtersCache?.location?.host === location.host

    // console.debug('getInitialFilterIds', needApplyCache, filtersCache?.location?.href, location.href)

    const filterIds: string[] = type === Filters.TYPE_MARKERS
      ? filtersCache?.markers
      : filtersCache?.search_conditions

    let applyFilterIds = []
    if (needApplyCache && filtersCache && filterIds && filterIds.length > 0) {
      // sessionStrageにキャッシュがあったらそちらを優先
      applyFilterIds = filterIds
    } else {
      // Urlのパラメータ
      applyFilterIds = type === Filters.TYPE_MARKERS
        ? storelocator.selected_markers
        : storelocator.selected_search_conditions
    }

    // 引き繋がないことを決めた時点でキャッシュは消す
    if (!needApplyCache) {
      Filters.cache.reset()
    }

    return applyFilterIds
  }

  /**
   * 初期化
   * 
   * 選択中の絞り込み、テキスト検索クエリいずれかのみリセットしたいケースがあるので
   * リセットするものを明示的に指定可能
   * 
   * @params selectedFilters Boolean trueを指定すると選択中の絞り込みをリセット
   * @params query Boolean trueを指定するとテキスト検索クエリをリセット
   */
  static reset(selectedFilters: boolean = true, query: boolean = true ) {
    if (selectedFilters) {
      Filters.selectedFilters.reset()
    }

    if (query) {
      Filters.query.reset()
    }
    
    storelocator.analytics.client?.send('ResetFilter')
  }
}
const listners = []
let last_hash = null
let hashchange_disabled = null

export default {
  init() {
    window.onhashchange = onhashchange
  },
  get() {
    return window.location.hash.substring(1)
  },
  set(hash, no_fire) {
    window.location.hash = hash
    last_hash = hash
    if (no_fire) {
      hashchange_disabled = true
    }
  },
  clear(no_fire) {
    this.set('', no_fire)
  },
  replace(hash) {
    window.history.replaceState(null, null, '#' + hash)
  },
  fire(listner) {
    const hash = window.location.hash.substring(1)
    listner(hash)
    hashchange_disabled = false
    last_hash = hash
  },
  addListener(listner) {
    listners.push(listner)
  }
}

function onhashchange() {
  const hash = window.location.hash.substring(1)

  if (hashchange_disabled && hash == last_hash) {
    hashchange_disabled = false
  } else {
    listners.forEach(function (f) {
      f(hash)
    })
    last_hash = hash
  }
}

<template>
  <div id="priority-points" ref="sliderRef" uk-slider>
    <div class="uk-position-relative uk-light uk-visible-toggle" tabindex="-1">
      <div class="uk-slider-items">
        <div
          v-for="point in priorityPointsInSlider"
          :key="point.key"
          class="priority-point-wrapper"
        >
          <div
            class="priority-point uk-box-shadow-small uk-box-shadow-hover-medium"
            :style="{ backgroundImage: `url(${getImageUrl(point)})` }"
            @click="onPointSelected(point)"
            v-wave
          >
            <div class="content-wrapper">
              <div class="content">
                <h3 class="title">{{ point.name }}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <a
        class="point-slide-nav left uk-position-center-left"
        href
        uk-slidenav-previous
        uk-slider-item="previous"
      ></a>

      <a
        class="point-slide-nav right uk-position-center-right"
        href
        uk-slidenav-next
        uk-slider-item="next"
      ></a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useStore } from 'vuex'

import { computed, ComputedRef, onBeforeUnmount, onMounted, ref, watch } from 'vue'

import { _debounce, enhancedEncodeURI, sortByDistance } from '@/utils/common'
import MapService from '@/services/MapService.ts'
import PointService from '@/services/PointService.ts'

const store = useStore()

interface Props {
  options: PriorityPointsConfig
}

const props = defineProps<Props>()

/**
 * フィールド設定
 */
const fieldSettings = storelocator.fields

/**
 * オプションの値を取得・デフォルト値の設定
 */
const DEFAULT_WIDTH = 180
const DEFAULT_MAX_TOTAL_COUNT = 10
const DEFAULT_MAX_DISPLAY_COUNT = 6

// 幅を元に縦横比2:3で高さを設定
const width = computed(() => props.options.point.width || DEFAULT_WIDTH)
const maxTotalCount = computed(() => props.options.total_count || DEFAULT_MAX_TOTAL_COUNT)
const maxDisplayCount = computed(() => props.options.display_count || DEFAULT_MAX_DISPLAY_COUNT)

/**
 * 画像用のフィールドキーを返す
 * conditionsの中で画像用のフィールドを探し、そのkeyを返す
 * conditions内にimage型のフィールドは一つのだけと想定している
 *
 * @return Map.fieldsのkey
 */
const imageFieldKey: ComputedRef<string> = computed(() => {
  const field = props.options.conditions.find((condition) => {
    return getFieldType(condition.key) === 'image'
  })

  return field.key
})

/**
 * マップの表示領域内のポイント
 */
const pointsInBounds: ComputedRef<Array<Point>> = computed(() => store.state.points.pointsInBounds)

/**
 * 表示領域内の優先ポイント
 */
const priorityPointsInBounds = ref<Array<Point>>([])

/**
 * スライドに含める優先ポイント
 */
const priorityPointsInSlider = ref<Array<Point>>([])

/**
 * 表示領域内の優先ポイントの数
 */
const priorityPointsCountInBounds = computed(() => {
  return priorityPointsInBounds.value.length
})

/**
 * 優先表示の件数を開放（領域全体の表示判定に必要）
 */
defineExpose({
  priorityPointsInBounds
})

/**
 * スライダー要素のref
 */
const sliderRef = ref(null)

/**
 * pointWidthPxを取得
 */
function getPointWidth() {
  if (window.innerWidth <= storelocator.breakpoints.small && priorityPointsCountInBounds.value === 1) {
    const container = document.getElementById('map_canvas')

    const _width = container.offsetWidth - 16
    return `${_width}px`
  }

  return `${width.value}px`
}

/**
 * pointWidthPxを更新
 */
function updatePointWidth() {
  pointWidthPx.value = getPointWidth()
}

/**
 * 1コンテンツの幅(px)
 */
const pointWidthPx = ref(getPointWidth())

/**
 * sliderRefの親要素の幅
 * 本コンポーネントの親要素の幅に該当し、表示可能上限に相当
 * リサイズ時に更新される
 */
const sliderParentWidth = ref(0)

/**
 * スライダー要素の幅を返す
 * 本コンポーネントの要素幅に相当
 * Pointの表示数によって変動させ、Point要素の途中切断の防止、ナビゲーションボタンの位置調整を行う
 */
const sliderWidthPx = computed(() => {
  if (displayCount.value === 1) {
    return `100%`
  }

  // Pointの表示数で最も制限が厳しいものを採用
  const count = Math.min(displayCount.value, totalCount.value)
  const padding = 16 // 16pxはpaddingの合計
  const adjustment = 2 // 幅がぴったりだと余分なスライダー表示がされるため調整

  return `${(width.value + padding) * count + adjustment}px`
})

/**
 * Pointの表示可能数
 * 親要素の幅とoptionsで指定された最大表示数を考慮して計算
 */
const displayCount = computed(() => {
  // 親要素の幅に収まるPoint数を計算、1以下の場合は1Point表示
  const padding = 16 // 16pxはpaddingの合計
  const pointWidth = width.value + padding
  const displayCount = Math.floor(sliderParentWidth.value / pointWidth) || 1

  return Math.min(maxDisplayCount.value, displayCount)
})

/**
 * スライダーに含めるPointの数
 * 取得されたPoint数とoptionsで指定された最大Point数の小さい方を返す
 */
const totalCount = computed(() => {
  return Math.min(maxTotalCount.value, priorityPointsCountInBounds.value)
})

/**
 * 優先ポイントの更新
 * 表示領域内の優先ポイント, スライダーに表示する優先ポイントの両方を更新
 * 優先ポイントの抽出 → スライダーに表示するポイントの選別
 */
function updatePriorityPoints() {
  // 優先条件に合致するポイントを抽出
  const filteredPointsInBounds = filterPriorityPoints(pointsInBounds.value)

  priorityPointsInBounds.value = filteredPointsInBounds

  // 表示領域内の優先ポイントからスライダーに含めるポイントを選別
  const pickedPointsInBounds = pickPriorityPoints(priorityPointsInBounds.value)

  priorityPointsInSlider.value = pickedPointsInBounds
}

/**
 * 優先条件に合致するポイントを抽出
 */
function filterPriorityPoints(points: Array<Point>): Array<Point> {
  return points.filter((point) => {
    return isMatchPriorityConditions(point)
  })
}

/**
 * スライドに含める優先ポイントを選別
 * 表示領域内の優先ポイントから更にポイントを選別
 * 上限はoptionで指定された数(total_count)
 */
function pickPriorityPoints(points: Array<Point>): Array<Point> {
  const originalPoints = [...points]

  // デフォルトはマップ中心から近い順に並び替える
  const sortedPoints = sortByDistanceFromCenter(originalPoints)
  // NOTE: 今後、表示の優先順位を変更する必要がある場合は、ここに処理を追加する

  return sortedPoints.slice(0, totalCount.value)
}

/**
 * フィールドキーからフィールド種類を取得する
 */
function getFieldType(fieldKey: string): FieldType | null {
  return fieldSettings[fieldKey]?.type || null
}

/**
 * ポイントの画像URLを取得
 */
function getImageUrl(point: Point): string {
  if (!props.options.point.image.enabled) return ''

  const key = imageFieldKey.value
  const imageURL = point.extra_fields[key]
  // cssパーサー用に ( ) を含めてエンコード
  const encodedURL = enhancedEncodeURI(imageURL)

  return encodedURL
}

/**
 * ポイントが優先条件に一致するかを判定
 */
function isMatchPriorityConditions(point: Point): boolean {
  // 各条件の判定結果を格納
  const passedOrNot: Array<boolean> = []

  let conditions = []

  // 画像が無効な場合は、条件から画像フィールドを除く
  // NOTE: 画像有効 且つ 画像が無いポイントも優先ポイントとして判定するためにはロジックの変更が必要
  if (props.options.point.image.enabled) {
    conditions = props.options.conditions
  } else {
    conditions = props.options.conditions.filter((condition) => {
      return condition.key !== imageFieldKey.value
    })
  }

  // 条件がない場合はfalseを返す
  if (conditions.length === 0) return false

  // TODO: 直近用に追加フィールド内の画像フィールドないのデータの存在を確認する程度に限定
  conditions.forEach((condition) => {
    // TODO: 共通化の余地あり(format.ts, Filters.vue) ここから
    let originalValue: string = point.extra_fields[condition.key]
    let conditionValue: string = condition.value

    // null, undefinedは空文字に変換
    if (originalValue === null || originalValue === undefined) {
      originalValue = ''
    }

    if (conditionValue === null || conditionValue === undefined) {
      conditionValue = ''
    }

    // TODO: 他の条件に対応する処理を追加する必要がある
    if (condition.comparison === '!=') {
      passedOrNot.push(originalValue !== conditionValue)
    }
    // TODO: 共通化の余地あり(format.ts, Filters.vue) ここまで
  })

  // NOTE: 直近では画像の有無を条件にしているため、切り替えは不要だが、条件が増えた場合は必要
  // 未設定の場合はデフォルトとし'AND'とする
  const operation = props.options.operation || 'AND'

  // AND: 全て満たす / OR: いずれか満たす
  if (operation === 'AND') {
    return passedOrNot.every((v) => v)
  } else if (operation === 'OR') {
    return passedOrNot.some((v) => v)
  }
}

/**
 * ポイントをマップ中心から近い順に並び替える
 */
function sortByDistanceFromCenter(points: Array<Point>): Array<Point> {
  const centerLatLng: google.maps.LatLng | null = MapService.map?.getCenter() || null

  // マップが初期化されていない場合はそのまま返す
  if (!centerLatLng) return points

  return sortByDistance(centerLatLng, points)
}

/**
 * ポイントが選択された時の処理
 * 選択したポイントのウィンドウを開く
 */
function onPointSelected(point: Point) {
  MapService.pointManager.clearPoint()

  MapService.pointManager.selectPoint(point.id)
  store.dispatch("selectPoint", point)

  PointService.showPoint(point.position)

  // 分析用のイベントを送信
  storelocator.analytics.client?.send('SelectPoint', {
    PointName: point.name,
    PointKey: point.key,
    PointAddress: point.address,
    ZoomLevel: MapService.map.getZoom()
  })
}

/**
 * スライダーの親要素の幅を取得する
 * 画面のリサイズ時に呼び出される
 */
function updateSliderParentWidth() {
  if (!sliderRef.value) return

  setTimeout(() => {
    sliderParentWidth.value = sliderRef.value.parentElement?.clientWidth
  })
}

/**
 * マップの表示領域内のポイントの変更を監視
 * 表示領域内のポイントが変更されたら、優先ポイントを更新
 * debounceを使用して、連続で更新されるのを防ぐ
 */
watch(pointsInBounds, _debounce(updatePriorityPoints, 500))

onMounted(() => {
  // 表示幅に依存する値の初期化とイベントリスナーの登録
  setTimeout(() => {
    updateSliderParentWidth()
    updatePointWidth()
  }, 1500) // TODO 初期化が間に合っていないことがある、より確実にしたい

  // リサイズ時にスライダーの幅を更新、更新回数を制限するために_debounceを使用
  window.addEventListener('resize', _debounce(() => {
    updateSliderParentWidth()
    updatePointWidth()
  }, 150))
})

/**
 * イベントリスナーの削除
 */
onBeforeUnmount(() => {
  window.removeEventListener('resize', updateSliderParentWidth)
})
</script>

<style scoped lang="scss">

@keyframes point-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#priority-points {
  // 要素数に応じて幅を変更し、ナビゲーションボタンの位置を調整する
  width: v-bind(sliderWidthPx);
  transition: all 0.5s;

  // PC(default)
  .priority-point-wrapper {
    padding: 0.5rem 0.25rem;

    .priority-point {
      display: block;
      background-color: #fff;
      width: v-bind(pointWidthPx);
      aspect-ratio: 3 / 2;
      height: calc(169px - 1rem);
      border-radius: 4px;
      background-size: cover;
      background-position: center;
      box-sizing: border-box;
      cursor: pointer;
      animation: point-fade-in 0.5s ease;

      &:hover .content-wrapper .content {
        background-color: var(--theme-color);

        .title {
          color: var(--text-color);
        }
      }
    }
  }

  .content-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    font-size: 10px;

    .content {
      background-color: rgba(255, 255, 255, 0.8);
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      padding: 0.5em 1em;
      transition: background-color 0.5s, color 0.5s;

      .title {
        margin: 0;
        text-align: center;
        color: #444;
        font-size: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  // tablet
  @media only screen and (max-width: $breakpoint-medium) {}

  // SP
  @media only screen and (max-width: $breakpoint-small) {
    .point-slide-nav {
      margin: 0 1rem !important;
    }
  }

  .point-slide-nav {
    width: 32px;
    height: 32px;
    margin: 0 1rem;
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #444;

    &:hover {
      background-color: rgba(255, 255, 255, 0.8);
      color: #222;
    }

    :deep(svg) {
      height: 16px;
    }

    &.right {
      :deep(svg) {
        transform: translateX(1px);
      }
    }
    &.left {
      :deep(svg) {
        transform: translateX(-1px);
      }
    }
  }
}
</style>
